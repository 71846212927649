import httpAdapter, { _apiUrlFactory } from '../../../_config/api-provider';
import { EntityId, Update } from '@reduxjs/toolkit';
import { BonLivraison, BonFilters, BonEntity, BonApi } from '../../Domain/Bons/entities';
import { _getQueryParamsToUrl } from './bon-api.utils';
import { _fromEntity, _mapToEntity } from './_mappers/bon';


const httpService = httpAdapter();

const prefix = 'bon';
const apiBonUrl = _apiUrlFactory(prefix);
const apiPlanningUrl = _apiUrlFactory('planning');

export interface GetArgs {
    filters: BonFilters;
    fromDate: string;
    toDate: string;
    query?: string;
}

export const all = async () => {
    return await httpService.get(apiBonUrl(''));
};

export const add = async (bon: BonEntity): Promise<Partial<BonEntity>> => {
    const bonApi = _fromEntity(bon);
    return await httpService.post(apiBonUrl(''), bonApi);
}

export const get = async ({ query, ...params }: GetArgs) => {
    const searchParams = _getQueryParamsToUrl(params);
    const bons = await httpService.get(apiBonUrl(query ?? '') + searchParams);
    return bons.map((bon: BonApi) => _mapToEntity(bon));
};

export const update = async (bon: Update<BonEntity>): Promise<BonEntity> => {
    const { id, changes } = bon;
    return await httpService.put(apiPlanningUrl(`${id}`), changes);
};

export const updateMany = async (bons: Update<Partial<BonEntity>>) => {
    return await httpService.put(apiBonUrl(''), bons);
}

export const archive = async (ids: EntityId[]) => {
    return await httpService.delete(apiPlanningUrl(''), { data: { ids } });
};

export const attach = async (props: { id: EntityId, file: File }) => {
    const { id, file } = props;
    const formData = new FormData();
    formData.append('document', file);

    return await httpService.post(apiBonUrl(`${id}/attach-file`), formData);
}

export const updateCommentsOfBon = async (id: string, comments: { evenement: string, commentaire_interne: string, consignes_chauffeur: string }) => {
    return httpService.put(apiPlanningUrl(`${id}/bon/consignes`), {
        consignes_chauffeur: comments.consignes_chauffeur,
        commentaire_interne: comments.commentaire_interne,
        evenement: comments.evenement
    });
}

export const getAttachment = async (props: { bonId: EntityId, fileId: EntityId }) => {
    const { bonId, fileId } = props;
    return await httpService.get(apiBonUrl(`${bonId}/attach-file/${fileId}`), { responseType: 'blob' });
}

export const confirmBonManually = async ({ bonId, ...values }: BonLivraison & { bonId: EntityId }) => {
    // create form data
    // const { bl, ...dto } = values;
    const formData: any = new FormData();
    for (const prop in values) {
        formData.append(prop, values[prop as keyof BonLivraison]);
    }
    return httpService.put(apiBonUrl(`${bonId}/validate-procedure`), formData);
}

export const getBonById = async (id: string): Promise<BonApi> => {
    return httpService.get(apiBonUrl(id));
}

export const startProcedure = async (id: string, bonDetails: { fraisAutoroute: number, observation: string, immatriculation: string }) => {
    return httpService.post(apiBonUrl(`${id}/procedure/start`), bonDetails);
}

export const getBonLivraisonFileApi = async (bonId: EntityId) => {
    return httpService.get(apiBonUrl(`${bonId}/BL`), { responseType: 'blob' });
}

export const justifyTollCharges = async (id: string, frais: number) => {
    return httpService.put(apiBonUrl(`${id}/frais-autoroute/validation`), { fraisAutoroute: frais })
}


