import { ChangeEvent, useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { setSuccess } from '../../../../App/core/notification/notification.slice';

import { updateBonsRefs } from '../../../Domain/Bons/use-cases';

interface Props {
    ids: EntityId[];
    open: boolean;

    onClose(): void;
}

interface RefDetail {
    referenceClient?: string;
    bonNumber?: string;
}

function BonEditionDialog({ ids, open, onClose }: Props) {
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const dispatch = useAppDispatch();

    const [refDetails, setRefDetails] = useState<RefDetail>({
        referenceClient: '',
        bonNumber: '',
    });

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target;
        setRefDetails({ ...refDetails, [name]: value });
    };

    const onConfirmEditionDialog = async () => {
        setIsButtonLoading(true);

        const res: any = await dispatch(updateBonsRefs({ ids, refDetails }));

        if ( !res.error ) {
            dispatch(setSuccess(`Les données ont bien été mis à jour`));
            onClose();
        }

        setIsButtonLoading(false);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Editer</DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={2}>
                    <TextField
                        label="N° Bon"
                        variant="standard"
                        name="bonNumber"
                        value={refDetails.bonNumber}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Ref. Client"
                        variant="standard"
                        name="referenceClient"
                        value={refDetails.referenceClient}
                        onChange={handleInputChange}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annuler</Button>
                <LoadingButton variant="contained" loading={isButtonLoading} onClick={onConfirmEditionDialog} autoFocus>
                    Enregistrer
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default BonEditionDialog;
