import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { retrieveAllEntreprises } from '../../../../core/entreprise/use-cases';
import { selectEntreprisesBySearch } from '../../../../core/entreprise/selectors';
import EntrepriseTableActionsCell from './EntrepriseTableActionsCell';
import { useNavigate } from 'react-router-dom';
import { CustomTable } from '../../../common/custom-table';
import { ColumnEntity } from '../../../common/custom-table/Table.entities';
import { EntrepriseEntity } from '../../../../core/entreprise/entities';

function EntrepriseTable({ onSelectRows }: any) {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useAppDispatch();
  const entreprises = useSelector(selectEntreprisesBySearch);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(retrieveAllEntreprises()).then(() => setIsLoading(false));

    return () => {
      setIsLoading(true);
    };
  }, [dispatch, setIsLoading]);

  const onRowDoubleClick = ({ id }: EntrepriseEntity) => navigate(`../${id}`, { state: id });

  const columns: ColumnEntity[] = [
    { field: 'name', headerName: 'Nom', width: 280, sortable: true, resizable: true },
    { field: 'code_fournisseur', headerName: 'Code Fourn.', width: 180, sortable: true, resizable: true },
    { field: 'code_client', headerName: 'Code Client', width: 180, sortable: true, resizable: true },
    { field: 'adresse.rue', headerName: 'Adresse', width: 300, sortable: true, resizable: true },
    { field: 'adresse.cp', headerName: 'Code Postal', width: 115, sortable: true, resizable: true },
    { field: 'adresse.ville', headerName: 'Ville', width: 160, sortable: true, resizable: true },
    { field: 'contact.email', headerName: 'Email', width: 180, sortable: true, resizable: true },
    { field: 'contact.telephone', headerName: 'Téléphone', width: 150, sortable: true, resizable: true },
  ];

  return (
    <>
      <CustomTable
        data={entreprises}
        columns={columns}
        loading={isLoading}
        hover={false}
        onRowDoubleClick={onRowDoubleClick}
        onSelectRows={onSelectRows}
        renderActionCell={(rowData, measuredRef) => (
          <EntrepriseTableActionsCell id={rowData.id} measuredRef={measuredRef} />
        )}
      />
    </>
  );
}

export default EntrepriseTable;
