import { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface Props {
    open: boolean;
    title: string;
    contentText: string;
    isButtonLoading: boolean;
    factureDetails?: { aClientName?: string, aFactureNumber?: string, aReceiverEmail: string }

    onClose(event: React.MouseEvent): void;

    onConfirm(event: React.MouseEvent | string): void;

}

function EmitFactureDialog(
    {
        open,
        title,
        contentText,
        isButtonLoading,
        onClose,
        onConfirm,
        factureDetails
    }: Props) {

    const [email, setEmail] = useState('')

    useEffect(() => {
        setEmail(factureDetails?.aReceiverEmail ?? "")
    }, [factureDetails?.aReceiverEmail])

    const onConfirmAction = () => onConfirm(email)

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{contentText}</DialogContentText>
                <br/>
                <TextField
                    fullWidth
                    label="email"

                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annuler</Button>
                <LoadingButton variant="contained" loading={isButtonLoading} onClick={onConfirmAction} autoFocus>
                    Confirmer
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
        ;
}

export default EmitFactureDialog;
