import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { retrieveAllRelances } from '../../../Domain/Application/use-cases/retrieve-all-relances';
import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { selectAllRelances } from '../../../Domain/Application/selectors';
import { FactureRelanceTable } from '../relance/FactureRelanceTable';

interface Props {
  open: boolean;
  onClose(): void;
}

const FactureRelanceDialog = ({ open, onClose }: Props) => {
  const [loading, setLoading] = useState(true);

  const dispatch = useAppDispatch();
  const relances = useSelector(selectAllRelances);

  useEffect(() => {
    dispatch(retrieveAllRelances()).then(() => setLoading(false));

    return () => {
      setLoading(true);
    };
  }, [dispatch]);

  return (
    <Dialog open={open} fullWidth maxWidth={'md'}>
      <DialogTitle>Liste des relances</DialogTitle>
      <DialogContent>
        <Box>
          <FactureRelanceTable relances={relances} loading={loading} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fermer</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FactureRelanceDialog;
