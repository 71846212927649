import { ListItemButton, ListItemButtonProps, ListItemIcon, ListItemIconProps, styled } from '@mui/material';

type CustomListItemButtonProps = ListItemButtonProps & { component?: any };

export const StyledItemIcon = styled(ListItemIcon)<ListItemIconProps>(({ theme }) => ({
  minWidth: '0',
  marginLeft: '-5.5px',
  color: '#eee',
}));

export const StyledListItemButton = styled(ListItemButton)<CustomListItemButtonProps>(({ theme }) => ({
  minHeight: 48,
  borderRadius: 10,
  color: '#eee',
  '&:hover, &.Mui-selected:hover, &.Mui-selected': {
    backgroundColor: '#eee',
    color: '#666767',
  },
  '&:hover > .MuiListItemIcon-root, &.Mui-selected > .MuiListItemIcon-root': {
    color: '#666767',
  },
}));
