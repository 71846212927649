// type BonQueryDateType = | "fromDate" | "toDate";
// type BonQueryArgsType = | "filters" | "fromDate" | "toDate" | 'query'
type BonFiltersType =
    | "fournisseur"
    | "chantier"
    | "client"
    | "interlocuteurClient"
    | "article"
    | "status"

type BonFilters = {
    [key in BonFiltersType]?: string | string[];
} | null;

export interface GetArgs {
    filters: BonFilters;
    fromDate: string;
    toDate: string;
    query?: string;
}

export const _getQueryParamsToUrl = ({ filters, fromDate, toDate }: GetArgs) => {
    const _querySearchParamUrl = new URLSearchParams();
    if (filters) {
        Object.keys(filters).forEach((key) => {
            const currentFilter = filters[key as unknown as BonFiltersType];
            if (currentFilter && currentFilter.length) {
                Array.isArray(currentFilter) ?
                    currentFilter.forEach(id => _querySearchParamUrl.append(key, id))
                    : _querySearchParamUrl.append(key, currentFilter);
            }
        })
    }
    if (fromDate) _querySearchParamUrl.append('from', fromDate);
    if (toDate) _querySearchParamUrl.append('to', toDate);

    const urlSearchParamsHasAtLeastOneParam = _querySearchParamUrl.toString().length > 0;
    return urlSearchParamsHasAtLeastOneParam ? `?${_querySearchParamUrl.toString()}` : '';
}


