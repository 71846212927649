import React from 'react';
import { StyledCell } from './StyledCell';

export const CustomCell = React.forwardRef((props: any, ref: any) => {
  const {
    children,
    rowData,
    field,
    onDoubleClick,
    onPointerEnter,
    onPointerLeave,
    rowIndex,
    checkedKeys,
    coloredRow,
    ...rest
  } = props;

  return (
    <StyledCell
      ref={ref}
      rowData={rowData}
      onDoubleClick={() => onDoubleClick(rowData)}
      onPointerEnter={(event: any) => {
        onPointerEnter(event, rowData, field);
      }}
      onPointerLeave={(event: any) => {
        onPointerLeave(event);
      }}
      {...rest}
      checkedKeys={checkedKeys}
      coloredRow={coloredRow}
      rowIndex={rowIndex}
    >
      {children}
    </StyledCell>
  );
});
