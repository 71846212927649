import { IAdresse } from "../../../../App/core/entreprise/entities/adresse.entity";
import { Box, Divider, IconButton, ListItemText, Stack, Tooltip } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import React from "react";

export function AdresseFacturationItem(props: { uneAdresse: IAdresse, onClick: () => void }) {
    return <>
        <Divider/>
        <Stack direction="row" alignItems="center" justifyContent="space-between" m={1}>
            <Box>
                <ListItemText primary={`${props.uneAdresse.rue ?? ""} ${props.uneAdresse.ville.toUpperCase()}`}/>
                <ListItemText secondary={props.uneAdresse.cp ?? ""}/>
            </Box>
            <Stack direction="row">
                <Tooltip title="Éditer">
                    <IconButton color="primary" size="medium"
                                disabled
                                onClick={props.onClick}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer">
                    <IconButton size="medium"
                                disabled
                                onClick={props.onClick}>
                        <Delete color="error" sx={{ fontSize: 24 }}/>
                    </IconButton>
                </Tooltip>
            </Stack>
        </Stack>
    </>;
}