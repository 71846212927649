import { createAsyncThunk } from "@reduxjs/toolkit";

import { User } from "../entities/user.entity";

export const addOneUser = createAsyncThunk(
  "[USER] addOne",
  async (user: Partial<User> & { interlocuteurId?: string }, thunk: { dispatch: any, rejectWithValue: any, extra: any }) => {
    try {
        return await thunk.extra.userQuery.add(user);
    } catch (error: any) {
      return thunk.rejectWithValue({ message: error.message ?? null });
   }
  }
)