import React from 'react';
import { Toolbar } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

export const CustomTableToolbar = ({ children }: Props) => {
  return (
    <Toolbar
      sx={(theme) => ({
        [theme.breakpoints.down('lg')]: { flexDirection: 'column', gap: 2, alignItems: 'start' },
        py: 2,
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      })}
    >
      {children}
    </Toolbar>
  );
};
