import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import { BonEntity as Bon } from '../entities/bon.entity';

export const updateOneBon = createAsyncThunk(
	'[BON] updateOneBon',
	async (bonDto: Update<Bon>, thunk: any): Promise<Update<Bon>> => {
		try {
			const { ...bon } = bonDto;
			return await thunk.extra.bonQuery.update(bon);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);