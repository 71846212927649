import React, { useEffect, useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { Link } from 'react-router-dom';

import { Button, InputAdornment, Stack, TextField } from '@mui/material';
import { Add, Archive, Search } from '@mui/icons-material';

import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { updateSearch } from '../../../../core/entreprise/entreprise.slice';
import ConfirmationDialog from '../../../common/modals/components/ConfirmationDialog';
import { archiveEntreprises, retrieveAllEntreprises } from '../../../../core/entreprise/use-cases';
import { setSuccess } from '../../../../core/notification/notification.slice';
import { CustomTableToolbar } from '../../../common/custom-table';
import DropDownButton from '../../../common/DropDownButton';

interface Props {
  selectedRows: EntityId[];
}

const labels = [
  { label: 'Toutes entreprises', value: '' },
  { label: 'Clients', value: 'client' },
  { label: 'Fournisseurs', value: 'fournisseur' },
];

function EntrepriseTableToolbar({ selectedRows }: Props) {
  const [search, setSearch] = useState('');
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [archiveButtonLoading, setArchiveButtonLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateSearch(search));
  }, [dispatch, search]);

  const handleArchiveDialog = () => setArchiveDialogOpen(!archiveDialogOpen);

  const onConfirmArchive = async () => {
    setArchiveButtonLoading(true);

    const res: any = await dispatch(archiveEntreprises(selectedRows));

    if (!res.error) {
      dispatch(setSuccess('Les données ont bien été archivées'));
      setArchiveDialogOpen(false);
    }

    setArchiveButtonLoading(false);
  };

  const onChangeType = (type?: any) => dispatch(retrieveAllEntreprises(type));

  return (
    <>
      <CustomTableToolbar>
        <Stack direction="row" alignItems="flex-end" spacing={2}>
          <TextField
            sx={{ width: 300 }}
            onChange={(event) => {
              const { value } = event.target;
              setSearch(value);
            }}
            label="Rechercher..."
            variant="outlined"
            size="small"
            InputProps={{
              type: 'search',
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />

          <DropDownButton labels={labels} onChange={onChangeType} />

          <Button
            variant="contained"
            size="small"
            color="warning"
            disabled={selectedRows.length < 1}
            onClick={handleArchiveDialog}
            startIcon={<Archive />}
          >
            Archiver
          </Button>
        </Stack>
        <Stack direction="row" spacing={4} alignItems="end">
          <Button component={Link} to="create" variant="contained" color="secondary" startIcon={<Add />}>
            Nouvelle Entreprise
          </Button>
        </Stack>
      </CustomTableToolbar>

      {/* Dialogs */}
      <ConfirmationDialog
        contentText="Etes-vous sûr de vouloir archiver ces données ?"
        title="Archiver"
        open={archiveDialogOpen}
        isButtonLoading={archiveButtonLoading}
        onClose={handleArchiveDialog}
        onConfirm={onConfirmArchive}
      />
    </>
  );
}

export default EntrepriseTableToolbar;
