import httpService from "../../../../_config/axios";
import { IAddReglementUseCasePayload, IEntrepriseService } from "../../../Domain/Ports/IEntreprise.service";
import { EntrepriseReglements } from "../../../Domain/Model/Reglement";
import { IAdresse } from "../../../../App/core/entreprise/entities/adresse.entity";

export class EntrepriseService implements IEntrepriseService {
    private readonly resourceUri = 'entreprise';

    async addReglement(entrepriseId: string, payload: IAddReglementUseCasePayload): Promise<{ entrepriseId: string, reglements: EntrepriseReglements }> {
        const result = await httpService.post(`${this.resourceUri}/${entrepriseId}/reglement`, {
            categoryId: payload.categoryId,
            paymentDeadline: payload.paymentDeadline,
            type: payload.type
        });

        return {
            entrepriseId: entrepriseId,
            reglements: result.data.reglements
        };
    }

    async addAdresseFacturation(entrepriseId: string, adresseDeFacturation: IAdresse): Promise<{ adresseFacturation: IAdresse }> {
        const result = await httpService.post(`${this.resourceUri}/${entrepriseId}/facture/adresse`, adresseDeFacturation);
        return {
            adresseFacturation: result.data.adresseFacturation
        };
    }

}

