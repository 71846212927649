import React, { useEffect, useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { Link } from 'react-router-dom';

import { Button, InputAdornment, Stack, TextField } from '@mui/material';
import { Add, PersonAdd, PersonOff, Search } from '@mui/icons-material';

import { updateFilter, updateSearch } from '../../../../core/user';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { setSuccess } from '../../../../core/notification/notification.slice';
import ConfirmationDialog from '../../../common/modals/components/ConfirmationDialog';
import { deactivateUsers, retrieveAllUsers } from '../../../../core/user/use-cases';
import { CustomTableToolbar } from '../../../common/custom-table';
import DropDownButton from '../../../common/DropDownButton';
import { roles } from '../../../common/_helpers';
import { reactivateUser } from '../../../../core/user/use-cases/reactivate-user';

import {debounce} from 'lodash';

interface Props {
  selectedRows: EntityId[];
}

const labels = [
  { label: 'Tous les statuts', value: '' },
  { label: 'Actifs', value: 'active' },
  { label: 'Inactifs', value: 'inactive' },
];

function UserTableToolbar({ selectedRows }: Props) {
  const [search, setSearch] = useState('');
  const [deactivateDialogOpen, setDeactivateDialogOpen] = useState(false);
  const [isDeactivating, setIsDeactivating] = useState(false);
  const [selected, setSelected] = useState({
    status: 'active',
    role: '',
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateSearch(search));
    dispatch(retrieveAllUsers({ search, ...selected }));
  }, [dispatch, search, selected]);

  useEffect(() => {
    dispatch(updateFilter(selected));
  }, [dispatch, selected]);

  const handleDeactivateDialog = () => setDeactivateDialogOpen(!deactivateDialogOpen);

  const onClickDeactivate = async () => {
    setIsDeactivating(true);
    const res: any = await dispatch(deactivateUsers(selectedRows));

    if (!res.error) {
      setDeactivateDialogOpen(false);
      dispatch(setSuccess(`Les utilisateurs ont bien été désactivés`));
    }

    setIsDeactivating(false);
  };

  const onClickReactivate = async () => {
    await dispatch(reactivateUser(selectedRows));
    dispatch(setSuccess(`Les utilisateurs ont bien été résactivés`));

  }

  const onChangeFilters = (value: string, key: keyof typeof selected) => {
    setSelected({ ...selected, [key]: value });
  };

  return (
    <>
      <CustomTableToolbar>
        <Stack direction="row" alignItems="flex-end" spacing={2}>
          <TextField
            sx={{ width: 300 }}
            onChange={debounce((event) => {
              const { value } = event.target;
              setSearch(value);
            }, 350)
              }
            label="Rechercher..."
            variant="outlined"
            size="small"
            InputProps={{
              type: 'search',
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />

          <DropDownButton
            labels={[{ label: 'Tous les rôles', value: '' }, ...roles]}
            onChange={(v) => onChangeFilters(v, 'role')}
          />

          <DropDownButton labels={labels} onChange={(v) => onChangeFilters(v, 'status')} defaultValue="active" />

          {selected.status === 'inactive'
            ? <Button
              color="info"
              variant="contained"
              size="small"
              onClick={onClickReactivate}
              disabled={selectedRows.length < 1}
              startIcon={<PersonAdd />}
            > Réactiver </Button>
            : <Button
              color="error"
              variant="contained"
              size="small"
              onClick={onClickDeactivate}
              disabled={selectedRows.length < 1}
              startIcon={<PersonOff />}
            > Désactiver </Button>
          }
        </Stack>
        <Stack direction="row" alignItems="end">
          <Button component={Link} to="create" variant="contained" color="secondary" startIcon={<Add />}>
            Nouvel Utilisateur
          </Button>
        </Stack>
      </CustomTableToolbar>

      {/* Dialogs */}
      <ConfirmationDialog
        open={deactivateDialogOpen}
        title="Désactiver"
        contentText={`Êtes-vous sûr de vouloir désactiver ces utilisateurs ?`}
        isButtonLoading={isDeactivating}
        onClose={handleDeactivateDialog}
        onConfirm={onClickDeactivate}
      />
    </>
  );
}

export default UserTableToolbar;
