import { createAsyncThunk } from "@reduxjs/toolkit";

export const retrieveAllUsers = createAsyncThunk(
  '[USER] retrieveAllUsers',
  async (filter: { search?: string, status?: string, role?: string }, thunk) => {
    try {
      return await (thunk.extra as { userQuery: any }).userQuery.getAll(filter);
    } catch (error: any) {
      return thunk.rejectWithValue({ message: error.message ?? null });
    }
})