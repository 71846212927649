import { IChantierWithBons } from "../../Domain/Model/Bon";

export function useMergeChantiersWithBons(chantiers: IChantierWithBons, bonFactureByChantier: IChantierWithBons) {
    if ( !chantiers || !chantiers?.length ) return bonFactureByChantier;
    return chantiers?.reduce((allChantiers, currentChantier) => {
        const chantierExists = allChantiers.findIndex((aChantier) => aChantier.name === currentChantier.name);
        if ( chantierExists !== -1 ) {
            allChantiers[chantierExists].bons.push(...currentChantier.bons)
        } else {
            allChantiers.push(currentChantier)
        }
        return allChantiers;
    }, [...bonFactureByChantier])
}
