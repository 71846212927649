import { Facture } from "../../Domain/Model";
import { InvoiceLine } from "../../Domain/Model/entities/facture.entity";

export function useExtractBons(aFacture?: Facture) {
    if ( aFacture?.bons == null || !aFacture.bons?.length ) return [];
    return aFacture.bons.reduce<Array<{ id?: any, name: string, bons: any[] }>>((chantiers, currentBon) => {
        const chantierExists = chantiers.findIndex(aChantier => aChantier.name === currentBon.chantier);
        if ( chantierExists === -1 ) return [...chantiers, {
            id: currentBon.id,
            name: currentBon.chantier,
            bons: [invoiceLineToBillableBon(currentBon)]
        }]

        chantiers[chantierExists].bons.push(invoiceLineToBillableBon(currentBon));
        return chantiers;
    }, [])
}


function invoiceLineToBillableBon(anInvoiceLine: InvoiceLine) {
    return {
        prixUnitaire: anInvoiceLine.prixUnitaireNet,
        quantity: anInvoiceLine.quantite,
        montantHT: anInvoiceLine.montantHT,
        articleLabel: anInvoiceLine.article,
        unite: anInvoiceLine.unite,
        dateOfPrestation: anInvoiceLine.dateDePrestation,
        id: anInvoiceLine.bonId ?? anInvoiceLine.id,
        referenceClient: anInvoiceLine.referenceClient,
        numeroBon: anInvoiceLine.bonNumber,
        fournisseur: anInvoiceLine.fournisseur,
        fraisAutoroute: anInvoiceLine.fraisAutoroute ?? 0
    }
}
