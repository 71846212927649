import { createAsyncThunk } from "@reduxjs/toolkit";
import { BonDeCommandeRepository } from "../../../Ports/BonDeCommandeRepository";

interface extraService {
    editBonDeCommandeFile: BonDeCommandeRepository['editBonDeCommandeFile']
}

export const editBonDeCommandePdf = createAsyncThunk<{ file: Blob }, string, { extra: { bonDeCommandeService: extraService } }>(
    '[BonDeCommande] editBonDeCommandePdf',
    async (bonDeCommandeId: string, thunkApi) => {
        return thunkApi.extra.bonDeCommandeService.editBonDeCommandeFile(bonDeCommandeId);
    }
);
