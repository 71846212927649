import { Planning } from "../../../Domain/Planning/entities/planning.entity";
import { BonEntity } from "../../../Domain/Bons/entities";

export function useSummary(collection: Array<Planning | BonEntity>) {
    const totalHT = collection.reduce((acc, planning) => acc + planning.achatHT, 0);
    const montantHT = collection.reduce((acc, planning) => acc + planning.montantHT, 0);
    const marge = (montantHT - totalHT);

    function toEuroString(amount: number) {
        return new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(amount);
    }

    return {
        totalHT: toEuroString(totalHT),
        montantHT: toEuroString(montantHT),
        marge: toEuroString(marge),
    };
}
