import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Stack,
    Switch,
} from '@mui/material';

import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { setSuccess } from '../../../../App/core/notification/notification.slice';
// import { updateOnePlanning } from '../../../../App/core/planning/use-cases';
import { informEntreprises } from '../../../Domain/Planning/use-cases';

interface Props {
    open: boolean;

    onClose(): void;

    rowData: any;
}

export const PlanningInformedDialog = ({ open, onClose, rowData }: Props) => {
    const [hasBeenWarned, setHasBeenWarned] = useState({
        client: false,
        fournisseur: false,
    });

    const dispatch = useAppDispatch();

    useEffect(() => {
        if ( rowData ) {
            const { hasBeenWarned } = rowData;
            setHasBeenWarned(hasBeenWarned);
        }
    }, [rowData]);

    const onConfirm = async () => {
        const res: any = await dispatch(informEntreprises({ id: rowData.id, hasBeenWarned }));

        if ( !res.error ) {
            dispatch(setSuccess(`Enregistrement des entreprises prévenus effectué`));
            onClose();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;

        setHasBeenWarned({ ...hasBeenWarned, [name]: checked });
    };

    return (
        <Dialog open={open}>
            <DialogTitle>Entreprises prévenus</DialogTitle>
            <DialogContent>
                <Stack direction="column" gap={4} alignItems="start">
                    <FormControl component="fieldset">
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={hasBeenWarned.client} onChange={handleChange} name="client"/>}
                                label={'Client'}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                control={<Switch checked={hasBeenWarned.fournisseur} onChange={handleChange}
                                                 name="fournisseur"/>}
                                label={'Fournisseur'}
                                labelPlacement="start"
                            />
                        </FormGroup>
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Fermer</Button>
                <Button variant="contained" color="primary" onClick={onConfirm}>
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    );
};
