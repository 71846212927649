import httpAdapter, { _apiUrlFactory } from '../../../_config/api-provider';
import { ArticleEntity } from '../../core/article/entities/article.entity';

const httpService = httpAdapter();

const prefix = 'article';
const apiArticleUrl = _apiUrlFactory(prefix);

export const all = async (): Promise<ArticleEntity[]> => {
    return httpService.get(apiArticleUrl());
}


interface ArticleAddCommandDto {
    name: string
    prixHT: number
    prixVenteHT: number
    fournisseur: string
    famille: string
    description?: string
}

export const add = async (article: ArticleAddCommandDto): Promise<Partial<ArticleEntity>> => {
    return httpService.post(apiArticleUrl(''), article);
};

export const update = async (article: { id: string, changes: ArticleAddCommandDto }): Promise<ArticleEntity> => {
    return httpService.put(apiArticleUrl(article.id), article.changes);
}


