import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';

import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { bonSelector } from '../../../../Location/Domain/Bons';

// used to populate inputvalue after a object is created from a modal
function FormFieldAutocompleteContext() {
    const { setFieldValue }: FormikContextType<FormikValues> = useFormikContext();

    const createdFromModal: any = useSelector(bonSelector.selectCreatedFromModal);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if ( createdFromModal ) {
            setFieldValue(createdFromModal.entity, createdFromModal.id);
        }
    }, [createdFromModal, setFieldValue, dispatch]);

    return null;
}

export default FormFieldAutocompleteContext;
