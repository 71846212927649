import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';

interface Props {
    comments: string;
    open: boolean;
    onClose: any;
    onSave?:any;
    edit?: boolean;
}

export const CommentsDialog = ({ comments, open, onClose, onSave = null, edit = false }: Props) => {

    const commentsHtml = comments?.replace(/\n/g, "<br>") ?? "";
    const [commentaire, setCommentaire] = useState(comments);

    const handleChange = (event:any) => {
        setCommentaire(event.target.value)
    }
    const handleSave = () => {
        onSave(commentaire);
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs"  fullWidth>
            <DialogTitle id="comment-dialog-title">Commentaire interne</DialogTitle>
            {edit && 
            <TextField
                multiline
                rows={8}
                variant="outlined"
                label="Commentaire(s)"
                id="commentaire_interne"
                value={commentaire}
                onChange={handleChange}
                name="commentaire_interne"
                sx={{width:'400px', marginLeft:'20px'}}
            /> 
            }
            {!edit && <DialogContent dangerouslySetInnerHTML={{ __html: commentsHtml }}>

            </DialogContent> }
            <DialogActions>
                <Button onClick={onClose}>Fermer</Button>
                {edit && 
                    <Button onClick={handleSave}>Enregistrer</Button>
                }
            </DialogActions>
        </Dialog>
    );
};
