import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';
// import { getBonLivraisonFileApi } from '../../../infra/api/bon.api-service';

// export const getBonLivraisonFile = async (bonId: EntityId) => await getBonLivraisonFileApi(bonId);
export const getBonLivraisonFile =  (bonId: EntityId) => retreiveBLFile(bonId);

export const retreiveBLFile = createAsyncThunk<any, EntityId, { extra: { bonQuery: { getBonLivraisonFileApi: (id: EntityId) => Promise<any> } } }>(
    "[BONS] retreiveBonLivraison",
    async (id, thunkApi) => {
        return thunkApi.extra.bonQuery.getBonLivraisonFileApi(id);
    }
)