import React from 'react';
import { useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { AttachmentsDialog } from '../../../../App/app/common/modals/components/AttachmentsDialog';

import { setSuccess } from '../../../../App/core/notification/notification.slice';

import { selectBonById } from '../../../Domain/Bons/selectors';
import { AttachedDocs } from '../../../Domain/Bons/entities';
import { attachFileToBon, retreiveBonAttachment } from '../../../Domain/Bons/use-cases';

interface Props {
    id: EntityId;
    open: boolean;

    onClose(): void;
}

export default function BonAttachmentsDialog({ id, open, onClose }: Props) {
    const dispatch = useAppDispatch();

    const bon = useSelector(selectBonById(id));

    const handleDownload = async (doc: AttachedDocs) => {
        const res = await dispatch(retreiveBonAttachment({ bonId: id, fileId: doc._id })).unwrap();
        const fileURL = window.URL.createObjectURL(res);
        window.open(fileURL);
    };

    const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        if ( files && files.length > 0 ) {
            const res: any = await dispatch(attachFileToBon({ id, file: files[0] }));
            if ( !res.error ) {
                dispatch(setSuccess(`Le fichier ${res.payload.originalname} a bien été sauvegardé.`));
            }
        }
    };

    return (
        <AttachmentsDialog
            data={bon}
            open={open}
            onClose={onClose}
            handleDownload={handleDownload}
            handleUpload={handleUpload}
        />
    );
};
