import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { CategorieProduit } from './entities';
import { addOneCategorieProduit, retrieveAllCategorieProduit, updateOneCategorieProduit, deleteOneCategorieProduit } from './use-cases';

export const categorieProduitEntityAdapter = createEntityAdapter<CategorieProduit>({
	selectId: (categorieProduit) => categorieProduit._id
});

export const categorieProduitSlice = createSlice({
	name: 'categorieProduit',
	initialState: categorieProduitEntityAdapter.getInitialState({}),
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(retrieveAllCategorieProduit.fulfilled, (state, action) => {
				categorieProduitEntityAdapter.setAll(state, action.payload);
			})
			.addCase(addOneCategorieProduit.fulfilled, (state, action) => {
				categorieProduitEntityAdapter.addOne(state, action.payload);
			})
			.addCase(updateOneCategorieProduit.fulfilled, (state, action: any) => {
				const { _id, ...otherProps} = action.payload;
				categorieProduitEntityAdapter.updateOne(state, { id: _id, changes: otherProps});
			})
			.addCase(deleteOneCategorieProduit.fulfilled, (state, action: any) => {
				categorieProduitEntityAdapter.removeOne(state, action.payload._id);
			})
	}
});

export default categorieProduitSlice.reducer;