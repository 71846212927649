export abstract class PersistenceId {

    protected _id: string

    constructor(entityId: string) {
        /*console.log('construct ent'); 
        console.log(typeof entityId);
        console.log(entityId);*/
      
        this._id = this.init(entityId);
    }

    protected abstract get PREFIX_ID(): string

    private init(entityId: any) {
  
   
        return entityId?.startsWith(this.PREFIX_ID)
            ? entityId?.substring(this.PREFIX_ID.length)
            : entityId;
    }

    protected get stringId(): string {
        return this.PREFIX_ID + this._id;
    }

    toString(): string {
        return this.stringId;
    }

    toEntityId(): string {
        return this.stringId;
    }

    toPersistenceId(): string {
        return this._id.toString();
    }
}