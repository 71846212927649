import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { EntityId } from "@reduxjs/toolkit";
import { List, ListSubheader, Stack, Typography } from "@mui/material";

import { AdresseFacturationListe } from "../components/adresse-de-facturation/AdresseFacturationListe";

import PageHeaderBar from "../../../App/app/common/layout/components/PageHeaderBar";
import { ViewCard } from "../../../App/app/common/layout/components";
import { selectEntrepriseById } from "../../../App/core/entreprise/selectors";
import { AddAdresseFacturation } from "../components/adresse-de-facturation/AddAdresseFacturation";
import useDocumentTitle from "../../../_config/react/useDocumentTitle";

export function ManageAdresseFacturation() {
    useDocumentTitle('MTTP - Adresse facturation');
    const { id: entrepriseId } = useParams();
    const uneEntreprise = useSelector(selectEntrepriseById(entrepriseId as EntityId));
    if ( !uneEntreprise ) throw new Error('une entreprise doit etre sélectionnée');

    return <>
        <PageHeaderBar title={"Gestion des adresses de facturation"}/>
        <ViewCard>
            <List sx={{ px: 4, py: 2 }} subheader={
                <ListSubheader>
                    <Stack direction="row" justifyContent="space-between" my={1}>
                        <Typography variant="h6">Adresses de facturation</Typography>
                        <AddAdresseFacturation entrepriseId={uneEntreprise.id}/>
                    </Stack>
                </ListSubheader>
            }>
                <AdresseFacturationListe uneEntreprise={uneEntreprise}/>
            </List>
        </ViewCard>
    </>
}