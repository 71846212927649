import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { ribType } from './entities';
import { addOneribType, deleteOneribType, retrieveAllribType, updateOneribType } from './use-cases';

export const ribTypeEntityAdapter = createEntityAdapter<ribType>({
	selectId: (ribType) => ribType._id
});

export const ribTypeSlice = createSlice({
	name: 'ribType',
	initialState: ribTypeEntityAdapter.getInitialState({}),
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(retrieveAllribType.fulfilled, (state, action) => {
				ribTypeEntityAdapter.setAll(state, action.payload);
			})
			.addCase(addOneribType.fulfilled, (state, action) => {
				ribTypeEntityAdapter.addOne(state, action.payload);
			})
			.addCase(updateOneribType.fulfilled, (state, action: any) => {
				const { _id, ...otherProps} = action.payload;
				ribTypeEntityAdapter.updateOne(state, { id: _id, changes: otherProps});
			})
			.addCase(deleteOneribType.fulfilled, (state, action: any) => {
				ribTypeEntityAdapter.removeOne(state, action.payload._id);
			})
	}
});

export default ribTypeSlice.reducer;