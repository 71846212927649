import React, { ReactChild } from 'react';
import './style.css';

import { Container, Paper, Typography } from '@mui/material';
import AuthLayoutMessages from './AuthLayoutMessages';

interface Props {
  children: ReactChild | ReactChild[];
  errors: any[];
  success?: string;
  title?: string;
}

function AuthLayout({ title, errors, success, children }: Props) {
  const logo = '/MTTPlogo2.jpg.webp';

  return (
    <div id="auth__container">
      {errors.length > 0 ? <AuthLayoutMessages message={errors[0].message} severity="error" /> : null}
      {success ? <AuthLayoutMessages message={success} severity="success" /> : null}

      <Container maxWidth="xs">
        <Paper elevation={3} sx={{ mt: 12, p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={logo} alt="logo" className="auth__logo" />
          <Typography component="h1" variant="h5" sx={{}}>
            {title}
          </Typography>
          {children}
        </Paper>
      </Container>
    </div>
  );
}

export default AuthLayout;
