import { createAsyncThunk } from "@reduxjs/toolkit";

export const retrieveAllEcritures = createAsyncThunk(
  '[FACTURE] retrieveAllEcritures',
  async (_: void, thunkAPI: any) => {
    try {
   //   console.log('lol');
      return await thunkAPI.extra.factureQuery.getEcritures()
    } catch (error: any) {
			return thunkAPI.rejectWithValue({ message: error.message ?? null });
		}
  })

  export const findAllEcritures = createAsyncThunk(
    '[ECRITURE] findAllEcritures',
    async (params: any, thunk: any) => {
      try {
        const findAllecriture = await thunk.extra.factureQuery.findAllEcritures(params);
       // console.log(findAllecriture);
        return findAllecriture;
      } catch (error: any) {
        return thunk.rejectWithValue({ message: error.message ?? null });
      }
    }
  )