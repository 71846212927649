import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import ArticleEntity from '../entities/'

export const updateOneArticle = createAsyncThunk(
	'[ARTICLE] updateOneArticle',
	async (article: Update<ArticleEntity>, thunk: any): Promise<Update<ArticleEntity>> => {
		try {
			return  thunk.extra.articleQuery.update(article);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
