import React from 'react';
import { Campaign as CampaignIcon } from '@mui/icons-material';
import { Box, Button, ButtonProps, styled, Tooltip } from '@mui/material';

const StyledButton = styled(Button)<ButtonProps>({
  padding: 0,
  minWidth: '40px',
  backgroundColor: '#fff',
});

export const PlanningTableInformedCell = ({ client, fournisseur, onClickInformed }: any) => {
  const [params, setParams] = React.useState<{ title: string; color: any }>({
    title: '',
    color: 'inherit',
  });

  React.useEffect(() => {
    determineParams(client, fournisseur);
  }, [client, fournisseur]);

  const determineParams = (client: boolean, fournisseur: boolean) => {
    if (client && fournisseur) return setParams({ title: 'Client et fournisseur prévenus', color: 'success' });
    if (!client && !fournisseur) return setParams({ title: 'Client et fournisseur non prévenus', color: 'error' });
    if (client && !fournisseur) return setParams({ title: 'Uniquement le client est prévenu', color: 'info' });
    if (!client && fournisseur) return setParams({ title: 'Uniquement le fournisseur est prévenu', color: 'info' });
    return setParams({ title: '', color: 'inherit' });
  };

  return (
    <Box alignContent="center" justifyContent="center">
      <Tooltip title={params.title}>
        <StyledButton variant="outlined" color={params.color} onClick={onClickInformed}>
          <CampaignIcon color={params.color} />
        </StyledButton>
      </Tooltip>
    </Box>
  );
};
