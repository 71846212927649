import { createSlice, createEntityAdapter, EntityId } from '@reduxjs/toolkit';

import InterlocuteurEntity from './entities';
import { deleteOneInterlocuteur, retreiveAllInterlocuteurs } from './use-cases';
import { addOneInterlocuteur } from './use-cases/add-one-interlocuteur';
import { updateOneInterlocuteur } from './use-cases/update-one-interlocuteur';

export const interlocuteurEntityAdapter = createEntityAdapter<InterlocuteurEntity>({
	selectId: (interlocuteur) => interlocuteur.id
});

export const interlocuteurSlice = createSlice({
	name: 'interlocuteurs',
	initialState: interlocuteurEntityAdapter.getInitialState(),
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(retreiveAllInterlocuteurs.fulfilled, (state, action) => {
			interlocuteurEntityAdapter.setAll(state, action);
		})
		builder.addCase(addOneInterlocuteur.fulfilled, (state, action) => {
			// modifying object structure to make sure the selector will pick it up 
			let { payload } = action;
			let employeur = { id: `${payload.employeur}` }

			payload = { ...payload, employeur }
			interlocuteurEntityAdapter.addOne(state, { ...action, payload });
		})
		builder.addCase(deleteOneInterlocuteur.fulfilled, (state, action) => {
			interlocuteurEntityAdapter.removeOne(state, action.payload);
		})
		builder.addCase(updateOneInterlocuteur.fulfilled, (state, action) => {
			interlocuteurEntityAdapter.updateOne(state, {id: action.payload.id as EntityId, changes: action.payload})
		});
	}
});

export default interlocuteurSlice.reducer