import React, { ReactChild } from 'react';
import { Grid, Stack } from '@mui/material';

interface Props {
  children: ReactChild | ReactChild[];
}

export const FormGridItem = ({ children }: Props) => {
  return (
    <Grid item xs={12}>
      <Stack direction="row" gap={2}>
        {children}
      </Stack>
    </Grid>
  );
};
