import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';

interface AttachedFile {
	id: EntityId;
	file: File;
}

export const attachFileToBon = createAsyncThunk(
	'[BON] attachFileToBon',
	async (file: AttachedFile, thunk: any): Promise<any> => {
		try {
			return await thunk.extra.bonQuery.attach(file);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
