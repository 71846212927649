import { EntityId } from '@reduxjs/toolkit';
import { RootState } from '../../../_config/redux';
import { interlocuteurEntityAdapter } from './interlocuteur.slice';

const interlocuteurSelector = interlocuteurEntityAdapter.getSelectors<RootState>((state) => state.interlocuteurs);

export const selectAllInterlocuteurs = (state: RootState) => {
    const interlocuteurs = interlocuteurSelector.selectAll(state);
    return interlocuteurs;
};

export const selectAllInterlocuteurOfCompany = (companyId: string) => (state: RootState) => {
    const allInterlocuteurs = interlocuteurSelector.selectAll(state);
    return allInterlocuteurs.filter((i) => i.employeur?.id === companyId).map((i) => ({ id: i.id, label: i.nom }));
};

export const selectAllInterlocuteursLabelsAndIds = (state: RootState) => {
    const interlocuteurs = interlocuteurSelector.selectAll(state);
    return interlocuteurs.map(({ id, nom, prenom }) => ({ id, label: `${prenom} ${nom}` }));
};

export const selectInterlocuteursByCompany = (id: EntityId) => (state: RootState) => {
    const interlocuteurs = interlocuteurSelector.selectAll(state);
    return interlocuteurs.filter(i => i.employeur?.id === id);
}