import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';
import { Facture } from '../../Model';

interface Params {
    id: EntityId;
    bons?: string[] | undefined;
    dateDeFacturation?: string;
    paymentDueDate?: string;
    rib?:string;
    adresseFacturation?:string;
    unpaidComment?:string;
    lettrage?:string;
}

export const updateOneFacture = createAsyncThunk(
    '[FACTURE] updateOneFacture',
    async (params: Params, thunk: any): Promise<Facture> => {
        try {
            return thunk.extra.factureQuery.update(params);
        } catch (error: any) {
            return thunk.rejectWithValue({ message: error.message ?? null });
        }
    }
);
