import React, { useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch } from "../../../../_config/react/useAppDispatch";

import { AddAdresseFacturationModale } from "./AddAdresseFacturationModale";
import { AddAdresseFacturationButton } from "./AddAdresseFacturationButton";

import { IAdresse } from "../../../../App/core/entreprise/entities/adresse.entity";
import { addAdresseFacturation as addAdresseFacturationUseCase } from "../../../Domain/Application/use-cases";

export function AddAdresseFacturation(props: { entrepriseId: EntityId }) {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);

    function addAdressFacturation(adresseFacturation: IAdresse) {
        dispatch(addAdresseFacturationUseCase({ entrepriseId: props.entrepriseId, adresseFacturation }))
            .unwrap()
            .finally(() => {
                setOpen(false)
            })
    }

    return <>
        <AddAdresseFacturationButton onClick={() => {
            setOpen(true)
        }}/>

        <AddAdresseFacturationModale
            displayDialog={open}
            submit={addAdressFacturation}
            closeDialog={() => {
                setOpen(false)
            }}/>
    </>


}