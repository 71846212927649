import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import { Planning } from '../entities/planning.entity';

export const updateOnePlanning = createAsyncThunk(
	'[PLANNING] updateOnePlanning',
	async (planningDto: Update<Planning>, thunk: any): Promise<Update<Planning>> => {
		try {
			const { ...planning } = planningDto;
			return await thunk.extra.planningService.update(planning);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
