import React from 'react';

import { IconButton, Stack, Typography } from '@mui/material';
import { BackspaceOutlined as BackspaceIcon } from '@mui/icons-material';

import { useNavigationFrom } from '../../hooks/useNavigationFrom';

interface Props {
  title: string;
}

function PageHeaderBar({ title }: Props) {
  const navigateBack = useNavigationFrom();

  return (
    <>
      <Stack direction="row" alignItems="center" mb={1} gap={4}>
        <IconButton onClick={navigateBack}>
          <BackspaceIcon />
        </IconButton>
        <Typography variant="h5" component="h2" color={'#666767'}>
          {title}
        </Typography>
      </Stack>
    </>
  );
}

export default PageHeaderBar;
