import React, { useRef, useState } from 'react';
import { CustomTable } from '../../../../App/app/common/custom-table';
import format from 'date-fns/format';
import { dateFormat } from '../../../../App/app/common/_helpers';
import parseISO from 'date-fns/parseISO';
import FactureRelanceTableActionsCell from './FactureRelanceTableActionsCell';
import RelanceFactureDialog from '../RelanceFactureDialog';
import { Facture } from '../../../Domain/Model';
import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { relancerFacture } from '../../../Domain/Application/use-cases/relancer-facture';
import { setSuccess } from '../../../../App/core/notification/notification.slice';
import { TableListSummary } from '../TableListSummary';
import { Box } from '@mui/material';
import { useContainerDimensions } from '../../../../App/app/common/custom-table/CustomTable';

interface Props {
  relances: object[];
  loading: boolean;
}

export const FactureRelanceTable = ({ relances, loading }: Props) => {

  const box = useRef();
  const { width, height } = useContainerDimensions(box);

  const onSelectRows = () => {};
  const dispatch = useAppDispatch();
  const [dialogsState, setDialogsState] = useState({
    edit: false,
    validate: false,
    avoir: false,
    relance: false,
    emit: false,
    bonDeCommandeValidation: false,
    delete: false,
  });

  const [rowData, setRowData] = useState<Facture>();

    // Relance
    const onConfirmRelance = async (aReveiverEmail: string) => {
      const res: any = rowData?.id
        ? await dispatch(
            relancerFacture({
              id: rowData.id,
              anEmail: aReveiverEmail,
            })
          )
        : null;
  
      if (!res?.error) {
        dispatch(setSuccess(`Le client a bien été relancé`));
        handleDialogs("relance");
      }
    };

    const handleDialogs = (
      buttonId: keyof typeof dialogsState,
      rowData?: Facture
    ) => {
     // setLoading(true);
      setRowData(rowData);
      const currentState = dialogsState[buttonId];
      const newState = !currentState;
      setDialogsState({ ...dialogsState, [buttonId]: newState });
  
      if (newState) {
        
        //refreshBons();
        //loadbdcs();
      }
    //  setLoading(false);
    };

  const columns = [
    { field: 'numeroFacture', headerName: 'N° Facture', width: 110, sortable: true, fixed: true },
    { field: 'client.name', headerName: 'Client', flexGrow: 1, sortable: true, fixed: true },
    { field: 'dateDeFacturation', headerName: 'Date facturation', width: 150, sortable: true, fixed: true },
    { field: 'paymentDueDateFormat', headerName: 'Date échéance', width: 150, sortable: true, fixed: true },
    { field: 'unpaidRelaunchInvoiceNumber', headerName: 'Relance(s)', width: 110, sortable: true, fixed: true },
    { field: 'unpaidRelaunchInvoiceLastDate', headerName: 'Dernière relance', width: 150, sortable: true, fixed: true },
    { field: 'totalTvae', headerName: 'Total TVA', width: 100, sortable: true, fixed: true },
    { field: 'totalTTCe', headerName: 'Total TTC', width: 100, sortable: true, fixed: true },
    { field: 'reglement.montante', headerName: 'Montant réglé', width: 130, sortable: true, fixed: true },
  ];

  const table_relances = JSON.parse(JSON.stringify(relances));
 
  table_relances.forEach((facture:any) => {
    facture.totalTTCe = Number(facture.totalTTC).toFixed(2) + " €";
    if(facture.totalHT) { facture.totalHTe = Number(facture.totalHT).toFixed(2) + " €"; }
    facture.totalTvae = Number(facture.totalTva).toFixed(2) + " €";
    facture.reglement.montante = Number(facture.reglement.montant).toFixed(2) + " €";
    facture.totalFraisAutorouteE = Number(facture.totalFraisAutoroute).toFixed(2) + " €";
    facture.unpaidRelaunchInvoiceNumber = facture.unpaidRelaunchInvoice.length;
    const unpaidRelaunchInvoiceLastDate = facture.unpaidRelaunchInvoice[(facture.unpaidRelaunchInvoice.length-1)];
    facture.unpaidRelaunchInvoiceLastDate = unpaidRelaunchInvoiceLastDate ? format(new Date(unpaidRelaunchInvoiceLastDate), dateFormat) : unpaidRelaunchInvoiceLastDate;
    facture.paymentDueDateFormat = facture?.paymentDueDate ? format(new Date(facture?.paymentDueDate), dateFormat) : facture?.paymentDueDate;
  });
 

  return (
    <Box sx={{ height: "100%" }} ref={box}>
      <CustomTable
        data={table_relances}
        columns={columns}
        loading={loading}
        hover={false}
        onSelectRows={onSelectRows}
        renderActionCell={(rowData, measuredRef) => (
          <FactureRelanceTableActionsCell
            rowData={rowData}
            measuredRef={measuredRef}
            handleDialogs={handleDialogs}
          />
        )}
        rendersummary={() => (<TableListSummary collection={table_relances}/>) }
        width={width}
        selectable={false}
      />
      <RelanceFactureDialog
        open={dialogsState.relance}
        title={"Relancer"}
        contentText={`Êtes-vous sûr de vouloir relancer ${rowData?.client.name} pour la facture / le bon de commande N° ${rowData?.numeroFacture}`}
        factureDetails={{
          aReceiverEmail: rowData?.client.contact?.email ?? "",
        }}
        onConfirm={onConfirmRelance}
        onClose={() => handleDialogs("relance")}
        isButtonLoading={false}
        key="relance"
      />
    </Box>
  );
};
