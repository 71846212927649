import { createAsyncThunk } from '@reduxjs/toolkit';
import { Planning } from '../entities/planning.entity';

interface Options {
	date: string;
}

export const retrievePlanningByDate = createAsyncThunk(
	'[PLANNING] retrievePlanningByDate',
	async (options: Options, thunk: any): Promise<Planning[]> => {
		try {
			return await thunk.extra.planningService.getByDate(options.date);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
