import React, { useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch } from "../../../../_config/react/useAppDispatch";
import { Button, InputAdornment, Stack, TextField, Tooltip } from "@mui/material";
import { Check } from "@mui/icons-material";
import { addOneAvoir } from "../../../Domain/Application/use-cases";
import { setSuccess } from "../../../../App/core/notification/notification.slice";
import { useSelector } from "react-redux";
import { selectRemainingInvoiceAmountOfFacture } from "../../../Domain/Application/selectors";

export function AddAvoirToFacture(props: { factureId: EntityId, close: () => void }) {
    const dispatch = useAppDispatch();
    const remainingInvoiceAmount = useSelector(selectRemainingInvoiceAmountOfFacture(props.factureId))

    const [montant, setMontant] = useState(remainingInvoiceAmount);
    const [description, setDescription] = useState('');

    const createAvoir = async () => {
        const res: any = await dispatch(addOneAvoir({ id: props.factureId, montant, description }));

        if ( !res.error ) {
            dispatch(setSuccess(`L'avoir a bien été créé`));
            setMontant(remainingInvoiceAmount);
            setDescription('');
            props.close();
        }
    };
    return <Stack direction="column" justifyContent="space-between" height="100%" pt={2} minHeight={'400px'}>
        <Stack direction="column" height='90%' justifyContent="space-around" >
            <TextField
                variant="outlined"
                label="Montant de l'avoir"
                value={montant}
                onChange={(e) => setMontant(parseFloat(e.target.value))}
                InputProps={{
                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                }}
                sx={{ margin: '13px 0' }}
            />

            <TextField
                variant="outlined"
                label="Description"
                type="text"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                multiline
                rows={6}
                sx={{ margin: '13px 0' }}

            />
        </Stack>

        <Tooltip title="Valider">
            <Button variant="contained" color="primary" onClick={createAvoir}
                    startIcon={<Check/>}>
                Confirmer
            </Button>
        </Tooltip>
    </Stack>;
}