import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';
import { Stack, Button } from '@mui/material';
import { CustomTable } from '../../../common/custom-table';
import { ColumnEntity } from '../../../common/custom-table/Table.entities';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import AffacturageTableActionsCell from './AffacturageTableActionsCell';
import { selectAllaffacturageTypes } from '../../../../core/affacturage-type/selectors';
import { deleteOneaffacturageType, retrieveAllaffacturageType } from '../../../../core/affacturage-type/use-cases';
import { affacturageType } from '../../../../core/affacturage-type/entities';
import ConfirmationDialog from '../../../common/modals/components/ConfirmationDialog';
import { setSuccess } from '../../../../core/notification/notification.slice';
import useDocumentTitle from '../../../../../_config/react/useDocumentTitle';
import AffacturageTableAddDialog from './AffacturageTableAddDialog';

const AffacturageTable = () => {

    // useDocumentTitle - Does not work properly?
    useDocumentTitle('MTTP - AFFACTURAGE');

    const [isLoading, setIsLoading] = useState(true);
    const [typeDialogOpen, setTypeDialogOpen] = useState(false);
    const [typeId, setTypeId] = useState('');
    const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [initialValues, setInitialValues] = useState({
        name: '',
        adresse: ''
    });

    const dispatch = useAppDispatch();
    const affacturageTypes = useSelector(selectAllaffacturageTypes);

    useEffect(() => {
        dispatch(retrieveAllaffacturageType())
            .then(() => setIsLoading(false));

        return () => {
            setIsLoading(true);
        };
    }, [dispatch, setIsLoading]);

    const onRowDoubleClick = (values: affacturageType) => {
        const { _id, ...otherProps } = values;
        setTypeId(values._id);
        setInitialValues(otherProps);

        setTypeDialogOpen(true);
    };

    const onSelectRows = () => {
    };

    const onClickEdit = (id: EntityId) => {
        let typeById = affacturageTypes.find((t) => t._id === id);
        if ( typeById ) {
            const { _id, ...otherProps } = typeById;
            setTypeId(_id);
            setInitialValues(otherProps);
        }

        setTypeDialogOpen(true);
    };

    const onClickDelete = (id: string) => {
        setConfirmationDialogOpen(true);
        setTypeId(id);
    };

    const onConfirmDeleteDialog = async () => {
        const res: any = await dispatch(deleteOneaffacturageType(typeId));
        setDeleteButtonLoading(true);
        if ( !res.error ) {
            dispatch(setSuccess(`Le affacturage a bien été supprimé`));
            setConfirmationDialogOpen(false);
        }
        setDeleteButtonLoading(false);
        setTypeId('');
    };

    const onCloseTypeDialog = () => {
        setTypeId('');
        setTypeDialogOpen(false);
        setTimeout(() => {
            setInitialValues({ name: '', adresse: '' });
        }, 200);
    };

    const columns: ColumnEntity[] = [
        { field: 'name', headerName: 'Nom', width: 120, sortable: true, resizable: true },
        { field: 'infos', headerName: 'Adresse', width: 320, sortable: true, resizable: true },
    ];

    const table_affacturageTypes = JSON.parse(JSON.stringify(affacturageTypes));

    table_affacturageTypes.forEach((affacturage:any) => {
       
        if(affacturage.isDefault === true) {
            affacturage.isDefaultString = "Oui";
        }
        else { affacturage.isDefaultString = ""; }
        
    });

    return (
        <>
            <Stack direction="row" gap={6}>
                <CustomTable
                    data={table_affacturageTypes}
                    columns={columns}
                    loading={isLoading}
                    hover={false}
                    width={600}
                    onRowDoubleClick={onRowDoubleClick}
                    onSelectRows={onSelectRows}
                    renderActionCell={(rowData, measuredRef) => (
                        <AffacturageTableActionsCell id={rowData._id} measuredRef={measuredRef} onClickEdit={onClickEdit}
                                             onClickDelete={onClickDelete}/>
                    )}
                />
                <Stack direction="column" gap={2}>
                    <Button variant="outlined" color="primary" onClick={() => setTypeDialogOpen(true)}>
                        Ajouter
                    </Button>
                </Stack>
            </Stack>
            <AffacturageTableAddDialog
                open={typeDialogOpen}
                onCloseDialog={onCloseTypeDialog}
                initialValues={initialValues}
                typeId={typeId}
            />
            <ConfirmationDialog
                title="Supprimer l'affacturage"
                contentText="Êtes-vous sûr de vouloir supprimer cette affacturage ?"
                isButtonLoading={deleteButtonLoading}
                open={confirmationDialogOpen}
                onConfirm={onConfirmDeleteDialog}
                onClose={() => {
                    setTypeId('');
                    setConfirmationDialogOpen(false);
                }}
            />
        </>
    );
};

export default AffacturageTable;
