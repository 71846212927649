import { addBusinessDays, format } from "date-fns";
import { dateFormat } from "../../../../App/app/common/_helpers";
import { BonEntity } from "../../../Domain/Bons/entities";
import { Planning } from "../../../Domain/Planning/entities/planning.entity";

export function useInitialValueOf(anEntity?: BonEntity | Planning) {
    if ( !anEntity ) return {}
    const {
        client = '',
        chantier = '',
        commandePar = '',
        chauffeur = '',
        article = '',
        fournisseur = '',
        date = format(addBusinessDays(new Date(), 1), dateFormat),
        prixAchatHT = '',
        prixVenteHT = '',
        quantite = '',
        unite = '',
        commentaire_interne = '',
        consignes_chauffeur = '',
        evenement = '',
    } = anEntity || {};

    return {
        client,
        chantier,
        chauffeur,
        interlocuteur: commandePar,
        article,
        fournisseur,
        date,
        prixAchatHT,
        prixVenteHT,
        quantite,
        unite,
        commentaire_interne,
        consignes_chauffeur,
        evenement,
    }

}

