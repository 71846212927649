import { createAsyncThunk } from "@reduxjs/toolkit";

export const retrieveAllRelances = createAsyncThunk(
  '[FACTURE] retrieveAllRelances',
  async (_: void, thunkAPI: any) => {
    try {
      return await thunkAPI.extra.factureQuery.getRelances()
    } catch (error: any) {
			return thunkAPI.rejectWithValue({ message: error.message ?? null });
		}
  })