import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { affacturageType } from './entities';
import { addOneaffacturageType, deleteOneaffacturageType, retrieveAllaffacturageType, updateOneaffacturageType } from './use-cases';

export const affacturageTypeEntityAdapter = createEntityAdapter<affacturageType>({
	selectId: (affacturageType) => affacturageType._id
});

export const affacturageTypeSlice = createSlice({
	name: 'affacturageType',
	initialState: affacturageTypeEntityAdapter.getInitialState({}),
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(retrieveAllaffacturageType.fulfilled, (state, action) => {
				affacturageTypeEntityAdapter.setAll(state, action.payload);
			})
			.addCase(addOneaffacturageType.fulfilled, (state, action) => {
				affacturageTypeEntityAdapter.addOne(state, action.payload);
			})
			.addCase(updateOneaffacturageType.fulfilled, (state, action: any) => {
				const { _id, ...otherProps} = action.payload;
				affacturageTypeEntityAdapter.updateOne(state, { id: _id, changes: otherProps});
			})
			.addCase(deleteOneaffacturageType.fulfilled, (state, action: any) => {
				affacturageTypeEntityAdapter.removeOne(state, action.payload._id);
			})
	}
});

export default affacturageTypeSlice.reducer;