import { TextField, InputAdornment, IconButton } from '@mui/material';
import { DateRange as DateRangeIcon } from '@mui/icons-material';
import React, { useState } from 'react';
import { format, isValid, parse } from 'date-fns';

import DatePickerPopover from './DatePickerPopover';
import { dateFormat } from '../../_helpers';

function DatePickerField({ onDateUpdate, ...props }: any) {
    const [displayCalendar, setDisplayCalendar] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());

    const onAdornmentClick = (event: React.MouseEvent<HTMLElement>) => {
        setDisplayCalendar(true);
        setAnchorEl(event.currentTarget);
    };

    const onPopoverClose = () => {
        setDisplayCalendar(false);
        setAnchorEl(null);
    };

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        onDateUpdate('date', value);

        let date = parse(value.trim(), dateFormat, new Date());

        if ( isValid(date) ) {
            setSelectedDate(date);
        }
    };

    const onSelectDate = (value: Date) => {
        let localValue = value ? format(value, dateFormat) : '';
        setSelectedDate(value);
        onDateUpdate('date', localValue);
        onPopoverClose();
    };

    return (
        <>
            <TextField
                fullWidth
                {...props}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={onAdornmentClick}>
                                <DateRangeIcon/>
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                onChange={onInputChange}
            />
            <DatePickerPopover
                onSelectDate={onSelectDate}
                selectedDate={selectedDate}
                onPopoverClose={onPopoverClose}
                displayCalendar={displayCalendar}
                anchorEl={anchorEl}
            />
        </>
    );
}

export default DatePickerField;
