import { BonEntity } from "../../../Domain/Bons/entities";
import { BonApi } from "../../../Domain/Bons/entities";

export const _mapToEntity = (bon: BonApi): BonEntity => {
    return {
        id: bon.id,
        client: bon.client?.name,
        chantier: bon.chantier?.name,
        commandePar: `${bon.interlocuteurClient?.name} ${bon.interlocuteurClient?.lastname}`,
        article: bon.article?.name,
        type: bon.article?.famille,
        fournisseur: bon.fournisseur?.name,
        chauffeur: bon.chauffeur?.name,
        unite: bon.commande?.unite,
        quantite: bon.commande?.quantite,
        prixAchatHT: bon.commande?.prixAchatHT,
        prixVenteHT: bon.commande?.prixVenteHT,
        achatHT: bon.commande?.marge,
        montantHT: bon.commande?.montantHT,
        date: bon.expectedDateOfPrestation,
        bonNumber: bon.bonNumber,
        referenceClient: bon.referenceClient,
        status: bon.status,
        docs: bon.docs,
        fraisAutoroute: bon.commande?.fraisAutoroute,
        confirmedFraisAutoroute: bon.commande?.confirmedFraisAutoroute,
        commentaire_interne: bon.consignes?.mttp,
        consignes_chauffeur: bon.consignes?.chauffeur,
        evenement: bon.consignes?.evenement,
        procedure: bon.procedure,
        ids: {
            client: bon.client?.id,
            commandePar: bon.interlocuteurClient?.id,
            chauffeur: bon.chauffeur?.id,
            fournisseur: bon.fournisseur?.id,
            article: bon.article?.id,
            chantier: bon.chantier?.id,
        }
    };
};

export const _fromEntity = (bon: Partial<BonEntity>): any => {
    const { date, unite, quantite, prixAchatHT, prixVenteHT, commandePar, fraisAutoroute, ...otherProps } = bon;

    return {
        expectedDateOfPrestation: date,
        interlocuteurClient: commandePar,
        prestation: {
            unite: unite,
            quantite: quantite,
            prixAchatHT: prixAchatHT,
            prixVenteHT: prixVenteHT,
            fraisAutoroute: fraisAutoroute
        },
        ...otherProps
    }
} 