import { createAsyncThunk } from '@reduxjs/toolkit';

export const retrieveAllPaymentType = createAsyncThunk(
	'[PAYMENT-TYPE] retrieveAllPaymentType',
	async (_: void, thunk: any) => {
		try {
			return await thunk.extra.paymentTypeQuery.all();
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
