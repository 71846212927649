import { EntityId } from "@reduxjs/toolkit";
import { Ecriture, Facture } from "../../../Facturation/Domain/Model";
import { Facture as FactureApi } from '../../../Facturation/Data/model/Facture.api'
import httpAdapter, { _apiUrlFactory } from "../../../_config/api-provider"
import { HttpFactureService } from "../../../Facturation/Data/source/http/HttpFacture.service";
import { BonDeCommandeHttpRepository } from "../../../Facturation/Data/source/http/BonDeCommande.httpRepository";
import { removeEmpty } from "../../app/common/_helpers";

const httpService = httpAdapter();

const prefix = 'facture';
const apiFacturesUrl = _apiUrlFactory(prefix);

export const all = async (params: any) => {
    return (await httpService.get(apiFacturesUrl(), { params: removeEmpty({ ...params }) })).map(_mapToEntity);
}
/*
export const get = async ({ query, ...params }: GetArgs) => {
    const bons = await httpService.get(apiBonUrl(query ?? '') + searchParams);
    return bons.map((bon: BonApi) => _mapToEntity(bon));
};*/

export const add = async (body: { bons: EntityId[], paymentDueDate: string, rib: string, adresseFacturation: string }) => {
    return await httpService.post(apiFacturesUrl(), body);
}

export const generateFile = async (id: EntityId) => {
    return await httpService.get(apiFacturesUrl(`${id}`), { responseType: 'blob' });
}

export const update = async ({ id, bons, dateDeFacturation, paymentDueDate, rib, adresseFacturation,  unpaidComment, lettrage }: { id: EntityId, bons: EntityId[], dateDeFacturation?: string, paymentDueDate?: string, rib?:string, adresseFacturation?:string, unpaidComment?: string, lettrage?:string }) => {
    return await httpService.put(apiFacturesUrl(`${id}`), { bons, dateDeFacturation, paymentDueDate, rib, adresseFacturation, unpaidComment, lettrage });
}
 
export const retrieveOneAvoir = async (id: EntityId) => {
    return await httpService.get(apiFacturesUrl(`${id}/avoir`));
}



export const addOneAvoir = async (
    { id, montant, description }: { id: EntityId; montant: string, description?: string }
) => {
    return httpService.post(apiFacturesUrl(`${id}/avoir`), { montant, description });
}

export const generateAvoirFile = async (factureId: EntityId, avoirId: EntityId) => {
    return await httpService.get(apiFacturesUrl(`${factureId}/avoir/${avoirId}/pdf`), { responseType: 'blob' });
}

export const relancerFacture = async (factureId: EntityId, aReceiverEmail: string) => {
    return await httpService.post(apiFacturesUrl(`relance/${factureId}`), { aReceiverEmail: aReceiverEmail });
}

export const getRelances = async () => {
    return await httpService.get(apiFacturesUrl(`relance`));
}

export const getEcritures = async () => {
    return await httpService.get(apiFacturesUrl(`ecritures`));
}

export const validatePayment = async ({
                                          id,
                                          montant,
                                          paymentType,
                                          bank,
                                          lettrage
                                      }: { id: string, montant: string, paymentType: string, bank?: string, lettrage?: string }) => {
    return httpService.patch(apiFacturesUrl(`${id}`), { montant: Number(montant), payment_type: paymentType, payment_banque : bank, lettrage: lettrage });
}

export const avoirToUse = async ({
    _id,
    ref_facture,
    used_at,
    facture_number
}: { 
    _id: string;
    ref_facture: any;
    used_at?: string;
    facture_number?: string; 
}) => {
    return httpService.patch(apiFacturesUrl(`${ref_facture._id}/avoirToUse/${_id}`), { used_at, facture_number });
}

export const emit = async (id: EntityId, aReceiverEmail: string) => {
    return httpService.post(apiFacturesUrl(`${id}/submission`), { aReceiverEmail: aReceiverEmail });
}

export const remove = async (id: EntityId) => {
    return httpService.post(apiFacturesUrl(`${id}/remove`));
}

export const getZipOfAllDocumentsOfFacture = async (id: EntityId) => {
    return httpService.get(apiFacturesUrl(`${id}/download-all-files`), { responseType: 'blob' });
}

export const findEditedBonDeCommandes = async (params: any) => new BonDeCommandeHttpRepository().findEditedBonDeCommandes(params);

 
export const findAllAvoirs = async (params: any) => {
    return (await httpService.get(apiFacturesUrl(`all/avoir`), { params: removeEmpty({ ...params }) })).map(_mapToEntity);
}

export const findAllEcritures = async (params: any) => {
    const ecrituresDatas = (await httpService.get(apiFacturesUrl(`all/ecriture`), { params: removeEmpty({ ...params }) }));
    //console.log(ecrituresDatas);
    return (ecrituresDatas);
}

export const findAllPendingBonsDeCommande = async () => new HttpFactureService().findAllPendingBonsDeCommande()



const _mapToEntity = (facture: FactureApi): Facture => {
    return {
        ...facture,
        bons: facture.bons?.map((bon: FactureApi['bons'][0]) => ({
            ...bon,
            id: bon._id,
            article: bon.libelle,
            bonNumber: bon.numeroBon,
            fournisseur: bon?.fournisseur?.name, // fournisseur is client in invoiceLine of facture
        })),
        type: 'client'
    };
};