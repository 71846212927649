import React from 'react';
import { useSelector } from 'react-redux';

import { selectLoggedInUser } from '../../../core/auth/selectors';
import { setSuccess } from '../../../core/notification/notification.slice';
import { User } from '../../../core/user/entities/user.entity';
import { updateOneUser, updatePassword } from '../../../core/user/use-cases';
import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { ViewCard } from '../../common/layout/components';
import PageHeaderBar from '../../common/layout/components/PageHeaderBar';
import { removeEmpty } from '../../common/_helpers';
import { UserAccountUpdateForm } from './UserAccountUpdateForm';
import useDocumentTitle from '../../../../_config/react/useDocumentTitle';

export const UserAccountView = () => {

  useDocumentTitle(`MTTP - Mon compte`);

  const {
    user: { id, name, email },
  } = useSelector(selectLoggedInUser);

  const dispatch = useAppDispatch();

  const initialValues = { name, email, password: '', passwordConfirm: '' };

  const onUpdateUserAccount = async ({ password, ...values }: Partial<User>) => {
    let onlyFilledValues: Partial<User> = removeEmpty(values);
    let hasError = false;

    if (values) {
      const res: any = await dispatch(updateOneUser({ id, changes: onlyFilledValues }));
      if (res.error) hasError = true;
    }

    if (!!password) {
      const resPwChange: any = await dispatch(updatePassword({ id, password }));
      if (resPwChange.error) hasError = true;
    }

    if (!hasError) {
      dispatch(setSuccess(`La mise à jour du compte à bien été effectuée`));
    }
  };

  return (
    <>
      <PageHeaderBar title={'Mettre à jour mon compte'} />
      <ViewCard>
        <UserAccountUpdateForm initialValues={initialValues} onSubmit={onUpdateUserAccount} />
      </ViewCard>
    </>
  );
};
