import React, { useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { TableViewLayout } from '../../../common/layout/components';
import { ArticleTable, ArticleTableToolbar } from './';
import useDocumentTitle from '../../../../../_config/react/useDocumentTitle';

function ArticleList() {

  useDocumentTitle(`MTTP - Articles`);

  const [selectedRows, setSelectedRows] = useState<EntityId[]>([]);

  return (
    <>
      <TableViewLayout title="Articles">
        <ArticleTableToolbar selectedRows={selectedRows} />
        <ArticleTable onSelectRows={setSelectedRows} />
      </TableViewLayout>
    </>
  );
}

export default ArticleList;
