import React from 'react';

// import { generateFile } from '../../../../App/core/facture/use-cases';

import { Stack, IconButton, Tooltip, Box } from '@mui/material';
import { Description, Edit, Euro, PriorityHigh, TaskAlt, ForwardToInbox, Delete, Abc, ClosedCaption, ClosedCaptionDisabled } from '@mui/icons-material';
import { Facture } from '../../../../Domain/Model';
import { useCases } from "../../../../Domain/Application";
import { useAppDispatch } from "../../../../../_config/react/useAppDispatch";
import BonDeCommandeActionsCell from "../../bondecommande/bdc-list/BonDeCommandeActionsCell";

interface Props {
    rowData: Facture;
    measuredRef: any;

    handleDialogs(buttonId: string, rowData: Facture): void;
}

const FactureTableActionsCell = ({ rowData, measuredRef, handleDialogs }: Props) => {
    /* Display Invoice */
    const dispatch = useAppDispatch()


    if ( rowData.type === 'fournisseur' ) {
        return <BonDeCommandeActionsCell
            rowData={rowData} measuredRef={measuredRef}
            handleDialogs={handleDialogs}
        />
    }

    const lettrage = () => {
        if(rowData.lettrage && rowData.lettrage.length > 0) { return true; }
        return false;
    }
    const onClickDisplayInvoice = async () => {
        const res = await dispatch(useCases.generateFile(rowData.id)).unwrap();
        const fileURL = window.URL.createObjectURL(res);
        window.open(fileURL);
    };

    const onClickButton = ({ currentTarget: { id: buttonId } }: { currentTarget: { id: string } }) =>
        handleDialogs(buttonId, rowData);

    return (
        <Stack direction="row">
            {/* Buttons */}

            <Box ref={measuredRef}>
                {/* Editing is available on invoices with CREATED status */}
                <Tooltip title="Afficher facture">
                    <IconButton aria-label="facture" id="facture" color="primary" size="small"
                                onClick={onClickDisplayInvoice}>
                        <Description/>
                    </IconButton>
                </Tooltip>

                {rowData.status !== 'CREATED' && (
                    <>
                        <Tooltip title="Valider règlement">
                            <IconButton aria-label="valider" id="validate" color="success" size="small"
                                        onClick={onClickButton}>
                                <TaskAlt/>
                            </IconButton>
                        </Tooltip>
                
                        <Tooltip title="Relancer">
                            <IconButton aria-label="relancer" id="relance" color="warning" size="small"
                                        onClick={onClickButton}>
                                <PriorityHigh/>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Avoirs">
                            <IconButton aria-label="avoir" id="avoir" color="primary" size="small"
                                        onClick={onClickButton}>
                                <Euro/>
                            </IconButton>
                        </Tooltip>
                    </>
                )}

                {rowData.status === 'CREATED' && (
                    <>
                        <Tooltip title="Editer">
                            <IconButton aria-label="editer" id="edit" color="primary" size="small"
                                        onClick={onClickButton}>
                                <Edit/>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Emettre facture">
                            <IconButton aria-label="emettre" id="emit" color="primary" size="small"
                                        onClick={onClickButton}>
                                <ForwardToInbox/>
                            </IconButton>
                        </Tooltip>
                    {rowData.numeroFacture === "Brouillon" && (
                        <Tooltip title="Supprimer le brouillon">
                            <IconButton aria-label="supprimer" id="delete" color="error" size="small"
                                        onClick={onClickButton}>
                                <Delete/>
                            </IconButton>
                        </Tooltip>
                    )}
                    
                        {/* Je triche avec un bouton vide pour que la taille de la div contenant les boutons soit correct */}
                        {/* Sans ca, la div prend que 3 boutons au lieu de 4 */}
                        {/* Pas ouf comme solution mais c'est rapide, je trouve autre chose plus propre et dynamique si j'ai le temps */}
                        <IconButton disabled={true} size="small">
                            <Box width={'40px'}/>
                        </IconButton>
                    </>
                )}
                {rowData.status !== 'CREATED' && (
                    <>
                    {!lettrage() && (
                        <Tooltip title="Lettrer">
                            <IconButton aria-label="lettrer" id="lettrer" color="primary" size="small"
                                        onClick={onClickButton}>
                                <ClosedCaption/>
                            </IconButton>
                        </Tooltip>
                    )}
                    {lettrage() && (
                        <Tooltip title="Retirer Lettrage">
                            <IconButton aria-label="unlettrer" id="unlettrer" color="primary" size="small"
                                        onClick={onClickButton}>
                                <ClosedCaptionDisabled/>
                            </IconButton>
                        </Tooltip>
                    )}
                    </>
                )}
            </Box>
        </Stack>
    );
};

export default FactureTableActionsCell;
