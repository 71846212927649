import { createSlice, createEntityAdapter, nanoid } from "@reduxjs/toolkit";
import { User } from "./entities/user.entity";
import { addOneUser, deactivateUsers, resetPassword, resetPasswordConfirm, updatePassword } from "./use-cases";
import { reactivateUser } from "./use-cases/reactivate-user";
import { retrieveAllUsers } from "./use-cases/retreive-all-users";
import { updateOneUser } from "./use-cases/update-one-user";

export const userEntityAdapter = createEntityAdapter<User>({
    selectId: (user) => user.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name),
})

interface UserError {
    id: string,
    name: string,
    message: string,
    code: number
}

// const addErrorAction: CaseReducer<typeof initial, PayloadAction<any>> = (state, action) => {
//     state.errors.push({ id: nanoid(), ...action.payload })
// };
export const userSlice = createSlice({
    name: "user",
    initialState: userEntityAdapter.getInitialState(
        {
            errors: [],
            search: '',
            filter: { status: '', role: '' }
        } as { errors: UserError[], search: string, filter: { status: string; role: string; } }),
    reducers: {
        userAdded: userEntityAdapter.addOne,
        addError(state, action: any) {
            let error;

            if ( action.error ) error = { id: nanoid(), ...action.error };
            else error = { id: nanoid(), message: 'Une erreur est survenue.' };

            state.errors.push(error)
        },
        clearErrors(state) {
            state.errors = []
        },
        updateSearch(state, action) {
            state.search = action.payload
        },
        updateFilter(state, action) {
            state.filter = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveAllUsers.fulfilled, (state, action) => {
                userEntityAdapter.setAll(state, action.payload)
            })
            .addCase(addOneUser.fulfilled, (state, action) => {
                // formatting the user to fit with current entity, making sure the user will be diplayed in the list when created from planning
                // 'roles' is returned in an array even though a user can only have one role
                const user = {
                    ...action.payload,
                    roles: action.payload.roles.join(''),
                    employeur: { id: action.payload.employeur }
                };
                userEntityAdapter.addOne(state, user)
            })
            .addCase(resetPassword.rejected, (state, action) => {
                userSlice.caseReducers.addError(state, action);
            })
            .addCase(resetPasswordConfirm.rejected, (state, action) => {
                userSlice.caseReducers.addError(state, action);
            })
            .addCase(updateOneUser.fulfilled, (state, action) => {
                userEntityAdapter.updateOne(state, action.payload);
            })
            .addCase(updatePassword.rejected, (state, action) => {
                userSlice.caseReducers.addError(state, action);
            })
            .addCase(deactivateUsers.fulfilled, (state, { payload }) => {
                const updatedObjects = payload.map(({ id, isActive }: any) => ({ id, changes: { isActive } }));
                userEntityAdapter.updateMany(state, updatedObjects);
            })
            .addCase(deactivateUsers.rejected, (state, action) => {
                userSlice.caseReducers.addError(state, action);
            })
            .addCase(reactivateUser.fulfilled, (state, { payload }) => {
                const updatedObjects = payload.map(({ id, isActive }: any) => ({ id, changes: { isActive } }));
                userEntityAdapter.updateMany(state, updatedObjects);
            })
            .addCase(reactivateUser.rejected, (state, action) => {
                userSlice.caseReducers.addError(state, action);
            })
    }
})

export const { userAdded, addError, clearErrors, updateSearch, updateFilter } = userSlice.actions;
export default userSlice.reducer;
