import tokenStorageService from "../storage-provider/token-storage.service";
import httpApiAdapter, { _apiUrlFactory } from "../../../_config/api-provider"

const httpService = httpApiAdapter();
const authApiUrl = _apiUrlFactory("auth");

export const login = async (username: string, password: string) => {
    return await httpService.post(authApiUrl('login'), { username, password });
}

export const authenticate = async (refreshToken: string) => {
    return await httpService.get(authApiUrl('refresh-token'), {
        headers: { "refresh-token": tokenStorageService.get().refresh() }
    });
    // 

    // return { user: {}, access: {}, refresh: {} }
}