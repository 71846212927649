import { Planning } from "../../../Domain/Planning/entities/planning.entity";
import { BonEntity } from "../../../Domain/Bons/entities";

export function useDefaultValueOf(anEntity?: Planning | BonEntity) {
    if ( !anEntity ) return {};

    const { ids } = anEntity;
    let result = {
        client: {
            id: ids?.client,
            label: anEntity.client ?? '',
        },
        chantier: {
            id: ids?.chantier,
            label: anEntity.chantier ?? '',
        },
        chauffeur: {
            id: ids?.chauffeur,
            label: anEntity.chauffeur ?? '',
        },
        interlocuteur: {
            id: ids?.commandePar,
            label: anEntity.commandePar ?? '',
        },
        article: {
            id: ids?.article,
            label: anEntity.article ?? '',
        },
        fournisseur: {
            id: ids?.fournisseur,
            label: anEntity.fournisseur ?? '',
        },
        commentaire_interne: anEntity.commentaire_interne ?? '',
        consignes_chauffeur: anEntity.consignes_chauffeur ?? '',
        evenement: anEntity.evenement ?? '',
    }

    if ( 'confirmedFraisAutoroute' in anEntity || 'fraisAutoroute' in anEntity ) {
        return {
            ...result,
            fraisAutoroute: anEntity.fraisAutoroute ?? 0,
            confirmedFraisAutoroute: anEntity.confirmedFraisAutoroute ?? 0
        };
    }
    return result;
}