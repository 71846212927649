import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';

interface Options {
    bonId: EntityId;
    fileId: EntityId;
}

export const retreiveBonAttachment = createAsyncThunk<any, Options, { extra: { bonQuery: { getAttachment: (options: Options) => Promise<any> } } }>(
    "[BONS] retreiveAttachment",
    async (options: Options, thunkAPI) => {
        return thunkAPI.extra.bonQuery.getAttachment({
            bonId: options.bonId,
            fileId: options.fileId
        });
    }
)
