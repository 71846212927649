import { createAsyncThunk, EntityId } from "@reduxjs/toolkit";

interface Args {
    id: string
    changes: {
        commentaire_interne: string,
        evenement: string
        consignes_chauffeur: string
    }
}

export const updateComments = createAsyncThunk<any, Args, { extra: { bonQuery: { updateCommentsOfBon(id: string, comments: Args['changes']): Promise<any> } } }
>("[BON] Update Comments",
    async ({ id, changes }, thunk) => {

        return thunk.extra.bonQuery.updateCommentsOfBon(id, changes)
    }
)