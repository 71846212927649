import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';
import { Form, Formik } from 'formik';
import { mixed, number, object, setLocale, string } from 'yup';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputAdornment,
    Stack,
    TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Pageview, Upload } from '@mui/icons-material';

import { setSuccess } from '../../../../App/core/notification/notification.slice';
import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { yupFr } from "../../../../App/app/common/i18n";

import { BonLivraison } from '../../../Domain/Bons/entities';
import { selectBonById } from '../../../Domain/Bons/selectors';
import { confirmBonManually, getBonLivraisonFile } from '../../../Domain/Bons/use-cases';


interface Props {
    bonId: EntityId;
    open: boolean;

    onClose(): void;
}

function BonSignatureFormDialog({ bonId, open, onClose }: Props) {
    const [filename, setFilename] = useState<string | null>(null);
    const bon = useSelector(selectBonById(bonId));
    const fileInput: React.LegacyRef<HTMLInputElement> | null = React.useRef(null);
    const dispatch = useAppDispatch();

    const initialValues: BonLivraison = {
        observationClient: bon?.procedure?.observations.client ?? '',
        observationFournisseur: bon?.procedure?.observations.fournisseur ?? '',
        fraisAutoroute: bon?.fraisAutoroute ?? 0,
        bonNumber: bon?.bonNumber ?? '',
        referenceClient: bon?.referenceClient ?? '',
        bl: null,
    };

    const isSigned = Boolean(bon?.status === 'SIGNED');

    setLocale(yupFr);
    const schema = object().shape({
        bonNumber: string().required(),
        referenceClient: string().notRequired(),
        fraisAutoroute: number().min(0).required(),
        observationClient: string().notRequired(),
        observationFournisseur: string().notRequired(),
        bl: mixed().required(),
    });

    const onSubmit = async (values: BonLivraison) => {
        const res: any = await dispatch(confirmBonManually({ bonId, ...values }));

        if ( !res.error ) {
            dispatch(setSuccess(`La signature du bon a bien été effectuée`));
            onClose();
        }
    };

    const onClickDisplayBL = async () => {
        if ( bon?.id ) {
            const res = await dispatch(getBonLivraisonFile(bon?.id)).unwrap();
            const fileURL = window.URL.createObjectURL(res);
            window.open(fileURL);
        }
    };

    return (
        <Dialog open={open}>
            <DialogTitle>Signature manuelle</DialogTitle>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
                {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isValid,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                    } = props;
                    return (
                        <>
                            <Form onSubmit={handleSubmit}>
                                <DialogContent sx={{ minWidth: 400 }}>
                                    <Grid container direction="column" spacing={3}>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label="N° Bon"
                                                error={Boolean(errors.bonNumber && touched.bonNumber)}
                                                name="bonNumber"
                                                value={values.bonNumber}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={errors.bonNumber && touched.bonNumber && errors.bonNumber}
                                                variant="outlined"
                                                disabled={isSigned}
                                                required
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label="Référence Client"
                                                name="referenceClient"
                                                value={values.referenceClient}
                                                onChange={handleChange}
                                                variant="outlined"
                                                disabled={isSigned}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                label="Frais Autoroute"
                                                error={Boolean(errors.fraisAutoroute && touched.fraisAutoroute)}
                                                name="fraisAutoroute"
                                                value={values.fraisAutoroute}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={errors.fraisAutoroute && touched.fraisAutoroute && errors.fraisAutoroute}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                }}
                                                variant="outlined"
                                                disabled={isSigned}
                                                required
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label="Observation Client"
                                                name="observationClient"
                                                value={values.observationClient}
                                                onChange={handleChange}
                                                variant="outlined"
                                                multiline
                                                rows={3}
                                                disabled={isSigned}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label="Observation Fournisseur"
                                                name="observationFournisseur"
                                                value={values.observationFournisseur}
                                                onChange={handleChange}
                                                variant="outlined"
                                                multiline
                                                rows={3}
                                                disabled={isSigned}
                                            />
                                        </Grid>
                                        <Grid item>
                                            {isSigned ? (
                                                <Button fullWidth variant="text" endIcon={<Pageview/>}
                                                        onClick={onClickDisplayBL}>
                                                    Voir le bon
                                                </Button>
                                            ) : (
                                                <Stack direction="column" gap={1}>
                                                    <Button
                                                        variant="contained"
                                                        fullWidth
                                                        startIcon={<Upload/>}
                                                        onClick={() => fileInput.current?.click()}
                                                    >
                                                        Ajouter BL*
                                                    </Button>
                                                    <span style={{
                                                        display: values.bl ? 'inline' : 'none',
                                                        marginTop: '4px'
                                                    }}>{filename}</span>
                                                    <input
                                                        ref={fileInput}
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        onChange={(event) => {
                                                            const { files } = event.currentTarget;
                                                            if ( files ) {
                                                                setFieldValue('bl', files[0]);
                                                                setFilename(files[0].name);
                                                            }
                                                        }}
                                                    />
                                                </Stack>
                                            )}
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={onClose}>Annuler</Button>
                                    <LoadingButton variant="contained" type="submit" loading={isSubmitting}
                                                   disabled={!dirty || !isValid}>
                                        Confirmer
                                    </LoadingButton>
                                </DialogActions>
                            </Form>
                        </>
                    );
                }}
            </Formik>
        </Dialog>
    );
}

export default BonSignatureFormDialog;
