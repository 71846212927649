import { createAsyncThunk } from '@reduxjs/toolkit';
import { ribType } from '../entities';

export const addOneribType = createAsyncThunk(
	'[rib-TYPE] addOneribType',
	async (ribType: ribType, thunk: any): Promise<ribType> => {
		try {
			return  thunk.extra.ribTypeQuery.add(ribType);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
