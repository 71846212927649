import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { object, ref, setLocale, string } from 'yup';

import { TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { AuthLayout } from '../Layout';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { resetPasswordConfirm } from '../../../../core/user/use-cases';
import { selectUserErrors } from '../../../../core/user/selectors';
import { clearErrors } from '../../../../core/user';
import AuthLayoutLink from '../Layout/AuthLayoutLink';
import { yupFr } from '../../../common/i18n';
import useDocumentTitle from '../../../../../_config/react/useDocumentTitle';

interface FormValues {
  password: string;
  passwordConfirm: string;
}

function ResetPasswordConfirm() {
  useDocumentTitle('MTTP - Reinitialisation');
  const errors = useSelector(selectUserErrors);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('query');
  const dispatch = useAppDispatch();

  const initialValues: FormValues = { password: '', passwordConfirm: '' };

  setLocale(yupFr);
  const validationSchema = object().shape({
    password: string().min(8).required(),
    passwordConfirm: string()
      .when('password', {
        is: (val: any) => (val && val.length > 0 ? true : false),
        then: string().oneOf([ref('password')], 'Le mot de passe de confirmation doit être identique.'),
      })
      .required(),
  });

  useEffect(() => {
    setTimeout(() => {
      if (errors.length > 0) dispatch(clearErrors());
    }, 4000);
  }, [dispatch, errors]);

  const handleSubmit = ({ password, passwordConfirm }: FormValues) => {
    setLoading(true);

    if (!token) return;

    dispatch(resetPasswordConfirm({ token, password })).then((res) => {
      if (res.meta?.requestStatus === 'fulfilled') setSuccess('Le mot de passe a bien été changé.');

      setLoading(false);
    });
  };

  return (
    <AuthLayout title="Réinitialiser mot de passe" errors={errors} success={success}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ dirty, isValid, values, errors, handleChange, handleBlur }) => {
          return (
            <Form style={{ marginTop: 10 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="Nouveau mot de passe"
                type="password"
                name="password"
                autoFocus
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                error={!!errors.password}
                helperText={errors.password}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="passwordConfirm"
                label="Confirmer mot de passe"
                type="password"
                id="passwordConfirm"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.passwordConfirm}
                error={!!errors.passwordConfirm}
                helperText={errors.passwordConfirm}
              />
              <LoadingButton
                loading={loading}
                disabled={!dirty || !isValid}
                type="submit"
                fullWidth
                variant="contained"
                color="warning"
                sx={{ mt: 3, mb: 2 }}
              >
                Réinitialiser
              </LoadingButton>
            </Form>
          );
        }}
      </Formik>
      <AuthLayoutLink to="/login" text="Se connecter" />
    </AuthLayout>
  );
}

export default ResetPasswordConfirm;
