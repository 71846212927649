import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { number, object, setLocale, string } from 'yup';
import { yupFr } from '../../common/i18n';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { addOneArticle } from '../../../core/article/use-cases';
import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { ArticleEntity } from '../../../core/article/entities/article.entity';
import { setSuccess } from '../../../core/notification/notification.slice';
import { updateCreatedFromModal } from '../../../../Location/Domain/Bons/bon.slice';
import { FormFieldAutocomplete, FormFieldLayout } from '../../common/form-field';
import { selectAllCategoriesLabelsAndIds } from '../../../core/categorie-produit/selectors';
import { retrieveAllCategorieProduit } from '../../../core/categorie-produit/use-cases';
import { removeEmpty, unites } from '../../common/_helpers';

type Article = Omit<ArticleEntity, 'id'>;

function ArticleModal() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const categories = useSelector(selectAllCategoriesLabelsAndIds);

    const defaultUnityValue = "journee";

    const {
        state: { keepIdInState },
    }: { state: any } = useLocation();

    const initialValues: Article = {
        name: '',
        description: '',
        prixHT: 0,
        prixVenteHT: 0,
        famille: '',
    };

    setLocale(yupFr);
    const schema = object().shape({
        name: string().required(),
        prixHT: number().required(),
        prixVenteHT: number().required(),
        famille: string().required(),
        description: string().notRequired(),
        unite: string().notRequired(),
    });

    useEffect(() => {
        dispatch(retrieveAllCategorieProduit());
    }, [dispatch]);

    const onDismiss = () => {
        navigate('', { replace: true });
    };

    const onSubmit = async (values: Article) => {
        let onlyFilledValues: any = removeEmpty(values);

        const res: any = await dispatch(addOneArticle({ ...onlyFilledValues }));

        if ( !res.error ) {
            dispatch(setSuccess(`L'article a bien été créé`));
            if ( keepIdInState ) dispatch(updateCreatedFromModal({ entity: 'article', id: res.payload.id }));
            onDismiss();
        }
    };

    return (
        <Dialog open>
            <DialogTitle>Créer un article</DialogTitle>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
                {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isValid,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    } =
                        props;
                    return (
                        <>
                            <Form onSubmit={handleSubmit}>
                                <DialogContent sx={{ minWidth: 400 }}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label="Nom"
                                                error={!!errors.name && !!touched.name}
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.name && errors.name}
                                                variant="outlined"
                                                required
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label="Prix Achat HT"
                                                error={!!errors.prixHT && !!touched.prixHT}
                                                name="prixHT"
                                                value={values.prixHT}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.prixHT && errors.prixHT}
                                                type="number"
                                                variant="outlined"
                                                required
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label="Prix Vente HT"
                                                error={!!errors.prixVenteHT && !!touched.prixVenteHT}
                                                name="prixVenteHT"
                                                value={values.prixVenteHT}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.prixVenteHT && errors.prixVenteHT}
                                                type="number"
                                                variant="outlined"
                                                required
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                component={TextField}
                                                select
                                                fullWidth
                                                required
                                                label="Unité"
                                                placeholder="Unité *"
                                                id="unite"
                                                name="unite"
                                                value={values.unite ? values.unite : defaultUnityValue}
                                                onChange={handleChange}
                                                inputProps={{ name: 'unite', id: 'unite' }}
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                error={!!errors.unite && !!touched.unite}
                                                helperText={touched.unite && errors.unite}
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                {unites.map((unite) => (
                                                    <MenuItem key={unite.value} value={unite.value}>
                                                        {unite.label}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Grid>
                                       
                                        {/* <Grid item>
                      <TextField
                        fullWidth
                        label="Famille"
                        error={!!errors.famille && !!touched.famille}
                        name="famille"
                        value={values.famille}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.famille && errors.famille}
                        variant="outlined"
                        required
                      />
                    </Grid> */}

                                        <Grid item xs={12}>
                                            <FormFieldLayout label="Famille">
                                                <FormFieldAutocomplete id="famille" options={categories}
                                                                       variant="outlined" required/>
                                                <></>
                                            </FormFieldLayout>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label="Description"
                                                error={!!errors.description && !!touched.description}
                                                name="description"
                                                value={values.description}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.description && errors.description}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        {/* <Grid item>
                      <TextField
                        fullWidth
                        label="Taux TVA"
                        error={!!errors.tauxTva && !!touched.tauxTva}
                        name="tauxTva"
                        value={values.tauxTva}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.tauxTva && errors.tauxTva}
                        variant="outlined"
                      />
                    </Grid> */}
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={onDismiss}>Annuler</Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        loading={isSubmitting}
                                        disabled={!dirty || !isValid}
                                    >
                                        Confirmer
                                    </LoadingButton>
                                </DialogActions>
                            </Form>
                        </>
                    );
                }}
            </Formik>
        </Dialog>
    );
}

export default ArticleModal;
