import { createAsyncThunk, EntityId } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../../_config/redux";
import { justifyFraisAutoroute } from "./justification-frais-autoroute";
import { updateComments } from "./update-comments";

interface ISignedBonPayload {
    confirmedFraisAutoroute: number
    commentaire_interne: string,
    evenement: string
    consignes_chauffeur: string
}

export const updateSignedBon = createAsyncThunk<any, { id: EntityId, changes: ISignedBonPayload }, { dispatch: AppDispatch }>(
    "[BON] update signed bon",
    async ({ id, changes }, thunkAPI) => {
        const { confirmedFraisAutoroute, ...rest } = changes;
        await thunkAPI.dispatch(justifyFraisAutoroute({ id, fraisAutoroute: confirmedFraisAutoroute }))
        await thunkAPI.dispatch(updateComments({ id: id.toString(), changes: rest }))
    }
)

