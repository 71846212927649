import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchRequiredData } from "./fetch-required-data";


export const userLogin = createAsyncThunk(
    "[AUTH] Login",
    async (userCredentials: { name: string, password: string }, thunk: any) => {
        const { name: username, password } = userCredentials;
        try {
            const user = await thunk.extra.authService.login(username, password);
            
            if (!user.user.roles.includes("admin")) throw new Error("ACCESS_DENIED : Vous n'avez pas les droits pour accéder à cette application");

            await thunk.dispatch(fetchRequiredData());

            return user;
        } catch (error: any) {
            const message = (error.message && (error.message as string).startsWith('ACCESS_DENIED')) ? error.message.split(":")[1] : "Mot de passe ou identifiant non valides";
            return thunk.rejectWithValue({ message })
        }


    })

