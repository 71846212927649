import httpService from "../../../../_config/axios";
import { StringDateFR } from "../../../../_config/types";
import { Facture } from "../../../Domain/Model";
import { BonDeCommandeRepository } from "../../../Domain/Ports/BonDeCommandeRepository";
import { BonDeCommande } from "../../model/BonDeCommande";
import { LigneDeCommande } from "../../model/LigneDeCommande";
import { InvoiceLine } from "../../../Domain/Model/entities/facture.entity";
import { FournisseurId } from "../../model/FournisseurId";
import { BonDeCommandeId } from "../../model/BonDeCommandeId";
import { removeEmpty } from "../../../../App/app/common/_helpers";

export class BonDeCommandeHttpRepository implements BonDeCommandeRepository {
    private readonly ressourceUri = "bon-de-commande";

    async findEditedBonDeCommandes(params: any): Promise<Facture[]> {
        const result = await httpService.get<BonDeCommande[]>(this.ressourceUri, { params: removeEmpty(params) });
        return result.data.map(toFactureEntity);
    }

    async addBonDeCommande(dto: { fournisseurId: string; bonIds: string[], rib: string, adresseFacturation: string }): Promise<{ bonDeCommandeCreated: Facture }> {
        const result = await httpService.post<BonDeCommande>(this.ressourceUri, {
            fournisseurId: new FournisseurId(dto.fournisseurId).toPersistenceId(),
            ids: dto.bonIds,
            rib: dto.rib,
            adresseFacturation: dto.adresseFacturation
        });

        return { bonDeCommandeCreated: toFactureEntity(result.data) };
    }

    async confirmWithFactureNumber(bonDeCommandeId: string, factureFournisseur: string): Promise<{ bonDeCommandeConfirmed: Facture }> {
        const result = await httpService.put<BonDeCommande>(
            this.ressourceUri + "/" + new BonDeCommandeId(bonDeCommandeId).toPersistenceId() + "/numero-facture-fournisseur", {
                numeroFactureFournisseur: factureFournisseur
            })

        return { bonDeCommandeConfirmed: toFactureEntity(result.data) };
    }

    async editBonDeCommandeFile(bonDeCommandeId: string): Promise<{ file: Blob }> {
        const base64Pdf = await httpService.get(this.ressourceUri + "/" + new BonDeCommandeId(bonDeCommandeId).toPersistenceId(),
            { responseType: 'blob' }
        );
        return { file: base64Pdf.data }
    }
}

function toFactureEntity(bonDeCommande: BonDeCommande): Facture {
    return {
        id: new BonDeCommandeId(bonDeCommande._id).toEntityId(),
        client: {
            id: new FournisseurId(bonDeCommande.fournisseurId).toEntityId(),
            name: bonDeCommande.commandeLines[0].fournisseur.raisonSociale,
        },
        dateDeFacturation: bonDeCommande.editionDate,
        paymentDueDate: bonDeCommande.editionDate,
        numeroFacture: bonDeCommande.numeroFacture,
        reglement: { montant: 0 },
        status: commandeStatus(),
        totalFraisAutoroute: bonDeCommande.commandeLines.reduce((frais, c) => frais + c.fraisAutoroute, 0),
        totalTTC: +(bonDeCommande.totalHT + bonDeCommande.totalTva).toFixed(2),
        totalTva: bonDeCommande.totalTva,
        type: "fournisseur",
        bons: bonDeCommande.commandeLines.map(toFactureLine),
    }

    function commandeStatus() {
        if ( Boolean(bonDeCommande.numeroFacture) ) return "PAID"
        return "CREATED"
    }

    function toFactureLine(aCommandeLine: LigneDeCommande): InvoiceLine {
        return {
            id: aCommandeLine._id,
            article: aCommandeLine.article.libelle,
            bonNumber: aCommandeLine.numeroBon,
            referenceClient: aCommandeLine.referenceClient,
            chantier: aCommandeLine.chantier.denomination,
            dateDePrestation: aCommandeLine.chantier.date,
            prixUnitaireNet: aCommandeLine.article.prixUnitaire.value,
            montantHT: aCommandeLine.article.prixUnitaire.value * aCommandeLine.article.quantite.value,
            quantite: aCommandeLine.article.quantite.value,
            unite: aCommandeLine.article.quantite.unite,
            fraisAutoroute: aCommandeLine.fraisAutoroute,
            fournisseur: aCommandeLine.fournisseur._id,
            client: aCommandeLine.chantier.clientId.name
        }
    }
}

