import React from 'react';
import {  Form, Formik } from 'formik';
import { boolean, object, string } from 'yup';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { addOneribType, retrieveAllribType, updateOneribType } from '../../../../core/rib-type/use-cases';
import { setSuccess } from '../../../../core/notification/notification.slice';

const AffacturageTableAddDialog = ({ open, onCloseDialog, initialValues, typeId }: any) => {
  const schema = object().shape({
    name: string().required(),
    infos: string().required(),
    isDefault: boolean().optional().default(false)
  });

  const dispatch = useAppDispatch();

  const onSubmit = async (values: any) => {
    let res: any;

    if (!!typeId) {
      res = await dispatch(updateOneribType({ id: typeId, ...values }));
    } else {
      res = await dispatch(addOneribType(values));
    }

    if (!res.error) {
      /** Get updated RIB list after submit */
      dispatch(retrieveAllribType())
      /** Await setSuccess to be dispatched before closing */
      await dispatch(setSuccess(!!typeId ? `L'affacturage' a bien été mis à jour` : `L'affacturage' a bien été créé`));
      
      onCloseDialog();
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{`${!!typeId ? 'Modifier' : 'Ajouter'} une société d'affacturage`}</DialogTitle>
      <Formik initialValues={{isDefault: false, ...initialValues }} onSubmit={onSubmit} validationSchema={schema}>
        {(props) => {
          const { values, touched, errors, dirty, isValid, handleChange, handleBlur, handleSubmit, isSubmitting } =
            props;
          return (
            <>
              <Form onSubmit={handleSubmit}>
                <DialogContent sx={{ minWidth: 400 }}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Nom"
                        error={!!errors.name && !!touched.name}
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.name && errors.name}
                        variant="outlined"
                        required
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Adresse"
                        error={!!errors.infos && !!touched.infos}
                        name="infos"
                        value={values.infos}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.infos && errors.infos}
                        variant="outlined"
                        multiline
                        rows={6}
                      />
                    </Grid>
                    <Grid item>
                      {/* <Field 
                        type={() =><Checkbox 
                          // error={!!errors.isDefault && !!touched.isDefault}
                          // onChange={handleChange}
                          // name="isDefault"
                          // defaultChecked={initialValues.isDefault}
                          // checked={values.isDefault}
                          />}
                        name="isDefault"
                        label="RIB par défaut"
                      /> */}
                      
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onCloseDialog}>Annuler</Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={isSubmitting}
                    disabled={!dirty || !isValid}
                  >
                    Confirmer
                  </LoadingButton>
                </DialogActions>
              </Form>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default AffacturageTableAddDialog;
