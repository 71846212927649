import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ChantierEntity } from '../../../../core/chantier/entities/chantier.entity';
import { ViewCard } from '../../../common/layout/components';
import PageHeaderBar from '../../../common/layout/components/PageHeaderBar';
import ConfirmationDialog from '../../../common/modals/components/ConfirmationDialog';
import { EntrepriseManageChantiersList } from './EntrepriseManageChantiersList';
import { selectEntrepriseById } from '../../../../core/entreprise/selectors';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { deleteOneChantier } from '../../../../core/chantier/use-cases/delete-one-chantier';
import { setSuccess } from '../../../../core/notification/notification.slice';

export const EntrepriseManageChantiersView = () => {
  const { id }: any = useParams();
  const entreprise = useSelector(selectEntrepriseById(id));
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState<ChantierEntity>();
  const [dialogsState, setDialogsState] = useState({
    delete: false,
    update: false,
  });

  const modalObj: { link: string; state: any; replace: boolean } = {
    link: '?modal=chantier',
    state: { backgroundLocation: location, extra: id, keepIdInState: false },
    replace: true,
  };

  const onClickAdd = () => {
    const { link, ...obj } = modalObj;
    navigate(link, obj);
  };

  const onClickUpdate = (chantier: ChantierEntity) => {
    const { link, ...obj } = modalObj;
    navigate(link, { ...obj, state: { chantier, ...obj.state } });
  };

  const onConfirmDelete = async () => {
    if (rowData) {
      setLoading(true);
      const res: any = await dispatch(deleteOneChantier(rowData.id));

      if (!res.error) {
        dispatch(setSuccess(`Le chantier a bien été supprimé`));
        handleDialogs('delete');
      }

      setLoading(false);
    }
  };

  const handleDialogs = (buttonId: keyof typeof dialogsState, rowData?: ChantierEntity) => {
    setRowData(rowData);
    setDialogsState({ ...dialogsState, [buttonId]: !dialogsState[buttonId] });
  };

  return (
    <>
      <PageHeaderBar title={`Gestion des chantiers de ${entreprise?.name}`} />
      <ViewCard>
        {entreprise && (
          <EntrepriseManageChantiersList
            entreprise={entreprise}
            onClickAdd={onClickAdd}
            onClickUpdate={onClickUpdate}
            onClickDelete={(c: ChantierEntity) => handleDialogs('delete', c)}
          />
        )}

        {/* Dialogs */}
        <ConfirmationDialog
          open={dialogsState.delete}
          title="Supprimer"
          contentText={`Êtes-vous sûr de vouloir supprimer le chantier ${rowData?.name} ?`}
          isButtonLoading={loading}
          onClose={() => handleDialogs('delete')}
          onConfirm={onConfirmDelete}
          key="delete"
        />
      </ViewCard>
    </>
  );
};
