import * as React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Layout } from '../../App/app/common/layout';

interface Props {
  isLoggedIn: boolean;
}

const ProtectedRoute = ({ isLoggedIn }: Props) => {
  const location = useLocation();

  // Add your own authentication on the below line.
  return !isLoggedIn ? (
    <Navigate replace to="/login" state={{ from: location }} />
  ) : (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default ProtectedRoute;
