import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';

interface InterlocuteurQuery {
	_delete(id: EntityId): Promise<EntityId>;
}

export const deleteOneInterlocuteur = createAsyncThunk('[INTERLOCUTEUR] deleteOneInterlocuteur', async (id: EntityId, thunkAPI) => {
	try {
		return await (thunkAPI.extra as { interlocuteurQuery: InterlocuteurQuery }).interlocuteurQuery._delete(id);
		} catch (error: any) {
			return thunkAPI.rejectWithValue({ message: error.message ?? null });
		}
});
