import { AxiosRequestConfig } from 'axios';
import { User as UserEntity } from '../../core/user/entities/user.entity';
import planningData from './planning_mock_data.json';
import { EntityId, Update } from '@reduxjs/toolkit';
import { Planning } from '../../../Location/Domain/Planning/entities/planning.entity';
import { ArticleEntity } from '../../core/article/entities/article.entity';
import { InterlocuteurEntity } from '../../core/interlocuteur/entities/interlocuteur.entity';
import { format, sub } from 'date-fns';

let planning = planningData;

const users = [
    {
        id: 1,
        name: 'Oscar',
        password: 'tramor',
        email: 'mymail@dat.com',
        roles: 'admin'
    },
    {
        id: 2,
        name: 'Wam',
        password: 'Me',
        roles: 'chauffeur'
    },
    {
        id: 3,
        name: 'Albert',
        password: 'le5eme',
        roles: 'admin'
    }
];

let articles: any = [];
let interlocuteurs: any = [];

const dateFormat = 'dd/MM/yyyy';

interface HttpMethod {
    (path: string, options?: HttpinterceptorOptions): Promise<any>;
}

interface HttpInterceptor {
    get: HttpMethod;
    post: HttpMethod;
    put: HttpMethod;
    patch?: HttpMethod;
    delete: HttpMethod;
}

interface HttpinterceptorOptions extends AxiosRequestConfig {
}

const httpInterceptor = (): HttpInterceptor => {
    async function post(path: string, options?: HttpinterceptorOptions): Promise<any> {
        if ( path.endsWith('login') ) {
            return await simulateApiCall(authenticateUser, options);
        }
        if ( path.endsWith('user') ) {
            return await simulateApiCall(createNewUser, options);
        }
        if ( path.endsWith('chantier') ) {
            return await simulateApiCall(createNewChantier, options);
        }
        if ( path.endsWith('planning') ) {
            return await simulateApiCall(createNewPlanning, options);
        }
        if ( path.endsWith('entreprises') ) {
            return await simulateApiCall(createNewEntreprise, options);
        }
        if ( path.endsWith('article') ) {
            return await simulateApiCall(createNewArticle, options);
        }
        if ( path.endsWith('interlocuteur') ) {
            return await simulateApiCall(createNewInterlocuteur, options);
        }
    }

    async function get(path: string, options?: HttpinterceptorOptions) {
        if ( path.endsWith('user') ) {
            return await simulateApiCall(findAllUsers);
        }
        if ( path.endsWith('entreprises') ) {
            return await simulateApiCall(findAllEntreprises);
        }
        if ( path.startsWith('planning') ) {
            return await simulateApiCall(findAllPlanningByDate, options);
        }
    }

    async function _delete(path: string, options?: HttpinterceptorOptions): Promise<any> {
        if ( path.endsWith('planning') ) {
            if ( options && 'data' in options ) {
                const { data: { ids } } = options;
                return await simulateApiCall(deletePlanning, ids);
            }
        }
    }

    async function put(path: string, options?: HttpinterceptorOptions): Promise<any> {
        if ( path.endsWith('planning') ) {
            return await simulateApiCall(updateOnePlanning, options);
        }
    }

    return {
        post,
        get,
        put,
        delete: _delete,

    };
};

function findAllEntreprises(_: void, cb: Function) {
    return cb([
        {
            name: 'entreprise1',
            id: 'E1',
            contact: { email: 'roget@nimes.com' },
            adresse: { rue: 'Zone du chantier', cp: '30900', ville: 'Nimes' },
            reglement: {}
        },
        {
            name: 'nouvelleCompany ',
            id: 'E2',
            contact: { email: 'new.nime@chantier.com' },
            adresse: { rue: 'du zinc', cp: '34200', ville: 'whereEverYouAre' },
            reglement: {}
        }
    ]);
}

function createNewEntreprise(options: any, cb: Function) {
    const { newEntreprise } = options;
    return cb({ id: `E${Math.floor(Math.random() * (1000 - 1) + 1)}`, ...newEntreprise });
}

function createNewChantier(body: any, cb: Function) {
    return cb({
        id: '1',
        name: body.name,
        clientId: body.clientId,
        imputation: body.imputation
    });
}

function authenticateUser(body: { username: string; password: string }, resFunc: Function) {
    const user = users.find((u) => u.name === body.username && u.password === body.password);
    resFunc(user);
}

function findAllUsers(_: undefined, cb: Function) {
    return cb([...users]);
}

function createNewUser(option: { user: UserEntity }, cb: Function) {
    const user = {
        ...option.user,
        id: Math.max(...users.map((u) => u.id)) + 1
    };
    users.push(user);

    return cb(user);
}

function findAllPlanningByDate(options: any, cb: Function) {
    const today = format(new Date(), dateFormat);
    const yesterday = format(sub(new Date(), { days: 1 }), dateFormat);

    const date = options ? options : today;

    planning = planningData.map((line, index) => ({ ...line, date: index > 5 ? yesterday : today }));
    planning = planning.filter((line) => line.date === date);
    return cb([...planning]);
}

function createNewPlanning(options: { newPlanningLine: Planning }, cb: Function) {
    const newPlanningLine = {
        ...options.newPlanningLine,
        id: `${Math.floor(Math.random() * (1000 - 1) + 1)}`
    };

    // planning.push(newPlanningLine);
    return cb(newPlanningLine);
}

function deletePlanning(ids: EntityId[], cb: Function) {
    planning = planning.filter((item: any) => !ids.includes(item.id));
    return cb(ids);
}

function updateOnePlanning(options: { updatedPlanningLine: Update<Planning> }, cb: Function) {
    const { id, changes } = options.updatedPlanningLine;
    planning = planning.map((line) => (line.id === id ? { ...line, ...changes } : line));
    return cb(planning);
}

function createNewArticle(options: { newArticle: ArticleEntity }, cb: Function) {
    const newArticle = {
        ...options.newArticle,
        id: `${Math.floor(Math.random() * (1000 - 1) + 1)}`
    };

    articles.push(newArticle);
    return cb(newArticle);
}

function createNewInterlocuteur(options: { newInterlocuteur: InterlocuteurEntity }, cb: Function) {
    const newInterlocuteur = {
        ...options.newInterlocuteur,
        id: `${Math.floor(Math.random() * (1000 - 1) + 1)}`
    };

    interlocuteurs.push(newInterlocuteur);
    return cb(newInterlocuteur);
}

function simulateApiCall(fakeRequest: Function, options?: any): any {
    return new Promise((res) =>
        setTimeout(() => {
            fakeRequest(options, res);
        }, 1000)
    );
}

export default httpInterceptor();
