import React from 'react';
import { useNavigate } from 'react-router-dom';
import { EntityId } from '@reduxjs/toolkit';

import { Stack, IconButton, Tooltip, Box } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';

interface Props {
  id: EntityId;
  measuredRef: any;
}

const EntrepriseTableActionsCell = ({ id, measuredRef }: Props) => {
  const navigate = useNavigate();

  /* Edit Action */
  const onClickEdit = () => navigate(`../${id}`, { state: id });

  return (
    <Stack direction="row">
      {/* Buttons */}
      <Box ref={measuredRef}>
        <Tooltip title="Editer">
          <IconButton aria-label="editer" color="primary" size="small" onClick={onClickEdit}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Stack>
  );
};

export default EntrepriseTableActionsCell;
