import { createAsyncThunk } from "@reduxjs/toolkit"
import { removeJwtFromStorage, removeRefreshToken } from "../../../app/auth/services/token.service"
import { authSlice } from "../slice"

export const logout = createAsyncThunk("[AUTH] Logout",
    async (_, thunk) => {
        removeJwtFromStorage();
        removeRefreshToken();
        thunk.dispatch(authSlice.actions.logout())
    }
)     