import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import { User } from '../entities/user.entity';

export const updateOneUser = createAsyncThunk(
	'[USER] updateOneUser',
	async (userDto: Update<User>, thunk: any): Promise<Update<User>> => {
		try {
			const { ...user } = userDto;
			return await thunk.extra.userQuery.update(user);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
