import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageHeaderBar from '../../../../App/app/common/layout/components/PageHeaderBar';
import { ViewCard } from '../../../../App/app/common/layout/components';

import { addOneBon, updateSignedBon, updateOneBon } from '../../../Domain/Bons/use-cases';
import { selectBonById } from '../../../Domain/Bons/selectors';
import { useDefaultValueOf } from "../hooks/useDefaultValueOf";
import { useInitialValueOf } from "../hooks/useInitialValueOf";
import { useSaveEntityOnSubmit } from "../hooks/useSaveEntityOnSubmit";

import PlanningForm from '../planning-form/PlanningForm';

import React from "react";
import useDocumentTitle from '../../../../_config/react/useDocumentTitle';

function BonEdition() {
    useDocumentTitle('MTTP - Edition bon');
    const { id }: any = useParams();
    const bon = useSelector(selectBonById(id));

    const defaultValues = useDefaultValueOf(bon);
    const initialValues = useInitialValueOf(bon);

    const { isLoading, onSubmit } = useSaveEntityOnSubmit({
        entityName: 'bon',
        entityId: id,
        add: addOneBon,
        update: bon?.isSigned
            ? updateSignedBon
            : updateOneBon
    })


    return (
        <>
            <PageHeaderBar title={id ? 'Modifier un bon' : 'Créer un nouveau bon'}/>
            <ViewCard>
                <PlanningForm
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    isButtonLoading={isLoading}
                    defaultValues={defaultValues}
                    isBon={true}
                    isSigned={bon?.isSigned ?? false}
                />
            </ViewCard>
        </>
    );
}

export default BonEdition;

