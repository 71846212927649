import React from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { Edit, Delete } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';

interface Props {
  id: EntityId;
  measuredRef: any;
  onClickEdit(id: EntityId): void;
  onClickDelete(id: EntityId): void;
}

const RibTableActionsCell = ({ id, measuredRef, onClickEdit, onClickDelete }: Props) => {
  return (
    <Stack direction="row">
      {/* Buttons */}
      <Box ref={measuredRef}>
        <Tooltip title="Editer">
          <IconButton aria-label="editer" color="primary" size="small" onClick={() => onClickEdit(id)}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Supprimer">
          <IconButton aria-label="supprimer" color="error" size="small" onClick={() => onClickDelete(id)}>
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    </Stack>
  );
};

export default RibTableActionsCell;
