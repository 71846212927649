import { createAsyncThunk } from '@reduxjs/toolkit';

export const restartType = createAsyncThunk(
	'[restart-TYPE] restart Server',
	async (_: void, thunk: any) => {
		try {
			return  thunk.extra.restartQuery.restartServer();
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);