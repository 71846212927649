import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import ArticleEntity from './entities';
import { addOneArticle } from './use-cases/add-one-article';
import { retreiveAllArticles } from './use-cases/retreive-all-articles';

export const articleEntityAdapter = createEntityAdapter<ArticleEntity>({
    selectId: (article) => article.id
});

export const articleSlice = createSlice({
    name: 'articles',
    initialState: articleEntityAdapter.getInitialState({ search: '', filter: '' }),
    reducers: {
        updateSearch: (state, action) => {
            state.search = action.payload;
        },
        updateFilter: (state, action) => {
            state.filter = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(retreiveAllArticles.fulfilled, (state, action) => {
                articleEntityAdapter.setAll(state, action);
            })
            .addCase(addOneArticle.fulfilled, (state, action) => {
                // modifying object structure to make sure the selector will pick it up
                // let { payload } = action;
                // let fournisseur = { id: `${payload.fournisseur}` }

                // payload = { ...payload, fournisseur }
                articleEntityAdapter.addOne(state, action.payload);
            });
    }
});

export const { updateSearch, updateFilter } = articleSlice.actions;
export default articleSlice.reducer;
