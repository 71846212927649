import React, { useCallback, useEffect, useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';

import { FactureTable, FactureTableToolbar } from '../components/facture/facture-list';
import { TableViewLayout } from '../../../App/app/common/layout/components';
import { useAppDispatch } from "../../../_config/react/useAppDispatch";
import { useCases } from "../../../Location/Domain/Bons";
import { retrieveAllribType } from "../../../App/core/rib-type/use-cases";
import useDocumentTitle from '../../../_config/react/useDocumentTitle';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import ListeEcritureTable from '../components/liste-ecritures/ListeEcritureTable';
import { FactureRelanceTable } from '../components/relance/FactureRelanceTable';
import { useSelector } from 'react-redux';
import { retrieveAllRelances } from '../../Domain/Application/use-cases/retrieve-all-relances';
import { selectAllRelances } from '../../Domain/Application/selectors';
import BdcTableToolbar from '../components/bondecommande/bdc-list/BdcTableToolbar';
import BdcTable from '../components/bondecommande/bdc-list/BdcTable';
import AvoirTableToolbar from '../components/avoir/AvoirTableToolbar';
import AvoirTable from '../components/avoir/AvoirTable';
import { updateFilters } from '../../Domain/Application/facture.slice';
import SaisieEncaissement from '../components/saisie-encaissement/SaisieEncaissement';
import ListeEcritureTableToolbar from '../components/liste-ecritures/ListeEcritureTableToolbar';

function FactureList() {
    useDocumentTitle(`MTTP - Factures`);
    const dispatch = useAppDispatch();

    const retreiveBillableBons = useCallback(function () {
        dispatch(useCases.retrieveBons({ filters: { status: "SIGNED" } }));
    }, [dispatch]);

    dispatch(retrieveAllribType());
    useEffect(() => {
        retreiveBillableBons()
    }, [retreiveBillableBons])


   // const [selectedRows, setSelectedRows] = useState<EntityId[]>([]);

    const [selectedFactures, setSelectedFactures] = useState<EntityId[]>([]);
    const [selectedAvoirs, setSelectedAvoirs] = useState<EntityId[]>([]);
    const [selectedBdc, setSelectedBdc] = useState<EntityId[]>([]);
    const [selectedEcritures, setSelectedEcritures] = useState<EntityId[]>([]);



   // const [selectedType, setSelectedType] = useState<'fournisseur' | 'client' | ''>('');
    const [selectedType] = useState<'fournisseur' | 'client' | ''>('');

    const [value, setValue] = React.useState('factures');

    const handleChange = async (event: React.SyntheticEvent, newValue: string) => {
        await dispatch(updateFilters(null));
        setValue(newValue);
    }


    const [loading, setLoading] = useState(true);
    const relances = useSelector(selectAllRelances);
  
    useEffect(() => {
      dispatch(retrieveAllRelances()).then(() => setLoading(false));
  
      return () => {
        setLoading(true);
      };
    }, [dispatch]);

    return (
        <>

            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange}>
                    <Tab label="Factures" value="factures" />
                    <Tab label="Bon de commandes" value="bdc" />
                    <Tab label="Relances" value="relances" />
                    <Tab label="Règlements" value="encaissements" />
                    <Tab label="Avoirs" value="avoirs" />
                    <Tab label="Ecritures" value="ecritures" />
                </TabList>
                </Box>
                <TabPanel value="factures">
                    <TableViewLayout title="">
                        <FactureTableToolbar
                            selectedRows={selectedFactures}
                            type={'client'}
                            /*selectedType={selectedType}
                            filterType={(t) => setSelectedType(t)}*//>
                        <FactureTable
                            onSelectRows={setSelectedFactures}
                            refreshBons={() => {
                                retreiveBillableBons()
                            }}
                        />
                    </TableViewLayout>
                </TabPanel>
                <TabPanel value="bdc">
                <TableViewLayout title="">
                        <BdcTableToolbar
                            selectedRows={selectedBdc}
                           
                            /*  type={'fournisseur'} selectedType={selectedType}
                            filterType={(t) => setSelectedType(t)}*//>
                        <BdcTable
                            onSelectRows={setSelectedBdc}
                            entrepriseType={'fournisseur'}
                            refreshBons={() => {
                                retreiveBillableBons()
                            }}
                        />
                    </TableViewLayout>
                </TabPanel>
                <TabPanel value="avoirs">
                    <TableViewLayout title="">
                        <AvoirTableToolbar
                            selectedRows={selectedAvoirs}
                            /*selectedType={selectedType}
                            filterType={(t) => setSelectedType(t)}*//>
                        <AvoirTable
                            onSelectRows={setSelectedAvoirs}
                            entrepriseType={selectedType}
                            refreshBons={() => {
                                retreiveBillableBons()
                            }}
                        />
                    </TableViewLayout>
                </TabPanel>
                <TabPanel value="relances">
                    <FactureRelanceTable relances={relances} loading={loading} />
                </TabPanel>
                <TabPanel value="ecritures">
                    <TableViewLayout title="">
                        <ListeEcritureTableToolbar
                                selectedRows={selectedEcritures}
                                /*selectedType={selectedType}
                                filterType={(t) => setSelectedType(t)}*//>
                        <ListeEcritureTable 
                            onSelectRows={setSelectedEcritures}/>
                    </TableViewLayout>
                </TabPanel>
                <TabPanel value="encaissements">
                    <SaisieEncaissement />
                </TabPanel>
          </TabContext>    

        </>
    );
}

export default FactureList;
