import React, { useState } from 'react';
import { Stack, IconButton, Tooltip, Box } from '@mui/material';
import { Chat, Description,PriorityHigh} from '@mui/icons-material';
import { Facture } from '../../../Domain/Model';
import { useCases } from "../../../Domain/Application";
import { useAppDispatch } from "../../../../_config/react/useAppDispatch";
import { CommentsDialog } from '../../../../App/app/common/modals/components/CommentsDialog';
import { updateOneFacture } from '../../../Domain/Application/use-cases/update-one-bon';
interface Props {
    rowData: any;
    measuredRef: any;
    handleDialogs(buttonId: string, rowData: Facture): void;
}

const FactureRelanceTableActionsCell = ({ rowData, measuredRef, handleDialogs }: Props) => {
    /* Display Invoice */
    const dispatch = useAppDispatch()

    const onClickDisplayInvoice = async () => {
        const res = await dispatch(useCases.generateFile(rowData.id)).unwrap();
        const fileURL = window.URL.createObjectURL(res);
        window.open(fileURL);
    };

    const onClickButton = ({ currentTarget: { id: buttonId } }: { currentTarget: { id: string } }) =>
    handleDialogs(buttonId, rowData);

    const [isDialogOpen, setDialogOpen] = useState(false);

    const onClicktoggleDialog = () => {
        setDialogOpen(!isDialogOpen)
    }
    const onClicksaveDialog = async (commentaire:any = null) => {
   
         if(commentaire && isDialogOpen === true) {
             const res: any = await dispatch(updateOneFacture({
                 id: rowData.id,
                 unpaidComment: commentaire
             })).unwrap();
         }
         setDialogOpen(!isDialogOpen)
     }

    return (
        <Stack direction="row">
            {/* Buttons */}
            <Box ref={measuredRef}>
                {/* Editing is available on invoices with CREATED status */}
                <Tooltip title="Relancer">
                            <IconButton aria-label="relancer" id="relance" color="warning" size="small"
                                        onClick={onClickButton}>
                                <PriorityHigh/>
                            </IconButton>
                        </Tooltip>
                <Tooltip title="Afficher facture">
                    <IconButton aria-label="facture" id="facture" color="primary" size="small"
                                onClick={onClickDisplayInvoice}>
                        <Description/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Commentaire(s)">
                    <IconButton aria-label="commentaire" id="commentaire" color={rowData.unpaidComment ? ("primary") : ("default")} size="small"
                                onClick={onClicktoggleDialog}>
                        <Chat/>
                    </IconButton>
                </Tooltip>
                <CommentsDialog
                    comments={rowData.unpaidComment}
                    open={isDialogOpen}                    
                    onClose={onClicktoggleDialog}
                    onSave={onClicksaveDialog}
                    edit={true}/>
            </Box>
        </Stack>
    );
};

export default FactureRelanceTableActionsCell;
