import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';
import { Avoir } from '../../Model';

export const addOneAvoir = createAsyncThunk(
    '[FACTURE] addOneAvoir',
    async (options: { id: EntityId; montant: number, description?: string }, thunk: any): Promise<Avoir> => {
        try {
            return await thunk.extra.factureQuery.addOneAvoir(options);
        } catch (error: any) {
            return thunk.rejectWithValue({ message: error.message ?? null });
        }
    }
);
