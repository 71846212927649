import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EntityId } from '@reduxjs/toolkit';

import { Stack, IconButton, Tooltip, Box } from '@mui/material';
import {
    EditRounded as EditIcon,
    ContentCopyRounded as CopyIcon,
    AttachFileRounded as AttachIcon,
} from '@mui/icons-material';
import {
    CommentaireTableActionCell,
    PlanningDuplicateDialog,
    PlanningAttachmentDialogs
} from '../common';

interface Props {
    planningId: EntityId;
    planningComments: string;
    measuredRef: any;
}

const PlanningTableActionsCell = ({ planningId, planningComments, measuredRef }: Props) => {
    const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false);
    const [isAttachmentsDialogOpen, setIsAttachmentsDialogOpen] = useState(false);

    const navigate = useNavigate();

    const onClickOnEdit = (event: React.MouseEvent<unknown>) => {
        navigate(`../${planningId}`, { state: planningId });
    };

    const handleDuplicateDialog = () => setIsDuplicateDialogOpen(!isDuplicateDialogOpen);

    const onConfirmDuplicateDialog = () => {
        setIsDuplicateDialogOpen(false);
    };

    const handleAttachmentsDialog = () => setIsAttachmentsDialogOpen(!isAttachmentsDialogOpen);


    return (
        <>
            <Stack direction="row">
                <Box ref={measuredRef}>
                    <Tooltip title="Editer">
                        <IconButton aria-label="editer" color="primary" size="small" onClick={onClickOnEdit}>
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Dupliquer">
                        <IconButton aria-label="dupliquer" color="primary" size="small" onClick={handleDuplicateDialog}>
                            <CopyIcon/>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Pièces-jointes">
                        <IconButton aria-label="consulter-piece-jointe" size="small" onClick={handleAttachmentsDialog}>
                            <AttachIcon/>
                        </IconButton>
                    </Tooltip>

                    <CommentaireTableActionCell comment={planningComments}/>

                </Box>
            </Stack>

            <PlanningDuplicateDialog
                planningId={[planningId]}
                open={isDuplicateDialogOpen}
                onClose={handleDuplicateDialog}
                onConfirm={onConfirmDuplicateDialog}
            />
            <PlanningAttachmentDialogs id={planningId} open={isAttachmentsDialogOpen}
                                       onClose={handleAttachmentsDialog}/>
        </>
    );
};

export default PlanningTableActionsCell;
