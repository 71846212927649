import { createAsyncThunk } from '@reduxjs/toolkit';
import InterlocuteurEntity from '../entities';

interface CreateInterlocuteurDto extends Omit<InterlocuteurEntity, 'id'> { }

export const addOneInterlocuteur = createAsyncThunk(
	'[Interlocuteur] addOneInterlocuteur',
	async (interlocuteurDto: CreateInterlocuteurDto, thunk: any): Promise<InterlocuteurEntity> => {
		try {
			const { ...interlocuteur } = interlocuteurDto;
			return await thunk.extra.interlocuteurQuery.add(interlocuteur);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
