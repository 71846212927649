import * as React from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginForm from './LoginForm';

import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { useCases, clearErrors, authSelectors as select } from '../../../../core/auth';
import { AuthLayout } from '../Layout';
import AuthLayoutLink from '../Layout/AuthLayoutLink';
import useDocumentTitle from '../../../../../_config/react/useDocumentTitle';

function Login() {
  useDocumentTitle('MTTP - Identification');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isLoading = useSelector(select.selectIsLoadingAuth);
  const hasError = useSelector(select.selectAuthErrors);
  const from = (location.state as any)?.from?.pathname || '/';

  const login = (userLogginDetails: { username: string; password: string }) => {
    const { username, password } = userLogginDetails;
    dispatch(useCases.userLogin({ name: username, password })).then(() => navigate(from, { replace: true }));
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (hasError.length > 0) dispatch(clearErrors());
    }, 4000);
  }, [dispatch, hasError]);

  return (
    <AuthLayout title="Connexion" errors={hasError}>
      <LoginForm login={login} isLoading={isLoading} />
      <AuthLayoutLink to="/reset-password" text="Mot de passe oublié ?" />
    </AuthLayout>
  );
}

export default Login;
