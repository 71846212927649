import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';


export const relancerFacture = createAsyncThunk(
  '[FACTURE] relancerFacture',
  async ({ id, anEmail }: { id: EntityId, anEmail: string }, thunk: any
    ): Promise<any> => {
    try {
      return await thunk.extra.factureQuery.relancerFacture(id, anEmail);
    } catch (error: any) {
      return thunk.rejectWithValue({ message: error.message ?? null });
    }
  }
)