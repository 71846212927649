import { createAsyncThunk } from '@reduxjs/toolkit';
import { Facture } from '../../Model'
import { BonDeCommandeRepository } from "../../Ports/BonDeCommandeRepository";

interface Params {
    /** @deprected don't use this => Params must be equal to request params */
    filters?: any;
    status?: string;
    from?: string;
    to?: string;

    [key: string]: string | undefined;
}

// Bon de Commande Repository ..?
interface FactureQuery extends Pick<BonDeCommandeRepository, 'findEditedBonDeCommandes'> {
    all(params: any): Promise<Facture[]>
}

type FactureQueryResult = Facture[]; 

export const retrieveAllFactures = createAsyncThunk<FactureQueryResult, Params, { extra: { factureQuery: FactureQuery } }>(
    '[FACTURE] retrieveAllFactures',
    async (params: Params, thunkAPI) => {
        try {
            // Mélange / merge de factures et bon de commandes
            const editedBonDeCommandes = await thunkAPI.extra.factureQuery.findEditedBonDeCommandes(params as any)
            const editedFactures = await thunkAPI.extra.factureQuery.all(params);
            return editedFactures.concat(editedBonDeCommandes)
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ message: error.message ?? null });
        }
    })

