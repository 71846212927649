import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface Props {
  open: boolean;
  title: string;
  contentText: string;
  isButtonLoading: boolean;
  onClose(event: React.MouseEvent): void;
  onConfirm(event: React.MouseEvent): void;
}

function ConfirmationDialog({ open, title, contentText, isButtonLoading, onClose, onConfirm }: Props) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <LoadingButton variant="contained" loading={isButtonLoading} onClick={onConfirm} autoFocus>
          Confirmer
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
