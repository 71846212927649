import React, { useEffect, useState } from 'react';
import { format, lastDayOfMonth, addDays } from 'date-fns';
import { useSelector } from 'react-redux';
import {
    Box,
    Divider, FormControl,
    InputLabel,
    MenuItem,
    Select, SelectChangeEvent,
    Stack,
    Typography
} from '@mui/material';
import { selectBonsForFactureGroupByCompany } from '../../../../Domain/Application/selectors';
import { selectEntrepriseById } from '../../../../../App/core/entreprise/selectors';
import { DatePickerField } from '../../../../../App/app/common/date-picker';
import { getSelectedBonsById, selectAllBons } from '../../../../../Location/Domain/Bons/selectors';
import { selectAllribTypes } from "../../../../../App/core/rib-type/selectors";
import { IAdresse } from "../../../../../App/core/entreprise/entities/adresse.entity";
import { Facture } from '../../../../Domain/Model';
import { CustomTable } from '../../../../../App/app/common/custom-table';
import { ColumnEntity } from '../../../../../App/app/common/custom-table/Table.entities';
import { ribType } from '../../../../../App/core/rib-type/entities';
import { IChantierWithBons } from '../../../../Domain/Model/Bon';
import { dateFormat } from '../../../../../App/app/common/_helpers';
 

interface Props {
    entrepriseId: string;
    bonsIds: string[];
    date: string;
    expirationDate: string;
    facture: any;
    setDate(_: any, date: string): void;
    setExpirationDate(_: any, expirationDate: string): void;
    onRibSelection(e: SelectChangeEvent): void;
    onAdresseSelection(e: SelectChangeEvent): void;
    ribTypes:ribType[];
    bonsByChantier: IChantierWithBons;
}

const BdcEditDialogConfirm = (
    {
        entrepriseId,
        bonsIds,
        date,
        expirationDate,
        setDate,
        setExpirationDate,
        onRibSelection,
        onAdresseSelection,
        facture,
        ribTypes,
        bonsByChantier
    }: Props
) => {
    const entreprise = useSelector(selectEntrepriseById(formatToEntrepriseIdIfNecessary(entrepriseId)));
 

    const [exipiration,setExipiration] = useState(expirationDate);
    const [factureDate, setFactureDate] = useState(date);

    const handleExpiration = (_: any, value: string) => {
        setExpirationDate('',value);
        setExipiration(value);
    }

    const handleFactureDate  = (_: any, value: string) => {
        setDate('',value);
        setFactureDate(value);
    }

    let bons:any = [];
    bonsByChantier.map((chantier) => {
        chantier.bons.map((bon) => {
            if(bonsIds.includes(bon.id)) {
                let addChantier = JSON.parse(JSON.stringify(bon));
                addChantier.chantier = chantier.name;
                bons.push(addChantier);
            }
        });
    }); //useSelector(getSelectedBonsById(bonsIds));
 
    

    function adresseToString(uneAdresse?: IAdresse) {
        if ( !uneAdresse ) return '';
        if (
            !uneAdresse.rue
            && !uneAdresse.ville
            && !uneAdresse.cp
        ) return 'Non renseigné';

        return `${uneAdresse.rue}, ${uneAdresse.cp}, ${uneAdresse.ville}`;
    }

    function adresseKey(uneAdresse?: IAdresse) {
        if ( !uneAdresse ) return '';
        return `${uneAdresse.rue}_${uneAdresse.cp}_${uneAdresse.ville}`;
    }

    let defaultRib = ribTypes.filter((rib:any) => rib.isDefault === true);
    if(entreprise && entreprise?.rib_mttp && entreprise?.rib_mttp != "") {
        defaultRib = ribTypes.filter((rib:any) => rib._id === entreprise?.rib_mttp);
    }
    if(facture?.rib) {
        defaultRib = ribTypes.filter((rib:any) => rib._id === facture?.rib._id);
    }
    
    const onSelectRows = () => {}
    const columns: ColumnEntity[] = [
        { field: 'datePrestation', headerName: 'Date', width: 110, sortable: true, resizable: true },
        { field: 'numeroBon', headerName: 'N° Bon', flexGrow: 1, sortable: true },
        { field: 'chantier', headerName: 'Chantier', flexGrow: 1, sortable: true, resizable: true },
        { field: 'articleLabel', headerName: 'Article', width: 160, sortable: true, resizable: true },
        { field: 'fraisAutoroutee', headerName: 'Frais Autoroute', width: 140, sortable: true, resizable: true },
        { field: 'unite', headerName: 'Unité', width: 110, sortable: true, resizable: true },
        { field: 'prixUnitairee', headerName: 'PU HT', sortable: true, resizable: true },
        { field: 'quantity', headerName: 'Qte', width: 60, sortable: true, resizable: true },
        { field: 'montantHTe', headerName: 'Montant HT', width: 110, sortable: true, resizable: true },
    ];
 
    const table_bons = JSON.parse(JSON.stringify(bons));
 
    table_bons.forEach((bon:any) => {
        bon.datePrestation = bon.dateOfPrestation.length > 10 ? format(new Date(bon.dateOfPrestation), dateFormat) : bon.dateOfPrestation;
        bon.prixUnitairee = Number(bon.prixUnitaire).toFixed(2) + " €";
        bon.fraisAutoroutee = Number(bon.fraisAutoroute).toFixed(2) + " €";
        /*bon.achatHTe = Number(bon.achatHT).toFixed(2) + " €";*/
        bon.montantHTe = Number(bon.montantHT).toFixed(2) + " €";
    });

    return (
        <Stack direction="column" my={3} spacing={2}>
            <Stack direction="row" spacing={3} justifyContent="space-between">

                <DatePickerField
                    required
                    variant="outlined"
                    label="Date de facturation"
                    id="Date"
                    value={factureDate}
                    onDateUpdate={handleFactureDate}
                />

                <DatePickerField
                    required
                    variant="outlined"
                    label="Echéance le"
                    id="Expire"
                    value={exipiration}
                    onDateUpdate={handleExpiration}
                />

                <FormControl fullWidth>
                    <InputLabel id="adresse-facturation">Adresse de facturation</InputLabel>
                    <Select label="Adresse de facturation" labelId="adresse-facturation"
                            onChange={onAdresseSelection}
                    >
                        <MenuItem value={adresseKey(entreprise?.adresse)}>
                            {adresseToString(entreprise?.adresse)}
                        </MenuItem>
                        {entreprise?.adresseFacturation && entreprise?.adresseFacturation?.length > 0 &&
                            entreprise.adresseFacturation.map(a =>
                                <MenuItem
                                    key={adresseKey(a)}
                                    value={adresseToString(a)}>
                                    {adresseToString(a)}
                                </MenuItem>
                            )}
                    </Select>
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel id="rib-type">Rib</InputLabel>
                    <Select 
                        label="RIB" 
                        labelId="rib-type"  
                        defaultValue={defaultRib[0]?._id} 
                        onChange={onRibSelection}>
                        {ribTypes.map(r =>
                            <MenuItem key={r._id} value={r._id}>
                                {r.name}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>

            </Stack>

            <Box>
                <Typography variant="h6">Entreprise sélectionnée:</Typography>
                <Typography variant="body1">{entreprise?.name}</Typography>
                <Typography variant="body1">
                    {entreprise?.adresse?.rue ? entreprise?.adresse?.rue : '' } {entreprise?.adresse?.cp && entreprise?.adresse?.ville ? ',' + entreprise?.adresse?.cp + ' - ' + entreprise?.adresse?.ville: ''}
                </Typography>
            </Box>

            <Divider/>

            <Box>
                <Typography variant="h6">Bons sélectionnés:</Typography>
                <CustomTable
                    data={table_bons}
                    columns={columns}
                    hover={false}
                    onSelectRows={onSelectRows}
                    selectable={false}
                />
            </Box>
        </Stack>
    );
};

function formatToEntrepriseIdIfNecessary(entrepriseId: string): string {
    if ( entrepriseId?.startsWith('F') ) return entrepriseId.substring(1);
    return entrepriseId;
}

export default BdcEditDialogConfirm;


