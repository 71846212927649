import { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface Props {
    open: boolean;
    title: string;
    contentText: string;
    isButtonLoading: boolean;
    onClose(event: React.MouseEvent): void;
    onConfirm(event: React.MouseEvent | string): void;
}

function DeleteFactureDialog(
    {
        open,
        title,
        contentText,
        isButtonLoading,
        onClose,
        onConfirm
    }: Props) {

    //const [email, setEmail] = useState('')

    const onConfirmAction = () => onConfirm("delete")

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{contentText}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annuler</Button>
                <LoadingButton variant="contained" loading={isButtonLoading} onClick={onConfirmAction} autoFocus>
                    Confirmer
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
        ;
}

export default DeleteFactureDialog;
