import { createAsyncThunk } from "@reduxjs/toolkit";
import ChantierEntity from "../entities";

interface CreateChantierDto extends Omit<ChantierEntity, 'id'> { }

export const addNewChantier = createAsyncThunk(
  "[CHANTIER] addNewChantier",
  async (chantierDto: CreateChantierDto, thunk: any): Promise<ChantierEntity> => {
    try {
        const { client, ...chantier } = chantierDto;
        return await thunk.extra.chantierQuery.add(client, chantier)
    } catch (error: any) {
        return thunk.rejectWithValue({ message: error.message ?? null });
    }
})