import { createAsyncThunk, EntityId } from "@reduxjs/toolkit";

interface Options {
  id: EntityId;
  password: string;
}

export const updatePassword = createAsyncThunk(
  "[USER] updatePassword",
  async (options: Options, thunk: any) => {
    try {
      return await thunk.extra.userQuery.updatePassword(options);
    } catch (error: any) {
      return thunk.rejectWithValue({ message: error.message ?? null });
    }
  }
)