import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';

interface Options {
    planningId: EntityId;
    fileId: EntityId;
}

export const retrievePlanningAttachment = ({ planningId, fileId }: Options) => {
    return retreivePlanningAttachmentThunk({ planningId, fileId })
}

export const retreivePlanningAttachmentThunk = createAsyncThunk<any, Options, { extra: { planningService: { getAttachment: (options: Options) => Promise<any> } } }>(
    "[PLANNING] retreiveAttachment",
    async (options, thunkApi) => {
        return thunkApi.extra.planningService.getAttachment(options)
    }
)