import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import { affacturageType } from '../entities'

export const updateOneaffacturageType = createAsyncThunk(
	'[affacturage-TYPE] updateOneaffacturageType',
	async (affacturage: Update<affacturageType>, thunk: any): Promise<Update<affacturageType>> => {
		try {
			return thunk.extra.affacturageTypeQuery.update(affacturage);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
