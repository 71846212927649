import { createAsyncThunk, EntityId, Update } from "@reduxjs/toolkit"
import { User } from "../entities/user.entity";

export const reactivateUser = createAsyncThunk(
    "[USER] REACTIVATE USER",
    async (userIds: EntityId[], thunk: any): Promise<Update<User>[]> => {
        return thunk.extra.userQuery.reactivate({ ids: userIds });
    }
)

