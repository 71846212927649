import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaymentType } from '../entities';

export const addOnePaymentType = createAsyncThunk(
	'[PAYMENT-TYPE] addOnePaymentType',
	async (paymentType: PaymentType, thunk: any): Promise<PaymentType> => {
		try {
			return await thunk.extra.paymentTypeQuery.add(paymentType);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
