import React from 'react';
import ReactDOM from 'react-dom';
import { store } from './_config/redux';
import { Provider } from 'react-redux';
import App from './_config/react/App';
import './index.css';
import { useCases } from './App/core/auth';
import tokenService from './App/infra/storage-provider/token-storage.service';
// import reportWebVitals from './reportWebVitals';

const refreshToken = tokenService.get().refresh();
if (refreshToken !== null) {
  store.dispatch(useCases.authenticateUser(refreshToken));
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// process.env.NODE_ENV !== 'production' && reportWebVitals(console.log);
