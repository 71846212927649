import React from 'react';
import { useSelector } from 'react-redux';
import { Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItemText,
  ListSubheader,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { AccountCircle, Add, Delete } from '@mui/icons-material';

import { selectInterlocuteursByCompany } from '../../../../core/interlocuteur/selectors';
import { EntrepriseEntity } from '../../../../core/entreprise/entities';
import InterlocuteurEntity from '../../../../core/interlocuteur/entities';

interface Props {
  entreprise: EntrepriseEntity;
  onClickDelete(interlocuteur: InterlocuteurEntity): void;
  onClickAdd(): void;
  onClickCreateAccount(interlocuteur: InterlocuteurEntity): void;
  onClickEdit(interlocuteur: InterlocuteurEntity): void;
}

const EntrepriseManageInterlocuteursList = ({ entreprise, onClickDelete, onClickAdd, onClickCreateAccount, onClickEdit}: Props) => {
  const interlocuteurs = useSelector(selectInterlocuteursByCompany(entreprise?.id));

  return (
    <List
      subheader={
        <ListSubheader>
          <Stack direction="row" justifyContent="space-between" my={1}>
            <Typography variant="h6">Interlocuteurs</Typography>
            <Tooltip title="Ajouter un interlocuteur">
              <Button variant="outlined" color="secondary" size="small" onClick={onClickAdd}>
                <Add />
              </Button>
            </Tooltip>
          </Stack>
        </ListSubheader>
      }
      sx={{ px: 4, py: 2 }}
    >
      {!!interlocuteurs.length ? (
        interlocuteurs.map((i) => (
          <div key={i.id}>
            <Divider />
            <Stack direction="row" alignItems="center" justifyContent="space-between" m={1}>
              <Box>
                <ListItemText primary={`${i.prenom ?? ''} ${i.nom.toUpperCase()}`} />
                <ListItemText secondary={i.fonction ?? ''} />
                <ListItemText secondary={`${i.email ?? ''} - ${i.telephone ?? ''}`} />
              </Box>
              <Stack direction="row">
                <Tooltip title="Créer compte utilisateur">
                  <IconButton size="medium" onClick={() => onClickCreateAccount(i)}>
                    <AccountCircle color="secondary" sx={{ fontSize: 24 }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Éditer un interlocuteur">
                  <IconButton color="primary" size="medium" onClick={() => onClickEdit(i)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer">
                  <IconButton size="medium" onClick={() => onClickDelete(i)}>
                    <Delete color="error" sx={{ fontSize: 24 }} />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          </div>
        ))
      ) : (
        <Typography variant="body1">Aucune donnée...</Typography>
      )}
    </List>
  );
};

export default EntrepriseManageInterlocuteursList;
