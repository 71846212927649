import { useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';

import { archiveBons } from '../../../Domain/Bons/use-cases';

import { setSuccess } from '../../../../App/core/notification/notification.slice';
import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import ConfirmationDialog from '../../../../App/app/common/modals/components/ConfirmationDialog';
import { TableViewLayout } from '../../../../App/app/common/layout/components';
import BonTableToolbar from './BonTableToolbar';
import BonTable from './BonTable';
import useDocumentTitle from '../../../../_config/react/useDocumentTitle';

function BonList() {

    useDocumentTitle(`MTTP - Bons`);

    const dispatch = useAppDispatch();

    const [selectedRows, setSelectedRows] = useState<EntityId[]>([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isConfirmDeleteButtonLoading, setIsConfirmDeleteButtonLoading] = useState(false);

    const handleDialog = () => setIsDialogOpen(!isDialogOpen);

    const onConfirmDialog = async () => {
        setIsConfirmDeleteButtonLoading(true);

        const res: any = await dispatch(archiveBons(selectedRows));
        if ( !res.error ) {
            setIsDialogOpen(false);
            dispatch(setSuccess(`Les données ont bien été archivées`));
        }

        setIsConfirmDeleteButtonLoading(false);
    };

    return (
        <>
            <TableViewLayout title="Bons">
                <BonTableToolbar onClickArchiveMultiple={handleDialog} selectedRows={selectedRows}/>
                <BonTable onSelectRows={setSelectedRows}/>
            </TableViewLayout>
            <ConfirmationDialog
                open={isDialogOpen}
                title="Archiver"
                contentText={`Êtes-vous sûr de vouloir archiver ces données ?`}
                isButtonLoading={isConfirmDeleteButtonLoading}
                onClose={handleDialog}
                onConfirm={onConfirmDialog}
            />
        </>
    );
}

export default BonList;
