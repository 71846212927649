import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import { AppRoute } from '../routes';
import './App.css';
import theme from './theme';
import useDocumentTitle from './useDocumentTitle';


function App() {
  useDocumentTitle(`MTTP - ERP`);
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <AppRoute />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
