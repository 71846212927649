import { createAsyncThunk } from '@reduxjs/toolkit';
import { CategorieProduit } from '../entities';

export const addOneCategorieProduit = createAsyncThunk(
	'[CATEGORIE-PRODUIT] addOneCategorieProduit',
	async (categorie: Omit<CategorieProduit, '_id'>, thunk: any): Promise<CategorieProduit> => {
		try {
			return await thunk.extra.categorieProduitQuery.add(categorie);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
