import React from "react";
import { Checkbox, IconButton } from "@mui/material";
import { Cell as RsuiteCell, Column, HeaderCell, Table } from "rsuite-table";
import { StringDateFR } from "../../../_config/types";
import { toDateStringFr } from "../../../App/app/common/_helpers";
import { IBillableBon, IChantierWithBons } from "../../Domain/Model/Bon";
import { EditRounded as EditIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import './facture/facture-list/FactureTable.style.css';
interface Props {
  bonsByChantier: IChantierWithBons;
  loading?: boolean;
  selectedBons: string[];
  addBonToSelection: (bons: string[]) => void;
  selectedEntrepriseType: string;
}

interface IBonsGroupByChantier {
  id: string;
  name: string;

  [key: string]: any;

  children: Array<
    IBillableBon & {
      id: string;
      name: StringDateFR;
      client?: string;
    }
  >;
}

type BonAsChantierChild = IBonsGroupByChantier["children"][number];

export default function SelectBonGroupByChantiers({
  bonsByChantier,
  loading,
  selectedBons,
  addBonToSelection: setSelectedBons,
  selectedEntrepriseType,
}: Props) {
  const navigate = useNavigate();
  function selectOneBon(id: string) {
    return () => {
      selectedBons.includes(id)
        ? setSelectedBons(selectedBons.filter((anInvoice) => anInvoice !== id))
        : setSelectedBons([...selectedBons, id]);
    };
  }

  function selectAllBonsOfChantier(rowData: IBonsGroupByChantier) {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        const childrensIds = rowData.children?.map((c) => c.id) ?? [];
        setSelectedBons([
          ...selectedBons.filter((sb) => !childrensIds.includes(sb)),
          ...childrensIds,
        ]);
      } else {
        setSelectedBons(
          selectedBons.filter(
            (anInvoice) =>
              rowData.children?.findIndex((child) => child.id === anInvoice) ===
              -1
          )
        );
      }
    };
  }

  const formatedBonsByChantier: IBonsGroupByChantier[] = bonsByChantier.map(
    (aChantier) => ({
      id: aChantier.id as string,
      name: aChantier.name,

      children: aChantier.bons
        .map((aBon) => ({
          ...aBon,
          id: aBon.id,
          name: toDateStringFr(aBon.dateOfPrestation),
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
        .flatMap((aChild) =>
          aChild.fraisAutoroute && aChild.fraisAutoroute > 0
            ? [aChild, createLineForFraisAutoroute(aChild)]
            : [aChild]
        ),
    })
  );

  function createLineForFraisAutoroute(
    aBillableBon: BonAsChantierChild
  ): BonAsChantierChild {
    return {
      ...aBillableBon,
      articleLabel: "Autoroute",
      montantHT: aBillableBon.fraisAutoroute as number,
      prixUnitaire: aBillableBon.fraisAutoroute as number,
      quantity: 1,
      referenceClient: "",
      type: "Transport",
      unite: "forfait",
    };
  }

  return (
    <>
      <Table
        data={formatedBonsByChantier}
        isTree
        rowKey="id"
        loading={loading}
        loadAnimation={true}
        rowHeight={37}
        height={600}
        defaultExpandAllRows
        hover
        className="custom-table"
      >
        <Column treeCol width={130} resizable>
          <HeaderCell>Chantier</HeaderCell>
          <Cell dataKey="name" />
        </Column>
        <Column verticalAlign="middle" width={50}>
          <HeaderCell>(s)</HeaderCell>
          <Cell>
            {(rowData: IBonsGroupByChantier) =>
              "children" in rowData ? (
                <Checkbox
                  data-testid="check-all-chantier"
                  style={{ height: 20, paddingLeft: 0 }}
                  onChange={selectAllBonsOfChantier(rowData)}
                  checked={rowData.children?.every((child) =>
                    selectedBons.includes(child.id)
                  )}
                  indeterminate={
                    rowData.children?.some((child) =>
                      selectedBons.includes(child.id)
                    ) &&
                    !rowData.children?.every((child) =>
                      selectedBons.includes(child.id)
                    )
                  }
                />
              ) : (
                <Checkbox
                  data-testid="check-one-bon"
                  style={{ height: 20, paddingLeft: 0 }}
                  onChange={selectOneBon(rowData.id)}
                  checked={selectedBons.includes(rowData.id)}
                />
              )
            }
          </Cell>
        </Column>
        <Column width={80} resizable>
          <HeaderCell>Bon</HeaderCell>
          <Cell dataKey="numeroBon" />
        </Column>
        <Column width={180} resizable>
          <HeaderCell>Libellé</HeaderCell>
          <Cell dataKey="articleLabel" />
        </Column>
        <Column width={150} resizable>
          <HeaderCell>
            {selectedEntrepriseType === "fournisseur"
              ? "Client"
              : "Fournisseur"}
          </HeaderCell>
          {/*<Cell dataKey='client'/>*/}
          <Cell
            dataKey={
              selectedEntrepriseType === "fournisseur"
                ? "Client"
                : "Fournisseur"
            }
          />
        </Column>
        <Column width={70} resizable>
          <HeaderCell>Type</HeaderCell>
          <Cell dataKey="type" />
        </Column>
        <Column width={130}>
          <HeaderCell>Ref</HeaderCell>
          <Cell dataKey="referenceClient" />
        </Column>
        <Column width={50}>
          <HeaderCell>Qté</HeaderCell>
          <Cell dataKey="quantity" />
        </Column>
        <Column width={80}>
          <HeaderCell>Unité</HeaderCell>
          <Cell dataKey="unite" />
        </Column>
        <Column width={80}>
          <HeaderCell>P.U Net</HeaderCell>
          <Cell dataKey="prixUnitaire" />
        </Column>
        <Column width={100}>
          <HeaderCell>Montant H.T</HeaderCell>
          <Cell dataKey="montantHT" />
        </Column>
        <Column width={80} fixed="right">
          <HeaderCell>...</HeaderCell>

          <Cell style={{ padding: "6px" }}>
            {(rowData: any) =>
              "children" in rowData ? (
                <></>
              ) : (
                <IconButton
                  aria-label="editer"
                  color="primary"
                  size="small"
                  onClick={() => navigate(`/bons/${rowData.id}`)}
                >
                  <EditIcon />
                </IconButton>
              )
            }
          </Cell>
        </Column>
      </Table>
    </>
  );
}

function Cell({ rowData, ...rest }: any) {
  if ("children" in rowData)
    return (
      <RsuiteCell
        {...rest}
        rowData={rowData}
        style={{
          background: "cadetBlue",
          color: "white",
          fontWeight: "bold",
        }}
      />
    );

  return <RsuiteCell {...rest} rowData={rowData} />;
}
