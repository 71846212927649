import { Facture } from "../../../../Facturation/Domain/Model/entities/facture.entity";

export function useSummary(collection: Array<Facture>) {
    const totalTVA = collection.reduce((acc, facture: any) => {
        const tva = typeof facture.totalTva === 'number' && !isNaN(facture.totalTva) ? facture.totalTva : 0;
        return acc + tva;
    }, 0);  
    const totalTTC = collection.reduce((acc, facture:any) => acc + facture.totalTTC, 0);
    const totalHT = (totalTTC - totalTVA);
 
    function toEuroString(amount: number) {
        return new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(amount);
    }

    return {
        totalTTC: toEuroString(totalTTC),
        totalTVA: toEuroString(totalTVA),
        totalHT: toEuroString(totalHT),
    };
}
