import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';

interface ChantierQuery {
	_delete(id: string): Promise<EntityId>;
}

export const deleteOneChantier = createAsyncThunk('[CHANTIER] deleteOneChantier', async (id: string, thunkAPI) => {
	try {
		return await (thunkAPI.extra as { chantierQuery: ChantierQuery }).chantierQuery._delete(id);
		} catch (error: any) {
			return thunkAPI.rejectWithValue({ message: error.message ?? null });
		}
});
