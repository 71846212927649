/* eslint-disable no-template-curly-in-string */

// Based on https://github.com/jquense/yup/blob/master/src/locale.ts
import { LocaleObject } from "yup/lib/locale";

export const mixed: LocaleObject['mixed'] = {
  default: '${path} est invalide.',
  required: '${path} est un champ obligatoire',
  oneOf: "${path} doit être l'une des valeurs suivantes : ${values}",
  notOneOf: "${path} ne doit pas être l'une des valeurs suivantes : ${values}"
}

export const string: LocaleObject['string'] = {
  length: '${path} doit être exactement ${length} caractères',
  min: '${path} doit être au moins ${min} caractères',
  max: '${path} doit être au plus ${max} caractères',
  matches: '${path} doit correspondre à ce qui suit : "${regex}"',
  email: '${path} doit être un email valide',
  url: '${path} doit être une URL valide',
  trim: '${path} doit être une chaîne garnie',
  lowercase: '${path} doit être une chaîne en minuscule',
  uppercase: '${path} doit être une chaîne de majuscules',
};

export const number: LocaleObject['number'] = {
  min: '${path} doit être supérieure ou égale à ${min}',
  max: '${path} doit être inférieur ou égal à ${max}',
  lessThan: '${path} doit être inférieure à ${less}',
  moreThan: '${path} doit être supérieure à ${more}',
  positive: '${path} doit être un nombre positif',
  negative: '${path} doit être un nombre négatif',
  integer: '${path} doit être un entier',
};