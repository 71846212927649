import { createAsyncThunk } from "@reduxjs/toolkit";
import { Bon } from "../../../Model/Bon";
import { FactureService } from "../../../Ports/FactureService";

interface BonsDeCommandeResponse {
    bonsDeCommande: Bon[]
}

export const findPendingBonsDeCommande = createAsyncThunk<BonsDeCommandeResponse, void, { extra: { factureQuery: FactureService } }>(
    "[FACTURE] find pending bon de commande",
    async (_, thunkAPI) => {
        return thunkAPI.extra.factureQuery.findAllPendingBonsDeCommande()
    }
)