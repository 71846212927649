import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';

interface Params {
  id: EntityId;
  montant: string;
  paymentType: string;
  bank? : string;
  lettrage? : string;
}

export const validatePayment = createAsyncThunk(
	'[FACTURE] validatePayment',
	async (params: Params, thunk: any): Promise<any> => {
		try {
			return await thunk.extra.factureQuery.validatePayment(params);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);