import React from 'react';
import { Box } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

export const ViewCard = ({ children }: Props) => {
  return (
    <Box sx={{ minHeight: '100%', borderRadius: 2, border: '1px solid #ddd', backgroundColor: '#fff' }}>{children}</Box>
  );
};
