import React from "react";
import { List, Stack, Typography } from "@mui/material";
import { Avoir } from "../../../Domain/Model";
import { AvoirListItem } from "./AvoirListItem";

export function AvoirList({ avoirs }: { avoirs: Avoir[] }) {

    if ( !avoirs.length ) return <Typography variant="body1">Aucun avoir...</Typography>;

    return <List sx={{ maxHeight: '500px', overflow: 'auto' }}>
        {avoirs.map((avoir) =>
            <AvoirListItem
                key={avoir._id}
                avoir={avoir}
            />
        )}
    </List>

}