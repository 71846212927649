import { retreiveFromStorage, saveInStorage } from "./storage.service";

const ACCESS_KEY = process.env.ACCESS_TOKEN_KEY || 'access-token';
const ACCESS_EXPIRES_KEY = process.env.ACCESS_TOKEN_EXPIRES_KEY || 'access-exp';
const REFRESH_KEY = process.env.REFRESH_TOKEN_KEY || "refresh-token";
const REFRESH_EXPIRES_KEY = process.env.REFRESH_TOKEN_EXPIRES_KEY || 'refresh-exp';

interface Token {
    token: string,
    exp?: number
}

const getAccessToken = (): string | null => {
    return retreiveFromStorage(ACCESS_KEY)
}
const getAccessTokenExpirationTime = () => {
    return retreiveFromStorage(ACCESS_EXPIRES_KEY);
}
const getRefreshToken = (): string | null => {
    return retreiveFromStorage(REFRESH_KEY)
}
const getRefreshTokenExpirationTime = () => {
    return retreiveFromStorage(REFRESH_EXPIRES_KEY);
}

const setAccessToken = (accessToken: string) => {
    saveInStorage(ACCESS_KEY, accessToken)
}
const setRefreshToken = (refreshToken: string) => {
    saveInStorage(REFRESH_KEY, refreshToken)
}

const setTokens = (tokens: { access: Token, refresh: Token }) => {
    const { access, refresh } = tokens;
    setAccessToken(access.token)
    setRefreshToken(refresh.token)
}

const tokenStorageService = {
    _getState() { return this; },
    get() {
        const state = this._getState();
        return {
            _state: state,
            _accessExpire: getAccessTokenExpirationTime,
            _refreshExpire: getRefreshTokenExpirationTime,
            access: getAccessToken,
            refresh: getRefreshToken,
            all(): { access: Token, refresh: Token } | void {
                if (!this._state.isValid()) return;

                return {
                    access: { token: `${this.access()}`, exp: +`${this._accessExpire()}` },
                    refresh: { token: `${this.refresh()}`, exp: +`${this._refreshExpire()}` }
                }
            }
        }
    },
    set() {
        return {
            all: setTokens
        }
    },
    isValid() {
        const refreshToken = getRefreshToken();
        return !!refreshToken;

    }
}

export default tokenStorageService;