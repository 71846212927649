/* eslint-disable react-hooks/exhaustive-deps */
import React, { MouseEvent, useEffect, useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IconButton, Stack, Badge, Tooltip, Button } from '@mui/material';
import { Archive as ArchiveIcon, Tune as TuneIcon, Add, Receipt, FileDownload } from '@mui/icons-material';

import { RootState } from '../../../../_config/redux';

import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { DateRangeField } from '../../../../App/app/common/date-picker';
import { CustomTableToolbar } from '../../../../App/app/common/custom-table';

import { updateDates } from '../../../Domain/Bons/bon.slice';

import { BonSearchPopover, BonEditionDialog } from '../common';
import { selectAllBons } from '../../../Domain/Bons/selectors';
import { exportToXLSX } from '../../../../App/app/common/_helpers';

interface Props {
    onClickArchiveMultiple(event: React.MouseEvent): void;

    selectedRows: EntityId[];
}

function BonTableToolbar({ onClickArchiveMultiple, selectedRows }: Props) {
    const selectedDates = useSelector((state: RootState) => state.bons.dates);
    const filters: any = useSelector((state: RootState) => state.bons.filters);

    const [isSearchPopoverOpen, setIsSearchPopoverOpen] = useState(false);
    const [isEditionDialogOpen, setIsEditionDialogOpen] = useState(false);
    const [filtersBadge, setFiltersBadge] = useState(0);
    const [searchAnchorEl, setSearchAnchorEl] = useState<Element | null>(null);

    const dispatch = useAppDispatch();

    useEffect(() => {
        let badgeCounter = 0;
        for (const property in filters) {
            if ( filters[property] !== '' ) {
                badgeCounter++;
            }
        }

        setFiltersBadge(badgeCounter);
    }, [filters]);

    const onOpenSearchPopover = (event: MouseEvent) => {
        const { currentTarget } = event;
        setIsSearchPopoverOpen(true);
        setSearchAnchorEl(currentTarget);
    };

    const onCloseSearchPopover = () => {
        setIsSearchPopoverOpen(false);
        setSearchAnchorEl(null);
    };

    const onDateUpdate = (field: string, value: string) => {
        if ( value !== selectedDates ) dispatch(updateDates(value));
    };

    const onHandleEditionDialog = () => setIsEditionDialogOpen(!isEditionDialogOpen);

    const bons = useSelector(selectAllBons);

    const onClickExport = () => {
        const toExport = bons.map(({ id, ids, ...otherProps }) => otherProps);
        const excelLine = toExport.map((bons) => {
            return { 
                'date' : bons.date,
                'numero' : bons.bonNumber,
                'client' : bons.client,
                'command' : bons.commandePar,
                'chantier' : bons.chantier,
                'fournisseur' : bons.fournisseur,
                'article' : bons.article,
                'pvht' : bons.prixVenteHT,
                'paht' : bons.prixAchatHT,
                'quantite' : bons.quantite,
                'unite' : bons.unite,
                'achatht' : bons.achatHT,
                'montantht' : bons.montantHT,
                'fraisautoroute' : bons.fraisAutoroute,
                'chauffeur' : bons.chauffeur,
                'consigne' : bons.consignes_chauffeur,
                'etat' : bons.status,
            }
        });

        const heading = [
            'Date',
            'Numéro',
            'Client',
            'Commandé par',
            'Chantier',
            'Fournisseur',
            'Article',
            'Prix Vente HT',
            'Prix Achat HT',
            'Quantité',
            'Unité',
            'Achat HT',
            'Montant HT',
            'Frais autoroute',
            'Chauffeur',
            'Consigne(s)',
            'Etat',
        ];
        exportToXLSX(excelLine, `bons-${selectedDates}`, heading);
    };

    return (
        <>
            <CustomTableToolbar>
                <Stack direction="row" alignItems="flex-end" spacing={1}>
                    <DateRangeField
                        sx={{ width: 270 }}
                        size="small"
                        variant="outlined" 
                        label="Filtrer par date..."
                        onDateUpdate={onDateUpdate}
                        value={selectedDates}
                    />

                    {/* Buttons */}
                    <Tooltip title="Filtrer">
                        <IconButton onClick={onOpenSearchPopover}>
                            <Badge badgeContent={filtersBadge} color="error">
                                <TuneIcon/>
                            </Badge>
                        </IconButton>
                    </Tooltip>

                    <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        disabled={selectedRows.length < 1}
                        onClick={onHandleEditionDialog}
                        startIcon={<Receipt/>}
                    >
                        Ajouter Ref. Client et N° Bon
                    </Button>

                    <Button
                        variant="contained"
                        size="small"
                        color="warning"
                        disabled={selectedRows.length < 1}
                        onClick={onClickArchiveMultiple}
                        startIcon={<ArchiveIcon/>}
                    >
                        Archiver
                    </Button>

                    <Button variant="contained" color="success" onClick={onClickExport} startIcon={<FileDownload/>}
                        size="small">
                    Exporter
                </Button>
                </Stack>
                <Stack direction="row" spacing={4} alignItems="end">
                    <Button component={Link} to="create" variant="contained" color="secondary" startIcon={<Add/>}>
                        Nouveau Bon
                    </Button>
                </Stack>
            </CustomTableToolbar>

            {/* Popover & Dialogs */}
            <BonEditionDialog open={isEditionDialogOpen} ids={selectedRows} onClose={onHandleEditionDialog}/>

            <BonSearchPopover
                open={isSearchPopoverOpen}
                anchorEl={searchAnchorEl}
                onCloseSearchPopover={onCloseSearchPopover}
            />
        </>
    );
}

export default BonTableToolbar;
