import { FactureService } from "../../../Domain/Ports/FactureService";
import httpService from "../../../../_config/axios";
import { LigneDeCommande } from "../../model/LigneDeCommande";
import { Bon } from "../../../Domain/Model/Bon";
import { FournisseurId } from "../../model/FournisseurId";

export class HttpFactureService implements FactureService {
    private readonly ressourceUri = "bon-de-commande";

    async findAllPendingBonsDeCommande(): Promise<{ bonsDeCommande: Bon[] }> {
        const pendingBonsDeCommande = await httpService.get<LigneDeCommande[]>(this.ressourceUri + '/pending');
        return {
            bonsDeCommande: pendingBonsDeCommande.data.map(BCtoEntity)
        }
    }
}


function BCtoEntity(bonDeCommande: LigneDeCommande): Bon {
    return {
        id: bonDeCommande._id,
        date: bonDeCommande.chantier.date,
        article: {
            denomination: bonDeCommande.article.libelle,
            prixUnitaire: bonDeCommande.article.prixUnitaire,
            quantity: bonDeCommande.article.quantite,
            type: bonDeCommande.article.type,
        },
        chantier: {
            id: bonDeCommande.chantier._id,
            denomination: bonDeCommande.chantier.denomination,
            adresse: "bonDeCommande.chantier",
            client: bonDeCommande.chantier.clientId.name,
        },
        entreprise: {
            id: new FournisseurId(bonDeCommande.fournisseur._id).toEntityId(),
            raisonSociale: bonDeCommande.fournisseur.raisonSociale
        },
        info: {
            category: 'fournisseur',
            numeroBon: bonDeCommande.numeroBon,
            referenceClient: bonDeCommande.referenceClient
        },
        fraisAutoroute: { value: bonDeCommande.fraisAutoroute },
        montantHT: { value: bonDeCommande.article.prixUnitaire.value * bonDeCommande.article.quantite.value },
        status: "PENDING"
    }
}

