import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { object, setLocale, string } from 'yup';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { addOneInterlocuteur } from '../../../core/interlocuteur/use-cases';
import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { setSuccess } from '../../../core/notification/notification.slice';
import { updateCreatedFromModal } from '../../../../Location/Domain/Bons/bon.slice';
import { yupFr } from '../../common/i18n';
import { removeEmpty } from '../../common/_helpers';

function InterlocuteurModal() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {
        state: { extra, keepIdInState },
    }: any = useLocation();

    const initialValues = {
        nom: '',
        prenom: '',
        telephone: '',
        email: '',
        fonction: '',
    };

    setLocale(yupFr);
    const schema = object().shape({
        nom: string().required(),
        prenom: string().notRequired(),
        telephone: string().notRequired(),
        email: string().email().notRequired(),
        fonction: string().notRequired(),
    });

    const onDismiss = () => {
        navigate('', { replace: true });
    };

    const onSubmit = async (values: any) => {
        let onlyFilledValues: any = removeEmpty(values);

        const res: any = await dispatch(addOneInterlocuteur({ employeur: extra, ...onlyFilledValues }));

        if ( !res.error ) {
            dispatch(setSuccess(`L'interlocuteur a bien été créé`));
            if ( keepIdInState ) dispatch(updateCreatedFromModal({ entity: 'interlocuteur', id: res.payload.id }));
            onDismiss();
        }
    };

    return (
        <Dialog open>
            <DialogTitle>Créer un interlocuteur</DialogTitle>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
                {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isValid,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    } =
                        props;
                    return (
                        <>
                            <Form onSubmit={handleSubmit}>
                                <DialogContent sx={{ minWidth: 400 }}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label="Nom"
                                                error={!!errors.nom && !!touched.nom}
                                                name="nom"
                                                value={values.nom}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.nom && errors.nom}
                                                variant="outlined"
                                                required
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label="Prénom"
                                                name="prenom"
                                                value={values.prenom}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label="Téléphone"
                                                name="telephone"
                                                value={values.telephone}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="email"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label="Fonction"
                                                name="fonction"
                                                value={values.fonction}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={onDismiss}>Annuler</Button>
                                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}
                                                   disabled={!dirty || !isValid}>
                                        Confirmer
                                    </LoadingButton>
                                </DialogActions>
                            </Form>
                        </>
                    );
                }}
            </Formik>
        </Dialog>
    );
}

export default InterlocuteurModal;
