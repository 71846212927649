import { Tooltip } from '@mui/material';
import { Announcement, Cancel, CheckCircle, Class, CreditScore, Edit, Euro } from '@mui/icons-material';

type StatusIcon = {
  tooltip: string;
  icon: JSX.Element;
};

export enum Status {
  CREATED,
  EMITTED,
  EDITED,
  PARTIALPAID,
  PAID,
  CANCELLED,
  ARCHIVE
}

interface Props {
  param: Status;
}

export const FactureTableStatusCell = ({ param }: Props) => {
  const status: { [key in keyof typeof Status]: StatusIcon } = {
    CREATED: {
      tooltip: 'Nouvelle facture',
      icon: <Edit color="primary" />,
    },
    EMITTED: {
      tooltip: 'Facture émise',
      icon: <Announcement color="primary" />,
    },
    EDITED: {
      tooltip: 'Facture à régler',
      icon: <Euro color="warning" />,
    },
    PARTIALPAID: {
      tooltip : 'Règlement incomplet',
      icon: <CreditScore color="warning" />,
    },
    PAID: {
      tooltip: 'Facture payée',
      icon: <CreditScore color="success" />,
    },
    CANCELLED: {
      tooltip: 'Facture annulée',
      icon: <Cancel color="error" />,
    },
    ARCHIVE: {
      tooltip: 'Facture archivée',
      icon: <Class color="warning" />
    }
  };

  return <Tooltip title={status[param].tooltip}>{status[param].icon}</Tooltip>;
};
