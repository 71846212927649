import { createAsyncThunk } from '@reduxjs/toolkit';
import ArticleEntity from '../entities';

interface CreateArticleDto extends Omit<ArticleEntity, 'id'> {}

export const addOneArticle = createAsyncThunk(
	'[ARTICLE] addOneArticle',
	async (articleDto: CreateArticleDto, thunk: any): Promise<ArticleEntity> => {
		try {
		const { ...article } = articleDto;
		return await thunk.extra.articleQuery.add(article);
	} catch (error: any) {
		return thunk.rejectWithValue({ message: error.message ?? null });
	}
	}
);
