import { Link, useLocation, useRoutes } from 'react-router-dom';
import { EntrepriseEdition } from '../components/entreprise-edition';
import { EntrepriseList } from '../components/entreprise-list';
import { EntrepriseManageChantiers } from '../components/entreprise-manage-chantiers';
import EntrepriseManageInterlocuteurs from '../components/entreprise-manage-interlocuteurs';
import { ManageReglements } from "../../../../Facturation/Presentation/pages/ManageReglements";
import { ManageAdresseFacturation } from "../../../../Facturation/Presentation/pages/ManageAdresseFacturation";

export default function EntrepriseRouter() {
    const location = useLocation();
    return useRoutes([
        { path: '', element: <EntrepriseList/> },
        { path: ':id', element: <EntrepriseEdition/> },
        { path: 'create', element: <EntrepriseEdition/> },
        { path: ':id/interlocuteurs', element: <EntrepriseManageInterlocuteurs/> },
        { path: ':id/reglements', element: <ManageReglements/> },
        { path: ':id/chantiers', element: <EntrepriseManageChantiers/> },
        { path: ':id/facturation/adresse', element: <ManageAdresseFacturation/> },
        {
            path: '',
            element: (
                <div>
                    <Link to="/planning/create" state={{ backgroundLocation: location }}/>
                </div>
            ),
        },
    ]);
}


