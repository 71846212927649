import { EntrepriseEntity } from "../../../../App/core/entreprise/entities";
import { ColumnEntity } from "../../../../App/app/common/custom-table/Table.entities";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Tab, Tabs } from "@mui/material";
import { CustomTable } from "../../../../App/app/common/custom-table";
import React from "react";

interface CategoryListProps {
    uneEntreprise: EntrepriseEntity,
    reglementType: 'client' | 'fournisseur'
    changeTypeOfReglement: (reglementType: 'client' | 'fournisseur') => void
}

export function CategoryList({ uneEntreprise, reglementType, changeTypeOfReglement }: CategoryListProps) {

    const columns: ColumnEntity[] = [
        { field: 'categoryLabel', headerName: 'Libellé', width: 210, sortable: true, resizable: true },
        {
            field: 'paymentDeadline',
            headerName: 'Délai de facturation',
            width: 170,
            sortable: true,
            resizable: true
        },
    ];

    return <TabContext value={reglementType}>
        <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
                value={reglementType}
                onChange={(e, newValue: 'client' | 'fournisseur') => changeTypeOfReglement(newValue)}
                aria-label="">
                <Tab label="fournisseur" value="fournisseur"/>
                <Tab label="client" value='client'/>
            </Tabs>
        </Box>
        {['fournisseur', 'client'].map(r =>
            <TabPanel key={r} value={r}>
                <CustomTable
                    selectable={false}
                    columns={columns}
                    data={uneEntreprise?.reglements?.[reglementType as 'client' | 'fournisseur'] as any}
                    onSelectRows={() => {
                    }}/>
            </TabPanel>
        )}
    </TabContext>
}