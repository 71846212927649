import React from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { Edit } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';

interface Props {
  id: EntityId;
  measuredRef: any;
  onClickEdit(id: EntityId): void;
}

const PaiementTableActionsCell = ({ id, measuredRef, onClickEdit }: Props) => {
  return (
    <Stack direction="row">
      {/* Buttons */}
      <Box ref={measuredRef}>
        <Tooltip title="Editer">
          <IconButton aria-label="editer" color="primary" size="small" onClick={() => onClickEdit(id)}>
            <Edit />
          </IconButton>
        </Tooltip>
      </Box>
    </Stack>
  );
};

export default PaiementTableActionsCell;
