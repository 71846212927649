import { EntityId } from '@reduxjs/toolkit';
import { Update } from '@reduxjs/toolkit';
import httpAdapter, { _apiUrlFactory } from '../../../_config/api-provider';

const httpService = httpAdapter();

const prefix = 'accounting/affacturage-type';
const apiaffacturageTypeUrl = _apiUrlFactory(prefix);

export const all = async () => {
	return  httpService.get(`${apiaffacturageTypeUrl('')}`);
};

export const add = async (mode: any): Promise<Partial<any>> => {
	return  httpService.post(apiaffacturageTypeUrl(''), mode);
};

export const update = async (mode: Update<any>): Promise<any> => {
	const { id, ...otherProps } = mode;
	return  httpService.put(apiaffacturageTypeUrl(`${id}`), otherProps);
};

export const _delete = async (id: EntityId) => {
	return  httpService.delete(apiaffacturageTypeUrl(`${id}`));
  }