import { Popover } from '@mui/material';
import { Calendar } from 'react-date-range';
import { fr } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface DatePickerPopoverProps {
  onSelectDate(value: any): void;
  onPopoverClose(): void;
  displayCalendar: boolean;
  anchorEl: HTMLElement | null;
  selectedDate: Date;
}

const DatePickerPopover = ({
  onSelectDate,
  onPopoverClose,
  displayCalendar,
  anchorEl,
  selectedDate,
}: DatePickerPopoverProps) => {
  return (
    <Popover
      open={displayCalendar}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onPopoverClose}
    >
      <Calendar
        date={selectedDate}
        onChange={onSelectDate}
        locale={fr}
        // maxDate={new Date()}
        showMonthAndYearPickers={true}
        showDateDisplay={false}
      />
    </Popover>
  );
};

export default DatePickerPopover;
