import { css } from '@emotion/css';
import { Cell } from 'rsuite-table';

export const StyledCell = ({ checkedKeys, coloredRow, rowIndex, ...props }: any) => (
  <Cell
    className={css`
      background-color: ${getBackgroundColor(checkedKeys, coloredRow, rowIndex, { ...props })} !important;
    `}
    {...props}
  />
);

const getBackgroundColor = (checkedKeys: any, coloredRow: any, rowIndex: number, { ...props }) => {
  if (checkedKeys.includes(props.rowData.id)) return '#faf8f4';
  if (!!props.rowData[coloredRow]) return '#fff6cc';
};
