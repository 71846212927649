import { AxiosInstance } from 'axios';
import httpService from './axios';
import fakeHttpProvider from '../App/infra/http-provider/fake.http-provider';

// FOR TESTING PURPOSE -> setup fakeBackEnd if node_env === development OR test
const includedEnv = [
    'test',
    // 'development'
];

const httpProvider = includedEnv.includes(process.env.NODE_ENV as string) ? fakeHttpProvider : httpService;

interface MethodHttp {
    <T = any>(path: string, config?: any): Promise<T>;
}

interface HttpProvider {
    get: MethodHttp;
    post: MethodHttp;
    delete: MethodHttp;
    patch: MethodHttp;
    put: MethodHttp;
    defaults: any;
}

const httpApiAdapter = (http = httpProvider): HttpProvider => {
    async function get<T = any>(path: string, ...options: any[]) {
        return await _requestHandler<T>('get', path, ...options);
    }

    async function post<T>(path: string, ...options: any[]) {
        return await _requestHandler<T>('post', path, ...options);
    }

    async function _delete<T>(path: string, ...options: any[]) {
        return await _requestHandler<T>('delete', path, ...options);
    }

    async function put<T>(path: string, ...options: any[]) {
        return await _requestHandler<T>('put', path, ...options);
    }

    async function patch<T>(path: string, ...options: any[]) {
        return await _requestHandler<T>('patch', path, ...options);
    }

    async function _requestHandler<T = any>(
        methods: keyof typeof httpProvider,
        path: string,
        ...options: any[]
    ): Promise<T>;
    async function _requestHandler(method: keyof typeof httpProvider, path: string, ...options: any[]) {
        try {
            const result = await (httpProvider as AxiosInstance)[method](path, ...options);
            if ( result.data ) return result.data;
            return result;
        } catch (error: any) {
            // if (axios.isAxiosError(error)) {
            //     if (error.response) throw new AxiosErrorAdapter(error);
            //     throw new NoResponseErrorAdapter(error)
            // }
            throw error.response?.data ?? error;
            // throw error;
            // throw new CustomError(error as Error);
        }
    }

    return {
        get,
        post,
        put,
        delete: _delete,
        patch,
        defaults: (http as AxiosInstance)?.defaults ?? {},
    };
};

export default httpApiAdapter;

export const _apiUrlFactory = (prefix: string) => (path: string = '') => `${prefix}${path ? '/' + path : ''}`;

// class NoResponseErrorAdapter extends Error {
// 	_err: AxiosError;
// 	constructor(err: AxiosError) {
// 		super(err.stack);
// 		this._err = err;
// 	}
// 	toJson() {
// 		return this._err.toJSON();
// 	}
// }

// class CustomError extends Error {
// 	error: any;
// 	constructor(error: any) {
// 		super(error.stack);
// 		this.error = error;
// 	}

// 	toJson() {
// 		
// 		return {
// 			name: this.error.error,
// 			code: this.error.statusCode,
// 			message: this.error.message
// 		};
// 	}
// }
