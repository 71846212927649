import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { EntityId } from "@reduxjs/toolkit";

import { Box } from "@mui/material";
import { CustomTable } from "../../../../../App/app/common/custom-table";
import { ColumnEntity } from "../../../../../App/app/common/custom-table/Table.entities";
import {
  emitOneFacture,
  retrieveAllFactures,
  deleteOneFacture,
  confirmBonDeCommande as confirmBonDeCommandeUsecase,
} from "../../../../Domain/Application/use-cases";

import { RootState } from "../../../../../_config/redux";
import { useAppDispatch } from "../../../../../_config/react/useAppDispatch";
import { Facture } from "../../../../Domain/Model";
import { selectAllFactures,/* selectSortedFactures*/ } from "../../../../Domain/Application/selectors";
import FactureEditDialog from "../facture-edit/FactureEditDialog";
import FactureTableActionsCell from "./FactureTableActionsCell";
import { PERIOD_SEPARATOR, assert, validateDates } from "../../../../../App/app/common/_helpers";
import { relancerFacture } from "../../../../Domain/Application/use-cases/relancer-facture";
import { setSuccess } from "../../../../../App/core/notification/notification.slice";
import { FactureTableStatusCell, Status } from "./FactureTableStatusCell";
import { FactureAvoirDialog, FactureValidatePaymentDialog } from "../../commun";
import EmitFactureDialog from "../../EmitFactureDialog";
import RelanceFactureDialog from "../../RelanceFactureDialog";
import DeleteFactureDialog from "../../DeleteFactureDialog";
import { IconByEntrepriseType } from "../../IconByEntrepriseType";
import { BonDeCommandeValidationDialog } from "../../bondecommande/bdc-list/BonDeCommandeValidationDialog";
import { TableListSummary } from "../../TableListSummary";
import { useContainerDimensions } from "../../../../../App/app/common/custom-table/CustomTable";
import { updateOneFacture } from "../../../../Domain/Application/use-cases/update-one-bon";

import './FactureTable.style.css';

interface Props { 
  onSelectRows(ids: EntityId[]): void;
  refreshBons(): void;
}

function FactureTable({ onSelectRows, refreshBons }: Props) {
  const type = "client";
  const box = useRef();
  const { width } = useContainerDimensions(box);

  const dispatch = useAppDispatch();
  //const factures = useSelector(selectSortedFactures);
  let factures = useSelector(selectAllFactures).filter((facture) => facture.type === type && facture.numeroFacture !== ''
  );


  const selectedDates: string = useSelector((state: RootState) => state.factures.dates);
 // const status = useSelector((state: RootState) => state.factures.status);
 
  const filters: any = useSelector((state: RootState) => state.factures.filters);

  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState<Facture>();

  const [dialogsState, setDialogsState] = useState({
    edit: false,
    validate: false,
    avoir: false,
    relance: false,
    emit: false,
    bonDeCommandeValidation: false,
    delete: false,
    lettrer: false,
    unlettrer: false,
  });
  
  const loadFactures = useCallback(
    async () => {
      setLoading(true);
      try {
        const [ from, to ] = selectedDates.split(PERIOD_SEPARATOR);
        assert(!!validateDates(selectedDates), 'Date validation failed')

        await dispatch(retrieveAllFactures({
          from: from, 
          to: to,
          type,
          ...filters,
        }
        ));
      } catch (err) {
        console.warn("Erreur lors de la récupération des bdcs", err);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, selectedDates, filters]
  );

  useEffect(() => {
    loadFactures();
    return () => {
      setLoading(false);
    };
  }, [loadFactures]);

  const handleDialogs = (
    buttonId: keyof typeof dialogsState,
    rowData?: Facture
  ) => { ////console.log(buttonId);
      setLoading(true);
      setRowData(rowData);
      const currentState = dialogsState[buttonId];
      const newState = !currentState;
    if(buttonId === "lettrer" || buttonId === "unlettrer") { 
      onConfirmLettrer();
    }
    else {
      setDialogsState({ ...dialogsState, [buttonId]: newState });
    }
    //if(((rowData?.status === 'CREATED' || !rowData) && buttonId === "edit") || buttonId == "avoir" || buttonId == "emit") {
      //if (newState) {
        refreshBons();
      //}
      loadFactures();
      setLoading(false);
   // }
  };

  const onConfirmLettrer = async () => {
    let lettrer  = "new";
    if(rowData && rowData.lettrage && rowData.lettrage !== "") { lettrer  = "clear"; }
    const res: any = rowData?.id
      ? await dispatch(
          updateOneFacture({
            id: rowData.id,
            lettrage: lettrer,
          })
        )
      : null;

    if (!res?.error) {
      dispatch(setSuccess(`La facture a bien été mise à jour`));
      refreshBons();
      loadFactures();
    }
  };

  // Relance
  const onConfirmRelance = async (aReveiverEmail: string) => {
    const res: any = rowData?.id
      ? await dispatch(
          relancerFacture({
            id: rowData.id,
            anEmail: aReveiverEmail,
          })
        )
      : null;

    if (!res?.error) {
      dispatch(setSuccess(`Le client a bien été relancé`));
      handleDialogs("relance");
    }
  };

  // Emettre
  const onConfirmEmit = async (aReveiverEmail: string) => {
    const res: any = rowData?.id
      ? await dispatch(
          emitOneFacture({
            id: rowData.id,
            anEmail: aReveiverEmail,
          })
        )
      : null;

    if (!res?.error) {
      dispatch(setSuccess(`La facture a bien été émise`));
      handleDialogs("emit");
    }
  };

  //Supprimer
  const onConfirmDelete = async (data: string) => {
    const res: any = rowData?.id
      ? await dispatch(
          deleteOneFacture({
            id: rowData.id,
          })
        )
      : null;

    if (!res?.error) {
      dispatch(setSuccess(`La facture a bien été supprimée`));
      loadFactures();
      handleDialogs("delete");
    }
  };

  const confirmBonDeCommande = async (unNumeroDeFactureFournisseur: string) => {
    dispatch(
      confirmBonDeCommandeUsecase({
        bonDeCommandeId: rowData?.id as string,
        numeroFactureFournisseur: unNumeroDeFactureFournisseur,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setSuccess(`Le bon de commande a bien été confirmé`));
        handleDialogs("bonDeCommandeValidation");
      });
  };

  const columns: ColumnEntity[] = [
    {
      field: "status",
      headerName: "",
      width: 40,
      renderCell: (param) =>
        Object.values(Status).includes(param as Status) ? (
          <FactureTableStatusCell param={param} />
        ) : (
          param
        ),
    },
    {
      field: "numeroFacture",
      headerName: "N°",
      width: 80,
      sortable: true,
      resizable: true,
    },
    {
      field: "type",
      headerName: "",
      sortable: true,
      width: 40,
      renderCell(params: "client" | "fournisseur"): React.ReactChild {
        if (Boolean(params))
          return <IconByEntrepriseType entrepriseType={params} />;
        return <></>;
      },
    },
    {
      field: "dateDeFacturation",
      headerName: "Date",
      width: 90,
      sortable: true,
      resizable: true,
    },
    {
      field: "client.name",
      headerName: "Client",
      flexGrow: 1,
      minWidth: 130,
      sortable: true,
    },
    {
      field: "fournisseurs",
      headerName: "Fournisseur(s)",
      flexGrow: 1,
      minWidth: 130,
      sortable: true,
    },
    {
      field: "bons.length",
      headerName: "Bons",
      width: 58,
      sortable: true,
      resizable: true,
    },
    {
      field: "totalFraisAutorouteE",
      headerName: "Frais Auto.",
      width: 90,
      sortable: true,
      resizable: true,
    },
    {
      field: "totalHTe",
      headerName: "HT",
      width: 100,
      sortable: true,
      resizable: true,
    },    
    {
      field: "totalTvae",
      headerName: "TVA",
      width: 70,
      sortable: true,
      resizable: true,
    },
    {
      field: "totalTTCe",
      headerName: "TTC",
      width: 100,
      sortable: true,
      resizable: true,
    },
    {
      field: "reglement.montante",
      headerName: "Encaissé",
      width: 100,
      sortable: true,
      resizable: true,
    },
    {
      field: "lettrage",
      headerName: "Lettrage",
      width: 130,
      sortable: true,
      resizable: true,
    },
  ];

  
  if(filters && filters.totalTTC && Number(filters.totalTTC) !== 0 && filters.totalTTC !== '') {
    factures = factures.filter((facture) => facture.totalTTC === Number(filters.totalTTC));
  }

 
  const table_factures = JSON.parse(JSON.stringify(factures));
 //console.log(factures);
  table_factures.forEach((facture:any) => {
    facture.totalTTCe = Number(facture.totalTTC).toFixed(2) + " €";
    if(facture.totalHT) { facture.totalHTe = Number(facture.totalHT).toFixed(2) + " €"; }
    facture.totalTvae = Number(facture.totalTva).toFixed(2) + " €";
    facture.reglement.montante = Number(facture.reglement.montant).toFixed(2) + " €";
    facture.totalFraisAutorouteE = Number(facture.totalFraisAutoroute).toFixed(2) + " €";
    let facturefournisseur = "";
    facture?.bons.forEach((bon:any) => {
      //console.log(facture.numeroFacture + "" + bon.fournisseur);
      
        if(bon?.fournisseur && !facturefournisseur.includes(bon.fournisseur)) {
          if(facturefournisseur === "") { facturefournisseur = bon.fournisseur; }
          else { facturefournisseur = facturefournisseur+", " + bon.fournisseur;}
        }

    });
    facture.fournisseurs = facturefournisseur;

  });

 
  
  return (
    <Box sx={{ height: "90%" }} ref={box}>
      
      <CustomTable
        data={table_factures}
        columns={columns}
        loading={loading}
        hover={false}
        sortByDefault="dateDeFacturation"
        onRowDoubleClick={(rowData: Facture) => handleDialogs("edit", rowData)}
        onSelectRows={onSelectRows}
        renderActionCell={(rowData, measuredRef) => (
          <FactureTableActionsCell
            rowData={rowData}
            measuredRef={measuredRef}
            handleDialogs={handleDialogs}
          />
        )}
        rendersummary={() => (<TableListSummary collection={table_factures}/>) }
        width={width}
      />

      {/* Edit Dialog */}
      <FactureEditDialog
        openEdit={dialogsState.edit}
        id={rowData?.id ?? ""}
        onCloseDialog={() => handleDialogs("edit")}
      />

      {/* Emit invoice  N° ${rowData?.numeroFacture} */}
      <EmitFactureDialog
        open={dialogsState.emit}
        title={"Emettre une facture"}
        contentText={`Êtes-vous sûr de vouloir émettre la facture à ${rowData?.client.name} ?`}
        factureDetails={{
          aReceiverEmail: rowData?.client.contact?.email ?? "",
        }}
        onConfirm={onConfirmEmit}
        onClose={() => handleDialogs("emit")}
        isButtonLoading={false}
        key="emit"
      />

      {/* Validate Payment Dialog */}
      <FactureValidatePaymentDialog
        open={dialogsState.validate}
        facture={rowData}
        onCloseDialog={() => handleDialogs("validate")}
      />

      {/* Avoir Dialog */}
      <FactureAvoirDialog
        open={dialogsState.avoir}
        id={rowData?.id ?? ""}
        onCloseDialog={() => handleDialogs("avoir")}
      />

      {/* Relance Dialog */}
      <RelanceFactureDialog
        open={dialogsState.relance}
        title={"Relancer"}
        contentText={`Êtes-vous sûr de vouloir relancer ${rowData?.client.name} pour la facture N° ${rowData?.numeroFacture}`}
        factureDetails={{
          aReceiverEmail: rowData?.client.contact?.email ?? "",
        }}
        onConfirm={onConfirmRelance}
        onClose={() => handleDialogs("relance")}
        isButtonLoading={false}
        key="relance"
      />

      {/* Delete invoice */}
      <DeleteFactureDialog
        open={dialogsState.delete}
        title={"Supprimer une facture"}
        contentText={`Êtes-vous sûr de vouloir supprimer la facture N° ${rowData?.numeroFacture}  ?`}
        onConfirm={onConfirmDelete}
        onClose={() => handleDialogs("delete")}
        isButtonLoading={false}
        key="remove"
      />

      {/* Validation Bon De commande */}
      <BonDeCommandeValidationDialog
        open={dialogsState.bonDeCommandeValidation}
        onClose={() => handleDialogs("bonDeCommandeValidation")}
        onConfirmAction={confirmBonDeCommande}
        numeroFacture={rowData?.numeroFacture ?? ""}
      />
    </Box>
  );
}

export default FactureTable;
