import { useRoutes, Link, useLocation } from 'react-router-dom';
import { ArticleEdition } from '../components/article-edition/';
import { ArticleList } from '../components/article-list';

export default function ArticleRouter() {
  const location = useLocation();
  const element = useRoutes([
    { path: '', element: <ArticleList /> },
    { path: ':id', element: <ArticleEdition /> },
    { path: 'create', element: <ArticleEdition /> },
    {
      path: '',
      element: (
        <div>
          <Link to="/planning/create" state={{ backgroundLocation: location }} />
        </div>
      ),
    },
  ]);
  return element;
}
