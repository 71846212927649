import React, { useEffect, useState } from 'react';
import { format, lastDayOfMonth, addDays } from 'date-fns';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Stack, Typography, SelectChangeEvent, Box, Stepper, Step, StepLabel
} from '@mui/material';
import { EntityId } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { selectBonsForFactureGroupByCompany, selectFactureById } from '../../../../Domain/Application/selectors';
import { updateOneFacture } from '../../../../Domain/Application/use-cases/update-one-bon';
import { setSuccess } from '../../../../../App/core/notification/notification.slice';
import { useExtractBons } from "../../../hooks/useExtractBons";
import { useMergeChantiersWithBons } from "../../../hooks/useMergeChantiersWithBons";
import { EntrepriseEntity } from "../../../../../App/core/entreprise/entities";
import SelectBonGroupByChantiers from "../../SelectBonGroupByChantiers";
import { BillableBonsGroupByCompany } from "../../../../Domain/Model/Bon";
import { IconByEntrepriseType } from "../../IconByEntrepriseType";
import FactureEditDialogConfirm from './FactureEditDialogConfirm';
import { dateFormat } from '../../../../../App/app/common/_helpers';
import { FooterDialog } from '../facture-create/FooterDialog';
import { selectAllribTypes } from '../../../../../App/core/rib-type/selectors';

interface Props {
    openEdit: boolean;
    id: EntityId;
    onCloseDialog(): void;
}
enum STEPS {
    BONS,
    CONFIRMATION,
}
const stepsLabels = ['Sélectionner des bons', 'Confirmation'];

const FactureEditDialog: React.FC<Props> = ({ openEdit, id, onCloseDialog }) => {
    const dispatch:any = useAppDispatch();
    const facture = useSelector(selectFactureById(id));
    const [selectedBons, setSelection] = useState<string[]>([])
    const [activeStep, setActiveStep] = useState(STEPS.BONS);
    const dateDeFacturationFormat = facture?.dateDeFacturation? facture?.dateDeFacturation: format(new Date(), dateFormat);

    const [dateFacturation, setDateFacturation] = useState(dateDeFacturationFormat);
    const paymentDueDateFormat = facture?.paymentDueDate ? format(new Date(facture?.paymentDueDate), dateFormat) : format(lastDayOfMonth(addDays(new Date(), 30)), dateFormat);

    const [expirationDate, setExpirationDate ] = useState(paymentDueDateFormat);
    
    const [selectedRib, setRibSelection] = useState('');
    const [selectedAddress, setAddressSelection] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    
    
    useEffect(() => {
        if ( paymentDueDateFormat  ) setExpirationDate(paymentDueDateFormat);
        if ( dateDeFacturationFormat ) setDateFacturation(dateDeFacturationFormat); 
        
    }, [facture, dateFacturation, expirationDate]);

    useEffect(() => {
        if ( activeStep === STEPS.BONS ) setIsButtonDisabled(!selectedBons.length);
        if ( activeStep === STEPS.CONFIRMATION ) setIsButtonDisabled(!selectedBons.length); // fetch bons here ?
        
    }, [activeStep, selectedBons]);

    const handleNext = async () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        setSelection(facture?.bons?.map(aBon => aBon.bonId ?? aBon.id) ?? []);
    }, [facture])


    const bons = useSelector(selectBonsForFactureGroupByCompany);
    
    const bonsByChantier = useMergeChantiersWithBons(
        chantierWithBillableBonsOfCompany(bons, facture?.client),
        useExtractBons(facture)
    );

    function chantierWithBillableBonsOfCompany(bons: BillableBonsGroupByCompany[], aCompany?: Partial<EntrepriseEntity>) {
        if ( !aCompany ) return [];
        const aClient = bons.find(b => b.companyId === aCompany?.id) ?? { chantiers: [] } as unknown as BillableBonsGroupByCompany;
        return aClient.chantiers;
    }

    const onClickConfirm = async () => {
        if ( !facture ) throw new Error('une facture doit être sélectionné');
        const res: any = await dispatch(updateOneFacture({
            id: facture.id,
            bons: selectedBons,
            dateDeFacturation: dateFacturation,
            paymentDueDate: expirationDate,
            rib: selectedRib,
            adresseFacturation: selectedAddress,
            unpaidComment: ''
        }));

        if ( !res.error ) dispatch(setSuccess(`La facture a bien été modifiée`));

        closeDialog();
    };

    const onDateUpdate = (_: any, value: string) => {
        if ( value && value !== dateFacturation ) setDateFacturation(value);
    };
    const onDateExpireUpdate = (_: any, value: string) => {
        if ( value && value !== expirationDate ) setExpirationDate(value);
    };

    function onRibSelection(e: SelectChangeEvent<string>) {
        setRibSelection(e.target.value);
    }
    function onAdresseSelection(e: SelectChangeEvent<string>) {
        setAddressSelection(e.target.value);
    }

    const closeDialog = () => {
        refreshVariables();
        onCloseDialog();
    }
    const refreshVariables = () => {
        // timeout to make sure the dialog is closed before refreshing
        // to avoid displaying first step before closing (ugly animation)
        setTimeout(() => {
            setSelection([]);
            setActiveStep(STEPS.BONS);
            setDateFacturation(facture?.dateDeFacturation? facture?.dateDeFacturation: format(new Date(), dateFormat));
            setExpirationDate(
                facture?.paymentDueDate ? format(new Date(facture?.paymentDueDate), dateFormat) : format(lastDayOfMonth(addDays(new Date(), 30)), dateFormat)
                );
        }, 0);
    };
    const ribTypes = useSelector(selectAllribTypes);

    return (
        <Dialog open={openEdit} fullWidth maxWidth="xl">
            <DialogTitle>
                Modifier {facture?.type === 'fournisseur' ? 'le bon de commande' : 'la facture'} {facture?.numeroFacture}
                <Stack direction="row" spacing={1}>
                    <Typography variant='subtitle1'>Entreprise: {facture?.client.name}</Typography>
                    <IconByEntrepriseType entrepriseType={facture?.type as 'fournisseur' | 'client'}/>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ height: '75vh' }}>
                    <Stepper activeStep={activeStep}>
                        {stepsLabels.map((label) => {
                            const stepProps: { completed?: boolean } = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {activeStep === STEPS.BONS && (
                        <div style={{ marginTop: 30 }}>
                            <SelectBonGroupByChantiers
                            bonsByChantier={bonsByChantier}
                            selectedEntrepriseType={facture?.type ?? 'client'}
                            loading={false}
                            selectedBons={selectedBons}
                            addBonToSelection={(v) => {
                                setSelection(v)
                            }}
                        />
                        </div>
                    )}
                    {activeStep === STEPS.CONFIRMATION && (
                        <FactureEditDialogConfirm
                            entrepriseId={facture?.client.id ? facture?.client.id : ''}
                            facture={facture}
                            bonsIds={selectedBons}
                            date={dateFacturation}
                            expirationDate={expirationDate}
                            setDate={onDateUpdate}
                            setExpirationDate={onDateExpireUpdate}
                            onRibSelection={onRibSelection}
                            onAdresseSelection={onAdresseSelection}
                            ribTypes={ribTypes}
                            bonsByChantier={bonsByChantier}
                        />
                    )}
                </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <FooterDialog
                    activeStep={activeStep}
                    stepLabels={stepsLabels}
                    disabled={isButtonDisabled}
                    expirationDate={expirationDate}
                    dateFacturation={dateFacturation}
                    goBack={handleBack}
                    goNextStep={handleNext}
                    onClickConfirm={onClickConfirm}
                    closeDialog={closeDialog}
                />
            </DialogActions>
            {/*<DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button onClick={onCloseDialog}>
                    Fermer
                </Button>
                <Button variant="contained" color="primary" onClick={onClickConfirm}>
                    Confirmer
                </Button>
            </DialogActions>*/}
        </Dialog>
    );
};

export default FactureEditDialog;
