import { createAsyncThunk } from '@reduxjs/toolkit';
import { EntrepriseEntity as Entreprise } from '../entities/entreprise.entity';

export interface CreateEntrepriseDto extends Omit<Entreprise, 'id'> { }

export const addOneEntreprise = createAsyncThunk(
	'[ENTREPRISE] addOneEntreprise',
	async (entrepriseDto: CreateEntrepriseDto, thunk: any): Promise<Entreprise> => {
		try {
			return await thunk.extra.entrepriseQuery.add(entrepriseDto);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
