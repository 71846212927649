import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import { ribType } from '../entities'

export const updateOneribType = createAsyncThunk(
	'[rib-TYPE] updateOneribType',
	async (rib: Update<ribType>, thunk: any): Promise<Update<ribType>> => {
		try {
			return thunk.extra.ribTypeQuery.update(rib);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
