import { createAsyncThunk } from "@reduxjs/toolkit";

export const resetPassword = createAsyncThunk(
  "[USER] resetPassword",
  async (email: object, thunk: any) => {
    try {
      return await thunk.extra.userQuery.resetPassword(email);
    } catch (error: any) {
      return thunk.rejectWithValue({ message: error.message ?? null });
    }
  }
)