import React from 'react';
import { Checkbox } from '@mui/material';
import { StyledCell } from './StyledCell';

export const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, rowIndex, coloredRow, ...props }: any) => (
  <StyledCell
    checkedKeys={checkedKeys}
    coloredRow={coloredRow}
    rowIndex={rowIndex}
    rowData={rowData}
    {...props}
    style={{ padding: 0 }}
  >
    <div style={{ lineHeight: '46px' }}>
      <Checkbox
        value={rowData[dataKey]}
        onChange={onChange}
        checked={checkedKeys.some((item: any) => item === rowData[dataKey])}
      />
    </div>
  </StyledCell>
);
