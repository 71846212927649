import { createAsyncThunk } from "@reduxjs/toolkit";
import { InterlocuteurEntity } from "../entities/interlocuteur.entity";

export const retreiveAllInterlocuteurs = createAsyncThunk<InterlocuteurEntity[], void, { extra: any }>(
  "[INTERLOCUTEUR] retreiveAllInterlocuteurs",
  async (_, thunk) => {
    try {
      return await thunk.extra.interlocuteurQuery.all();
    } catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
  })