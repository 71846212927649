import { createAsyncThunk } from '@reduxjs/toolkit';
import { Facture } from '../../Model';

export const addOneFacture = createAsyncThunk(
    '[FACTURE] addOneFacture',
    async (params: { dateDeFacturation: string; paymentDueDate: string; bons: string[], rib: string, adresseFacturation: string }, thunk: any): Promise<Facture> => {
        try {
            return await thunk.extra.factureQuery.add(params);
        } catch (error: any) {
            return thunk.rejectWithValue({ message: error.message ?? null });
        }
    }
);
