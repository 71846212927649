import { Avoir } from "../../../Domain/Model";
import { useAppDispatch } from "../../../../_config/react/useAppDispatch";
import { EntityId } from "@reduxjs/toolkit";
import { generateAvoirFile } from "../../../Domain/Application/use-cases";
import { IconButton, ListItem, ListItemText } from "@mui/material";
import { Pageview } from "@mui/icons-material";
import React from "react";

export function AvoirListItem(props: { avoir: Avoir }) {
    const dispatch = useAppDispatch();
    const { avoir } = props;

    const onClickDisplayAvoir = async (avoirId: EntityId, factureId: EntityId) => {
        const res = await dispatch(generateAvoirFile(factureId, avoirId)).unwrap()
        window.open(window.URL.createObjectURL(res));
    };

    return <ListItem secondaryAction={
        <IconButton color="primary" onClick={() => {
            onClickDisplayAvoir(avoir.ref_facture.id, avoir._id)
        }}>
            <Pageview/>
        </IconButton>}>

        <ListItemText primary={avoir.numero}
                      secondary={`${avoir.net_a_deduire.value}€`}
        />

    </ListItem>;
}