import * as React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate, useLocation, Location, useSearchParams } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';

import { LoginView, SendMailToResetPassword, ResetPasswordConfirm } from '../../App/app/auth';
import { UserRouter } from '../../App/app/user/routes';
import EntrepriseRouter from '../../App/app/entreprise/routes';
import ChantierRouter from '../../App/app/chantier/routes';

import { BonRouter, PlanningRouter } from '../../Location/Presentation/routes';
import { FactureRouter } from '../../Facturation/Presentation/routes';

import ChantierModal from '../../App/app/chantier/components/ChantierModal';
import { selectIsLoggedIn } from '../../App/core/auth/selectors';
import EntrepriseModal from '../../App/app/entreprise/components/EntrepriseModal';
import ArticleModal from '../../App/app/article/components/ArticleModal';
import InterlocuteurModal from '../../App/app/interlocuteur/components/InterlocuteurModal';
import ArticleRouter from '../../App/app/article/routes';
import ChauffeurModal from '../../App/app/user/components/ChauffeurModal';
import { ParametreRouter } from '../../App/app/parametre/routes';
import { UserAccountRouter } from '../../App/app/user-account/routes';
import CustomReglementModal from "../../Facturation/Presentation/pages/CustomReglementModal";

function AppRoute() {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const location = useLocation();
    const state = location.state as { backgroundLocation?: Location };
    const [searchParams] = useSearchParams();
    const modal = searchParams.get('modal');
    return (
        <>
            <Routes location={state?.backgroundLocation || location}>
                <Route element={<PublicRoute isLoggedIn={isLoggedIn}/>}>
                    <Route path="/login" element={<LoginView/>}/>
                    <Route path="/reset-password" element={<SendMailToResetPassword/>}/>
                    <Route path="/reset-password-confirm" element={<ResetPasswordConfirm/>}/>
                </Route>
                <Route element={<ProtectedRoute isLoggedIn={isLoggedIn}/>}>
                    <Route path="/planning/*" element={<PlanningRouter/>}/>
                    <Route path="/entreprises/*" element={<EntrepriseRouter/>}/>
                    <Route path="/chantiers/*" element={<ChantierRouter/>}/>
                    <Route path="/user/*" element={<UserRouter/>}/>
                    <Route path="/bons/*" element={<BonRouter/>}/>
                    <Route path="/factures/*" element={<FactureRouter/>}/>
                    <Route path="/articles/*" element={<ArticleRouter/>}/>
                    <Route path="/parametres/*" element={<ParametreRouter/>}/>
                    <Route path="mon-compte/*" element={<UserAccountRouter/>}/>
                </Route>
                <Route path="/" element={<Navigate replace to="/planning"/>}></Route> {/* Default Route */}
                <Route path="*" element={<Navigate replace to="/login"/>}></Route> {/* No Match Route */}
            </Routes>

            {/* Modal Routes */}
            {state?.backgroundLocation && modal && (
                <Routes>
                    <Route path="planning/create" element={getComponentByName(modal)}/>
                    <Route path="planning/:id" element={getComponentByName(modal)}/>
                    <Route path="user/create" element={getComponentByName(modal)}/>
                    <Route path="user/:id" element={getComponentByName(modal)}/>
                    <Route path="bons/create" element={getComponentByName(modal)}/>
                    <Route path="bons/:id" element={getComponentByName(modal)}/>
                    <Route path="entreprises/:id/interlocuteurs" element={getComponentByName(modal)}/>
                    <Route path="entreprises/:id/chantiers" element={getComponentByName(modal)}/>
                    <Route path="entreprises/:id/reglements" element={getComponentByName(modal)}/>

                    <Route path="articles" element={getComponentByName(modal)}/>
                </Routes>
            )}
        </>
    );
}

function getComponentByName(name: string) {
    // in production comp names are different (minimified), using ifs until a better approach is found
    //   const comps = [ChantierModal, EntrepriseModal, ArticleModal, InterlocuteurModal];
    //   const Component = comps.find((component) => component.name.toLowerCase().includes(name));
    //   if (Component) return <Component />;
    if ( name === 'article' ) return <ArticleModal/>;
    if ( name === 'chantier' ) return <ChantierModal/>;
    if ( name === 'interlocuteur' ) return <InterlocuteurModal/>;
    if ( name === 'chauffeur' ) return <ChauffeurModal/>;
    if ( ['client', 'fournisseur', 'employeur'].includes(name) ) return <EntrepriseModal/>;
    if ( name === 'reglement' ) return <CustomReglementModal/>;
    return <></>;
}

export default AppRoute;


