import { createAsyncThunk, EntityId, Update } from '@reduxjs/toolkit';
import { Planning } from '../entities/planning.entity';

export const informEntreprises = createAsyncThunk(
	'[PLANNING] informEntreprises',
	async (params: {id: EntityId, hasBeenWarned: {client: boolean, fournisseur: boolean }}, thunk: any): Promise<Update<Planning>> => {
		try {
			return await thunk.extra.planningService.informEntreprises(params);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
