/// <reference path="../Model/Bon.ts"/>

import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { Ecriture } from '../Model';
import { Bon } from "../Model/Bon"
import { getFormatedDefaultPeriodOnFiscalReferenceYear } from '../../../App/app/common/_helpers';
import { findAllEcritures, retrieveAllEcritures } from './use-cases/retrieve-all-ecritures';

export const ecritureEntityAdapter = createEntityAdapter<Ecriture>({
    selectId: (ecriture) => ecriture.id
});

export const ecritureSlice = createSlice({
        name: 'ecriture',
        initialState: ecritureEntityAdapter.getInitialState({
            filters: null,
            dates: getFormatedDefaultPeriodOnFiscalReferenceYear(),
            status: '',
            bonsDeCommandes: [] as Bon[],
            ecriture : [] as Ecriture[]
        }),
        reducers: {
            updateFilters: (state, action) => {
                state.filters = action.payload;
            },
            updateDate: (state, action) => {
                state.dates = action.payload;
            },
            updateStatus: (state, action) => {
                state.status = action.payload;
            }
        },
        extraReducers: (builder) => {
            builder
            .addCase(findAllEcritures.fulfilled, (state, action) => {
                //console.log("ecriture slice "); console.log(action.payload);
                state.ecriture = action.payload;
                //console.log(state.ecriture);
            })
               
        }
    })
;

export const { updateFilters, updateDate, updateStatus } = ecritureSlice.actions;
export default ecritureSlice.reducer;
