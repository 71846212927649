import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  to: string;
  text: string;
}

const AuthLayoutLink = ({ to, text }: Props) => {
  return (
    <Link to={to} style={{ textDecoration: 'none', color: '#808080' }}>
      {text}
    </Link>
  );
};

export default AuthLayoutLink;
