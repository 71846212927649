import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageHeaderBar from '../../../common/layout/components/PageHeaderBar';
import { ViewCard } from '../../../common/layout/components';
import ArticleForm from './ArticleForm';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { useNavigationFrom } from '../../../common/hooks/useNavigationFrom';
import { setSuccess } from '../../../../core/notification/notification.slice';
import { selectArticleById } from '../../../../core/article/selectors';
import { addOneArticle } from '../../../../core/article/use-cases';
import { updateOneArticle } from '../../../../core/article/use-cases/update-one-article';
import { removeEmpty } from '../../../common/_helpers';
import ArticleEntity from "../../../../core/article/entities";
import useDocumentTitle from '../../../../../_config/react/useDocumentTitle';

function ArticleEdition() {
    useDocumentTitle('MTTP - Edition article');

    const dispatch = useAppDispatch();
    const navigateBack = useNavigationFrom();

    const { id }: any = useParams();
    const article: any = useSelector(selectArticleById(id));

    const defaultValues = {
        fournisseur: {
            id: article?.fournisseur?.id,
            label: article?.fournisseur?.name,
        },
        famille: {
            id: article?.famille,
            label: article?.famille,
        },
    };

    const initialValues = {
        name: article?.name ?? '',
        description: article?.description ?? '',
        prixHT: article?.prixHT ?? '',
        prixVenteHT: article?.prixVenteHT ?? '',
        famille: article?.famille ?? '',
        fournisseur: article?.fournisseur?.name ?? '',
        unite: article?.unite ?? '',
    };

    const onSubmit = async (values: object) => {
     
        const articleUpdated: any = await saveOrUpdate(values)
        if ( !articleUpdated?.error ) {
            notifySuccess();
            navigateBack();
        }
    };

    function saveOrUpdate(values: Partial<ArticleEntity>) {
        if ( Boolean(id) ) {
            return dispatch(updateOneArticle({ id, changes: removeEmpty(values) }));
        }
        return dispatch(addOneArticle({ ...removeEmpty(values) as Omit<ArticleEntity, 'id'> }));
    }

    function notifySuccess() {
        dispatch(setSuccess(id ? `L'article a bien été modifié` : `L'article a bien été créé`));
    }

    const PAGE_TITLE = Boolean(id) ? 'Modifier un article' : 'Créer un article';

    return (
        <>
            <PageHeaderBar title={PAGE_TITLE}/>
            <ViewCard>
                <ArticleForm initialValues={initialValues} onSubmit={onSubmit} defaultValues={defaultValues}/>
            </ViewCard>
        </>
    );
}

export default ArticleEdition;
