import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import { ChantierEntity } from '../entities/chantier.entity';

export const updateOneChantier = createAsyncThunk(
	'[CHANTIER] updateOneChantier',
	async (chantier: Update<ChantierEntity>, thunk: { extra: any, rejectWithValue: any }): Promise<Update<ChantierEntity>> => {
		try {
			return await thunk.extra.chantierQuery.update(chantier);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);