import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { EntrepriseEntity } from './entities';
import {
    retrieveAllEntreprises,
    addOneEntreprise,
    archiveEntreprises,
    updateOneEntreprise,
    addCustomReglement
} from './use-cases';
import { addAdresseFacturation } from "../../../Facturation/Domain/Application/use-cases";

export const entrepriseEntityAdapter = createEntityAdapter<EntrepriseEntity>({
    selectId: (entreprise) => entreprise.id,
    sortComparer: (a, b) => a?.name?.localeCompare(b?.name)
});

export const entrepriseSlice = createSlice({
    name: 'entreprises',
    initialState: entrepriseEntityAdapter.getInitialState({ search: '' }),
    reducers: {
        updateSearch: (state, action) => {
            state.search = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveAllEntreprises.fulfilled, (state, action) => {
                entrepriseEntityAdapter.setAll(state, action);
            })
            .addCase(addOneEntreprise.fulfilled, (state, action) => {
                entrepriseEntityAdapter.addOne(state, action);
            })
            .addCase(archiveEntreprises.fulfilled, (state, action) => {
                const ids = action.meta?.arg;
                entrepriseEntityAdapter.removeMany(state, ids);
            })
            .addCase(updateOneEntreprise.fulfilled, (state, action) => {
                entrepriseEntityAdapter.updateOne(state, action.payload);
            })
            .addCase(addCustomReglement.fulfilled, (state, action) => {
                entrepriseEntityAdapter.updateOne(state, {
                    id: action.payload.entrepriseId,
                    changes: {
                        reglements: action.payload.reglements
                    }
                })
            })
            .addCase(addAdresseFacturation.fulfilled, (state, action) => {
                const { entrepriseId } = action.meta.arg;
                if ( state.entities[entrepriseId] ) {
                    const entity = state.entities[entrepriseId] as EntrepriseEntity;
                    if ( !entity.adresseFacturation ) {
                        entity.adresseFacturation = [];
                    }
                    entity.adresseFacturation.push(action.meta.arg.adresseFacturation);
                }
            })
        ;
    }
});

export const { updateSearch } = entrepriseSlice.actions;
export default entrepriseSlice.reducer;
