import { createAsyncThunk, EntityId, Update } from '@reduxjs/toolkit';
import { User } from '../entities/user.entity';

export const deactivateUsers = createAsyncThunk(
	'[USER] deactivateUsers',
	async (ids: EntityId[], thunk: any): Promise<Update<User>[]> => {
		try {
			return await thunk.extra.userQuery.deactivate({ids, isActive: false});
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
