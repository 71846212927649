import {
  removeFromStorage,
  retreiveFromStorage,
  saveInStorage,
} from "../../../infra/storage-provider/storage.service";

const accessTokenKey = process.env.REACT_APP_ACCESS_TOKEN_KEY || "access-token";
const refreshTokenKey = process.env.REACT_APP_REFRESH_TOKEN_KEY || "refresh-token";

export const retreiveJwtTokenFromLocalStorage = () => {
  return retreiveFromStorage(accessTokenKey);
};

export const saveJwtToLocalStorage = (token: string): void => {
  saveInStorage(accessTokenKey, token);
};

export const removeJwtFromStorage = () => {
  removeFromStorage(accessTokenKey);
};

export const retrieveRefreshToken = () => {
  return retreiveFromStorage(refreshTokenKey)
}

export const saveRefreshToken = (token: string) => {
  return saveInStorage(refreshTokenKey, token)
}
export const removeRefreshToken = () => {
  return removeFromStorage(refreshTokenKey)
}
