import { createAsyncThunk } from '@reduxjs/toolkit';

export const retrieveAllribType = createAsyncThunk(
	'[rib-TYPE] retrieveAllribType',
	async (_: void, thunk: any) => {
		try {
			return  thunk.extra.ribTypeQuery.all();
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
