import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from '@mui/material';
import { Upload } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

interface Props {
  data: any;
  open: boolean;
  onClose(): void;
  handleDownload: any;
  handleUpload(event: React.ChangeEvent<HTMLInputElement>): any;
}

export const AttachmentsDialog = ({ data, open, onClose, handleDownload, handleUpload }: Props) => {
  const [loading, setLoading] = useState(false);
  const fileInput: React.LegacyRef<HTMLInputElement> | null = React.useRef(null);

  const onClickUploadButton = () => fileInput.current?.click();

  const onClickUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);

    await handleUpload(event);

    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="attachment-dialog-title">Pièces-Jointes</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={3}>
          {data && data.docs?.length > 0 ? (
            <List sx={{ width: '400px' }}>
              {data.docs?.map((doc: any) => {
                return (
                  <ListItem key={doc._id} disablePadding>
                    <ListItemButton>
                      <ListItemText onClick={() => handleDownload(doc)}>{doc.originalname}</ListItemText>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <DialogContentText>Aucune pièce-jointe associée...</DialogContentText>
          )}
          <LoadingButton
            color="primary"
            onClick={onClickUploadButton}
            loading={loading}
            loadingPosition="start"
            startIcon={<Upload />}
            variant="contained"
          >
            Ajouter PJ
          </LoadingButton>
          {/* using ref to simulate click on file type input */}
          <input ref={fileInput} type="file" style={{ display: 'none' }} onChange={onClickUpload} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fermer</Button>
      </DialogActions>
    </Dialog>
  );
};
