import { EntityId } from '@reduxjs/toolkit';
import { RootState } from '../../../_config/redux';

import { bonEntityAdapter } from './bon.slice';
import { BonEntity } from "./entities";
import { Status } from "../model/status";

const bonSelector = bonEntityAdapter.getSelectors<RootState>((state) => state.bons);

export const selectAllBons = (state: RootState) => {
    const allBons = bonSelector.selectAll(state)
    return allBons
        .sort((a, b) =>
            a?.client?.localeCompare(b?.client)
            // || a.chantier.localeCompare(b.chantier)
            // || a.article.localeCompare(b.article)
        )
}

export const selectSortedBons = (state: RootState) => bonSelector
    .selectAll(state)
    .sort((a, b) => a?.chantier?.localeCompare(b?.chantier))
    .sort((a, b) => a?.client?.localeCompare(b?.client));
// .sort((a, b) => a?.client?.localeCompare(b?.client))
;


export const selectBillableBons = (state: RootState) => {
    return bonSelector.selectAll(state).filter(bon => bon.status === "SIGNED")
}


export const selectBonById = (id: EntityId) => (state: RootState) => makeBonStatusExplicit(bonSelector.selectById(state, id));

const makeBonStatusExplicit = (aBon?: BonEntity): BonEntity & { isSigned: boolean } | undefined => {
    if ( !aBon ) return undefined;
    return {
        ...aBon,
        isSigned: isValidStatus(aBon.status) && Status[aBon.status] >= Status.SIGNED
    }
}

function isValidStatus(s: string): s is keyof typeof Status {
    return Object.keys(Status).includes(s.toUpperCase());
}

export const selectFilters = (state: RootState) => state.bons.filters;

export const selectCreatedFromModal = (state: RootState) => state.bons.createdFromModal;

export const selectBonsByChantiers = (ids: any[]) => (state: RootState) => {
    const bons = selectAllBons(state);
    return bons.filter(bon => ids.includes(bon.ids?.chantier))
}


export const getSelectedBonsById = (ids: EntityId[]) => (state: RootState) => {
    const bons = selectAllBons(state);
    return bons
        .filter(bon => ids.includes(bon.id))
        ;
}

