import httpAdapter, { _apiUrlFactory } from '../../../_config/api-provider';

const httpService = httpAdapter();

const prefix = 'app/restart';
const apiRestartTypeUrl = _apiUrlFactory(prefix);

export const restartServer = async () => {
	return  httpService.post(`${apiRestartTypeUrl('')}`);
};

 
 