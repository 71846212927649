export const factureStatus = [
    { label: 'Nouvelle facture', value: 'CREATED' },
    { label: 'Facture émise', value: 'EMITTED' },
    { label: 'Facture à régler', value: 'EDITED' },
    { label: 'Règlement incomplet', value: 'PARTIALPAID' },
    { label: 'Facture payée', value: 'PAID' },
    { label: 'Facture annulée', value: 'CANCELLED' },
    { label: 'Facture archivée', value: 'ARCHIVE' },
]

export const factureEntreprise = [
    { label : 'Facture client', value : 'client'},
    { label : 'Facture fournisseur', value : 'fournisseur'},
]