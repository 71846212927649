import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';

export const deleteOneFacture = createAsyncThunk(
        '[FACTURE] deleteOneFacture',
        async ({ id }: { id: EntityId }, thunk: any
        ): Promise<any> => {
            try {
                return await thunk.extra.factureQuery.remove(id);
            } catch (error: any) {
                return thunk.rejectWithValue({ message: error.message ?? null });
            }
        }
    )
;