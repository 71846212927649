import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Typography, Paper } from '@mui/material';

import { selectAllEntreprises } from '../../../../App/core/entreprise/selectors';
import { selectAllChantiers } from '../../../../App/core/chantier/selectors';
import { selectAllInterlocuteurs } from '../../../../App/core/interlocuteur/selectors';
import { selectAllArticles } from '../../../../App/core/article/selectors';
import { selectAllUsers } from '../../../../App/core/user/selectors';

import ArticleEntity from '../../../../App/core/article/entities';
import InterlocuteurEntity from '../../../../App/core/interlocuteur/entities';
import { ChantierEntity } from '../../../../App/core/chantier/entities/chantier.entity';
import { EntrepriseEntity } from '../../../../App/core/entreprise/entities';
import { User } from '../../../../App/core/user/entities/user.entity';

interface Props {
  entityName: string;
  id: string | undefined;
}

interface Preview {
  text: string;
}

function PlanningFormPreview({ entityName, id }: Props) {
  const entreprises = useSelector(selectAllEntreprises);
  const chantiers = useSelector(selectAllChantiers);
  const interlocuteurs = useSelector(selectAllInterlocuteurs);
  const articles = useSelector(selectAllArticles);
  const users = useSelector(selectAllUsers);

  const [previews, setPreviews] = React.useState<Preview[] | null | undefined>(null);

  useEffect(() => {
    const prev = id ? formatPreviewData(entityName) : null;
    setPreviews(prev);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityName, id]);

  const formatPreviewData = (entity: string) => {
    let formattedData;

    switch (entity) {
      case 'client':
        const client = entreprises.find((val) => val.id === id);
        if (client) formattedData = formatEntreprise(client);
        break;
      case 'chantier':
        const chantier = chantiers.find((val) => val.id === id);
        if (chantier) formattedData = formatChantier(chantier);
        break;
      case 'interlocuteur':
        const interlocuteur = interlocuteurs.find((val) => val.id === id);
        if (interlocuteur) formattedData = formatInterlocuteur(interlocuteur);
        break;
      case 'fournisseur':
        const fournisseur = entreprises.find((val) => val.id === id);
        if (fournisseur) formattedData = formatEntreprise(fournisseur);
        break;
      case 'chauffeur':
        const chauffeur = users.find((val) => val.id === id);
        if (chauffeur) formattedData = formatChauffeur(chauffeur);
        break;
      case 'article':
        const article = articles.find((val) => val.id === id);
        if (article) formattedData = formatArticle(article);
        break;
      default:
        formattedData = null;
        break;
    }

    return formattedData;
  };

  const formatEntreprise = (entreprise: EntrepriseEntity) => {
    return [
      { text: `${entreprise.name}` },
      { text: `${entreprise.adresse?.rue ?? ''}` },
      { text: `${entreprise.adresse?.cp ?? ''}, ${entreprise.adresse?.ville ?? ''}` },
    ];
  };

  const formatChantier = (chantier: ChantierEntity) => {
    const lines = [{ text: chantier.name }];
    if (chantier.adresse) {
      lines.push({ text: `${chantier.adresse?.rue ?? ''}` });
      lines.push({ text: `${chantier.adresse?.cp ?? ''}, ${chantier.adresse?.ville ?? ''}` });
    }
    if (chantier.imputationNumber) lines.push({ text: `Imputation: ${chantier.imputationNumber}` });
    return lines;
  };

  const formatInterlocuteur = (interlocuteur: InterlocuteurEntity) => {
    return [
      { text: `${interlocuteur.prenom ?? ''} ${interlocuteur.nom.toUpperCase()}` },
      { text: `Fonction: ${interlocuteur.fonction ?? ''}` },
      { text: `Contact: ${interlocuteur.email ?? ''} - ${interlocuteur.telephone ?? ''}` },
    ];
  };

  const formatChauffeur = (chauffeur: User) => {
    return [
      { text: `${chauffeur.name} ${chauffeur.lastname.toUpperCase()}` },
      { text: `Contact: ${chauffeur.telephone ?? ''}` },
    ];
  };

  const formatArticle = (article: ArticleEntity) => {
    return [
      { text: article.name },
      { text: `Famille: ${article.famille}` },
      { text: `Prix Achat: ${article.prixHT}` },
      { text: `Prix Vente: ${article.prixVenteHT}` },
    ];
  };

  return previews ? (
    <Paper elevation={1} sx={{ backgroundColor: '#f5fbff', width: '100%', borderRadius: '5px', p: 1, mt: 1 }}>
      {previews.map((preview, index) => {
        return (
          <Typography key={index} variant="body1">
            {preview.text}
          </Typography>
        );
      })}
    </Paper>
  ) : null;
}

export default PlanningFormPreview;
