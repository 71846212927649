import React, { useEffect, useState } from 'react';

import {
    Popover,
    Stack,
    TextField,
    Button,
    Typography,
    Autocomplete,
    Tooltip,

    FormControl,
} from '@mui/material';
import { RestartAlt } from '@mui/icons-material';

import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';

import { selectAllChantiers } from '../../../../../App/core/chantier/selectors';
import { selectAllLabelsAndIds } from '../../../../../App/core/entreprise/selectors';

import { updateFilters } from '../../../../Domain/Application/ecriture.slice';
import { EcritureFilters } from '../../../../Domain/Model/entities/filter-ecriture.entity';
import { selectEcrituresFilters } from '../../../../Domain/Application/selectors';
import { selectAllPaymentTypes } from '../../../../../App/core/payment-type/selectors';
import { selectAllribTypes } from '../../../../../App/core/rib-type/selectors';

interface Props {
    open: boolean;
    anchorEl: Element | null;
    type: "client" | "fournisseur" | "";
    onCloseSearchPopover(): void;
}

function EcritureSearchPopover({ open, anchorEl, onCloseSearchPopover, type }: Props) {
    const savedFilters:any = useSelector(selectEcrituresFilters);
    const emptyFilters = {
        type: '',
        fournisseur: '',
        chantier: '',
        numeroFacture: '',
        dateDeFacturation: '',
        status: '',
        banque: '',
        compte: '',
        client: '',
        reglement: '',
        totalTva: 0,
        totalTTC: '',
        totalFraisAutoroute: 0,
    };
    const defaultValues = savedFilters ? savedFilters : emptyFilters;

    const [filters, setFilters] = useState<EcritureFilters>(defaultValues);

    
    const factureType = useSelector(selectAllPaymentTypes).map(({ _id, wording }) => ({ id: _id, label: wording }));
    const factureBanque = useSelector(selectAllribTypes).map(({ _id, name }) => ({ id: _id, label: name }));
    const entreprises = useSelector(selectAllLabelsAndIds);
    const chantiers = useSelector(selectAllChantiers);
    const [filteredChantiers, setFilteredChantiers] = useState(chantiers.map(({ id, name }) => ({ id, label: name })).sort((a, b) => a.label.localeCompare(b.label))); // create a state for filtered chantiers
    const [isChantierDisabled, setIsChantierDisabled] = useState(true);
    const dispatch = useAppDispatch();

    const isOptionEqualToValue = (option: any, value: any) => option.id === value.id || value.id === ''; // if id is empty returns true to remove warnings in console

    const getFilterValue = (options: { id: string; label: string }[], property: keyof EcritureFilters) => {
        return options.find((option) => option.id === filters[property] || option.label === filters[property]) ?? { id: '', label: '' };
    };

    const handleInputChange = (params: { property: string; value: any }) => {
        const { property, value } = params;
        if ( value ) {
            if(property === 'chantier' || property === 'banque' || property === 'reglement') { setFilters({ ...filters, [property]: value.label }); }
            else { setFilters({ ...filters, [property]: value.id }); }
        } else {
            setFilters({ ...filters, [property]: '' });
        }
    };

    const onClickSaveFilters = () => {
        onCloseSearchPopover();
        dispatch(updateFilters(filters));
    };

    const onResetForm = () => {
        setFilters(emptyFilters);
        dispatch(updateFilters(emptyFilters));
    };
    

    useEffect(() => {
        if (filters.client) {
            const updatedChantiers = chantiers.filter(chantier => chantier.client === filters.client.toString()).map(({ id, name }) => ({ id, label: name }))
            setFilteredChantiers(updatedChantiers.sort((a, b) => a.label.localeCompare(b.label)));
            setIsChantierDisabled(false);
        }
        else {
            setIsChantierDisabled(true);
        }
    }, [filters.client]); 

 

    return (
        <Popover open={open} anchorEl={anchorEl}>
            <Stack direction="column" gap={2} width={400} p={3}>
                <Typography variant="h5">Filtres</Typography>


                <FormControl>
                <TextField
                    fullWidth
                    label="Compte"
                    placeholder="41100XXXX"
                    id="compte"
                    value={filters.compte}
                    onChange={(event) => setFilters({ ...filters, compte: event.target.value })}
                    variant="outlined"
                    name="compte"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*.?[0-9]*' }}
                    type="text"
                    InputLabelProps={{ shrink: true }}
                />

                </FormControl>


                {/*type === "" && <FormControl>
                <TextField
                    fullWidth
                    label="Numéro d'avoir"
                    placeholder="20YYMMXXXX"
                    id="numeroFacture"
                    value={filters.numeroFacture}
                    onChange={(event) => setFilters({ ...filters, numeroFacture: event.target.value })}
                    variant="outlined"
                    name="numeroFacture"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*.?[0-9]*' }}
                    type="text"
                    InputLabelProps={{ shrink: true }}
                />

                </FormControl>*/}


                {/* Numéro de facture */}
                <FormControl>
                <TextField
                    fullWidth
                    label="Numéro de facture"
                    placeholder="20YYMMXXXX"
                    id="numeroFacture"
                    value={filters.numeroFacture}
                    onChange={(event) => setFilters({ ...filters, numeroFacture: event.target.value })}
                    variant="outlined"
                    name="numeroFacture"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*.?[0-9]*' }}
                    type="text"
                    InputLabelProps={{ shrink: true }}
                />

                </FormControl>


                                {/* Montant total TTC*/}
                                <FormControl>
                <TextField
                    fullWidth
                    label="Montant"
                    placeholder="123.45"
                    id="totalTTC"
                    value={filters.totalTTC}
                    onChange={(event) => setFilters({ ...filters, totalTTC: event.target.value })}
                    variant="outlined"
                    name="totalTTC"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*.?[0-9]*' }}
                    type="number"
                    InputLabelProps={{ shrink: true }}
                />

                </FormControl>


                {/* TypeEntreprise 
                <FormControl>
                    <InputLabel sx={{ ml: -2 }} id="select-type-label">
                        Type
                    </InputLabel>
                    <Select
                        labelId="select-type-label"
                        value={filters.type}
                        variant="standard"
                        label="Type"
                        onChange={(event) => setFilters({ ...filters, type: event.target.value })}
                    >
                        <MenuItem value="">
                            <em>Tout</em>
                        </MenuItem>
                        {factureEntreprise.map((s) => (
                            <MenuItem key={s.value} value={s.value}>
                                {s.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>*/}

                {/* Facture fournisseur : bdc  */}
                {(type === "fournisseur"|| type === "") && <Autocomplete
                    id="fournisseur"
                    options={entreprises}
                    isOptionEqualToValue={isOptionEqualToValue}
                    value={getFilterValue(entreprises, 'fournisseur')}
                    onChange={(e, value) => handleInputChange({ property: 'fournisseur', value })}
                    renderInput={(params) => <TextField {...params} label="Fournisseur" variant="standard"/>}
                /> }
                {/* Client */}
                
                {(type === "client" || type === "") && <Autocomplete
                    id="client"
                    options={entreprises}
                    isOptionEqualToValue={isOptionEqualToValue}
                    value={getFilterValue(entreprises, 'client')}
                    onChange={(e, value) => handleInputChange({ property: 'client', value })}
                    renderInput={(params) => <TextField {...params} label="Client" variant="standard"/>}
                /> }
              
                {/* Chantier */}
                <Autocomplete
                    disabled={isChantierDisabled}
                    options={filteredChantiers}
                    isOptionEqualToValue={isOptionEqualToValue}
                    value={getFilterValue(filteredChantiers, 'chantier')}
                    onChange={(e, value) => handleInputChange({ property: 'chantier', value })}
                    renderInput={(params) => <TextField {...params} label="Chantier" variant="standard"/>}
                />
                {/* Fournisseur 
                <Autocomplete
                    options={entreprises}
                    isOptionEqualToValue={isOptionEqualToValue}
                    value={getFilterValue(entreprises, 'fournisseur')}
                    onChange={(e, value) => handleInputChange({ property: 'fournisseur', value })}
                    renderInput={(params) => <TextField {...params} label="Fournisseur" variant="standard"/>}
                />*/}

                {/* Type règlement */}

                <Autocomplete
                    options={factureType}
                    isOptionEqualToValue={isOptionEqualToValue}
                    value={getFilterValue(factureType, 'reglement')}
                    onChange={(e, value) => handleInputChange({ property: 'reglement', value })}
                    renderInput={(params) => <TextField {...params} label="Type règlement" variant="standard"/>}
                />


             


                {/* Banque */}

                <Autocomplete
                    options={factureBanque}
                    isOptionEqualToValue={isOptionEqualToValue}
                    value={getFilterValue(factureBanque, 'banque')}
                    onChange={(e, value) => handleInputChange({ property: 'banque', value })}
                    renderInput={(params) => <TextField {...params} label="Banque" variant="standard"/>}
                />

 

                <Stack direction="row" justifyContent="space-between" mt={2}>
                    <Tooltip title="Réinitialiser">
                        <Button variant="outlined" color="error" onClick={onResetForm}>
                            <RestartAlt/>
                        </Button>
                    </Tooltip>
                    <Stack direction="row" justifyContent="flex-end" gap={2}>
                        <Button onClick={onCloseSearchPopover}>Fermer</Button>
                        <Button color="primary" variant="contained" onClick={onClickSaveFilters}>
                            Enregistrer
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </Popover>
    );
}

export default EcritureSearchPopover;
