import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PageHeaderBar from '../../../common/layout/components/PageHeaderBar';
import EntrepriseForm from './EntrepriseForm';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { addOneEntreprise, updateOneEntreprise } from '../../../../core/entreprise/use-cases';
import { useNavigationFrom } from '../../../common/hooks/useNavigationFrom';
import { CreateEntrepriseDto } from '../../../../core/entreprise/use-cases/add-one-entreprise';
import { selectEntrepriseById } from '../../../../core/entreprise/selectors';
import { EntrepriseEntity } from '../../../../core/entreprise/entities';
import { setSuccess } from '../../../../core/notification/notification.slice';
import { ViewCard } from '../../../common/layout/components';
import { removeEmpty } from '../../../common/_helpers';
import useDocumentTitle from '../../../../../_config/react/useDocumentTitle';
import { SelectChangeEvent } from '@mui/material';

function EntrepriseEdition() {

  useDocumentTitle(`MTTP - Edition entreprise`);


  const dispatch = useAppDispatch();
  const navigateBack = useNavigationFrom();

  const { id }: any = useParams();
  const entreprise: EntrepriseEntity | undefined = useSelector(selectEntrepriseById(id));

  const initialValues = {
    name: entreprise?.name ?? '',
    rue: entreprise?.adresse?.rue ?? '',
    cp: entreprise?.adresse?.cp ?? '',
    ville: entreprise?.adresse?.ville ?? '',
    email: entreprise?.contact?.email ?? '',
    telephone: entreprise?.contact?.telephone ?? '',
    code_fournisseur: entreprise?.code_fournisseur ?? '',
    code_client: entreprise?.code_client ?? '',
    fin_du_mois : entreprise?.fin_du_mois ?? false,
    rib_mttp : entreprise?.rib_mttp ?? '',
    isPaiementInEndOfMonth: entreprise?.isPaiementInEndOfMonth || false,
    observations : entreprise?.observations ?? '',
  };

  const [selectedRib, setRibSelection] = useState('');
  function onRibSelection(e: SelectChangeEvent<string>) {
    setRibSelection(e.target.value);
  }

  const onSubmit = async (values: any) => {
    const formattedEntreprise: CreateEntrepriseDto = {
      name: values.name,
      code_fournisseur: values.code_fournisseur,
      code_client: values.code_client,
      isPaiementInEndOfMonth: values.isPaiementInEndOfMonth,
      adresse: {
        rue: values?.rue,
        cp: values.cp,
        ville: values.ville,
      },
      contact: {
        email: values.email,
        telephone: values.telephone,
      },
      fin_du_mois: values.fin_du_mois,
      rib_mttp: values.rib_mttp,
      observations: values.observations,
    };

    let onlyFilledValues: any = removeEmpty(formattedEntreprise);
    if(!onlyFilledValues.isPaiementInEndOfMonth) { 
      onlyFilledValues.isPaiementInEndOfMonth = false;
    }

    const res: any = id
      ? await dispatch(updateOneEntreprise({ id, changes: onlyFilledValues }))
      : await dispatch(addOneEntreprise(onlyFilledValues));

    if (!res.error) {
      dispatch(setSuccess(id ? `L'entreprise a bien été mise à jour` : `L'entreprise a bien été créée`));
      navigateBack();
    }
  };

  return (
    <>
      <PageHeaderBar title={id ? 'Modifier une entreprise' : 'Créer une nouvelle entreprise'} />
      <ViewCard>
        <EntrepriseForm initialValues={initialValues} onRibSelection={onRibSelection} onSubmit={onSubmit} entreprise={entreprise} />
      </ViewCard>
    </>
  );
}

export default EntrepriseEdition;
