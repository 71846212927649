import { createTheme } from '@mui/material';
import { frFR } from '@mui/material/locale';


const theme = createTheme({
	palette: {
		primary: {
			light: '#f5fbff',
			main: '#0d6db6',

		},
		secondary: {
			light: '#14b4e6',
			main: '#0064fa'
		},
		background: {
			// default: '#f2f7fa'
			default: '#f3f3f3'
		},
		error: {
			light: '#f9b7c3',
			main: '#f04b69'
		},
		success: {
			light: '#93dcaf',
			main: '#008732',
			dark: '#114b26'
		},
		warning: {
			light: '#ffb74d',
			main: '#ef6c00'
		}
	}
}, frFR);

export default theme;
