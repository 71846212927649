import { useRoutes } from 'react-router-dom';
import FactureList from '../pages/FactureList';
import ListeEcritureTable from '../components/liste-ecritures/ListeEcritureTable';

export default function PlanningRouter() {
    const element = useRoutes([
        { path: '', element: <FactureList/> },
        // { path: '/:id', element: < /> },
        // { path: 'create', element: <FactureCreate /> },
        //{ path: 'ecriture', element: <ListeEcritureTable /> },
    ]);
    return element;
}
