import { createAsyncThunk, EntityId } from "@reduxjs/toolkit";
import { IAdresse } from "../../../../../App/core/entreprise/entities/adresse.entity";
import { IEntrepriseService } from "../../../Ports/IEntreprise.service";

interface Payload {
    entrepriseId: EntityId,
    adresseFacturation: IAdresse
}

interface Result {
    adresseFacturation: IAdresse
}

interface entrepriseService {
    addAdresseFacturation: IEntrepriseService['addAdresseFacturation'];
}

export const addAdresseFacturation = createAsyncThunk<Result, Payload, { extra: { entrepriseQuery: entrepriseService } }>(
    "[Entreprise] add address facturation",
    async ({ entrepriseId, adresseFacturation }, thunkAPI) => {
        await thunkAPI.extra.entrepriseQuery.addAdresseFacturation(
            entrepriseId.toString(),
            adresseFacturation);

        return { adresseFacturation };
    }
)