import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, ListItemText, Stack } from '@mui/material';

import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { CustomTable } from '../../../../App/app/common/custom-table';
import { ColumnEntity } from '../../../../App/app/common/custom-table/Table.entities';
import { validateDates } from '../../../../App/app/common/_helpers';

import { RootState } from '../../../../_config/redux';
import { retrieveBons } from '../../../Domain/Bons/use-cases';
import { selectSortedBons } from '../../../Domain/Bons/selectors';
import { BonEntity } from '../../../Domain/Bons/entities';

import BonTableActionsCell from './BonTableActionsCell';
import { BonTableStatusCell, Status } from './BonTableStatusCell';
import PlanningEdition from "../planning-edition/PlanningEdition";
import { Planning } from "../../../Domain/Planning/entities/planning.entity";
import { useSummary } from "../hooks/useListSummary";
import { TableListSummary } from "../TableListSummary";

interface Props {
    onSelectRows(ids: EntityId[]): void;
}

function BonTable({ onSelectRows }: Props) {
    const [isLoading, setIsLoading] = useState(true);

    const bons = useSelector(selectSortedBons);
    const selectedDates = useSelector((state: RootState) => state.bons.dates);
    const filters: any = useSelector((state: RootState) => state.bons.filters);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    useEffect(() => {
        let validDates = validateDates(selectedDates);

        // request will be sent if both dates are valid or no dates is selected
        if ( !!validDates ) {
            const { fromDate, toDate } = validDates;
            dispatch(retrieveBons({ filters, fromDate, toDate })) 
                .then(() => setIsLoading(false));
        }

        return () => {
            setIsLoading(true);
        };
    }, [dispatch, selectedDates, filters]);

    const onRowDoubleClick = ({ id }: BonEntity) => navigate(`../${id}`, { state: id });

    const columns: ColumnEntity[] = [
        {
            field: 'status',
            headerName: '',
            width: 40,
            renderCell: (param) =>
                Object.values(Status).includes(param as Status) ? <BonTableStatusCell param={param}/> : param,
        },
        { field: 'date', headerName: 'Date', width: 110, sortable: true, resizable: true },
        { field: 'referenceClient', headerName: 'Ref. Client', width: 130, sortable: true, resizable: true },
        { field: 'bonNumber', headerName: 'N° Bon', width: 125, sortable: true, resizable: true },
        { field: 'client', headerName: 'Client', width: 140, sortable: true, resizable: true },
        { field: 'chantier', headerName: 'Chantier', width: 140, sortable: true, resizable: true },
        { field: 'commandePar', headerName: 'Commandé par', width: 160, sortable: true, resizable: true },
        { field: 'article', headerName: 'Article', width: 140, sortable: true, resizable: true },
        { field: 'fournisseur', headerName: 'Fournisseur', width: 140, sortable: true, resizable: true },
        { field: 'unite', headerName: 'Unité', width: 80, sortable: true, resizable: true },
        { field: 'quantite', headerName: 'Qte', width: 60, sortable: true, resizable: true },
        { field: 'prixAchatHTe', headerName: 'PA HT', sortable: true, resizable: true },
        { field: 'prixVenteHTe', headerName: 'PV HT', sortable: true, resizable: true },
        { field: 'achatHTe', headerName: 'Achat HT', sortable: true, resizable: true },
        { field: 'montantHTe', headerName: 'Montant HT', width: 110, sortable: true, resizable: true },
    ];

    const table_bons = JSON.parse(JSON.stringify(bons));
 
    table_bons.forEach((bon:any) => {
        bon.prixAchatHTe = Number(bon.prixAchatHT).toFixed(2) + " €";
        bon.prixVenteHTe = Number(bon.prixVenteHT).toFixed(2) + " €";
        bon.achatHTe = Number(bon.achatHT).toFixed(2) + " €";
        bon.montantHTe = Number(bon.montantHT).toFixed(2) + " €";
    });

    return (
        <Box sx={{ height: '100%' }}>
            <CustomTable
                data={table_bons}
                columns={columns}
                loading={isLoading}
                sortByDefault="date"
                onRowDoubleClick={onRowDoubleClick}
                onSelectRows={onSelectRows}
                renderActionCell={(rowData, measuredRef) =>
                    <BonTableActionsCell
                        id={rowData.id}
                        comments={rowData.commentaire_interne}
                        measuredRef={measuredRef}
                        status={rowData.status}/>
                }
                rendersummary={() => <TableListSummary collection={bons}/>}
            />
        </Box>
    );
}

export default BonTable;
