import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';

interface EntrepriseQuery {
	_delete(ids: EntityId[]): Promise<EntityId[]>;
}

export const archiveEntreprises = createAsyncThunk('[ENTREPRISE] archiveEntreprises', async (ids: EntityId[], thunkAPI) => {
	try {
		return await (thunkAPI.extra as { entrepriseQuery: EntrepriseQuery }).entrepriseQuery._delete(ids);
	} catch (error: any) {
		return thunkAPI.rejectWithValue({ message: error.message ?? null });
	}
});
