import { Link, useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { AddCircleRounded as AddIcon } from '@mui/icons-material';
import { useEffect, useState } from 'react';

interface Props {
  label?: string;
  selection?: string;
}

export default function FormModalLink(props: Props) {
  const { label, selection } = props;

  const toModalPath = typeof label === 'string' ? `?modal=${label.toLowerCase()}` : '';
  const location = useLocation();
  const [linkState, setLinkState] = useState<any>({
    backgroundLocation: location,
  });

  useEffect(() => {
    if (selection) {
      setLinkState({
        ...linkState,
        extra: selection,
        keepIdInState: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selection]);

  const isActive = typeof props.selection === 'string' ? !!props.selection : true;

  return (
    <>
      <Link to={isActive ? toModalPath : '#'} state={linkState} replace>
        <IconButton color="primary" disabled={!isActive} edge="end">
          <AddIcon />
        </IconButton>
      </Link>
    </>
  );
}
