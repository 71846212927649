import { useRoutes } from 'react-router-dom';
import { UserEdition } from '../components/user-edition';
import { UserList } from '../components/user-list';

export default function UserRouter() {
  const element = useRoutes([
    { path: '', element: <UserList /> },
    { path: ':id', element: <UserEdition /> },
    { path: 'create', element: <UserEdition /> },
  ]);
  return element;
}
