import React from 'react';
import { Box, Stack } from '@mui/material';

interface Props {
  children: [React.ReactElement, React.ReactElement?, React.ReactElement?];
  label: string;
  selection?: string;
  value?: any;
}

export default function FormFieldLayout({ children, label, selection }: Props) {
  const cloneElementWithProps = (element: React.ReactElement) => {
    return React.cloneElement(React.Children.only(element), { label, selection });
  };

  return (
    <Box flexDirection="column">
      <Stack direction="row" gap={2}>
        {cloneElementWithProps(children[0])}

        {children[1] ? <Box mt={2}>{cloneElementWithProps(children[1])}</Box> : null}
      </Stack>
      {children[2] ? <Box>{cloneElementWithProps(children[2])}</Box> : null}
    </Box>
  );
}
