import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';

export const emitOneFacture = createAsyncThunk(
        '[FACTURE] emitOneFacture',
        async ({ id, anEmail }: { id: EntityId, anEmail: string }, thunk: any
        ): Promise<any> => {
            try {
                return await thunk.extra.factureQuery.emit(id, anEmail);
            } catch (error: any) {
                return thunk.rejectWithValue({ message: error.message ?? null });
            }
        }
    )
;