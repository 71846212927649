import { EntityId } from '@reduxjs/toolkit';
import { CategorieProduit } from '../../core/categorie-produit/entities';
import httpAdapter, { _apiUrlFactory } from '../../../_config/api-provider';

const httpService = httpAdapter();

const prefix = 'category';
const apiCategorieProduitUrl = _apiUrlFactory(prefix);

export const all = async () => {
	return await httpService.get(`${apiCategorieProduitUrl('')}`);
};

export const add = async (categorie: any): Promise<Partial<any>> => {
	return await httpService.post(apiCategorieProduitUrl(''), categorie);
};

export const update = async (categorie: CategorieProduit): Promise<CategorieProduit> => {
	const { _id, ...otherProps } = categorie;
	return await httpService.put(apiCategorieProduitUrl(`${_id}`), otherProps);
};

export const _delete = async (id: EntityId) => {
  return await httpService.delete(apiCategorieProduitUrl(`${id}`));
}