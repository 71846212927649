import { createAsyncThunk } from "@reduxjs/toolkit";

interface Options {
  token: string;
  password: string;
}

export const resetPasswordConfirm = createAsyncThunk(
  "[USER] resetPasswordConfirm",
  async (options: Options, thunk: any) => {
    try {
      return await thunk.extra.userQuery.resetPasswordConfirm(options);
    } catch (error: any) {
      return thunk.rejectWithValue({ message: error.message ?? null });
    }
  }
)