/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, MouseEvent } from "react";
import { Badge, Button, IconButton, Stack, Tooltip } from "@mui/material";
import { FileDownload,  Tune as TuneIcon,} from "@mui/icons-material";
import { DateRangeField } from "../../../../App/app/common/date-picker";
import { CustomTableToolbar } from "../../../../App/app/common/custom-table";
import { useAppDispatch } from "../../../../_config/react/useAppDispatch";
import { useSelector } from "react-redux";
import { RootState } from "../../../../_config/redux";
import { updateDate } from "../../../Domain/Application/ecriture.slice";
import { exportToXLSX,  dateFormat} from "../../../../App/app/common/_helpers";
import { EntityId } from "@reduxjs/toolkit";
//import { ClientFournisseurRadioSelect } from "../ClientFournisseurRadioSelect";
import { selectAllEcritures, selectAllFactures } from "../../../Domain/Application/selectors";
import { format } from 'date-fns';
import { selectAllPaymentTypes } from "../../../../App/core/payment-type/selectors";
import { selectAllribTypes } from "../../../../App/core/rib-type/selectors";
import EcritureSearchPopover from "./ecriture-filters/EcritureSearchPopover";
//import { Link } from 'react-router-dom';
interface Props {
  selectedRows: EntityId[];
  /*selectedType: "fournisseur" | "client" | "";
  filterType: (type: "fournisseur" | "client" | "") => void;*/
}

function ListeEcritureTableToolbar({
  selectedRows,
 /* filterType,
  selectedType,*/
}: Props) {
  const dispatch = useAppDispatch();
  const selectedDates = useSelector((state: RootState) => state.ecritures.dates);
  const [filtersFactureBadge, setfiltersFactureBadge] = useState(0);
  const filters: any = useSelector((state: RootState) => state.ecritures.filters);
  const [isSearchPopoverOpen, setIsSearchPopoverOpen] = useState(false);
  const [searchAnchorEl, setSearchAnchorEl] = useState<Element | null>(null);

  const onDateUpdate = (field: string, value: string) => {
    if (value !== selectedDates) dispatch(updateDate(value));
  };

 

  //const onChangeFilter = (selected: string) => dispatch(updateStatus(selected));
  const ecritures = useSelector(selectAllEcritures);
  const paymentTypes = useSelector(selectAllPaymentTypes);
  const banques = useSelector(selectAllribTypes);

  const onClickExport = () => {
 
    const heading = [
      'Compte',
      'Date de règlement', 
      'N° Facture', 
      'Client', 
      'Fournisseur', 
      'Type', 
      'Banque', 
      'Montant'
    ];
    
    let export_dates = format(new Date(), dateFormat);
    if(selectedDates !== "") {
      export_dates = selectedDates;
    }
    exportToXLSX(ecritures.ecriture, `Ecritures-${export_dates}`, heading);
  };

  useEffect(() => {
    let badgeCounter = 0;
    for (const property in filters) {
        if ( filters[property] !== '' && filters[property] !== 0) {
            badgeCounter++;
        }
    }
    setfiltersFactureBadge(badgeCounter);
}, [filters]);

  const onOpenSearchPopover = (event: MouseEvent) => {
    const { currentTarget } = event;
    setIsSearchPopoverOpen(true);
    setSearchAnchorEl(currentTarget);
  };
  const onCloseSearchPopover = () => {
    setIsSearchPopoverOpen(false);
    setSearchAnchorEl(null);
};


  return (
    <>
      <CustomTableToolbar>
        <Stack direction="row" alignItems="flex-end" spacing={1}>
          <DateRangeField
            sx={{ width: 270 }}
            size="small"
            variant="outlined"
            label="Filtrer par date..."
            onDateUpdate={onDateUpdate}
            value={selectedDates}
          />

          {/* Buttons */}
          <Tooltip title="Filtrer">
            <IconButton onClick={onOpenSearchPopover}>
              <Badge badgeContent={filtersFactureBadge} color="error">
                  <TuneIcon/>
              </Badge>
            </IconButton>
          </Tooltip>

          {/*<ClientFournisseurRadioSelect
            onChange={(e) => filterType(e.target.value as any)}
            selectedValue={selectedType}
          />*/}

          {/* Buttons 
          
          <DropDownButton
            labels={[
              { label: "Toutes les factures", value: "" },
              ...factureStatus,
            ]}
            onChange={onChangeFilter}
          />

           <Button
            size="small"
            variant="contained"
            color="warning"
            startIcon={<PriorityHigh />}
            onClick={handleRelanceDialog}
          >
            Relances
          </Button>
        
          <Button 
            component={Link} 
            size="small"
            to="ecriture" 
            variant="contained" 
            color="secondary" 
            startIcon={<Edit/>}>
                    Ecritures
          </Button>
          
          */}

          {/*<Button
            size="small"
            variant="contained"
            color="info"
            startIcon={<FolderZip />}
            onClick={downloadZipFileForSelection}
            disabled={selectedRows.length === 0}
          >
            Zip
          </Button>*/}
          {<Button
            variant="contained"
            color="success"
            onClick={onClickExport}
            startIcon={<FileDownload />}
            size="small"
          >
            XLSX
          </Button>}
        </Stack>
        {/*<Stack>*/}
        {/*</Stack>*/}

      </CustomTableToolbar>

      {/* Dialogs */}


      <EcritureSearchPopover
                open={isSearchPopoverOpen}
                anchorEl={searchAnchorEl}
                onCloseSearchPopover={onCloseSearchPopover}
                type={""}
            />

    </>
  );
}

export default ListeEcritureTableToolbar;
