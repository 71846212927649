import React from 'react';
import { Form, Formik } from 'formik';
import { object, ref, setLocale, string } from 'yup';

import { Grid, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { yupFr } from '../../common/i18n';
import { User } from '../../../core/user/entities/user.entity';
import { AccountCircle, Email, Key } from '@mui/icons-material';

interface Props {
  initialValues: Partial<User> & { passwordConfirm: string };
  onSubmit(values: Partial<User>): void;
}

export const UserAccountUpdateForm = ({ initialValues, onSubmit }: Props) => {
  setLocale(yupFr);
  // fields aren't required. If password is not empty then passwordConfirm should have the same value
  const schema = object().shape(
    {
      name: string().notRequired(),
      email: string().email().notRequired(),
      password: string()
        .nullable()
        .notRequired()
        .when('password', {
          is: (value: string) => value?.length,
          then: (rule) => rule.min(8),
        }),
      passwordConfirm: string()
        .nullable()
        .notRequired()
        .when('password', {
          is: (value: string) => value?.length,
          then: string()
            .required()
            .oneOf([ref('password'), null]),
        }),
    },
    [
      // Add Cyclic deps here because when require itself
      ['password', 'password'],
    ]
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
      {(props) => {
        const { values, touched, errors, dirty, isValid, handleChange, handleBlur, handleSubmit, isSubmitting } = props;
        return (
          <>
            <Form onSubmit={handleSubmit} style={{ display: 'flex' }}>
              <Grid container direction="row" spacing={4} p={4} width={{ xs: '100%', md: '75%', xl: '60%' }}>
                <Grid item xs={12}>
                  <Stack direction="row" gap={2}>
                    <AccountCircle sx={{ mt: 2 }} />
                    <TextField
                      fullWidth
                      label="Nom"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" gap={2}>
                    <Email sx={{ mt: 2 }} />
                    <TextField
                      fullWidth
                      label="Adresse email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      variant="outlined"
                      type="email"
                      onBlur={handleBlur}
                      error={!!errors.email && !!touched.email}
                      helperText={touched.email && errors.email}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" gap={2}>
                    <Key sx={{ mt: 2 }} />
                    <TextField
                      fullWidth
                      label="Mot de passe"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      variant="outlined"
                      type="password"
                      error={!!errors.password && !!touched.password}
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" gap={5}>
                    <span />
                    <TextField
                      fullWidth
                      label="Confirmation mot de passe"
                      name="passwordConfirm"
                      value={values.passwordConfirm}
                      onChange={handleChange}
                      variant="outlined"
                      type="password"
                      error={!!errors.passwordConfirm && !!touched.passwordConfirm}
                      helperText={touched.passwordConfirm && errors.passwordConfirm}
                      onBlur={handleBlur}
                    />
                  </Stack>
                </Grid>

                <Grid item display="flex" justifyContent="flex-end" sx={{ my: 2 }} xs={12} gap={3}>
                  <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={!dirty || !isValid}>
                    Mettre à jour
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
