import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Chat } from "@mui/icons-material";
import { CommentsDialog } from "../../../../App/app/common/modals/components/CommentsDialog";

interface Props {
    comment: string
}

export default function CommentaireTableActionCell({ comment }: Props) {

    const [isDialogOpen, setDialogOpen] = useState(false);

    function toggleDialog() {
        setDialogOpen(!isDialogOpen)
    }

    return <>
        <Tooltip title="Commentaire">
            <IconButton
                aria-label="commentaire"
                size="small"
                disabled={!Boolean(comment)}
                color={comment ? ("primary") : ("default")}
                onClick={toggleDialog}>
                <Chat/>
            </IconButton>
        </Tooltip>
        <CommentsDialog
            comments={comment}
            open={isDialogOpen}
            onClose={toggleDialog}/>
    </>
}
