import { useRoutes } from 'react-router-dom';
import { BonEdition, BonList } from '../components';

export default function BonRouter() {
    const element = useRoutes([
        { path: '', element: <BonList/> },
        { path: '/:id', element: <BonEdition/> },
        { path: 'create', element: <BonEdition/> },
    ]);
    return element;
}
