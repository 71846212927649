import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import { CategorieProduit } from '../entities'

export const updateOneCategorieProduit = createAsyncThunk(
	'[CATEGORIE-PRODUIT] updateOneCategorieProduit',
	async (categorie: CategorieProduit, thunk: any): Promise<Update<CategorieProduit>> => {
		try {
			return await thunk.extra.categorieProduitQuery.update(categorie);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
