import { useRoutes, Link, useLocation } from "react-router-dom"



export default function ChantierRouter() {
    const location = useLocation()

    const element = useRoutes([
        {
            path: "", element: <>
                <Link
                    to="/planning/create"
                    // This is the trick! Set the `backgroundLocation` in location state
                    // so that when we open the modal we still see the current page in
                    // the background.

                    state={{ backgroundLocation: location, entreprise: { id: "E1", name: "WhateeverBuilder" } }}
                >
                    <button onClick={() => console.log(location)}>
                        link
                    </button></Link>
            </>
        },
    ])
    return element;
}