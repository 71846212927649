import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PageHeaderBar from '../../../../App/app/common/layout/components/PageHeaderBar';
import { ViewCard } from '../../../../App/app/common/layout/components';
import PlanningForm from '../planning-form/PlanningForm';

import { selectPlanningById } from '../../../Domain/Planning/selectors';
import { addOnePlanning, updateOnePlanning } from '../../../Domain/Planning/use-cases';
import { useDefaultValueOf } from "../hooks/useDefaultValueOf";
import { useInitialValueOf } from "../hooks/useInitialValueOf";
import { useSaveEntityOnSubmit } from "../hooks/useSaveEntityOnSubmit";
import useDocumentTitle from '../../../../_config/react/useDocumentTitle';

function PlanningEdition() {
    useDocumentTitle(`MTTP - Modification Planning`);
    const { id }: any = useParams();
    const planningLine = useSelector(selectPlanningById(id));
    const defaultValues = useDefaultValueOf(planningLine);
    const initialValues = useInitialValueOf(planningLine);
//console.log(planningLine);
    const { onSubmit, isLoading } = useSaveEntityOnSubmit({
        entityName: 'planning',
        entityId: id,
        add: addOnePlanning,
        update: updateOnePlanning
    })

    const title = id ? `Modification planning` : 'Créer un nouveau planning';

    return (
        <>
            <PageHeaderBar title={title}/>
            <ViewCard>
                <PlanningForm
                    initialValues={initialValues}
                    defaultValues={defaultValues}
                    onSubmit={onSubmit}
                    isButtonLoading={isLoading}
                    isBon={false}
                    isSigned={false}
                />
            </ViewCard>
        </>
    );
}

export default PlanningEdition;
