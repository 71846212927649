import { createAsyncThunk } from '@reduxjs/toolkit';
import { serveurType } from '../entities';

export const addOneserveurType = createAsyncThunk(
	'[serveur-TYPE] addOneserveurType',
	async (serveurType: serveurType, thunk: any): Promise<serveurType> => {
		try {
			return  thunk.extra.serveurTypeQuery.add(serveurType);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
