import React from 'react';
import { List } from '@mui/material';
import {
  SvgIconComponent,
  DateRange,
  ContactMail,
  Description,
  Receipt,
  Unarchive,
  MiscellaneousServices,
  Business,
} from '@mui/icons-material';
import MenuListItem from './SidebarMenuListItem';

export interface SidebarMenuItemProps {
  text: string;
  icon: React.ReactElement<SvgIconComponent>;
  href: string;
}

const menuItems: SidebarMenuItemProps[] = [
  {
    text: 'Planning',
    icon: <DateRange />,
    href: '/planning',
  },
  {
    text: 'Bons',
    icon: <Receipt />,
    href: '/bons',
  },
  {
    text: 'Factures',
    icon: <Description />,
    href: '/factures',
  },
  {
    text: 'Entreprises',
    icon: <Business />,
    href: '/entreprises',
  },
  {
    text: 'Utilisateurs',
    icon: <ContactMail />,
    href: '/user',
  },
  {
    text: 'Articles',
    icon: <Unarchive />,
    href: '/articles',
  },
  {
    text: 'Paramètres',
    icon: <MiscellaneousServices />,
    href: '/parametres',
  },
];

function SidebarMenuList({ open }: any) {
  return (
    <List>
      {menuItems.map((item) => (
        <MenuListItem text={item.text} icon={item.icon} href={item.href} key={item.text} open={open} />
      ))}
    </List>
  );
}

export default SidebarMenuList;
