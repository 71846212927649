import { createAsyncThunk } from '@reduxjs/toolkit';
import { Planning } from '../entities/planning.entity';

interface CreatePlanningDto extends Omit<Planning, 'id'> { }

export const addOnePlanning = createAsyncThunk(
	'[PLANNING] addNewPlanning',
	async (planningDto: CreatePlanningDto, thunk: any): Promise<Planning> => {
		try {
			const { ...planning } = planningDto;
			return await thunk.extra.planningService.add(planning);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
