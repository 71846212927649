import { createAsyncThunk } from '@reduxjs/toolkit';

export const retrieveAllPlanning = createAsyncThunk(
	'[PLANNING] retrieveAllPlanning',
	async (options, thunk: any) => {
		try {
			return await thunk.extra.planningService.all(options);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
