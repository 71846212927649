import React from 'react';
import { Container, Box, CssBaseline } from '@mui/material';

import Sidebar from './Sidebar';
import LayoutAlertMessage from './LayoutAlertMessage';

interface Props {
  children?: React.ReactNode;
  title?: string;
}

function Main({ children, title }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
      }}
    >
      <CssBaseline />
      <Sidebar />
      <Container
        component="main"
        maxWidth={false}
        disableGutters
        sx={{
          m: 1,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <LayoutAlertMessage />
        <Box sx={{ minHeight: '100%', pb: 6 }}>{children}</Box>
      </Container>
    </Box>
  );
}

export default Main;
