import { EntityId } from '@reduxjs/toolkit';
import { RootState } from '../../../_config/redux';
import { categorieProduitEntityAdapter } from './categorie-produit.slice';

const categorieProduitSelector = categorieProduitEntityAdapter.getSelectors<RootState>((state) => state.categorieProduit);

export const selectCategorieProduitById = (id: EntityId) => (state: RootState) => categorieProduitSelector.selectById(state, id);

export const selectAllCategorieProduit = (state: RootState) => categorieProduitSelector.selectAll(state);

export const selectAllCategoriesLabelsAndIds = (state: RootState) => {
    const categories = categorieProduitSelector.selectAll(state);
    return categories.map((c) => ({ _id: c._id, id: c.label, label: c.label })); // labels are used as ids in the back-end
}