import React from 'react';
import { Form, Formik } from 'formik';
import { number, object, string } from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { setSuccess } from '../../../../core/notification/notification.slice';
import { addOneCategorieProduit, updateOneCategorieProduit } from '../../../../core/categorie-produit/use-cases';
import { CategorieProduit } from '../../../../core/categorie-produit/entities';

const CategorieProduitAddDialog = ({ open, onCloseDialog, initialValues, categorieId }: any) => {
  const schema = object().shape({
    label: string().required(),
    tva: number().min(0).required(),
    delai_de_facturation: number().min(0).required(),
  });

  const dispatch = useAppDispatch();

  const onSubmit = async (values: any) => {
    let res: any;
    let { label, tva, delai_de_facturation } = values;

    // So... we want to make sure we send numbers, but backend is expecting strings so that's the fastest solution (I cam think of)
    let categorie: Omit<CategorieProduit, '_id'> = {
      label,
      tva: tva.toString(),
      delai_de_facturation: delai_de_facturation.toString(),
    };

    if (!!categorieId) {
      res = await dispatch(updateOneCategorieProduit({ _id: categorieId, ...categorie }));
    } else {
      res = await dispatch(addOneCategorieProduit(categorie));
    }

    if (!res.error) {
      dispatch(setSuccess(!!categorieId ? `Le type a bien été mis à jour` : `Le mode de paiement a bien été créé`));
      onCloseDialog();
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{`${!!categorieId ? 'Modifier' : 'Ajouter'} une catégorie produit`}</DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
        {(props) => {
          const { values, touched, errors, dirty, isValid, handleChange, handleBlur, handleSubmit, isSubmitting } =
            props;
          return (
            <>
              <Form onSubmit={handleSubmit}>
                <DialogContent sx={{ minWidth: 400 }}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Libellé"
                        error={!!errors.label && !!touched.label}
                        name="label"
                        value={values.label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.label && errors.label}
                        variant="outlined"
                        required
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="TVA"
                        error={!!errors.tva && !!touched.tva}
                        name="tva"
                        value={values.tva}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.tva && errors.tva}
                        variant="outlined"
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        required
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Délai de facturation"
                        error={!!errors.delai_de_facturation && !!touched.delai_de_facturation}
                        name="delai_de_facturation"
                        value={values.delai_de_facturation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.delai_de_facturation && errors.delai_de_facturation}
                        variant="outlined"
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">jour(s)</InputAdornment>,
                        }}
                        required
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onCloseDialog}>Annuler</Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={isSubmitting}
                    disabled={!dirty || !isValid}
                  >
                    Confirmer
                  </LoadingButton>
                </DialogActions>
              </Form>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default CategorieProduitAddDialog;
