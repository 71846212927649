import { LoadingButton } from '@mui/lab';
import { Box, TextField } from '@mui/material';
import { SyntheticEvent, useState } from 'react';

interface Props {
  login(inputValues: unknown): any;
  isLoading: boolean;
  notifyError?(message: string): void;
}

export default function LoginForm({ login, isLoading }: Props) {
  const [loginDetails, setLoginDetails] = useState<{ username: string; password: string }>({
    username: '',
    password: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    login(loginDetails);
  };

  return (
    <Box component="form" noValidate sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="username"
        label="Identifiant"
        name="username"
        autoFocus
        onChange={handleInputChange}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Mot de passe"
        type="password"
        id="password"
        autoComplete="current-password"
        onChange={handleInputChange}
      />
      <LoadingButton
        loading={isLoading}
        type="submit"
        fullWidth
        variant="contained"
        color="warning"
        sx={{ mt: 3, mb: 2 }}
        onClick={handleSubmit}
      >
        Connexion
      </LoadingButton>
    </Box>
  );
}
