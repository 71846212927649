import React, { SyntheticEvent, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { resetPassword } from '../../../../core/user/use-cases';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { selectUserErrors } from '../../../../core/user/selectors';
import { clearErrors } from '../../../../core/user';
import { AuthLayout } from '../Layout';
import AuthLayoutLink from '../Layout/AuthLayoutLink';
import useDocumentTitle from '../../../../../_config/react/useDocumentTitle';

export default function SendMailToResetPassword() {
  useDocumentTitle('MTTP - Mot de passe perdu ?');
  const errors = useSelector(selectUserErrors);
  const [email, setEmail] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    setTimeout(() => {
      if (errors.length > 0) dispatch(clearErrors());
    }, 4000);
  }, [dispatch, errors]);

  const sendLinkToResetPassword = (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    dispatch(resetPassword({ email })).then((res) => {
      if (res.meta?.requestStatus === 'fulfilled')
        setSuccessMessage('Un e-mail de réinitialisation de mot de passe a été envoyé.');

      setLoading(false);
    });
  };

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  return (
    <>
      <AuthLayout title="Mot de passe oublié" errors={errors} success={successMessage}>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="email"
            label="Email"
            type="email"
            onChange={handleEmailInputChange}
          />
          <LoadingButton
            loading={loading}
            type="submit"
            fullWidth
            variant="contained"
            color="warning"
            sx={{ mt: 3, mb: 2 }}
            onClick={sendLinkToResetPassword}
          >
            Réinitialiser mot de passe
          </LoadingButton>
        </Box>
        <AuthLayoutLink to="/login" text="Se connecter" />
      </AuthLayout>
    </>
  );
}
