import React, { useEffect, useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Stack,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { selectAvoir, selectFactureById } from '../../../Domain/Application/selectors';

import { retrieveOneAvoir } from '../../../Domain/Application/use-cases';
import { AddAvoirToFacture } from "./AddAvoirToFacture";
import { AvoirList } from "./AvoirList";

interface Props {
    open: boolean;
    id: EntityId;

    onCloseDialog(): void;
}

const FactureAvoirDialog = ({ id, open, onCloseDialog }: Props) => {

    const dispatch = useAppDispatch();

    const facture = useSelector(selectFactureById(id));
    const avoirs = useSelector(selectAvoir);


    const [newAvoirOpen, setNewAvoirOpen] = useState(false);

    useEffect(() => {
        const fetchAvoir = async () => {
            await dispatch(retrieveOneAvoir(id));
        };
        if ( Boolean(id) ) fetchAvoir();
    }, [dispatch, id]);

    return (
        <Dialog open={open}>
            <DialogTitle>Avoir de la facture {facture?.numeroFacture}</DialogTitle>
            <DialogContent>
                <Box minHeight={'290px'} maxHeight={'600px'} width={'300px'}>
                    {newAvoirOpen
                        ? <AddAvoirToFacture factureId={id} close={() => setNewAvoirOpen(false)}/>
                        : <Stack direction="column" justifyContent="space-between" minHeight={'290px'}>
                            <AvoirList avoirs={avoirs}/>
                            <Button variant="contained" onClick={() => setNewAvoirOpen(true)}>Ajouter un avoir</Button>
                        </Stack>}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button sx={{ display: newAvoirOpen ? 'inline' : 'none' }} onClick={() => setNewAvoirOpen(false)}>
                    Retour
                </Button>
                <Button onClick={onCloseDialog}>Fermer</Button>
            </DialogActions>
        </Dialog>
    );
};

export default FactureAvoirDialog;
