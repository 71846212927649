import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';

export const addBonsFromPlanning = createAsyncThunk(
	'[PLANNING] addBonsFromPlanning',
	async (plannings: EntityId[], thunk: any): Promise<any> => {
		try {
			return await thunk.extra.planningService.addBonsFromPlanning(plannings);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
