import { useCallback, useState } from "react";

export function useElementDimensions() {
  const [rect, setRect] = useState<any>(null);
  const ref = useCallback(node => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);

  return [rect, ref];
}