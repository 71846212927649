import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';

export const archiveBons = createAsyncThunk(
    '[BON] archiveBons',
    async (ids: EntityId[], thunk: any
    ) => {
        try {
            return await thunk.extra.bonQuery.archive(ids);
        } catch (error: any) {
            return thunk.rejectWithValue({ message: error.message ?? null });
        }
    });
