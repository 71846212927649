import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { EntityId } from "@reduxjs/toolkit";
import {
    Box, Button, FormControl, InputLabel, MenuItem,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Select, SelectChangeEvent, Stack, TextField
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "../../../_config/react/useAppDispatch";
import { addCustomReglement } from "../../Domain/Application/use-cases";
import { selectAllCategoriesLabelsAndIds } from "../../../App/core/categorie-produit/selectors";

export default function CustomReglementModal() {

    const [category, setCategory] = useState('');
    const handleChange = (event: SelectChangeEvent) => {
        setCategory(event.target.value);
    };

    const [paymentDeadline, setPaymentDeadline] = useState('');
    const handlePaymentDeadline = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentDeadline(event.target.value);
    }

    const dispatch = useAppDispatch();
    const { id } = useParams();
    const location = useLocation();
    const state = location.state as { reglementType: 'client' | 'fournisseur' };
    const onSubmit = () => {
        dispatch(addCustomReglement({
            entrepriseId: id as EntityId,
            payload: {
                type: state.reglementType,
                categoryId: category,
                paymentDeadline: parseInt(paymentDeadline),
            }
        })).finally(onDismiss);
    };

    const navigate = useNavigate();
    const onDismiss = () => {
        navigate('', { replace: true });
    };

    const categories = useSelector(selectAllCategoriesLabelsAndIds);

    return <Dialog open>
        <DialogTitle>Ajouter un reglement</DialogTitle>

        <DialogContent>
            <Box component='form' sx={{ p: 2 }}>
                <Stack spacing={4}>
                    <FormControl fullWidth>
                        <InputLabel id="select-category">Catégorie</InputLabel>
                        <Select
                            labelId="select-category"
                            label="Catégorie"
                            onChange={handleChange}
                        >{categories.map((category) =>
                            <MenuItem key={category._id}
                                      value={category._id}>
                                {category.label}
                            </MenuItem>)
                        }</Select>
                    </FormControl>

                    <TextField id="select-payment-deadline" label="Délai de paiement"
                               onChange={handlePaymentDeadline}/>
                </Stack>
            </Box>
        </DialogContent>

        <DialogActions>
            <Button onClick={onDismiss}>Annuler</Button>
            <LoadingButton type="submit" variant="contained" color="primary"
                           onClick={onSubmit}>Confirmer
            </LoadingButton>
        </DialogActions>

    </Dialog>
}
