import React, { useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';

import { EntrepriseTable, EntrepriseTableToolbar } from './';
import { TableViewLayout } from '../../../common/layout/components';
import useDocumentTitle from '../../../../../_config/react/useDocumentTitle';

function EntrepriseList() {
  useDocumentTitle(`MTTP - Entreprises`);
  const [selectedRows, setSelectedRows] = useState<EntityId[]>([]);

  return (
    <>
      <TableViewLayout title="Entreprises">
        <EntrepriseTableToolbar selectedRows={selectedRows} />
        <EntrepriseTable onSelectRows={setSelectedRows} />
      </TableViewLayout>
    </>
  );
}

export default EntrepriseList;
