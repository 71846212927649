import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import { serveurType } from '../entities'

export const updateOneserveurType = createAsyncThunk(
	'[serveur-TYPE] updateOneserveurType',
	async (serveur: Update<serveurType>, thunk: any): Promise<Update<serveurType>> => {
		try {
			return thunk.extra.serveurTypeQuery.update(serveur);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
