import React from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ViewCard } from '../../common/layout/components';
import PageHeaderBar from '../../common/layout/components/PageHeaderBar';
import { PaiementTable } from './paiement';
import { CategorieProduitTable } from './categorie-produit';
import useDocumentTitle from '../../../../_config/react/useDocumentTitle';
import { AffacturageTable } from './affacturage';
import  RibTable  from './rib/RibTable';
import {ParamServer} from './serveur'; 

const ParametreView = () => {

  useDocumentTitle(`MTTP - Paramètres`);

  const [value, setValue] = React.useState('reglement');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => setValue(newValue);
//<Tab label="Paramètres serveur" value="serveur" />
  return (
    <>
      <PageHeaderBar title="Paramètres" />
      <ViewCard>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange}>
                <Tab label="Modes de règlement" value="reglement" />
                <Tab label="Banque" value="rib" />
               
                <Tab label="Catégories produit" value="catProduit" />
                
              </TabList>
            </Box>
            <TabPanel value="reglement">
              <PaiementTable />
            </TabPanel>
            <TabPanel value="rib">
              <RibTable />
            </TabPanel>
            <TabPanel value="affacturage">
              <AffacturageTable />
            </TabPanel>
            <TabPanel value="catProduit">
              <CategorieProduitTable />
            </TabPanel>
            <TabPanel value="serveur">
              <ParamServer />
            </TabPanel>
          </TabContext>
        </Box>
      </ViewCard>
    </>
  );
};

export default ParametreView;
