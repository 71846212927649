import { createAsyncThunk, EntityId, Update } from '@reduxjs/toolkit';
import { BonEntity } from '../entities/bon.entity';

interface Options {
	ids: EntityId[];
	refDetails: {
		bonNumber?: string;
		refClient?: string;
	}
}

export const updateBonsRefs = createAsyncThunk(
	'[BON] updateBonsRefs',
	async (options: Options, thunk: any): Promise<Update<BonEntity>[]> => {
		try {
			const { ids, refDetails} = options;
			const removeEmpty = Object.fromEntries(Object.entries(refDetails).filter(([_, v]) => v !== ''));
			
			return await thunk.extra.bonQuery.updateMany({ ids, ...removeEmpty });
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
