import React from "react";
import { Button, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";


export function AddAdresseFacturationButton(props: { onClick: () => void }) {
    return <Tooltip title="Ajouter une adresse">
        <Button variant="outlined"
                color="secondary"
                size="small"
                onClick={props.onClick}>

            <Add/>

        </Button>
    </Tooltip>;
}

