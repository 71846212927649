import { List } from "@mui/material";
import EntrepriseItem from "./EntrepriseItem";
import React from "react";

interface EntrepriseListParams {
    loading: boolean;
    entreprises: any[];
    selectedEntreprise: string;
    onSelectEntreprise: (id: string) => void;
}

export function EntrepriseList(props: EntrepriseListParams) {

    if ( props.loading ) return <div data-testid="loading-companies"><p>loading ...</p></div>

    if ( props.entreprises.length <= 0 ) return <p>Aucune Donnée...</p>

    return <List>{
        props.entreprises.map((entreprise: any) => {
            return (
                <EntrepriseItem
                    key={entreprise.id}
                    entreprise={entreprise}
                    selectedEntreprise={props.selectedEntreprise}
                    updateSelection={props.onSelectEntreprise}
                />
            );
        })
    }</List>;
}