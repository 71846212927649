import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { User } from '../../../../core/user/entities/user.entity';
import PageHeaderBar from '../../../common/layout/components/PageHeaderBar';
import UserEditionForm from './UserEditionForm';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { addOneUser } from '../../../../core/user/use-cases';
import { useNavigationFrom } from '../../../common/hooks/useNavigationFrom';
import { selectUserById } from '../../../../core/user/selectors';
import { updateOneUser } from '../../../../core/user/use-cases/update-one-user';
import { setSuccess } from '../../../../core/notification/notification.slice';
import { ViewCard } from '../../../common/layout/components';
import { removeEmpty } from '../../../common/_helpers';
import useDocumentTitle from '../../../../../_config/react/useDocumentTitle';

function UserEdition() {

  useDocumentTitle('MTTP - Edition utilisateur');

  const { id }: any = useParams();
  const user: User | undefined = useSelector(selectUserById(id));

  const dispatch = useAppDispatch();
  const navigateBack = useNavigationFrom();

  const {
    name = '',
    lastname = '',
    telephone = '',
    email = '',
    password = '',
    roles = 'client',
    employeur = '',
    fonction = '',
  } = user || {};

  const initialValues: any = {
    name,
    lastname,
    telephone,
    email,
    password,
    roles,
    employeur,
    fonction,
  };

  const defaultValues = {
    employeur: {
      id: user?.employeur?.id ?? '',
      label: user?.employeur?.name ?? '',
    },
  };

  const onSubmit = async (values: Partial<User>) => {
    let res: any;
    if (values.roles === 'admin' && values.employeur) values.employeur.id = '';

    let onlyFilledValues: any = removeEmpty(values);

    if (id) {
      res = await dispatch(updateOneUser({ id, changes: onlyFilledValues }));
    } else {
      res = await dispatch(addOneUser(onlyFilledValues));
    }

    if (!res.error) {
      dispatch(setSuccess(id ? `L'utilisateur a bien été modifié` : `L'utilisateur a bien été créé`));
      navigateBack();
    }
  };

  return (
    <>
      <PageHeaderBar title={id ? `Modification utilisateur` : 'Création nouvel utilisateur'} />
      <ViewCard>
        <UserEditionForm
          initialValues={initialValues}
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          isEditing={Boolean(id)}
        />
      </ViewCard>
    </>
  );
}

export default UserEdition;
