import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { serveurType } from './entities';
import { addOneserveurType, deleteOneserveurType, retrieveAllserveurType, updateOneserveurType } from './use-cases';

export const serveurTypeEntityAdapter = createEntityAdapter<serveurType>({
	selectId: (serveurType) => serveurType._id
});

export const serveurTypeSlice = createSlice({
	name: 'serveurType',
	initialState: serveurTypeEntityAdapter.getInitialState({}),
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(retrieveAllserveurType.fulfilled, (state, action) => {
				serveurTypeEntityAdapter.setAll(state, action.payload);
			})
			.addCase(addOneserveurType.fulfilled, (state, action) => {
				serveurTypeEntityAdapter.addOne(state, action.payload);
			})
			.addCase(updateOneserveurType.fulfilled, (state, action: any) => {
				const { _id, ...otherProps} = action.payload;
				serveurTypeEntityAdapter.updateOne(state, { id: _id, changes: otherProps});
			})
			.addCase(deleteOneserveurType.fulfilled, (state, action: any) => {
				serveurTypeEntityAdapter.removeOne(state, action.payload._id);
			})
	}
});

export default serveurTypeSlice.reducer;