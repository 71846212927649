import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';

import { Stack, Button } from '@mui/material';

import { CustomTable } from '../../../common/custom-table';
import { ColumnEntity } from '../../../common/custom-table/Table.entities';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import RibTableActionsCell from './RibTableActionsCell';
import { selectAllribTypes } from '../../../../core/rib-type/selectors';
import { deleteOneribType, retrieveAllribType } from '../../../../core/rib-type/use-cases';
import RibTableAddDialog from './RibTableAddDialog';
import { ribType } from '../../../../core/rib-type/entities';
import ConfirmationDialog from '../../../common/modals/components/ConfirmationDialog';
import { setSuccess } from '../../../../core/notification/notification.slice';
import useDocumentTitle from '../../../../../_config/react/useDocumentTitle';

const RibTable = () => {

    // useDocumentTitle - Does not work properly?
    useDocumentTitle('MTTP - BANQUE');

    const [isLoading, setIsLoading] = useState(true);
    const [typeDialogOpen, setTypeDialogOpen] = useState(false);
    const [typeId, setTypeId] = useState('');
    const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [initialValues, setInitialValues] = useState({
        name: '',
        infos: ''
    });

    const dispatch = useAppDispatch();
    const ribTypes = useSelector(selectAllribTypes);

    useEffect(() => {
        dispatch(retrieveAllribType())
            .then(() => setIsLoading(false));

        return () => {
            setIsLoading(true);
        };
    }, [dispatch, setIsLoading]);

    const onRowDoubleClick = (values: ribType) => {
        const { _id, ...otherProps } = values;
        setTypeId(values._id);
        setInitialValues(otherProps);

        setTypeDialogOpen(true);
    };

    const onSelectRows = () => {
    };

    const onClickEdit = (id: EntityId) => {
        let typeById = ribTypes.find((t) => t._id === id);
        if ( typeById ) {
            const { _id, ...otherProps } = typeById;
            setTypeId(_id);
            setInitialValues(otherProps);
        }

        setTypeDialogOpen(true);
    };

    const onClickDelete = (id: string) => {
        setConfirmationDialogOpen(true);
        setTypeId(id);
    };

    const onConfirmDeleteDialog = async () => {
        const res: any = await dispatch(deleteOneribType(typeId));
        setDeleteButtonLoading(true);
        if ( !res.error ) {
            dispatch(setSuccess(`Le rib a bien été supprimé`));
            setConfirmationDialogOpen(false);
        }
        setDeleteButtonLoading(false);
        setTypeId('');
    };

    const onCloseTypeDialog = () => {
        setTypeId('');
        setTypeDialogOpen(false);
        setTimeout(() => {
            setInitialValues({ name: '', infos: '' });
        }, 200);
    };

    const columns: ColumnEntity[] = [
        { field: 'name', headerName: 'Nom', width: 120, sortable: true, resizable: true },
        { field: 'infos', headerName: 'Informations', width: 320, sortable: true, resizable: true },
        { field: 'isDefaultString', headerName: 'Default', sortable: true, resizable: true },
    ];

    const table_ribTypes = JSON.parse(JSON.stringify(ribTypes));

    table_ribTypes.forEach((rib:any) => {
       
        if(rib.isDefault === true) {
            rib.isDefaultString = "Oui";
        }
        else { rib.isDefaultString = ""; }
        
    });

    return (
        <>
            <Stack direction="row" gap={6}>
                <CustomTable
                    data={table_ribTypes}
                    columns={columns}
                    loading={isLoading}
                    hover={false}
                    width={600}
                    onRowDoubleClick={onRowDoubleClick}
                    onSelectRows={onSelectRows}
                    renderActionCell={(rowData, measuredRef) => (
                        <RibTableActionsCell id={rowData._id} measuredRef={measuredRef} onClickEdit={onClickEdit}
                                             onClickDelete={onClickDelete}/>
                    )}
                />
                <Stack direction="column" gap={2}>
                    <Button variant="outlined" color="primary" onClick={() => setTypeDialogOpen(true)}>
                        Ajouter
                    </Button>
                </Stack>
            </Stack>
            <RibTableAddDialog
                open={typeDialogOpen}
                onCloseDialog={onCloseTypeDialog}
                initialValues={initialValues}
                typeId={typeId}
            />
            <ConfirmationDialog
                title="Supprimer le rib"
                contentText="Êtes-vous sûr de vouloir supprimer ce rib ?"
                isButtonLoading={deleteButtonLoading}
                open={confirmationDialogOpen}
                onConfirm={onConfirmDeleteDialog}
                onClose={() => {
                    setTypeId('');
                    setConfirmationDialogOpen(false);
                }}
            />
        </>
    );
};

export default RibTable;
