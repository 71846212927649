/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useField } from 'formik';
import { TextField, Autocomplete } from '@mui/material';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { selectCreatedFromModal } from '../../../../Location/Domain/Bons/selectors';
import { updateCreatedFromModal } from '../../../../Location/Domain/Bons/bon.slice';

interface Option {
    id: string;
    label: string;
}

interface Props {
    options: Option[];
    label?: string;
    id: string;
    defaultValue?: Option;
    emitSelection?: (field: { name: string; value: string }) => void;
    //selection should be an id that is required to populate & complete lower input
    // IT IS NOT SELECTION OF THE CURRENT INPUT COMPONENT
    selection?: string;
    required?: boolean;
    variant?: 'standard' | 'filled' | 'outlined' | undefined;
    inputDisabled?: boolean
}

export default function FormFieldAutocomplete(
    {
        options,
        label,
        id,
        defaultValue,
        emitSelection,
        selection,
        required,
        variant,
        inputDisabled
    }: Props) {
    const [field, meta, helpers] = useField(id);
    const [valueLabel, setValueLabel] = useState<string>('');
    const createdFromModal = useSelector(selectCreatedFromModal);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if ( typeof emitSelection === 'function' ) emitSelection(field);

        // set the input value if found in the options (used when an object is created from modal)
        if ( createdFromModal ) {
            const option = options.find((o) => o.id === field.value);
            const value = option ? option.label : '';

            console.log(option); console.log(value);

            setValueLabel(value);

            // clear variable to make sure it doesnt cause conflict on other autocompletes
            dispatch(updateCreatedFromModal(null));
        }

        if ( field.value === '' ) setValueLabel('');
    }, [field.value]);

    useEffect(() => {
        // sets selected planning line ids to default values
        if ( defaultValue?.id ) {
            helpers.setValue(defaultValue.id);
            setValueLabel(defaultValue.label);
        }
    }, []);

    return (
        <Autocomplete
            id={`${id}-selector`}
            sx={{ width: '100%' }}
            options={options}
            onBlur={() => helpers.setTouched(true, true)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            // defaultValue={defaultValue?.id ? defaultValue : undefined}
            disabled={(inputDisabled || typeof selection === 'string') && !!!selection}
            inputValue={valueLabel}
            onInputChange={(event, value) => {
                if ( event ) {
                    return setValueLabel(value);
                }
            }}
            onChange={(_, option) => {
                if ( option ) {
                    helpers.setValue(option.id);
                } else {
                    helpers.setValue('');
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    disabled={inputDisabled}
                    required={!!required}
                    error={meta.touched && !!meta.error}
                    variant={variant ?? 'standard'}
                    helperText={meta.touched ? meta.error : ''}
                />
            )}
        />
    );
}
