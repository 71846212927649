import { createAsyncThunk } from "@reduxjs/toolkit";
import { Facture } from "../../../Model";
import { BonDeCommandeRepository } from "../../../Ports/BonDeCommandeRepository";

interface ConfirmBonDeCommandePayload {
    bonDeCommandeId: string;
    numeroFactureFournisseur: string;
}

interface ConfirmBonDeCommandeResponse {
    confirmedBonDeCommande: Facture
}

interface BonDeCommandeService extends Pick<BonDeCommandeRepository, 'confirmWithFactureNumber'> {
}


export const confirmBonDeCommande = createAsyncThunk<ConfirmBonDeCommandeResponse, ConfirmBonDeCommandePayload, { extra: { bonDeCommandeService: BonDeCommandeService } }>("[Facture] confirm Bon de Commande",
    async ({ bonDeCommandeId, numeroFactureFournisseur }, thunkAPI) => {
        const confirmedBC = await thunkAPI.extra.bonDeCommandeService.confirmWithFactureNumber(bonDeCommandeId, numeroFactureFournisseur);
        return {
            confirmedBonDeCommande: confirmedBC.bonDeCommandeConfirmed
        };
    }
);