import { removeEmpty } from "../../../../App/app/common/_helpers";
import { setSuccess } from "../../../../App/core/notification/notification.slice";
import { useNavigationFrom } from "../../../../App/app/common/hooks/useNavigationFrom";
import { useAppDispatch } from "../../../../_config/react/useAppDispatch";
import { useState } from "react";
import { AsyncThunkAction, EntityId } from "@reduxjs/toolkit";

interface Props {
    entityName: string
    entityId: EntityId

    add(args: any): AsyncThunkAction<any, any, {}>

    update(args: { id: string, changes: any }): AsyncThunkAction<any, any, {}>
}

export function useSaveEntityOnSubmit({ add, update, entityName, entityId }: Props) {
    const dispatch = useAppDispatch();
    const navigateBack = useNavigationFrom();
    const [isLoading, setLoader] = useState(false);

    const onSubmit = async (values: any) => {
        setLoader(true);

        // using interlocuteur as commandePar
        const { interlocuteur, ...newValues } = removeEmpty(values);
        newValues.commandePar = interlocuteur;

        let res: any;
        if ( entityId ) {
            res = await dispatch(update({ id: entityId.toString(), changes: newValues }));
        } else {
            res = await dispatch(add(newValues));
        }

        if ( !res.error ) {
            dispatch(setSuccess(entityId ? `Le ${entityName} a bien été mis à jour` : `Le ${entityName} a bien été créé`));
            navigateBack();
        }

        setLoader(false);
    };

    return { onSubmit, isLoading };

}