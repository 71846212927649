import { createAsyncThunk } from '@reduxjs/toolkit';

export const retrieveAllaffacturageType = createAsyncThunk(
	'[affacturage-TYPE] retrieveAllaffacturageType',
	async (_: void, thunk: any) => {
		try {
			return  thunk.extra.affacturageTypeQuery.all();
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
