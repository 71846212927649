import { createAsyncThunk, EntityId } from "@reduxjs/toolkit";


interface Payload {
    id: EntityId;
    fraisAutoroute: number;
}


export const justifyFraisAutoroute = createAsyncThunk<any, Payload, { extra: { bonQuery: { justifyTollCharges: (id: string, frais: number) => Promise<any> } } }>(
    "[BON] Justification Frais Autoroute ",
    async ({ id, fraisAutoroute }, thunkAPI) => {

        await thunkAPI.extra.bonQuery.justifyTollCharges(id.toString(), fraisAutoroute);

        return { id: id.toString(), fraisAutoroute: parseFloat(fraisAutoroute.toString()) };
    }
)