import { createAsyncThunk } from '@reduxjs/toolkit';

export const retrieveAllCategorieProduit = createAsyncThunk(
	'[CATEGORIE-PRODUIT] retrieveAllCategorieProduit',
	async (_: void, thunk: any) => {
		try {
			return await thunk.extra.categorieProduitQuery.all();
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
