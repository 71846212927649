import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';
import { BonLivraison } from '../entities';

export const confirmBonManually = createAsyncThunk(
	'[BON] confirmBonManually',
	async (bonLivraison: BonLivraison & { bonId: EntityId }, thunk: any) => {
		try {
			return await thunk.extra.bonQuery.confirmBonManually(bonLivraison);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
