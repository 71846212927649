import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';
import InterlocuteurEntity from '../entities';

interface updateInterloculteurDto extends Omit<InterlocuteurEntity, 'isActive'>
{

}
export const updateOneInterlocuteur = createAsyncThunk<Partial<InterlocuteurEntity>, updateInterloculteurDto, {
	extra: {
		interlocuteurQuery: {
			update(entrepriseId: EntityId, interlocuteur: updateInterloculteurDto): Promise<InterlocuteurEntity>
		}
	}
}>(
	'[Interlocuteur] updateOneInterlocuteur',
	async (interlocuteurDto, thunk) => {
		const { ...interlocuteur } = interlocuteurDto;
		return thunk.extra.interlocuteurQuery.update(interlocuteurDto.employeur.id, interlocuteur);
	}
);
