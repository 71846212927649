import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import { PaymentType } from '../entities/'

export const updateOnePaymentType = createAsyncThunk(
	'[PAYMENT-TYPE] updateOnePaymentType',
	async (payment: Update<PaymentType>, thunk: any): Promise<Update<PaymentType>> => {
		try {
			return await thunk.extra.paymentTypeQuery.update(payment);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
