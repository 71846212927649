import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';

interface PlanningQuery {
	_delete(ids: EntityId[]): Promise<EntityId[]>;
}

export const deletePlanning = createAsyncThunk('[PLANNING] deletePlanning', async (ids: EntityId[], thunkAPI) => {
	try {
		return await (thunkAPI.extra as { planningService: PlanningQuery }).planningService._delete(ids);
		} catch (error: any) {
			return thunkAPI.rejectWithValue({ message: error.message ?? null });
		}
});
