import { store } from "../redux";
import Axios, { AxiosInstance } from "axios";
import tokenStorageService from "../../App/infra/storage-provider/token-storage.service";
import { useCases } from "../../App/core/auth";

const httpService: AxiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,

  headers: {
    // 'X-Requested-With': 'XMLHttpRequest',
    Authorization: `Bearer ${tokenStorageService.get().access()}`,
    // "refresh-token": `${storageService.get('refresh-token')}`,
    'Content-Type': 'application/json',
  },

});

httpService.interceptors.request.use(
  (config) => {
    if (!config.headers) config.headers = {};

    config.headers['Authorization'] = `Bearer ${tokenStorageService.get().access()}`;

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);


httpService.interceptors.response.use(
  (response) => response,
  async (error) => {

    const originalConfig = error.config;

    if (!originalConfig.url.endsWith("auth/login")) {
      if (error.response) {
        if (error.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true

          try {
            const refresh = await refreshToken()
            tokenStorageService.set().all(refresh.data)
            httpService.defaults.headers.common["Authorization"] = `Bearer ${refresh.data.access.token}`;
            return httpService(originalConfig)
          } catch (_error: any) {
            if (_error.response && _error.response.data) {
              return Promise.reject(_error.response.data);
            }
            return Promise.reject(_error);
          }

        }
        if (error.response.status === 403 && error.response.data) {
          return Promise.reject(error.response.data);
        }
      }
    }
    return Promise.reject(error);
  }
)


const instance = Axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/auth`,
  headers: {
    Authorization: `Bearer ${tokenStorageService.get().access()}`,
    'Content-Type': 'application/json'
  }
})

instance.interceptors.response.use(
  response => response,
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        useOnErrorLogout()
        return Promise.reject(error.response.data)
      }
    }
    return Promise.reject(error)
  })


function refreshToken() {
  return instance.get('/refresh-token', {
    headers: {
      "refresh-token": `${tokenStorageService.get().refresh()}`
    }
  });
}

function useOnErrorLogout() {
  store.dispatch(useCases.logout())
}


export default httpService;
