import { Alert, Snackbar } from '@mui/material';
import { useSelector } from 'react-redux';

import { clearNotification } from '../../../../core/notification/notification.slice';
import { selectAllNotifications } from '../../../../core/notification/selector';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';


function LayoutAlertMessage() {
    const notifications = useSelector(selectAllNotifications);
    const dispatch = useAppDispatch();

    const removeAlertFromState = async (id: string) => {
        dispatch(clearNotification(id));
    };

    return (
        <>
            {notifications.map((notification) => {
                return (
                    <Snackbar
                        key={notification.id}
                        open
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        autoHideDuration={4000}
                        onClose={() => removeAlertFromState(notification.id)}
                    >
                        <Alert
                            severity={notification.severity}
                            variant="filled"
                            onClose={() => removeAlertFromState(notification.id)}
                        >
                            {notification.message}
                        </Alert>
                    </Snackbar>
                );
            })}
        </>
    );
}

export default LayoutAlertMessage;
