import React, { useEffect, useState } from 'react';
import { Stack, TextField, } from '@mui/material';
import { EntrepriseList } from "./EntrepriseList";
import { updateSearch } from "../../../../App/core/entreprise/entreprise.slice";
import { retrieveBons } from "../../../../Location/Domain/Bons/use-cases";
import { findPendingBonsDeCommande } from "../../../Domain/Application/use-cases/find-all-pending-bons-de-commande";
import { useAppDispatch } from "../../../../_config/react/useAppDispatch";
import { ClientFournisseurRadioSelect } from "../ClientFournisseurRadioSelect";

interface Props {
    entreprises: { name: string, id: string, adresse?: { [key: string]: string } }[];
    entrepriseType: string;
    selectedEntreprise: string;

    // setSearch(search: string): void;

    setEntrepriseType(type: 'client' | 'fournisseur'): void;

    onSelectEntreprise(id: string): void;

    // loading?: boolean
}

/**
 * HAS NOTHING TO DO WITH A DIALOG ELEMENT
 * - name should be modify to express the intent more specifically -
 */
function FactureDialogEntrepriseList(
    {
        entreprises,
        setEntrepriseType,
        entrepriseType,
        selectedEntreprise,
        onSelectEntreprise,
    }: Props) {

    const [loading, setLoading] = useState(true);

    const dispatch = useAppDispatch()
    const [search, setSearch] = useState('');
    dispatch(updateSearch(search));

    useEffect(() => {
        setLoading(true);
        Promise.all([
            dispatch(retrieveBons({ filters: { billableOnly: true } })),
            dispatch(findPendingBonsDeCommande())
        ]).then(() => {
            setLoading(false)
        })
        return () => {
            setLoading(false)
        }
    }, [dispatch])

    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-end" mt={3}>
                <TextField
                    variant="outlined"
                    sx={{ width: '50%' }}
                    label="Rechercher..."
                    onChange={(event) => setSearch(event.target.value)}
                />
                <ClientFournisseurRadioSelect
                    onChange={(event) => setEntrepriseType(event.target.value as 'client' | 'fournisseur')}
                    selectedValue={entrepriseType}/>
            </Stack>
            <EntrepriseList
                loading={loading ?? false}
                entreprises={entreprises}
                selectedEntreprise={selectedEntreprise}
                onSelectEntreprise={onSelectEntreprise}
            />

        </>
    );
}

export default FactureDialogEntrepriseList;


