import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';

export const deleteOneribType = createAsyncThunk(
    '[rib-TYPE] deleteOneRibType',
    async (id: EntityId, thunk: any) => {
        try {
            return thunk.extra.ribTypeQuery._delete(id);
        } catch (error: any) {
            return thunk.rejectWithValue({ message: error.message ?? null });
        }
    }
);
