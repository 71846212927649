import React from 'react';
import { Stack, IconButton, Tooltip, Box } from '@mui/material';
import { Description, Edit, TaskAlt } from '@mui/icons-material';
import { useAppDispatch } from "../../../../../_config/react/useAppDispatch";
import { Facture } from '../../../../Domain/Model';
import { editBonDeCommandePdf } from "../../../../Domain/Application/use-cases";

interface Props {
    rowData: Facture;
    measuredRef: any;

    handleDialogs(buttonId: string, rowData: Facture): void;
}

const FactureTableActionsCell = ({ rowData, measuredRef, handleDialogs }: Props) => {
    /* Display Invoice */
    const dispatch = useAppDispatch();
    const onClickDisplayInvoice = async () => {
        const res = await dispatch(editBonDeCommandePdf(rowData.id)).unwrap();
        const fileURL = window.URL.createObjectURL(res.file);
        window.open(fileURL);
    };

    const onClickButton = ({ currentTarget: { id: buttonId } }: { currentTarget: { id: string } }) =>
        handleDialogs(buttonId, rowData);

    return (
        <Stack direction="row">

            <Box ref={measuredRef}>
                {/*NEED TO ALWAYS HAVE 4 ELEMENTS !!! */}
                {/*Hack with empty buttons */}
                <IconButton disabled={true} size="small">
                    <Box width={'40px'}/>
                </IconButton>

                <Tooltip title="Editer">
                            <IconButton aria-label="editer" id="edit" color="primary" size="small"
                                        onClick={onClickButton}>
                                <Edit/>
                     </IconButton>
                </Tooltip>

                <Tooltip title="Afficher Bon de Commande">
                    <IconButton aria-label="facture" id="bon-de-commande-pdf" color="primary" size="small"
                                onClick={onClickDisplayInvoice}>
                        <Description/>
                    </IconButton>
                </Tooltip>

                <Tooltip title="Valider bon de commande">
                    <IconButton aria-label="valider" id="bonDeCommandeValidation" color="success" size="small"
                                onClick={onClickButton}>
                        <TaskAlt/>
                    </IconButton>
                </Tooltip>

                <IconButton disabled={true} size="small">
                    <Box width={'40px'}/>
                </IconButton>

            </Box>
        </Stack>
    );
};

export default FactureTableActionsCell;
