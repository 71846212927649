import { InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import React from "react";

interface Props {
    onChange: (changedValue: string) => void
}

export function SearchBar({ onChange }: Props) {
    return <TextField
        sx={{ width: 300 }}
        onChange={(e) => onChange(e.target.value)}
        label="Rechercher..."
        size="small"
        variant="outlined"
        InputProps={{
            type: "search",
            name: "search",
            endAdornment: (
                <InputAdornment position="end">
                    <Search/>
                </InputAdornment>
            ),
        }}
    />;
}