import { createAsyncThunk } from '@reduxjs/toolkit';
import { BonEntity } from '../entities';

interface CreateBonDto extends Omit<BonEntity, 'id' | 'docs' | 'bonNumber' | 'referenceClient'> { }

export const addOneBon = createAsyncThunk(
	'[BON] addNewBon',
	async (bonDto: CreateBonDto, thunk: any): Promise<BonEntity> => {
		try {
		const { ...bon } = bonDto;
		return await thunk.extra.bonQuery.add(bon);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
