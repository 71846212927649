import { createAsyncThunk } from "@reduxjs/toolkit";

export const retrieveOneBon = createAsyncThunk(
  '[BON] retrieveOneBon',
  async (bonId: string, thunk: any) => {
    try {
      return await thunk.extra.bonQuery.getBonById(bonId);
    } catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
  }
)