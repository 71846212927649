import { EntityId, Update } from "@reduxjs/toolkit";
import httpAdapter, { _apiUrlFactory } from "../../../_config/api-provider"
import { ChantierEntity } from "../../core/chantier/entities/chantier.entity";

const httpService = httpAdapter();

const prefix = 'chantier';
const apiChantiersUrl = _apiUrlFactory(prefix);

export const all = async () => {
    return await httpService.get(apiChantiersUrl(''));
}

export const add = async (clientId: string, chantier: any) => {
    return await httpService.post(apiChantiersUrl(), { clientId, ...chantier });
}

export const update = async (chantier: Update<ChantierEntity>): Promise<any> => {
	const { id, ...otherProps } = chantier;
	return await httpService.put(apiChantiersUrl(`${id}`), otherProps);
};

export const _delete = async (id: EntityId) => {
    return await httpService.delete(apiChantiersUrl(`${id}`));
  }