import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';

import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { addNewChantier } from '../../../core/chantier/use-cases/add-new-chantier';
import { setSuccess } from '../../../core/notification/notification.slice';
import ChantierEntity from '../../../core/chantier/entities';
import { object, setLocale, string } from 'yup';
import { LoadingButton } from '@mui/lab';
import { yupFr } from '../../common/i18n';
import { removeEmpty } from '../../common/_helpers';
import { updateOneChantier } from '../../../core/chantier/use-cases/update-one-chantier';

import { updateCreatedFromModal } from '../../../../Location/Domain/Bons/bon.slice';

type Chantier = Omit<ChantierEntity, 'id'>;

export default function ChantierModal() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {
        state: { extra, keepIdInState, chantier },
    }: any = useLocation();

    const initialValues: Chantier = {
        name: chantier?.name ?? '',
        client: extra,
        adresse: {
            rue: chantier?.adresse?.rue ?? '',
            cp: chantier?.adresse?.cp ?? '',
            ville: chantier?.adresse?.ville ?? '',
        },
        imputationNumber: chantier?.imputationNumber ?? '',
        observation: chantier?.observation ?? '',
    };

    setLocale(yupFr);
    const schema = object().shape({
        name: string().required(''),
        adresse: object().shape({
            rue: string().notRequired(),
            cp: string().notRequired(),
            ville: string().notRequired(),
        }),
        observation: string().notRequired(),
        imputationNumber: string().notRequired(),
    });

    const onDismiss = () => {
        navigate('', { replace: true });
    };

    const onSubmit = async (values: Chantier) => {
        let onlyFilledValues: any = removeEmpty(values);

        const res: any = chantier
            ? await dispatch(updateOneChantier({ id: chantier.id, ...onlyFilledValues }))
            : await dispatch(addNewChantier(onlyFilledValues));

        if ( !res.error ) {
            dispatch(setSuccess(chantier ? `Le chantier a été mis à jour` : `Le chantier a bien été créé`));
            if ( keepIdInState ) dispatch(updateCreatedFromModal({ entity: 'chantier', id: res.payload.id }));
            onDismiss();
        }
    };

    return (
        <>
            <Dialog open>
                <DialogTitle>Créer un chantier</DialogTitle>
                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isValid,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                        } =
                            props;
                        return (
                            <>
                                <Form onSubmit={handleSubmit}>
                                    <DialogContent sx={{ minWidth: 400 }}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    label="Nom"
                                                    error={!!errors.name && !!touched.name}
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={touched.name && errors.name}
                                                    variant="outlined"
                                                    required
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    label="Rue"
                                                    error={!!errors.adresse?.rue && !!touched.adresse?.rue}
                                                    name="adresse.rue"
                                                    value={values?.adresse?.rue}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={touched.adresse?.rue && errors.adresse?.rue}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    label="Code Postal"
                                                    error={!!errors.adresse?.cp && !!touched.adresse?.cp}
                                                    name="adresse.cp"
                                                    value={values?.adresse?.cp}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={touched.adresse?.cp && errors.adresse?.cp}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    label="Ville"
                                                    error={!!errors.adresse?.ville && !!touched.adresse?.ville}
                                                    name="adresse.ville"
                                                    value={values?.adresse?.ville}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={touched.adresse?.ville && errors.adresse?.ville}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    label="Imputation"
                                                    error={!!errors.imputationNumber && !!touched.imputationNumber}
                                                    name="imputationNumber"
                                                    value={values.imputationNumber}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={touched.imputationNumber && errors.imputationNumber}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    label="Observation"
                                                    error={!!errors.observation && !!touched.observation}
                                                    name="observation"
                                                    value={values.observation}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={touched.observation && errors.observation}
                                                    variant="outlined"
                                                    multiline
                                                    rows={3}
                                                />
                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={onDismiss}>Annuler</Button>
                                        <LoadingButton
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            loading={isSubmitting}
                                            disabled={!dirty || !isValid}
                                        >
                                            Confirmer
                                        </LoadingButton>
                                    </DialogActions>
                                </Form>
                            </>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
}
