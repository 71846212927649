import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { format, lastDayOfMonth, addDays } from 'date-fns';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, SelectChangeEvent, Stack,
    Step,
    StepLabel,
    Stepper,
} from '@mui/material';

import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';

import { selectEntreprisesBySearch } from '../../../../../App/core/entreprise/selectors';
import { setSuccess } from '../../../../../App/core/notification/notification.slice';
import { dateFormat } from '../../../../../App/app/common/_helpers';

import { FactureDialogEntrepriseList } from '../../commun';
import SelectBonGroupByChantiers from "../../SelectBonGroupByChantiers";
import FactureCreateDialogConfirm from './FactureCreateDialogConfirm';
import { FooterDialog } from "./FooterDialog";

import { addOneFacture, addBonDeCommande } from "../../../../Domain/Application/use-cases";
import { selectBonsForFacturesAndCommandes } from "../../../../Domain/Application/selectors";
import { IconByEntrepriseType } from "../../IconByEntrepriseType";
import { selectAllPaymentTypes } from '../../../../../App/core/payment-type/selectors';
import { retrieveAllPaymentType } from '../../../../../App/core/payment-type/use-cases';
import { selectAllCategorieProduit } from '../../../../../App/core/categorie-produit/selectors';
import { retrieveAllCategorieProduit } from '../../../../../App/core/categorie-produit/use-cases';

interface Props {
    open: boolean;
    handleDialog(): void;
    type: "client" | "fournisseur" | "";
    onClose(): void;
}

enum STEPS {
    ENTREPRISE,
    BONS,
    CONFIRMATION,
}

const stepsLabels = ['Sélectionner une entreprise', 'Sélectionner des bons', 'Confirmation'];

function FactureCreateDialog({ open, handleDialog,type, onClose }: Props) {
    const dispatch = useAppDispatch();
    const [activeStep, setActiveStep] = useState(STEPS.ENTREPRISE);

    const [selectedEntreprise, setSelectedEntreprise] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [dateFacturation, setDateFacturation] = useState(format(new Date(), dateFormat)); //lastDayOfMonth(
    const [expirationDate, setExpirationDate ] = useState(format(addDays(new Date(), 30), dateFormat));

    const [entrepriseType, setEntrepriseType] = useState<'client' | 'fournisseur' | ''>(type);
    
    const listOfBonsByCompany = useSelector(selectBonsForFacturesAndCommandes(entrepriseType));
    const entreprises = useSelector(selectEntreprisesBySearch);

 
    const categorieProduits = useSelector(selectAllCategorieProduit);
    useEffect(() => {
        dispatch(retrieveAllCategorieProduit());
      }, [dispatch]);

    const entreprisesWithBonsToBill = listOfBonsByCompany
        .filter(company =>
            entreprises.find(e => e.id === company.companyId) != null
            || entreprises.find(e => e.id === company.companyId.toString().substring(1)) != null)
        // pour distinguer fournisseur des client avec les memes noms, on rajoute un F devant l'id dans la redux facture
        // mais la liste des entreprises vient du context Entreprise qui n'a pas cette particularité.
        // Donc on veut qu'un nom s'affiche deux fois si il est à la foir client et fournisseur sur différentes prestations
        // d'ou le substring(1) pour l'enlever lors du filter pour etre sur d'avoir l'entreprise type fournisseur dans le filter
        .map(c => ({ ...c, name: c.companyName, id: c.companyId as string, children: c.chantiers }));

    const bonsByChantier = listOfBonsByCompany
            .find(co => co.companyId === selectedEntreprise)?.chantiers
            .map(chantier => ({
                ...chantier,
                bons: chantier.bons.slice()
            }))
        ?? [];
    const selectedEntrepriseType = listOfBonsByCompany.find(e => e.companyId === selectedEntreprise)?.type;

    const [selectedBons, setSelectedBons] = useState<string[]>([]);
    useEffect(() => {
        // determines if 'next' button should be disabled or not
        if ( activeStep === STEPS.ENTREPRISE ) setIsButtonDisabled(!selectedEntreprise);
        if ( activeStep === STEPS.BONS ) setIsButtonDisabled(!selectedBons.length);
        if ( activeStep === STEPS.CONFIRMATION ) setIsButtonDisabled(!selectedBons.length); // fetch bons here ?
    }, [activeStep, selectedEntreprise, selectedBons]);

    const handleNext = async () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if ( activeStep - 1 === STEPS.ENTREPRISE ) setSelectedBons([]);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const onDateUpdate = (_: any, value: string) => {
        if ( value && value !== dateFacturation ) setDateFacturation(value);
    };
    const onDateExpireUpdate = (_: any, value: string) => {
        if ( value && value !== expirationDate ) setExpirationDate(value);
    };
    const [selectedAddress, setAddressSelection] = useState('');

    function onAdresseSelection(e: SelectChangeEvent<string>) {
        setAddressSelection(e.target.value);
    }

    const [selectedRib, setRibSelection] = useState('');

    function onRibSelection(e: SelectChangeEvent<string>) {
        setRibSelection(e.target.value);
    }

    const onClickConfirm = async () => {
        const isClient = listOfBonsByCompany.find(c => c.companyId === selectedEntreprise)?.type === 'client';
        const action = isClient ? createFacture : createBonDeCommande;
        action()
            .then(() => {
                const targetedEntity = isClient ? "La Facture" : 'Le bon de commande'
                dispatch(setSuccess(targetedEntity + ' a bien été créée'))
                closeDialog();
            });
    };

    const createFacture = () => {
        return dispatch(addOneFacture({
            dateDeFacturation : dateFacturation,
            paymentDueDate: expirationDate,
            bons: selectedBons,
            rib: selectedRib,
            adresseFacturation: selectedAddress
        }))
            .unwrap()
    }


    const createBonDeCommande = () => {
        return dispatch(addBonDeCommande({
            fournisseur: selectedEntreprise,
            bonIds: selectedBons,
            rib: selectedRib,
            adresseFacturation: selectedAddress,
        }))
            .unwrap()
    }

    const closeDialog = () => {
        onClose();
        handleDialog();
        refreshVariables();
    }

    const refreshVariables = () => {
        // timeout to make sure the dialog is closed before refreshing
        // to avoid displaying first step before closing (ugly animation)
        setTimeout(() => {
            setSelectedEntreprise('');
            setSelectedBons([]);
            setActiveStep(STEPS.ENTREPRISE);
        }, 100);
    };

    return (
        <Dialog open={open} fullWidth maxWidth="lg" aria-label="modal">
            <DialogTitle>
                <Stack direction='row' spacing={1} >
                    {type === "client" && <div>Création : Facture client</div>}
                    {type === "fournisseur" && <div>Création : Bon de commande </div>}
                    <div>{
                        activeStep !== STEPS.ENTREPRISE
                        && '- '
                        + entreprises.find(e => e.id === selectedEntreprise || e.id === selectedEntreprise.substring(1))?.name.toUpperCase()}
                    </div>
                    <div style={{ justifyContent: 'center' }}>{selectedEntrepriseType != null
                        && activeStep !== STEPS.ENTREPRISE
                        && <IconByEntrepriseType entrepriseType={selectedEntrepriseType}/>
                    }</div>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ height: '75vh' }}>
                    <Stepper activeStep={activeStep}>
                        {stepsLabels.map((label) => {
                            const stepProps: { completed?: boolean } = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {activeStep === STEPS.ENTREPRISE && (
                        <FactureDialogEntrepriseList
                            entreprises={entreprisesWithBonsToBill}
                            setEntrepriseType={setEntrepriseType}
                            entrepriseType={entrepriseType}
                            selectedEntreprise={selectedEntreprise}
                            onSelectEntreprise={setSelectedEntreprise}
                        />
                    )}
                    {activeStep === STEPS.BONS && (
                        <div style={{ marginTop: 30 }}>

                            <SelectBonGroupByChantiers
                                bonsByChantier={bonsByChantier}
                                selectedEntrepriseType={selectedEntrepriseType ?? 'client'}
                                selectedBons={selectedBons}
                                addBonToSelection={(bons: string[]) => {
                                    setSelectedBons(bons)
                                }}
                            />
                        </div>
                    )}
                    {activeStep === STEPS.CONFIRMATION && (
                        <FactureCreateDialogConfirm
                            entrepriseId={selectedEntreprise}
                            bonsIds={selectedBons}
                            date={dateFacturation}
                            expirationDate={expirationDate}
                            setDate={onDateUpdate}
                            setExpirationDate={onDateExpireUpdate}
                            onRibSelection={onRibSelection}
                            onAdresseSelection={onAdresseSelection}
                            categorieProduits={categorieProduits}
                        />
                    )}
                </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <FooterDialog
                    activeStep={activeStep}
                    stepLabels={stepsLabels}
                    disabled={isButtonDisabled}
                    expirationDate={expirationDate}
                    dateFacturation={dateFacturation}
                    goBack={handleBack}
                    goNextStep={handleNext}
                    onClickConfirm={onClickConfirm}
                    closeDialog={closeDialog}
                />
            </DialogActions>
        </Dialog>
    );
}

export default FactureCreateDialog;
