import React, { ReactElement } from 'react';
import { Box, ClickAwayListener, Paper, Popper, PopperPlacementType, styled } from '@mui/material';

interface Props {
  content: ReactElement;
  children?: ReactElement;
  anchorEl: HTMLAnchorElement | null;
  onClose?: () => void;
  arrow?: boolean;
  placement?: PopperPlacementType;
}

// Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js
const StyledPopper = styled(Popper)(() => ({
  zIndex: 2000,
  '&[x-placement*="bottom"] $arrow': {
    top: 0,
    left: 0,
    marginTop: '-0.71em',
    marginLeft: 4,
    marginRight: 4,
    '&::before': {
      transformOrigin: '0 100%',
    },
  },
  '&[x-placement*="top"] $arrow': {
    bottom: 0,
    left: 0,
    marginBottom: '-0.71em',
    marginLeft: 4,
    marginRight: 4,
    '&::before': {
      transformOrigin: '100% 0',
    },
  },
  '&[x-placement*="right"] $arrow': {
    left: 0,
    marginLeft: '-0.71em',
    height: '1em',
    width: '0.71em',
    marginTop: 4,
    marginBottom: 4,
    '&::before': {
      transformOrigin: '100% 100%',
    },
  },
  '&[x-placement*="left"] $arrow': {
    right: 0,
    marginRight: '-0.71em',
    height: '1em',
    width: '0.71em',
    marginTop: 4,
    marginBottom: 4,
    '&::before': {
      transformOrigin: '0 0',
    },
  },
}));

const StyledPopoverRoot = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  maxWidth: 1000,
}));

// const StyledArrow = styled('span')(({ theme }) => ({
//   width: 12,
//   height: 12,
//   borderWidth: 1,
//   borderStyle: `solid`,
//   borderColor: `transparent black black transparent`,
//   backgroundColor: theme.palette.background.paper,
//   transform: 'rotate(45deg)', // rotate not working for some reason, cant figure out why
// }));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const RichTooltip = ({ placement = 'top', arrow = true, anchorEl, onClose = () => {}, content, children }: Props) => {
  // const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  return (
    <div>
      <StyledPopper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        keepMounted
        disablePortal
        modifiers={[
          {
            name: 'arrow',
            enabled: true,
            options: {
              // element: arrowRef,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: false,
              tether: false,
              rootBoundary: 'document',
            },
          },
        ]}
      >
        <Paper>
          <ClickAwayListener onClickAway={onClose}>
            <StyledPopoverRoot>
              <StyledBox>{content}</StyledBox>
              {/* {arrow ? <StyledArrow ref={setArrowRef} /> : null} */}
            </StyledPopoverRoot>
          </ClickAwayListener>
        </Paper>
      </StyledPopper>
    </div>
  );
};

export default RichTooltip;
