import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

interface Props {
  labels: { label: string; value: string }[];
  onChange(selected: string): void;
  defaultValue?: string;
}

const DropDownButton = ({ labels, onChange, defaultValue }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [label, setLabel] = useState(labels.find((l) => l.value === defaultValue)?.label ?? labels[0].label);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Button variant="outlined" size="small" color="secondary" endIcon={<KeyboardArrowDown />} onClick={handleClick}>
        {label}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {labels.map((l) => (
          <MenuItem
            key={l.value}
            onClick={() => {
              setLabel(l.label);
              handleClose();
              onChange(l.value);
            }}
          >
            {l.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DropDownButton;
