import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';

interface CategorieProduitQuery {
	_delete(id: EntityId): Promise<EntityId>;
}

export const deleteOneCategorieProduit = createAsyncThunk('[CATEGORIE-PRODUIT] deleteOneCategorieProduit', async (id: EntityId, thunkAPI) => {
	try {
		return await (thunkAPI.extra as { categorieProduitQuery: CategorieProduitQuery }).categorieProduitQuery._delete(id);
		} catch (error: any) {
			return thunkAPI.rejectWithValue({ message: error.message ?? null });
		}
});
