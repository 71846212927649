import { RootState } from '../../../_config/redux';
import { articleEntityAdapter } from './article.slice';

const articleSelector = articleEntityAdapter.getSelectors<RootState>((state) => state.articles);

export const selectAllArticles = (state: RootState) => {
	const articleInMemory = articleSelector.selectAll(state);
	return articleInMemory;
};

export const selectArticleById = (articleId: string) => (state: RootState) => {
	return articleSelector.selectById(state, articleId);
};

// export const selectArticlesByFournisseur = (fournisseurId: string) => (state: RootState) => {
// 	const allArticles = selectAllArticles(state);
// 	return allArticles
// 		.filter((a: ArticleEntity) => a.fournisseur?.id === fournisseurId)
// 		.map((a) => ({ id: a.id, label: a.name, prixAchat: a.prixHT, prixVente: a.prixVenteHT }));
// };

export const selectAllArticlesLabelsAndIds = (state: RootState) => {
	const articles = articleSelector.selectAll(state);
	return articles.map(({ id, name }) => ({ id, label: name }));
};

export const selectArticlesBySearch = (state: RootState) => {
	const articles = selectAllArticles(state);
	const { search, filter } = state.articles;
	return articles.filter((article) => article.name.toLowerCase().includes(search.toLowerCase()) && article.famille.includes(filter));
}