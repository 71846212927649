import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

export function ClientFournisseurRadioSelect(props: { onChange: (event: React.ChangeEvent<HTMLInputElement>) => void, selectedValue: string }) {
    return <RadioGroup row
                       onChange={props.onChange}
                       value={props.selectedValue}>

        <FormControlLabel value="" control={<Radio/>} label="Toutes"/>
        <FormControlLabel value="fournisseur" control={<Radio/>} label="Fournisseur"/>
        <FormControlLabel value="client" control={<Radio/>} label="Client"/>

    </RadioGroup>;
}