import React from "react";
import { Grid, ListItem, ListItemButton, ListItemIcon, ListItemText, Radio } from "@mui/material";
import { IconByEntrepriseType } from "../IconByEntrepriseType";

interface Props {
    updateSelection: (id: string) => void,
    entreprise: {
        id: string,
        name: string,
        type: 'client' | 'fournisseur',
        adresse?: { rue: string, cp: string, ville: string }
    },
    selectedEntreprise: string
}

export default function EntrepriseItem(props: Props) {

    const { adresse } = props.entreprise;
    const adresseString = `${appendIfNotEmpty(adresse?.rue, ',')} ${appendIfNotEmpty(adresse?.cp, '-')} ${adresse?.ville ?? ''}`;

    function appendIfNotEmpty(value: string | undefined, separator: string = '') {
        return Boolean(value) ? value + separator : '';
    }

    return <ListItem
        disablePadding
        onClick={() => props.updateSelection(props.entreprise.id)}>
        <ListItemButton>
            <ListItemIcon>
                <Radio
                    checked={props.selectedEntreprise === props.entreprise.id}
                    value={props.entreprise.id}
                    name="id"
                />
            </ListItemIcon>

            <Grid container columnSpacing={3} alignItems="center">
                <Grid item>
                    <ListItemText
                        primary={props.entreprise.name}
                        secondary={adresseString}
                    />
                </Grid>
                <Grid item>
                    <IconByEntrepriseType entrepriseType={props.entreprise.type}/>
                </Grid>
            </Grid>
        </ListItemButton>
    </ListItem>;
}

