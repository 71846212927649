import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { ListItemText, Box } from '@mui/material';
import { SidebarMenuItemProps } from './SidebarMenuList';
import { StyledItemIcon, StyledListItemButton } from './StyledListItem';

const SidebarMenuListItem = ({ text, icon, href, open }: SidebarMenuItemProps & { open: boolean }) => {
  type CustomNavLinkProps = Omit<NavLinkProps, 'to'>;

  const CustomNavLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, CustomNavLinkProps>((navLinkProps, ref) => {
        const { className: previousClasses, ...rest } = navLinkProps;
        const elementClasses = previousClasses ? previousClasses.toString() : '';

        return (
          <NavLink
            {...rest}
            ref={ref}
            to={href}
            end
            className={({ isActive }) => (isActive ? elementClasses + ' Mui-selected' : elementClasses)}
          />
        );
      }),
    [href]
  );

  return (
    <Box sx={{ mx: 1, my: 0.5 }}>
      <StyledListItemButton component={CustomNavLink}>
        <StyledItemIcon>{icon}</StyledItemIcon>
        {open && <ListItemText primary={text} sx={{ ml: 3 }} />}
      </StyledListItemButton>
    </Box>
  );
};

export default SidebarMenuListItem;
