import { useRoutes } from 'react-router-dom';
import { PlanningList, PlanningEdition } from '../components';

export default function PlanningRouter() {
	const element = useRoutes([
		{ path: '', element: <PlanningList /> },
		{ path: ':id', element: <PlanningEdition /> },
		{ path: 'create', element: <PlanningEdition /> }
	]);
	return element;
}
