import { createSlice, createEntityAdapter, isAnyOf, nanoid } from '@reduxjs/toolkit';
import { Notification } from './entities';
import { addOneArticle, retreiveAllArticles } from '../article/use-cases';
import {
    archiveBons,
    attachFileToBon,
    confirmBonManually,
    retrieveBons,
    retrieveOneBon,
    updateBonsRefs,
    updateOneBon
} from '../../../Location/Domain/Bons/use-cases';
import { retrieveAllChantiers, addNewChantier, deleteOneChantier, updateOneChantier } from '../chantier/use-cases';
import {
    addOneEntreprise,
    archiveEntreprises,
    retrieveAllEntreprises,
    updateOneEntreprise
} from '../entreprise/use-cases';
import {
    addOneAvoir,
    addOneFacture,
    emitOneFacture,
    deleteOneFacture,
    retrieveAllFactures,
    findAllAvoirs,
    retrieveOneAvoir,
    findAllEcritures
} from '../../../Facturation/Domain/Application/use-cases';
import { addOneInterlocuteur, deleteOneInterlocuteur, retreiveAllInterlocuteurs } from '../interlocuteur/use-cases';
import {
    addOnePlanning,
    attachFileToPlanning,
    deletePlanning,
    retrieveAllPlanning,
    retrievePlanningByDate,
    updateOnePlanning
    , addBonsFromPlanning, informEntreprises
} from '../../../Location/Domain/Planning/use-cases';

import { addOneUser, retrieveAllUsers, deactivateUsers, updateOneUser } from '../user/use-cases';
import { updateOneArticle } from '../article/use-cases/update-one-article';
import { updateOneFacture } from '../../../Facturation/Domain/Application/use-cases/update-one-bon';
import { addOnePaymentType, retrieveAllPaymentType, updateOnePaymentType } from '../payment-type/use-cases';
import {
    addOneCategorieProduit,
    retrieveAllCategorieProduit,
    updateOneCategorieProduit
} from '../categorie-produit/use-cases';
import { retrieveAllRelances } from '../../../Facturation/Domain/Application/use-cases/retrieve-all-relances';
import { relancerFacture } from '../../../Facturation/Domain/Application/use-cases/relancer-facture';
import { validatePayment } from '../../../Facturation/Domain/Application/use-cases/validate-payment';
import { reactivateUser } from '../user/use-cases/reactivate-user';
import { avoirToUse } from '../../../Facturation/Domain/Application/use-cases/use-avoir';

export const notificationEntityAdapter = createEntityAdapter<Notification>({
    selectId: (notification) => notification.id
});

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: notificationEntityAdapter.getInitialState({ notifications: [] as Notification[] }),
    reducers: {
        setError: (state, action: any) => {
            let message = '';

            if ( action.payload?.message ) {
                const payloadMesage = action.payload?.message
                message = Array.isArray(payloadMesage) ? payloadMesage.join(', ') : payloadMesage;
            } else if ( action.error.message ) {
                message = action.error.message;
            } else {
                message = "Une erreur technique est survenue.";
            }

            const error: Notification = { id: nanoid(), message, severity: 'error' };

            state.notifications.push(error);
        },
        setSuccess: (state, action) => {
            const success: Notification = { id: nanoid(), message: action.payload, severity: 'success' };

            state.notifications.push(success);
        },
        clearNotification: (state, action) => {
            const newNotifications = state.notifications.filter(notif => notif.id !== action.payload);

            state.notifications = newNotifications;
        }
    },
    extraReducers: builder => {
        builder
            .addMatcher(
                isAnyOf(
                    /* Article */
                    retreiveAllArticles.rejected,
                    addOneArticle.rejected,
                    updateOneArticle.rejected,
                    /* Bon */
                    retrieveBons.rejected,
                    confirmBonManually.rejected,
                    updateOneBon.rejected,
                    archiveBons.rejected,
                    updateBonsRefs.rejected,
                    attachFileToBon.rejected,
                    retrieveOneBon.rejected,
                    /* Chantier */
                    retrieveAllChantiers.rejected,
                    addNewChantier.rejected,
                    updateOneChantier.rejected,
                    deleteOneChantier.rejected,
                    /* Entreprise */
                    retrieveAllEntreprises.rejected,
                    addOneEntreprise.rejected,
                    archiveEntreprises.rejected,
                    updateOneEntreprise.rejected,
                    /* Interlocuteur */
                    retreiveAllInterlocuteurs.rejected,
                    addOneInterlocuteur.rejected,
                    deleteOneInterlocuteur.rejected,
                    /* Planning */
                    retrieveAllPlanning.rejected,
                    retrievePlanningByDate.rejected,
                    addOnePlanning.rejected,
                    deletePlanning.rejected,
                    updateOnePlanning.rejected,
                    addBonsFromPlanning.rejected,
                    informEntreprises.rejected,
                    attachFileToPlanning.rejected,
                    /* User */
                    retrieveAllUsers.rejected,
                    addOneUser.rejected,
                    updateOneUser.rejected,
                    deactivateUsers.rejected,
                    reactivateUser.rejected,
                    /* Factures */
                    retrieveAllFactures.rejected,
                    findAllAvoirs.rejected,
                    findAllEcritures.rejected,
                    addOneFacture.rejected,
                    updateOneFacture.rejected,
                    retrieveOneAvoir.rejected,
                    addOneAvoir.rejected,
                    retrieveAllRelances.rejected,
                    relancerFacture.rejected,
                    validatePayment.rejected,
                    emitOneFacture.rejected,
                    deleteOneFacture.rejected,
                    avoirToUse.rejected,
                    /* Payment Types */
                    retrieveAllPaymentType.rejected,
                    addOnePaymentType.rejected,
                    updateOnePaymentType.rejected,
                    /* Categorie Produit */
                    retrieveAllCategorieProduit.rejected,
                    addOneCategorieProduit.rejected,
                    updateOneCategorieProduit.rejected
                ), (state, action) => {
                    notificationSlice.caseReducers.setError(state, action);
                }
            )
    }
});

export const { setError, setSuccess, clearNotification } = notificationSlice.actions;
export default notificationSlice.reducer