import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EntityId } from "@reduxjs/toolkit";

import { Box } from "@mui/material";
import { CustomTable } from "../../../../../App/app/common/custom-table";
import { ColumnEntity } from "../../../../../App/app/common/custom-table/Table.entities";
import {
  emitOneFacture,
  retrieveAllFactures,
  deleteOneFacture,
  confirmBonDeCommande as confirmBonDeCommandeUsecase,
} from "../../../../Domain/Application/use-cases";

import { RootState } from "../../../../../_config/redux";
import { useAppDispatch } from "../../../../../_config/react/useAppDispatch";
import { Facture } from "../../../../Domain/Model";
import { selectAllbdcs, selectAllFactures, selectSortedFactures} from "../../../../Domain/Application/selectors";

import BonDeCommandeActionsCell from "./BonDeCommandeActionsCell";
import { validateDates } from "../../../../../App/app/common/_helpers";
import { relancerFacture } from "../../../../Domain/Application/use-cases/relancer-facture";
import { setSuccess } from "../../../../../App/core/notification/notification.slice";
import { FactureTableStatusCell, Status } from "../../facture/facture-list/FactureTableStatusCell";
import { FactureAvoirDialog, FactureValidatePaymentDialog } from "../../commun";
import EmitFactureDialog from "../../EmitFactureDialog";
import RelanceFactureDialog from "../../RelanceFactureDialog";
import DeleteFactureDialog from "../../DeleteFactureDialog";
import { IconByEntrepriseType } from "../../IconByEntrepriseType";
import { BonDeCommandeValidationDialog } from "./BonDeCommandeValidationDialog";
import { TableListSummary } from "../../TableListSummary";
import BdcEditDialog from "../bdc-edit/BdcEditDialog";
 
interface Props {
  entrepriseType: "client" | "fournisseur" | "";

  onSelectRows(ids: EntityId[]): void;

  refreshBons(): void;
}

function BdcTable({ onSelectRows, refreshBons, entrepriseType }: Props) {
 
  const dispatch = useAppDispatch();
  //const bdcs = useSelector(selectSortedbdcs);
  /*const bdcs = useSelector(selectAllbdcs).filter(
    (facture) => entrepriseType === "" || facture.type === entrepriseType
  );*/
  let bdcs = useSelector(selectAllbdcs).filter(
   
    // filtered by invoice type
    // If Invoice is not a draft
    // [???] - Back office filtered invoices loading?
    (facture) => facture.type === 'fournisseur' && facture.numeroFacture !== ''
  );
  //console.log(bdcs);
  const selectedDates = useSelector((state: RootState) => state.bdcs.dates);
  const status = useSelector((state: RootState) => state.bdcs.status);
 
  const filters: any = useSelector((state: RootState) => state.bdcs.filters);

  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState<Facture>();

  const [dialogsState, setDialogsState] = useState({
    edit: false,
    validate: false,
    avoir: false,
    relance: false,
    emit: false,
    bonDeCommandeValidation: false,
    delete: false,
  });

  const loadbdcs = useCallback(
    function () {
      try {
        const { fromDate, toDate } = validateDates(selectedDates);
        dispatch(retrieveAllFactures({ filters, from: fromDate, to: toDate }))
          .unwrap()
          .then(() => {
            setLoading(false);
          });
      } catch (err) {
        console.warn("Erreur lors de la récupération des bdcs", err);
      }
    },
    [dispatch, selectedDates, filters]
  );

  useEffect(() => {
    loadbdcs();
    return () => {
      setLoading(false);
    };
  }, [loadbdcs]);

  const handleDialogs = (
    buttonId: keyof typeof dialogsState,
    rowData?: Facture
  ) => {
    if((rowData?.status === 'CREATED' || !rowData) && buttonId === "edit") {
      setLoading(true);
      setRowData(rowData);
      const currentState = dialogsState[buttonId];
      const newState = !currentState;
      setDialogsState({ ...dialogsState, [buttonId]: newState });

      if (newState) {
      
        refreshBons();
        loadbdcs();
      }
      setLoading(false);
    }
  };

  // Relance
  const onConfirmRelance = async (aReveiverEmail: string) => {
    const res: any = rowData?.id
      ? await dispatch(
          relancerFacture({
            id: rowData.id,
            anEmail: aReveiverEmail,
          })
        )
      : null;

    if (!res?.error) {
      dispatch(setSuccess(`Le client a bien été relancé`));
      handleDialogs("relance");
    }
  };

  // Emettre
  const onConfirmEmit = async (aReveiverEmail: string) => {
    const res: any = rowData?.id
      ? await dispatch(
          emitOneFacture({
            id: rowData.id,
            anEmail: aReveiverEmail,
          })
        )
      : null;

    if (!res?.error) {
      dispatch(setSuccess(`La facture a bien été émise`));
      handleDialogs("emit");
    }
  };

  //Supprimer
  const onConfirmDelete = async (data: string) => {
    const res: any = rowData?.id
      ? await dispatch(
          deleteOneFacture({
            id: rowData.id,
          })
        )
      : null;

    if (!res?.error) {
      dispatch(setSuccess(`La facture a bien été supprimée`));
      loadbdcs();
      handleDialogs("delete");
    }
  };

  const confirmBonDeCommande = async (unNumeroDeFactureFournisseur: string) => {
    dispatch(
      confirmBonDeCommandeUsecase({
        bonDeCommandeId: rowData?.id as string,
        numeroFactureFournisseur: unNumeroDeFactureFournisseur,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setSuccess(`Le bon de commande a bien été confirmé`));
        handleDialogs("bonDeCommandeValidation");
      });
  };

  const columns: ColumnEntity[] = [
    {
      field: "status",
      headerName: "",
      width: 40,
      renderCell: (param) =>
        Object.values(Status).includes(param as Status) ? (
          <FactureTableStatusCell param={param} />
        ) : (
          param
        ),
    },
    {
      field: "numeroFacture",
      headerName: "N°",
      width: 110,
      sortable: true,
      resizable: true,
    },
    {
      field: "type",
      headerName: "",
      sortable: true,
      width: 40,
      renderCell(params: "client" | "fournisseur"): React.ReactChild {
        if (Boolean(params))
          return <IconByEntrepriseType entrepriseType={params} />;
        return <></>;
      },
    },
    {
      field: "dateDeFacturation",
      headerName: "Date",
      width: 120,
      sortable: true,
      resizable: true,
    },
    {
      field: "client.name",
      headerName: "Fournisseur",
      flexGrow: 1,
      sortable: true,
    },
    {
      field: "bons.length",
      headerName: "Bons",
      width: 80,
      sortable: true,
      resizable: true,
    },
    {
      field: "totalFraisAutorouteE",
      headerName: "Frais Auto.",
      width: 110,
      sortable: true,
      resizable: true,
    },
    {
      field: "totalHTe",
      headerName: "HT",
      width: 150,
      sortable: true,
      resizable: true,
    },    
    {
      field: "totalTvae",
      headerName: "TVA",
      width: 110,
      sortable: true,
      resizable: true,
    },
    {
      field: "totalTTCe",
      headerName: "TTC",
      width: 150,
      sortable: true,
      resizable: true,
    },
    {
      field: "reglement.montante",
      headerName: "Encaissé",
      width: 150,
      sortable: true,
      resizable: true,
    },
  ];

  
  if(filters && filters.totalTTC && Number(filters.totalTTC) !== 0 && filters.totalTTC !== '') {
    bdcs = bdcs.filter((facture) => facture.totalTTC === Number(filters.totalTTC));
  }


  const table_bdcs = JSON.parse(JSON.stringify(bdcs));
 
  table_bdcs.forEach((facture:any) => {
    facture.totalTTCe = Number(facture.totalTTC).toFixed(2) + " €";
    if(facture.totalHT) { facture.totalHTe = Number(facture.totalHT).toFixed(2) + " €"; }
    facture.totalTvae = Number(facture.totalTva).toFixed(2) + " €";
    facture.reglement.montante = Number(facture.reglement.montant).toFixed(2) + " €";
    facture.totalFraisAutorouteE = Number(facture.totalFraisAutoroute).toFixed(2) + " €";
  });

  
  return (
    <Box sx={{ height: "100%" }}>
      <CustomTable
        data={table_bdcs}
        columns={columns}
        loading={loading}
        hover={false}
        sortByDefault="dateDeFacturation"
        onRowDoubleClick={(rowData: Facture) => handleDialogs("edit", rowData)}
        onSelectRows={onSelectRows}
        renderActionCell={(rowData, measuredRef) => (
          <BonDeCommandeActionsCell
            rowData={rowData}
            measuredRef={measuredRef}
            handleDialogs={handleDialogs}
          />
        )}
        rendersummary={() => <TableListSummary collection={table_bdcs}/>}
      />

      {/* Edit Dialog */}
      <BdcEditDialog
        openEdit={dialogsState.edit}
        id={rowData?.id ?? ""}
        onCloseDialog={() => handleDialogs("edit")}
      />

      {/* Emit invoice  N° ${rowData?.numeroFacture} */}
      <EmitFactureDialog
        open={dialogsState.emit}
        title={"Emettre une facture"}
        contentText={`Êtes-vous sûr de vouloir émettre le bon de commande à ${rowData?.client.name} ?`}
        factureDetails={{
          aReceiverEmail: rowData?.client.contact?.email ?? "",
        }}
        onConfirm={onConfirmEmit}
        onClose={() => handleDialogs("emit")}
        isButtonLoading={false}
        key="emit"
      />

      {/* Validate Payment Dialog */}
      <FactureValidatePaymentDialog
        open={dialogsState.validate}
        facture={rowData}
        onCloseDialog={() => handleDialogs("validate")}
      />

      {/* Avoir Dialog */}
      <FactureAvoirDialog
        open={dialogsState.avoir}
        id={rowData?.id ?? ""}
        onCloseDialog={() => handleDialogs("avoir")}
      />

      {/* Relance Dialog */}
      <RelanceFactureDialog
        open={dialogsState.relance}
        title={"Relancer"}
        contentText={`Êtes-vous sûr de vouloir relancer ${rowData?.client.name} pour le bon de commande N° ${rowData?.numeroFacture}`}
        factureDetails={{
          aReceiverEmail: rowData?.client.contact?.email ?? "",
        }}
        onConfirm={onConfirmRelance}
        onClose={() => handleDialogs("relance")}
        isButtonLoading={false}
        key="relance"
      />

      {/* Delete invoice */}
      <DeleteFactureDialog
        open={dialogsState.delete}
        title={"Supprimer le bon de commande"}
        contentText={`Êtes-vous sûr de vouloir supprimer le bon de commande N° ${rowData?.numeroFacture}  ?`}
        onConfirm={onConfirmDelete}
        onClose={() => handleDialogs("delete")}
        isButtonLoading={false}
        key="remove"
      />

      {/* Validation Bon De commande */}
      <BonDeCommandeValidationDialog
        open={dialogsState.bonDeCommandeValidation}
        onClose={() => handleDialogs("bonDeCommandeValidation")}
        onConfirmAction={confirmBonDeCommande}
        numeroFacture={rowData?.numeroFacture ?? ""}
      />
    </Box>
  );
}

export default BdcTable;
