import React, { useEffect, useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';

import { Checkbox, Divider, Stack, TablePagination } from '@mui/material';
import { Cell, Column, HeaderCell, RowDataType, SortType, Table } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.min.css';
import { useElementDimensions } from '../hooks/useElementDimensions';
import { CheckCell, CustomCell } from './';
import { TableEntity } from './Table.entities';
import { customTableFr as locale } from '../i18n';
import './CustomTable.style.css';

function CustomTable(props: TableEntity & { data: any[], selectable?: boolean, rendersummary?: () => JSX.Element | React.FC }) {
    const {
        data,
        columns,
        onRowDoubleClick,
        renderActionCell,
        rendersummary,
        onSelectRows,
        handlePointerEnter,
        handlePointerLeave,
        sortByDefault,
        height,
        width,
        coloredRow,
        selectable = true,
        ...rest
    } = props;

    const [sortColumn, setSortColumn] = React.useState<string>(sortByDefault ?? '');
    const [sortType, setSortType] = React.useState<SortType>();
    const [checkedKeys, setCheckedKeys] = useState<EntityId[]>([]);
    const [page, setPage] = React.useState(0);
    const [rect, ref] = useElementDimensions();
    const actionsColumnWidth = rect ? rect.width + 6 : 50;
    const rowsPerPage = 15;

    const getData = () => {
        let sortedData = data;

        if ( sortColumn && sortType ) {
            /*sortedData = data.sort((a, b) => {

                const varA = _valueToSort(sortColumn, a);
                const varB = _valueToSort(sortColumn, b);

                let Ar = varA?.replace('€', '');
                let Ars = Ar?.replace(' ', '');
                let Br = varB?.replace('€', '');
                let Brs = Br?.replace(' ', '');
                if(!isNaN(Ars)) { Ars = Number(Ars); }
                if(!isNaN(Brs)) { Brs = Number(Brs); }
                let comparison = 0;
                if ( Ars > Brs ) {
                    comparison = 1;
                } else if ( Ars < Brs ) {
                    comparison = -1;
                }
                return sortType === 'desc' ? comparison * -1 : comparison;
            });*/
            const sortedData = data.sort((a, b) => {
                const varA = _valueToSort(sortColumn, a);
                const varB = _valueToSort(sortColumn, b);
            
                // Ensure null or undefined values are placed at the end
                if (varA === null || varA === undefined) return 1;
                if (varB === null || varB === undefined) return -1;
            
                let comparison = 0;
            
                // Perform comparison, assuming varA and varB are either both strings or both numbers
                if (varA > varB) {
                    comparison = 1;
                } else if (varA < varB) {
                    comparison = -1;
                }
            
                return sortType === 'desc' ? comparison * -1 : comparison;
            });
            
        }

        return sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage); //pagination
    };

    /*const _valueToSort = (col: string, value: RowDataType) => {
        const nestedValue: any = col
            .split('.')
            .reduce((p, c) => (p && p[c]) || null, value);

        return typeof nestedValue === 'string'
            ? nestedValue.toUpperCase()
            : nestedValue;
    };*/

    const _valueToSort = (col: string, value: RowDataType): string | number | null => {
        const nestedValue = col.split('.').reduce((p: any, c: string) => (p && p[c] !== undefined ? p[c] : null), value);
    
        if (typeof nestedValue === 'string') {
            return nestedValue.toUpperCase(); // Case-insensitive sorting for strings
        }
        
        return nestedValue;
    };
    

    const handleChangePage = (event: unknown, newPage: number) => setPage(newPage);

    const handleSortColumn = (sortColumn: string, sortType?: SortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    };

    const handleCheckAll = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = target;
        const keys = checked ? data.map((item: any) => item.id) : [];
        setCheckedKeys(keys);
        onSelectRows(keys);
    };

    const handleCheck = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = target;
        const keys = checked ? [...checkedKeys, value] : checkedKeys.filter((item) => item !== value);
        setCheckedKeys(keys);
        onSelectRows(keys);
    };

    let isChecked = false;
    let indeterminate = false;

    if ( checkedKeys.length === data.length ) {
        isChecked = true;
    } else if ( checkedKeys.length === 0 ) {
        isChecked = false;
    } else if ( checkedKeys.length > 0 && checkedKeys.length < data.length ) {
        indeterminate = true;
    }

    const onPointerEnter = (event: React.MouseEvent, rowData: object, field: string) => {
        if ( !!handlePointerEnter ) handlePointerEnter(event, rowData, field);
    };

    const onPointerLeave = (event: React.MouseEvent) => {
        if ( !!handlePointerLeave ) handlePointerLeave(event);
    };

    return (
        <Stack direction="column">
            <Table
                data={getData()} // sort if needed + pagination
                width={width}
                height={height ?? document.documentElement.clientHeight - 210}
                rowClassName="active-row"
                className="custom-table"
                affixHeader
                affixHorizontalScrollbar
                locale={locale}
                onSortColumn={handleSortColumn}
                sortColumn={sortColumn}
                sortType={sortType}
                style={{ borderRadius: 8 }}
                {...rest}
            >
                {selectable &&
                    <Column width={50} align="center">
                        <HeaderCell style={{ padding: 0 }}>
                            <div style={{ lineHeight: '40px' }}>
                                <Checkbox checked={isChecked} onChange={handleCheckAll} indeterminate={indeterminate}/>
                            </div>
                        </HeaderCell>
                        <CheckCell dataKey="id" checkedKeys={checkedKeys} onChange={handleCheck}
                                   coloredRow={coloredRow}/>
                    </Column>
                }
                {columns.map((column, index) => {
                    const { field, headerName, style, ...otherProps } = column;
                    return (
                        <Column key={`table-col-field-${field}` /* `table-xxx-col-${field}` */} {...otherProps}>
                            <HeaderCell>{headerName}</HeaderCell>
                            <CustomCell
                                dataKey={field}
                                onDoubleClick={onRowDoubleClick}
                                onPointerEnter={onPointerEnter}
                                onPointerLeave={onPointerLeave}
                                field={field}
                                rowIndex={index}
                                style={style}
                                checkedKeys={checkedKeys}
                                // coloredRow = name of the property
                                // (example: a row has to be colored when a planning line has a bon, we pass 'hasBon', value has to be boolean)
                                coloredRow={coloredRow}
                            />
                        </Column>
                    );
                })}

                {!!renderActionCell && (
                    <Column fixed="right" align="center" width={actionsColumnWidth}>
                        <HeaderCell className="custom-table-action-header">Actions</HeaderCell>
                        <Cell
                            className="custom-table-action-cell">{(rowData) => renderActionCell(rowData, ref)}</Cell>
                    </Column>
                )}


            </Table>

            <Stack direction={'row'} justifyContent={'end'}>
                {props.rendersummary && <>{
                    props.rendersummary()
                }<Divider orientation={'vertical'} flexItem/></>}

                {/*<Stack justifyContent={'end'}>*/}
                <TablePagination
                    component="div"
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[]} // empty array to remove options
                    count={data.length}
                    page={page}
                    onPageChange={handleChangePage}
                />
                {/*</Stack>*/}
            </Stack>
        </Stack>
    )
        ;
}

export default CustomTable;


export const useContainerDimensions = (myRef:any) => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  
    useEffect(() => {
      const getDimensions = () => ({
        width: myRef.current.offsetWidth,
        height: myRef.current.offsetHeight
      })
  
      const handleResize = () => {
        setDimensions(getDimensions())
      }
  
      if (myRef.current) {
        setDimensions(getDimensions())
      }
  
      window.addEventListener("resize", handleResize)
  
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [myRef])
  
    return dimensions;
  };