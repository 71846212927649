import { createAsyncThunk } from "@reduxjs/toolkit";
import { EntrepriseEntity } from "../entities";

type EntrepriseParam = string | undefined;

// interface EntrepriseQuery {
//   all(type: EntrepriseParam): Promise<EntrepriseEntity[]>
// }

type EntrepriseQueryResult = EntrepriseEntity[]


export const retrieveAllEntreprises = createAsyncThunk<EntrepriseQueryResult, EntrepriseParam, { extra: any }>(
    '[ENTREPRISE] retrieveAllEntreprises',
    async (type: EntrepriseParam, thunkAPI) => {
        try {
            return await thunkAPI.extra.entrepriseQuery.all(type)
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ message: error.message ?? null });
        }
    })


