import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { PaymentType } from './entities';
import { addOnePaymentType, retrieveAllPaymentType, updateOnePaymentType } from './use-cases';

export const paymentTypeEntityAdapter = createEntityAdapter<PaymentType>({
	selectId: (paymentType) => paymentType._id
});

export const paymentTypeSlice = createSlice({
	name: 'paymentType',
	initialState: paymentTypeEntityAdapter.getInitialState({}),
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(retrieveAllPaymentType.fulfilled, (state, action) => {
				paymentTypeEntityAdapter.setAll(state, action.payload);
			})
			.addCase(addOnePaymentType.fulfilled, (state, action) => {
				paymentTypeEntityAdapter.addOne(state, action.payload);
			})
			.addCase(updateOnePaymentType.fulfilled, (state, action: any) => {
				const { _id, ...otherProps} = action.payload;
				paymentTypeEntityAdapter.updateOne(state, { id: _id, changes: otherProps});
			})
	}
});

export default paymentTypeSlice.reducer;