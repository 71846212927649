import { createAsyncThunk } from '@reduxjs/toolkit';
import { affacturageType } from '../entities';

export const addOneaffacturageType = createAsyncThunk(
	'[affacturage-TYPE] addOneaffacturageType',
	async (affacturageType: affacturageType, thunk: any): Promise<affacturageType> => {
		try {
			return  thunk.extra.affacturageTypeQuery.add(affacturageType);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
