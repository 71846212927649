import { createAsyncThunk } from "@reduxjs/toolkit";
import ChantierEntity from "../entities";

export interface QueryService {
    all(): Promise<ChantierEntity[]>
}

export const retrieveAllChantiers = createAsyncThunk<ChantierEntity[], void, { extra: any }>(
    "[CHANTIERS] retrieveAll",
    async (_, thunk) => {
        try {
            return await thunk.extra.chantierQuery.all();
        } catch (error: any) {
            return thunk.rejectWithValue({ message: error.message ?? null });
        }
    }
)

