import { Button, Stack } from "@mui/material";
import React from "react";

interface FooterDialogProps {
    dateFacturation: string;
    expirationDate:string;
    activeStep: number;
    stepLabels: string[];
    disabled: boolean;

    goNextStep(): Promise<void>;

    goBack(): void;

    onClickConfirm(): Promise<void>;

    closeDialog(): void
}

export function FooterDialog(props: FooterDialogProps) {
    return <>
        <Button onClick={props.closeDialog}>Fermer</Button>
        <Stack direction="row" gap={1}>
            <Button onClick={props.goBack} disabled={props.activeStep === 0}>
                Précédent
            </Button>
            {props.activeStep !== props.stepLabels.length - 1 ? (
                <Button variant="contained" color="primary" onClick={props.goNextStep}
                        disabled={props.disabled}>
                    Suivant
                </Button>
            ) : (
                <Button
                    variant="contained" color="primary"
                    onClick={props.onClickConfirm}
                    disabled={!props.dateFacturation}>
                    Confirmer
                </Button>
            )}
        </Stack>
    </>
}