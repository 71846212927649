import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { dateFormat } from '../../../App/app/common/_helpers';
import { BonEntity } from './entities';
import {
    updateOneBon,
    archiveBons,
    retrieveBons,
    attachFileToBon,
    updateBonsRefs,
    confirmBonManually,
    updateComments, justifyFraisAutoroute
} from './use-cases';

const formattedStartOfMonth = format(startOfMonth(new Date()), dateFormat);
const formattedEndOfMonth = format(endOfMonth(new Date()), dateFormat);

export const bonEntityAdapter = createEntityAdapter<BonEntity>({
    selectId: (bon) => bon.id,

});

export const bonSlice = createSlice({
    name: 'bons',
    initialState: bonEntityAdapter.getInitialState({
        filters: null,
        dates: `${formattedStartOfMonth} - ${formattedEndOfMonth}`,
        createdFromModal: null // TODO: inspect, why this is in the state ? Seems more like a UI Responsability
    }),
    reducers: {
        updateFilters: (state, action) => {
            state.filters = action.payload;
        },
        updateDates: (state, action) => {
            state.dates = action.payload;
        },
        updateCreatedFromModal: (state, action) => {
            // null | { entity: ..., id: ...}
            state.createdFromModal = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveBons.fulfilled, (state, action) => {
                bonEntityAdapter.setAll(state, action.payload);
            })
            .addCase(updateOneBon.fulfilled, (state, action) => {
                bonEntityAdapter.updateOne(state, action.payload);
            })
            .addCase(archiveBons.fulfilled, (state, action) => {
                let ids = action.meta?.arg;
                bonEntityAdapter.removeMany(state, ids);
            })
            .addCase(updateBonsRefs.fulfilled, (state, { payload }) => {
                const updatedObjects = payload.map(({ id, referenceClient, bonNumber }: any) => ({
                    id,
                    changes: { referenceClient, bonNumber }
                }));
                bonEntityAdapter.updateMany(state, updatedObjects);
            })
            .addCase(attachFileToBon.fulfilled, (state, { payload }) => {
                // updating state of bon, adding uploaded document
                const { _id, mimetype, originalname, on } = payload;
                const bon = state.entities[on];

                if ( bon ) {
                    if ( !bon.docs ) bon.docs = [];
                    bon.docs.push({ _id, mimetype, originalname });
                }
            })
            .addCase(confirmBonManually.fulfilled, (state, { payload }) => {
                bonEntityAdapter.updateOne(state, {
                    id: payload.id,
                    changes: {
                        status: payload.bon?.status,
                        bonNumber: payload.bon?.numeroBon,
                        referenceClient: payload.bon?.referenceClient,
                        fraisAutoroute: payload.prestation.fraisAutoroute,
                        procedure: {
                            observations: {
                                client: payload.bon?.procedure.client.observation,
                                fournisseur: payload.bon?.procedure.fournisseur.observation
                            }
                        }
                    }
                });
            })
            .addCase(updateComments.fulfilled, (state, { payload }) => {
                bonEntityAdapter.updateOne(state, {
                    id: payload.id,
                    changes: payload.changes
                })
            })
            .addCase(justifyFraisAutoroute.fulfilled, (state, { payload }) => {
                bonEntityAdapter.updateOne(state, {
                    id: payload.id,
                    changes: {
                        fraisAutoroute: payload.fraisAutoroute
                    }
                })
            })

    }
});

export const { updateFilters, updateDates, updateCreatedFromModal } = bonSlice.actions;
export default bonSlice.reducer;

