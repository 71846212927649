import { Navigate, Outlet, useLocation } from "react-router-dom";

interface Props {
    isLoggedIn: boolean
}

function PublicRoute({ isLoggedIn }: Props): React.ReactElement {
   
    const location = useLocation()
    const from = (location.state as any)?.from?.pathname || "/";

    return isLoggedIn ? <Navigate replace to={from} /> : <Outlet />;
}

export default PublicRoute;