import {  EngineeringSharp, LocalShipping } from "@mui/icons-material";
import React from "react";

export function IconByEntrepriseType({ entrepriseType }: { entrepriseType: 'client' | 'fournisseur' }) {
    switch (entrepriseType) {
        case 'client':
            return <EngineeringSharp style={{ color: 'orange' }}/>
        case 'fournisseur':
            return <LocalShipping color={'primary'}/>
        default:
            return null
    }
}