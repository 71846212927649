import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PageHeaderBar from '../../../common/layout/components/PageHeaderBar';
import { selectEntrepriseById } from '../../../../core/entreprise/selectors';
import EntrepriseManageInterlocuteursList from './EntrepriseManageInterlocuteursList';
import ConfirmationDialog from '../../../common/modals/components/ConfirmationDialog';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { setSuccess } from '../../../../core/notification/notification.slice';
import { deleteOneInterlocuteur } from '../../../../core/interlocuteur/use-cases';
import EntrepriseManageInterlocuteurUserDialog from './EntrepriseManageInterlocuteurUserDialog';
import InterlocuteurEditDialog from './InterlocuteurEditDialog';
import { InterlocuteurEntity } from '../../../../core/interlocuteur/entities/interlocuteur.entity';
import { ViewCard } from '../../../common/layout/components';

function EntrepriseManageInterlocuteursView() {
  const { id }: any = useParams();
  const entreprise = useSelector(selectEntrepriseById(id));
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [interlocuteur, setInterlocuteur] = useState<InterlocuteurEntity>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [createAccountDialogOpen, setCreateAccountDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const dispatch = useAppDispatch();

  const onClickAdd = () => {
    navigate('?modal=interlocuteur', {
      state: { backgroundLocation: location, extra: id, keepIdInState: false },
      replace: true,
    });
  };

  // User Account
  const handleAccountDialog = () => setCreateAccountDialogOpen(!createAccountDialogOpen);

  const onClickCreateAccount = (interlocuteur: InterlocuteurEntity) => {
    setInterlocuteur(interlocuteur);
    setCreateAccountDialogOpen(true);
  };

  // Edit
  const handleEditDialog = () => setEditDialogOpen(!editDialogOpen);

  const onClickEdit = (interlocuteur: InterlocuteurEntity) => {
    setInterlocuteur(interlocuteur);
    setEditDialogOpen(true)
  }

  // Delete
  const handleDeleteDialog = (event: React.MouseEvent<Element, MouseEvent>) => setDeleteDialogOpen(!deleteDialogOpen);

  const onClickDelete = (interlocuteur: InterlocuteurEntity) => {
    setInterlocuteur(interlocuteur);
    setDeleteDialogOpen(true);
  };

  const onConfirmDelete = async () => {
    setLoading(true);

    if (interlocuteur) {
      const res: any = await dispatch(deleteOneInterlocuteur(interlocuteur.id));

      if (!res.error) {
        dispatch(setSuccess(`L'interlocuteur a bien été supprimé`));
        setDeleteDialogOpen(false);
      }
    }

    setLoading(false);
  };

  return (
    <>
      <PageHeaderBar title={`Gestion des interlocuteurs de ${entreprise?.name}`} />
      <ViewCard>
        {entreprise && (
          <EntrepriseManageInterlocuteursList
            entreprise={entreprise}
            onClickAdd={onClickAdd}
            onClickCreateAccount={onClickCreateAccount}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
           
          />
        )}

        {/* Dialogs */}
        <ConfirmationDialog
          open={deleteDialogOpen}
          title="Supprimer"
          contentText="Êtes-vous sûr de vouloir supprimer ces données ?"
          isButtonLoading={loading}
          onClose={handleDeleteDialog}
          onConfirm={onConfirmDelete}
        />

        <InterlocuteurEditDialog
          interlocuteur={interlocuteur}
          open={editDialogOpen}
          onClose={handleEditDialog}
        />

        <EntrepriseManageInterlocuteurUserDialog
          interlocuteur={interlocuteur}
          open={createAccountDialogOpen}
          onClose={handleAccountDialog}
        />
      </ViewCard>
    </>
  );
}

export default EntrepriseManageInterlocuteursView;
