import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchRequiredData } from "./fetch-required-data";

export interface AuthenticateQuery {
    (token: string): Promise<any>
}

interface AuthenticateQueryResult {
    access: { token: string, exp: number }
    refresh: { token: string, exp: number }
    user: { id: string, name: string, email: string, roles: string[] }
}

interface ThunkExtraArgs {
    authService: { authenticate: AuthenticateQuery }
}

export const authenticateUser = createAsyncThunk<
    AuthenticateQueryResult,
    string,
    { extra: ThunkExtraArgs }
>(
    "[AUTH] authenticateUser",
    async (refreshToken, thunk) => {
        const userAuthenticated = await thunk.extra.authService.authenticate(refreshToken);
        if (!userAuthenticated.user.roles.includes("admin")) thunk.rejectWithValue({ message: "Vous n'avez pas les droits pour accéder à cette application" });

        await thunk.dispatch(fetchRequiredData());
        return userAuthenticated;
    });