import React from 'react';
import { useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { number, object, setLocale, string } from 'yup';
import { yupFr } from '../../../common/i18n';

import { Grid, MenuItem, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { FormFieldAutocomplete, FormFieldLayout } from '../../../common/form-field';
import { selectAllCategoriesLabelsAndIds } from '../../../../core/categorie-produit/selectors';
import { unites } from '../../../common/_helpers';
// import { selectAllLabelsAndIds } from '../../../../core/entreprise/selectors';

interface Props {
  initialValues: any;
  onSubmit(values: any): void;
  defaultValues: any;
}

function ArticleForm({ initialValues, onSubmit, defaultValues }: Props) {
  setLocale(yupFr);
  const schema = object().shape({
    name: string().required(),
    prixHT: number().required(),
    prixVenteHT: number().required(),
    famille: string().required(),
    description: string().notRequired(),
    unite : string().notRequired(),
    // tauxTva: number().min(0).notRequired(),
  });

  // const entreprises = useSelector(selectAllLabelsAndIds);

  const categories = useSelector(selectAllCategoriesLabelsAndIds);
  //const defaultUnityValue = "journee";

 

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
      {(props) => {
        const { values, touched, errors, dirty, isValid, handleChange, handleBlur, handleSubmit, isSubmitting } = props;
        return (
          <>
            <Form onSubmit={handleSubmit}>
              <Grid container direction="row" spacing={4} p={4} width={{ xs: '100%', md: '75%', xl: '60%' }}>
                {/* <Grid item xs={12}>
                  <FormFieldLayout label="Fournisseur">
                    <FormFieldAutocomplete
                      id="fournisseur"
                      options={entreprises}
                      defaultValue={defaultValues.fournisseur}
                      variant="outlined"
                      required
                    />
                    <></>
                  </FormFieldLayout>
                </Grid> */}

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Nom"
                    error={!!errors.name && !!touched.name}
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Prix Achat HT"
                    error={!!errors.prixHT && !!touched.prixHT}
                    name="prixHT"
                    value={values.prixHT}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.prixHT && errors.prixHT}
                    type="number"
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Prix Vente HT"
                    error={!!errors.prixVenteHT && !!touched.prixVenteHT}
                    name="prixVenteHT"
                    value={values.prixVenteHT}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.prixVenteHT && errors.prixVenteHT}
                    type="number"
                    variant="outlined"
                    required
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Famille"
                    error={!!errors.famille && !!touched.famille}
                    name="famille"
                    value={values.famille}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.famille && errors.famille}
                    variant="outlined"
                    required
                  />
                </Grid> */}

                <Grid item xs={12}>
                  <FormFieldLayout label="Famille">
                    <FormFieldAutocomplete
                      id="famille"
                      options={categories}
                      defaultValue={defaultValues.famille}
                      variant="outlined"
                      required
                    />
                    <></>
                  </FormFieldLayout>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    error={!!errors.description && !!touched.description}
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.description && errors.description}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                    <Field
                              component={TextField}
                              select
                              fullWidth
                              
                              label="Unité par défaut"
                              placeholder="Unité par défaut"
                              id="unite"
                              name="unite"
                              value={values.unite ? values.unite : ''}
                              onChange={handleChange}
                              inputProps={{ name: 'unite', id: 'unite' }}
                              variant="outlined"
                              onBlur={handleBlur}
                              error={!!errors.unite && !!touched.unite}
                              helperText={touched.unite && errors.unite}
                              InputLabelProps={{ shrink: true }}
                          >
                              {unites.map((unite) => (
                                  <MenuItem key={unite.value} value={unite.value}>
                                      {unite.label}
                                  </MenuItem>
                              ))}
                     </Field>
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Taux TVA"
                    error={!!errors.tauxTva && !!touched.tauxTva}
                    name="tauxTva"
                    value={values.tauxTva}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.tauxTva && errors.tauxTva}
                    variant="outlined"
                  />
                </Grid> */}

                <Grid item xs={12} display="flex" justifyContent="flex-end" mt={2}>
                  <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={!dirty || !isValid}>
                    Enregistrer
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}

export default ArticleForm;
