import React from "react";
import { EntrepriseEntity } from "../../../../App/core/entreprise/entities";
import { AdresseFacturationItem } from "./AdresseFacturationItem";

export function AdresseFacturationListe(props: { uneEntreprise: EntrepriseEntity }) {
    const { uneEntreprise } = props;

    if ( !Boolean(uneEntreprise.adresseFacturation) || !uneEntreprise?.adresseFacturation?.length ) {
        return <>... aucune donnée</>
    }

    return <>{
        uneEntreprise
            .adresseFacturation
            .map((uneAdresse, ind) =>
                <AdresseFacturationItem key={ind}
                                        uneAdresse={uneAdresse}
                                        onClick={() => {
                                        }}
                />
            )
    }</>
}