import React, { useEffect, useState } from 'react';
import {  Form, Formik } from 'formik';
import { boolean, object, string } from 'yup';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { addOneserveurType, retrieveAllserveurType, updateOneserveurType } from '../../../../core/serveur-type/use-cases';
import { setSuccess } from '../../../../core/notification/notification.slice';
import { restartType } from '../../../../core/restart/restart-type';

function ProgressBar() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const totalDuration = 30000; // 30 seconds
    const updateInterval = 100;  // Update every 100ms
    const increment = (updateInterval / totalDuration) * 100;  // Percentage increment per interval

    const interval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(interval);  // Stop when progress reaches 100%
          return 100;
        }
        return prev + increment;
      });
    }, updateInterval);

    return () => clearInterval(interval);  // Clean up interval when component unmounts
  }, []);

  return (
    <div style={{ width: '100%', marginTop: '20px' }}>
      <div
        style={{
          width: `${progress}%`,
          height: '25px',
          backgroundColor: 'green',
          transition: 'width 0.1s ease-in-out',
          borderRadius: '5px'
        }}
      />
      <p>{Math.round(progress)}%</p>
    </div>
  );
}

const ParamServerAddDialog = ({ open, onCloseDialog, initialValues, typeId }: any) => {

  const [isform, setIsForm] = useState(true);

  const schema = object().shape({
    name: string().required(),
    value: string().required(),
    isDefault: boolean().optional().default(false)
  });

  const dispatch = useAppDispatch();

  const  refreshPageAfterTimeout = async () => {
    setIsForm(false);
    const res = await dispatch(restartType());
    setTimeout(() => {
      window.location.reload();
    }, 30000);  // 10 seconds
  };

  const onSubmit = async (values: any) => {
    let res: any;

    if (!!typeId) {
      res = await dispatch(updateOneserveurType({ id: typeId, ...values }));
    } else {
      res = await dispatch(addOneserveurType(values));
    }

    if (!res.error) {
      /** Get updated serveur list after submit */
      dispatch(retrieveAllserveurType())
      /** Await setSuccess to be dispatched before closing */
      await dispatch(setSuccess(!!typeId ? `Paramètre mis à jour` : `Paramètre créé`));
      //onCloseDialog();
      refreshPageAfterTimeout();

     
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{`${!!typeId ? 'Modifier' : 'Ajouter'} un paramètre`}</DialogTitle>
      {isform ? <Formik initialValues={{isDefault: false, ...initialValues }} onSubmit={onSubmit} validationSchema={schema}>
        {(props) => {
          const { values, touched, errors, dirty, isValid, handleChange, handleBlur, handleSubmit, isSubmitting } =
            props;
          return (
            <>
              <Form onSubmit={handleSubmit}>
                <DialogContent sx={{ minWidth: 400 }}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Nom"
                        error={!!errors.name && !!touched.name}
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.name && errors.name}
                        variant="outlined"
                        required
                        InputProps={{ readOnly : values.name !== ''}}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Informations"
                        error={!!errors.value && !!touched.value}
                        name="value"
                        value={values.value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.value && errors.value}
                        variant="outlined"
                        multiline
                        rows={6}
                      />
                    </Grid>
                    <Grid item>
                      {/* <Field 
                        type={() =><Checkbox 
                          // error={!!errors.isDefault && !!touched.isDefault}
                          // onChange={handleChange}
                          // name="isDefault"
                          // defaultChecked={initialValues.isDefault}
                          // checked={values.isDefault}
                          />}
                        name="isDefault"
                        label="serveur par défaut"
                      /> */}
                    
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onCloseDialog}>Annuler</Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={isSubmitting}
                    disabled={!dirty || !isValid}
                  >
                    Confirmer
                  </LoadingButton>
                </DialogActions>
              </Form>
            </>
          );
        }}
      </Formik> : <div style={{padding: "30px", textAlign: "center"}}><p>Le serveur redémarre. Merci de patienter</p><ProgressBar /></div> }
    </Dialog>
  );
};

export default ParamServerAddDialog;
