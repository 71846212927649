import { Alert, AlertColor, Snackbar } from '@mui/material';

interface Props {
  message: string;
  severity: AlertColor;
}

function AuthLayoutMessages({ message, severity }: Props) {
  return (
    <>
      <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={4000}>
        <Alert severity={severity} variant="filled">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AuthLayoutMessages;
