import { createAsyncThunk, EntityId } from "@reduxjs/toolkit";
import {
    IEntrepriseService,
    IAddReglementUseCasePayload,
    ICustomReglementResponse
} from "../../Ports/IEntreprise.service";

export const addCustomReglement = createAsyncThunk<
    ICustomReglementResponse,
    { entrepriseId: EntityId, payload: IAddReglementUseCasePayload },
    { extra: { entrepriseQuery: IEntrepriseService } }
>(
    "[ENTREPRISE] add Custom reglement",
    async ({ entrepriseId, payload }, thunkAPI) => {
        return thunkAPI.extra.entrepriseQuery.addReglement(entrepriseId.toString(), {
            categoryId: payload.categoryId,
            paymentDeadline: payload.paymentDeadline,
            type: payload.type
        })
    }
)
