import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';

interface AttachedFile {
	id: EntityId;
	file: File;
}

export const attachFileToPlanning = createAsyncThunk(
	'[PLANNING] attachFileToPlanning',
	async (file: AttachedFile, thunk: any): Promise<any> => {
		try {
			return await thunk.extra.planningService.attach(file);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
