import { createAsyncThunk } from "@reduxjs/toolkit";
import { BonDeCommandeRepository } from "../../../Ports/BonDeCommandeRepository";
import { Facture } from "../../../Model";

interface Response {
    createdBonDeCommande: Facture
}

interface Dto {
    fournisseur: string,
    bonIds: string[],
    rib: string,
    adresseFacturation: string
}

interface BCService
    extends Pick<BonDeCommandeRepository, 'addBonDeCommande'> {
}

export const addBonDeCommande = createAsyncThunk<Response, Dto, { extra: { bonDeCommandeService: BCService } }>("[FACTURE] nouveau bon de Commande",
    async ({ fournisseur, bonIds, rib, adresseFacturation }, thunkAPI) => {
        const bcCreated = await thunkAPI.extra.bonDeCommandeService.addBonDeCommande({
            fournisseurId: fournisseur,
            bonIds,
            rib,
            adresseFacturation
        });

        return { createdBonDeCommande: bcCreated.bonDeCommandeCreated };
    }
)