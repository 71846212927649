import React from 'react';
import { object, string } from 'yup';
import { Form, Formik } from 'formik';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import InterlocuteurEntity from '../../../../core/interlocuteur/entities';
import { updateOneInterlocuteur } from '../../../../core/interlocuteur/use-cases/update-one-interlocuteur';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { setSuccess } from '../../../../core/notification/notification.slice';

interface Props {
  interlocuteur: InterlocuteurEntity | undefined;
  open: boolean;
  onClose(): void;
}

const InterlocuteurEditDialog = ({ interlocuteur, open, onClose }: Props) => {
  const dispatch = useAppDispatch()
  const initialValues = {
    nom: interlocuteur?.nom??'',
    prenom: interlocuteur?.prenom??'',
    telephone: interlocuteur?.telephone??'',
    email: interlocuteur?.email??'',
    fonction: interlocuteur?.fonction??''
  };

  const schema = object().shape({
    nom: string().required('Veuillez saisir un nom'),
    prenom: string().required('Veuillez saisir un prénom'),
    telephone: string().notRequired(),
    email: string().notRequired(),
    fonction: string().notRequired(),
  });

  // const onSubmit = async () => {
  // };
  const onSubmit = async ({ nom, prenom, telephone, email, fonction }: { nom: string; prenom: string; telephone: string; email: string; fonction: string }) => {
    if(interlocuteur?.id != null) {
      const res: any = await dispatch(updateOneInterlocuteur({ id: interlocuteur?.id, nom, prenom, telephone, email, fonction, employeur: interlocuteur.employeur }));
      if (!res.error) {
        dispatch(setSuccess(`Le compte de l'interlocuteur a bien été mis à jour`));
        onClose();
      }
    }

    
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Éditer un interlocuteur</DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <DialogContent sx={{ width: 500 }}>
              <Stack direction="column" gap={2}>
                  <TextField
                    fullWidth 
                    variant="standard" 
                    label="Nom"
                    error={!!errors.nom && !!touched.nom}
                    name="nom"
                    value={values.nom}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {values.nom}
                  </TextField>

                  <TextField 
                    fullWidth 
                    variant="standard" 
                    label="Prénom"
                    error={!!errors.prenom && !!touched.prenom}
                    name="prenom"
                    value={values.prenom}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    >
                    {values.prenom}
                  </TextField>

                  <TextField fullWidth 
                    variant="standard" 
                    label="Téléphone"
                    error={!!errors.telephone && !!touched.telephone}
                    name="telephone"
                    value={values.telephone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    >
                    {values.telephone}
                  </TextField>

                  <TextField 
                    fullWidth 
                    variant="standard" 
                    label="Email"
                    error={!!errors.email && !!touched.email}
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    >
                    {values.email}
                  </TextField>

                  <TextField 
                    fullWidth 
                    variant="standard" 
                    label="Fonction"
                    error={!!errors.fonction && !!touched.fonction}
                    name="fonction"
                    value={values.fonction}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    >
                    {values.fonction}
                  </TextField>
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Fermer</Button>
                <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={!dirty || !isValid}>
                  Enregistrer
                </LoadingButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default InterlocuteurEditDialog;
