import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import ChantierEntity from './entities';
import { retrieveAllChantiers, addNewChantier, updateOneChantier, deleteOneChantier } from './use-cases';

export const chantierEntityAdapter = createEntityAdapter<ChantierEntity>({
	selectId: (chantier) => chantier.id
});

export const chantierSlice = createSlice({
	name: 'chantiers',
	initialState: chantierEntityAdapter.getInitialState(),
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(retrieveAllChantiers.fulfilled, (state, action) => {
			chantierEntityAdapter.setAll(state, action);
		});
		builder.addCase(addNewChantier.fulfilled, (state, action) => {
			chantierEntityAdapter.addOne(state, action);
		});
		builder.addCase(updateOneChantier.fulfilled, (state, action: any) => {
			const { _id, ...otherProps} = action.payload;
			chantierEntityAdapter.updateOne(state, { id: _id, changes: otherProps});
		});
		builder.addCase(deleteOneChantier.fulfilled, (state, action: any) => {
			chantierEntityAdapter.removeOne(state, action.payload._id);
		});
	}
});
