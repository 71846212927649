import React from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
    // Child[0]: Toolbar, Child[1]: Table
    children: [React.ReactElement, React.ReactElement, React.ReactElement?];
    title: string;
}

const TableViewLayout = ({ children, title }: Props) => {
    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="h5" component="h2" color={'#666767'}>
                {title}
            </Typography>
            <Box sx={{ border: '1px solid #ddd', borderRadius: 2, backgroundColor: '#fff' }}>
                {/* Toolbar */}
                {children[0] ? <>{children[0]}</> : null}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    borderRadius: 2,
                    border: '1px solid #ddd',
                    backgroundColor: '#fff',
                }}
            >
                {/* Table */}
                {children[1] ? <>{children[1]}</> : null}
            </Box>
            {children[2] ? <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                borderRadius: 2,
                border: '1px solid #ddd',
                backgroundColor: '#fff',
            }}>{children[2]}</Box> : null}
        </Box>
    );
};

export default TableViewLayout;
