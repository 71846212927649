import { EntityId, Update } from '@reduxjs/toolkit';
import { EntrepriseEntity, EntrepriseType } from '../../core/entreprise/entities';
import httpAdapter, { _apiUrlFactory } from '../../../_config/api-provider';
import { EntrepriseService } from "../../../Facturation/Data/source/http/Entreprise.service";
import {
    IAddReglementUseCasePayload,
    ICustomReglementResponse
} from "../../../Facturation/Domain/Ports/IEntreprise.service";
import { IAdresse } from "../../core/entreprise/entities/adresse.entity";

const httpService = httpAdapter();

const prefix = 'entreprise';
const apiEntreprisesUrl = _apiUrlFactory(prefix);

export const all = async (type?: EntrepriseType) => {
    const query = type ? `?type=${type}` : '';
    return await httpService.get(`${apiEntreprisesUrl('')}${query}`);
};

export const add = async (entreprise: EntrepriseEntity): Promise<Partial<EntrepriseEntity>> => {
    return await httpService.post(apiEntreprisesUrl(''), entreprise);
};

export const _delete = async (ids: EntityId[]): Promise<EntityId[]> => {
    return await httpService.delete(apiEntreprisesUrl(''), { data: { ids } });
}

export const update = async (entreprise: Update<EntrepriseEntity>): Promise<EntrepriseEntity> => {
    const { id, changes } = entreprise;
    return await httpService.put(apiEntreprisesUrl(`${id}`), changes);
};

export const addReglement: (entrepriseId: string, payload: IAddReglementUseCasePayload) => Promise<ICustomReglementResponse> = async (entrepriseId, payload) => {
    return new EntrepriseService().addReglement(entrepriseId, payload);
}

export const addAdresseFacturation: (entrepriseId: string, adresseDeFacturation: IAdresse) => Promise<{ adresseFacturation: IAdresse }> = async (entrepriseId, adresseDeFacturation) => {
    return new EntrepriseService().addAdresseFacturation(entrepriseId, adresseDeFacturation);
}
