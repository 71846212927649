import { useNavigate, useSearchParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { object, setLocale, string } from 'yup';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { addOneEntreprise } from '../../../core/entreprise/use-cases';
import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { CreateEntrepriseDto } from '../../../core/entreprise/use-cases/add-one-entreprise';
import { setSuccess } from '../../../core/notification/notification.slice';
import { updateCreatedFromModal } from '../../../../Location/Domain/Bons/bon.slice';
import { yupFr } from '../../common/i18n';
import { removeEmpty } from '../../common/_helpers';

interface InitialFormValues {
  name: string;
  rue: string;
  cp: string;
  ville: string;
  email: string;
  telephone: string;
  code_client: string;
  code_fournisseur: string;
  code_imputation: string;
}

function EntrepriseModal() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const modal = searchParams.get('modal');

  const initialValues: InitialFormValues = {
    name: '',
    rue: '',
    cp: '',
    ville: '',
    email: '',
    telephone: '',
    code_client: '',
    code_fournisseur: '',
    code_imputation: '',
  };

  setLocale(yupFr);
  const schema = object().shape({
    name: string().required(),
    rue: string().notRequired(),
    cp: string().notRequired(),
    ville: string().notRequired(),
    email: string().notRequired(),
    telephone: string().notRequired(),
    code_client: string().notRequired(),
    code_fournisseur: string().notRequired(),
  });

  const onDismiss = () => {
    navigate('', { replace: true });
  };

  const onSubmit = async (values: InitialFormValues) => {
    const entrepriseToCreate: CreateEntrepriseDto = {
      name: values.name,
      adresse: {
        rue: values?.rue,
        cp: values?.cp,
        ville: values?.ville,
      },
      contact: {
        email: values.email,
        telephone: values.telephone,
      },
      code_client: values.code_client,
      code_fournisseur: values.code_fournisseur,
    };

    let onlyFilledValues: any = removeEmpty(entrepriseToCreate);

    const res: any = await dispatch(addOneEntreprise(onlyFilledValues));

    if (!res.error) {
      dispatch(setSuccess(`L'entreprise a bien été créée`));
      dispatch(updateCreatedFromModal({ entity: modal, id: res.payload.id }));
      onDismiss();
    }
  };

  return (
    <Dialog open>
      <DialogTitle>Créer une entreprise</DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
        {(props) => {
          const { values, touched, errors, dirty, isValid, handleChange, handleBlur, handleSubmit, isSubmitting } =
            props;
          return (
            <>
              <Form onSubmit={handleSubmit}>
                <DialogContent sx={{ minWidth: 400 }}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Nom"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        variant="outlined"
                        required
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Code Fournisseur"
                        name="code_fournisseur"
                        value={values.code_fournisseur}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Code Client"
                        name="code_client"
                        value={values.code_client}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item>
                      <TextField
                        fullWidth
                        label="Rue"
                        name="rue"
                        value={values?.rue}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Code Postal"
                        name="cp"
                        value={values?.cp}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Ville"
                        name="ville"
                        value={values?.ville}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Email"
                        error={!!errors.email && !!touched.email}
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.email && errors.email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Téléphone"
                        error={!!errors.telephone && !!touched.telephone}
                        name="telephone"
                        value={values.telephone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.telephone && errors.telephone}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onDismiss}>Annuler</Button>
                  <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={!dirty || !isValid}>
                    Confirmer
                  </LoadingButton>
                </DialogActions>
              </Form>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
}

export default EntrepriseModal;
