import { Update } from '@reduxjs/toolkit';
import httpAdapter, { _apiUrlFactory } from '../../../_config/api-provider';

const httpService = httpAdapter();

const prefix = 'accounting/payment-type';
const apiPaymentTypeUrl = _apiUrlFactory(prefix);

export const all = async () => {
	return await httpService.get(`${apiPaymentTypeUrl('')}`);
};

export const add = async (mode: any): Promise<Partial<any>> => {
	return await httpService.post(apiPaymentTypeUrl(''), mode);
};

export const update = async (mode: Update<any>): Promise<any> => {
	const { id, ...otherProps } = mode;
	return await httpService.put(apiPaymentTypeUrl(`${id}`), otherProps);
};
