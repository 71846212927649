import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { object, setLocale, string } from 'yup';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { addOneUser } from '../../../core/user/use-cases';
import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { setSuccess } from '../../../core/notification/notification.slice';
import { updateCreatedFromModal } from '../../../../Location/Domain/Bons/bon.slice';
import { yupFr } from '../../common/i18n';
import { removeEmpty } from '../../common/_helpers';

function ChauffeurModal() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {
        state: { extra, keepIdInState },
    }: { state: any } = useLocation();

    const initialValues: any = {
        name: '',
        lastname: '',
        password: '',
        email: '',
        telephone: '',
    };

    setLocale(yupFr);
    const schema = object().shape({
        name: string().required(),
        lastname: string().required(),
        password: string().min(8).required(),
        email: string().email().required(),
        telephone: string().required(),
        fonction: string().notRequired(),
    });

    const onDismiss = () => {
        navigate('', { replace: true });
    };

    const onSubmit = async (values: any) => {
        let onlyFilledValues: any = removeEmpty(values);
        const res: any = await dispatch(addOneUser({ ...onlyFilledValues, employeur: extra, roles: 'chauffeur' }));

        if ( !res.error ) {
            dispatch(setSuccess(`L'utilisateur a bien été créé`));
            if ( keepIdInState ) dispatch(updateCreatedFromModal({ entity: 'chauffeur', id: res.payload.id }));
            onDismiss();
        }
    };

    return (
        <Dialog open>
            <DialogTitle>Créer un chauffeur</DialogTitle>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
                {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isValid,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    } =
                        props;
                    return (
                        <>
                            <Form onSubmit={handleSubmit}>
                                <DialogContent sx={{ minWidth: 400 }}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label="Prénom"
                                                error={!!errors.name && !!touched.name}
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.name && errors.name}
                                                variant="outlined"
                                                required
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label="Nom"
                                                error={!!errors.lastname && !!touched.lastname}
                                                name="lastname"
                                                value={values.lastname}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.lastname && errors.lastname}
                                                variant="outlined"
                                                required
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label="Mot de passe"
                                                error={!!errors.password && !!touched.password}
                                                name="password"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.password && errors.password}
                                                variant="outlined"
                                                type="password"
                                                required
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                error={!!errors.email && !!touched.email}
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.email && errors.email}
                                                type="email"
                                                variant="outlined"
                                                
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label="Téléphone"
                                                error={!!errors.telephone && !!touched.telephone}
                                                name="telephone"
                                                value={values.telephone}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.telephone && errors.telephone}
                                                variant="outlined"
                                                
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label="Fonction / poste"
                                                error={!!errors.fonction && !!touched.fonction}
                                                name="fonction"
                                                value={values.fonction}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.fonction && errors.fonction}
                                                variant="outlined"
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={onDismiss}>Annuler</Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        loading={isSubmitting}
                                        disabled={!dirty || !isValid}
                                    >
                                        Confirmer
                                    </LoadingButton>
                                </DialogActions>
                            </Form>
                        </>
                    );
                }}
            </Formik>
        </Dialog>
    );
}

export default ChauffeurModal;
