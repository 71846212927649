import React, { useReducer } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { IAdresse } from "../../../../App/core/entreprise/entities/adresse.entity";

export function AddAdresseFacturationModale(props: { displayDialog: boolean, closeDialog: () => void, submit: (adresseFacturation: IAdresse) => void }) {

    const [formState, formDispatch] = useReducer(
        reducer,
        { rue: '', ville: '', cp: '' } as IAdresse
    )

    function handleChange(type: "STREET" | 'CITY' | 'POSTAL_CODE') {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            formDispatch({ type, payload: event.target.value })
        }
    }

    return <Dialog open={props.displayDialog} onClose={props.closeDialog}>
        <DialogTitle>Ajouter une adresse de facturation</DialogTitle>
        <Divider/>
        <DialogContent>
            <form>
                <Stack spacing={4}>
                    <TextField label="rue"
                               onChange={handleChange('STREET')}
                               value={formState.rue}/>
                    <TextField label="code postal"
                               onChange={handleChange('POSTAL_CODE')}
                               value={formState.cp}/>
                    <TextField label="ville"
                               onChange={handleChange('CITY')}
                               value={formState.ville}/>
                    
                </Stack>
            </form>
        </DialogContent>

        <DialogActions>
            <Button onClick={props.closeDialog}>Annuler</Button>
            <LoadingButton type="submit" variant="contained" color="primary" onClick={() => props.submit(formState)}>
                Confirmer
            </LoadingButton>
        </DialogActions>

    </Dialog>
}


function reducer(state: IAdresse, action: { type: string, payload: string }) {
    switch (action.type) {
        case "STREET":
            return { ...state, rue: action.payload };
        case "CITY":
            return { ...state, ville: action.payload };
        case "POSTAL_CODE":
            return { ...state, cp: action.payload };
        default:
            return state;
    }
}