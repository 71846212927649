import { EntityId } from '@reduxjs/toolkit';
import httpAdapter, { _apiUrlFactory } from '../../../_config/api-provider';
import { InterlocuteurEntity } from '../../core/interlocuteur/entities/interlocuteur.entity';

const httpService = httpAdapter();

const prefix = 'entreprise';
const apiInterlocuteurUrl = _apiUrlFactory(prefix);

interface InterlocuteurApi {
    _id: string;
    name: string;
    lastname: string;
    entreprise: {
        id: string;
        name?: string;
        contact?: {
            email: string;
            telephone: string;
        }
    };
    job: string;
    contact: { email: string, telephone: string, portable: string }
}

type CreateInterlocuteurDTO = Omit<InterlocuteurApi, '_id' | 'contact'> & { email: string, phone: string }


export const all = async (): Promise<InterlocuteurEntity[]> => {
    const interlocuteurs = await httpService.get<InterlocuteurApi[]>(apiInterlocuteurUrl("all-interlocuteurs"))
    return interlocuteurs.map((i) => _mapToEntity(i));
}

export const add = async (interlocuteur: InterlocuteurEntity): Promise<Partial<InterlocuteurEntity>> => {
    const createdInterlocuteur = await httpService.post(apiInterlocuteurUrl(`${interlocuteur.employeur}/interlocuteur`), _fromEntity(interlocuteur));
    return _mapToEntity(createdInterlocuteur);

};

export const _delete = async (id: EntityId): Promise<EntityId> => await httpService.delete(apiInterlocuteurUrl(`interlocuteur/${id}`));

export const update = async (entrepriseId: string, interlocuteur: InterlocuteurEntity): Promise<InterlocuteurEntity> => {
    const updatedInterlocuteur = await httpService.put(apiInterlocuteurUrl(`${entrepriseId}/interlocuteur/${interlocuteur.id}`), _fromEntity(interlocuteur));
    return _mapToEntity(updatedInterlocuteur)
};

const _mapToEntity = (interlocuteur: InterlocuteurApi): InterlocuteurEntity => {
    return {
        id: interlocuteur._id,
        nom: interlocuteur.lastname,
        prenom: interlocuteur.name,
        employeur: interlocuteur.entreprise,
        fonction: interlocuteur.job,
        email: interlocuteur.contact.email,
        telephone: interlocuteur.contact.telephone,
        isActive: true
    };
}

const _fromEntity = (interlocuteur: InterlocuteurEntity): CreateInterlocuteurDTO => {
    return {
        name: interlocuteur.prenom,
        lastname: interlocuteur.nom,
        entreprise: interlocuteur.employeur,
        job: interlocuteur.fonction,
        email: interlocuteur.email,
        phone: interlocuteur.telephone,
    }
}