import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import { EntrepriseEntity as Entreprise } from '../entities/'

export const updateOneEntreprise = createAsyncThunk(
	'[ENTREPRISE] updateOneEntreprise',
	async (entrepriseDto: Update<Entreprise>, thunk: any): Promise<Update<Entreprise>> => {
		try {
			const { ...entreprise } = entrepriseDto;
			return await thunk.extra.entrepriseQuery.update(entreprise);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);
