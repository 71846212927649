import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';
import { Avoir } from '../../Model';



export const avoirToUse = createAsyncThunk(
	'[FACTURE] avoirToUse',
	async (params: Avoir, thunk: any): Promise<any> => {
		try {
			return await thunk.extra.factureQuery.avoirToUse(params);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);