import React from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';

import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { Edit } from '@mui/icons-material';

interface Props {
  id: EntityId;
  measuredRef: any;
}

const ArticleTableActionsCell = ({ id, measuredRef }: Props) => {
  const navigate = useNavigate();

  const onClickEdit = () => navigate(`../${id}`, { state: id });

  return (
    <>
      {/* Buttons */}
      <Stack direction="row">
        {/* Use ref here to dynamically get the size of the div and use the same in column */}
        <Box ref={measuredRef}>
          <Tooltip title="Editer">
            <IconButton aria-label="editer" color="primary" size="small" onClick={onClickEdit}>
              <Edit />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
    </>
  );
};

export default ArticleTableActionsCell;
