import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';

import { Stack, Button } from '@mui/material';

import { CustomTable } from '../../../common/custom-table';
import { ColumnEntity } from '../../../common/custom-table/Table.entities';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import CategorieProduitTableActionsCell from './CategorieProduitTableActionsCell';
import { deleteOneCategorieProduit, retrieveAllCategorieProduit } from '../../../../core/categorie-produit/use-cases';
import CategorieProduitAddDialog from './CategorieProduitAddDialog';
import { CategorieProduit } from '../../../../core/categorie-produit/entities';
import { selectAllCategorieProduit } from '../../../../core/categorie-produit/selectors';
import { setSuccess } from '../../../../core/notification/notification.slice';
import ConfirmationDialog from '../../../common/modals/components/ConfirmationDialog';
import useDocumentTitle from '../../../../../_config/react/useDocumentTitle';

const CategorieProduitTable = () => {

    useDocumentTitle('MTTP - Catégories articles');

    const [isLoading, setIsLoading] = useState(true);
    const [categorieDialogOpen, setCategorieDialogOpen] = useState(false);
    const [categorieId, setCategorieId] = useState('');
    const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [initialValues, setInitialValues] = useState({
        label: '',
        tva: '',
        delai_de_facturation: '30', // 30 days by default
    });

    const dispatch = useAppDispatch();
    const categorieProduits = useSelector(selectAllCategorieProduit);

    useEffect(() => {
        dispatch(retrieveAllCategorieProduit())
            .then(() => setIsLoading(false));

        return () => {
            setIsLoading(true);
        };
    }, [dispatch, setIsLoading]);

    const onRowDoubleClick = (values: CategorieProduit) => {
        const { _id, ...otherProps } = values;
        setCategorieId(values._id);
        setInitialValues(otherProps);

        setCategorieDialogOpen(true);
    };

    const onSelectRows = () => {
    };

    const onClickEdit = (id: EntityId) => {
        let categorieById = categorieProduits.find((t) => t._id === id);
        if ( categorieById ) {
            const { _id, ...otherProps } = categorieById;
            setCategorieId(_id);
            setInitialValues(otherProps);
        }

        setCategorieDialogOpen(true);
    };

    const onClickDelete = (id: string) => {
        setConfirmationDialogOpen(true);
        setCategorieId(id);
    };

    const onConfirmDeleteDialog = async () => {
        const res: any = await dispatch(deleteOneCategorieProduit(categorieId));

        setDeleteButtonLoading(true);

        if ( !res.error ) {
            dispatch(setSuccess(`La catégorie a bien été supprimée`));
            setConfirmationDialogOpen(false);
        }

        setDeleteButtonLoading(false);
        setCategorieId('');
    };

    const onCloseCategorieDialog = () => {
        setCategorieId('');
        setCategorieDialogOpen(false);

        setTimeout(() => {
            setInitialValues({ label: '', tva: '', delai_de_facturation: '' });
        }, 200);
    };

    const columns: ColumnEntity[] = [
        { field: 'label', headerName: 'Libellé', width: 210, sortable: true, resizable: true },
        { field: 'tva', headerName: 'TVA', width: 70, sortable: true, resizable: true },
        {
            field: 'delai_de_facturation',
            headerName: 'Délai de facturation',
            width: 170,
            sortable: true,
            resizable: true
        },
    ];

    return (
        <>
            <Stack direction="row" gap={6}>
                <CustomTable
                    data={categorieProduits}
                    columns={columns}
                    loading={isLoading}
                    hover={false}
                    width={600}
                    onRowDoubleClick={onRowDoubleClick}
                    onSelectRows={onSelectRows}
                    renderActionCell={(rowData, measuredRef) => (
                        <CategorieProduitTableActionsCell
                            id={rowData._id}
                            measuredRef={measuredRef}
                            onClickEdit={onClickEdit}
                            onClickDelete={onClickDelete}
                        />
                    )}
                />
                <Stack direction="column" gap={2}>
                    <Button variant="outlined" color="primary" onClick={() => setCategorieDialogOpen(true)}>
                        Ajouter
                    </Button>
                </Stack>
            </Stack>
            {/* Dialogs */}
            <CategorieProduitAddDialog
                open={categorieDialogOpen}
                onCloseDialog={onCloseCategorieDialog}
                initialValues={initialValues}
                categorieId={categorieId}
            />

            <ConfirmationDialog
                title="Supprimer la catégorie"
                contentText="Êtes-vous sûr de vouloir supprimer cette catégorie ?"
                isButtonLoading={deleteButtonLoading}
                open={confirmationDialogOpen}
                onConfirm={onConfirmDeleteDialog}
                onClose={() => {
                    setCategorieId('');
                    setConfirmationDialogOpen(false);
                }}
            />
        </>
    );
};

export default CategorieProduitTable;
