import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { EntityId } from '@reduxjs/toolkit';

import { Button, Stack } from '@mui/material';
import { Add } from '@mui/icons-material';

import { CustomTableToolbar } from '../../../common/custom-table';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { updateFilter, updateSearch } from '../../../../core/article/article.slice';
import DropDownButton from '../../../common/DropDownButton';
import { useSelector } from 'react-redux';
import { selectAllCategoriesLabelsAndIds } from '../../../../core/categorie-produit/selectors';
import { SearchBar } from "./SearchBar";

interface Props {
    selectedRows: EntityId[];
}

function ArticleTableToolbar({ selectedRows }: Props) {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');

    useEffect(() => {
        dispatch(updateSearch(search));
    }, [dispatch, search]);

    const categories = useSelector(selectAllCategoriesLabelsAndIds);
    const localCategories = [{ value: '', label: 'Tous les articles' },
        ...categories.map((c) => ({
            value: c.label,
            label: c.label
        }))
    ];


    const onChangeFilter = (selected: string) => dispatch(updateFilter(selected));

    return (
        <CustomTableToolbar>
            <Stack direction="row" alignItems="flex-end" spacing={2}>
                <SearchBar onChange={setSearch}/>
                <DropDownButton labels={localCategories} onChange={onChangeFilter}/>
            </Stack>
            <Stack direction="row" spacing={4} alignItems="end">
                <Button component={Link} to="create" variant="contained" color="secondary" startIcon={<Add/>}>
                    Nouveau Article
                </Button>
            </Stack>
        </CustomTableToolbar>
    );
}

export default ArticleTableToolbar;
