import React, { useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export function BonDeCommandeValidationDialog(props: {
    open: boolean,
    onClose: () => void,
    onConfirmAction: (numeroFactureFournisseur: string) => void,
    isButtonLoading?: boolean,
    numeroFacture: string
}) {
    const [numeroDeFactureFournisseur, setNumeroDeFactureFournisseur] = React.useState(props.numeroFacture);
    useEffect(() => {
        setNumeroDeFactureFournisseur(props.numeroFacture)
    }, [props.numeroFacture])

    return <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle id="alert-dialog-title">Valider un bon de commande</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">Renseigner le numero de facture du fournisseur pour valider
                le bon de commande</DialogContentText>
            <br/>
            <TextField
                fullWidth
                label="Numero de facture fournisseur"
                value={numeroDeFactureFournisseur}
                onChange={(e) => {
                    setNumeroDeFactureFournisseur(e.target.value)
                }}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose}>Annuler</Button>
            <LoadingButton
                autoFocus variant="contained"
                loading={props.isButtonLoading}
                onClick={() => props.onConfirmAction(numeroDeFactureFournisseur)}>
                Confirmer
            </LoadingButton>
        </DialogActions>


    </Dialog>;
}