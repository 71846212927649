import { EntityId, Update } from '@reduxjs/toolkit';
import httpClient, {  _apiUrlFactory } from '../../../_config/api-provider';
import { Planning as PlanningEntity } from '../../Domain/Planning/entities/planning.entity';

const httpService = httpClient();

const prefix = 'planning';
const apiPlanningUrl = _apiUrlFactory(prefix);
const apiBonUrl = _apiUrlFactory('bon');

type Consignes = {
    consignes: {
        mttp?: string;
        chauffeur?: string;
        evenement?: string;
    }
}

export const all = async () => {
    const planning = await httpService.get(apiPlanningUrl(''));
    return planning.map((p: PlanningEntity & Consignes) => _mapToEntity(p));
};

export const getByDate = async (seekingDate: string) => {
    const searchParam = seekingDate ? `?search=${seekingDate}` : '';
    const planning = await httpService.get(apiPlanningUrl() + searchParam);
    return planning.map((p: PlanningEntity & Consignes) => _mapToEntity(p));
};

export const add = async (planning: PlanningEntity): Promise<Partial<PlanningEntity>> => {
    return await httpService.post(apiPlanningUrl(''), planning);
};

export const _delete = async (ids: EntityId[]): Promise<Partial<PlanningEntity>> => {
    return await httpService.delete(apiPlanningUrl(''), { data: { ids } });
};

export const update = async (planning: Update<PlanningEntity>): Promise<PlanningEntity> => {
    return await httpService.put(apiPlanningUrl(`${planning?.id}`), planning.changes);
};

export const addBonsFromPlanning = async (plannings: EntityId[]): Promise<any> => {
    return await httpService.post(apiPlanningUrl(`bon`), { ids: plannings });
}

export const informEntreprises = async ({
                                            id,
                                            hasBeenWarned
                                        }: { id: EntityId, hasBeenWarned: { client: boolean, fournisseur: boolean } }) => {
    const { client, fournisseur } = hasBeenWarned;
    return await httpService.put(apiPlanningUrl(`${id}/procedure`), { client, fournisseur });
}

// using bon API route for attachments, cause it's the same thing
export const attach = async (props: { id: EntityId, file: File }) => {
    const { id, file } = props;
    const formData = new FormData();
    formData.append('document', file);

    return await httpService.post(apiBonUrl(`${id}/attach-file`), formData);
}

export const getAttachment = async (props: { planningId: EntityId, fileId: EntityId }) => {
    const { planningId, fileId } = props;
    return await httpService.get(apiBonUrl(`${planningId}/attach-file/${fileId}`), { responseType: 'blob' });
}

const _mapToEntity = (p: PlanningEntity & Consignes): PlanningEntity => {
    return {
        ...p,
        commentaire_interne: p.consignes?.mttp,
        consignes_chauffeur: p.consignes?.chauffeur,
        evenement: p.consignes?.evenement
    };
};