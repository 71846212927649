import { RootState } from '../../../_config/redux';
import { chantierEntityAdapter } from './chantier.slice';
import ChantierEntity from './entities';

const chantierSelector = chantierEntityAdapter.getSelectors<RootState>((state) => state.chantiers);

export const selectAllChantiers = (state: RootState) => chantierSelector.selectAll(state);

export const selectByClientId = (state: RootState, action: { payload: { clientId: string } }) => {
	const ids = chantierSelector.selectIds(state);
	const entities = chantierSelector.selectEntities(state) as any;

	return ids.reduce((clientChantiersList: ChantierEntity[], chantierId): ChantierEntity[] => {
		return entities[chantierId].client === action.payload.clientId
			? [ ...clientChantiersList, entities[chantierId] ]
			: clientChantiersList;
	}, []);
};

export const selectChantierLabelsAndIdsByClientId = (id: string) => (state: RootState) => {
	const filteredChantiers = selectByClientId(state, { payload: { clientId: id } });

	return filteredChantiers.map((c) => ({
		id: c.id,
		label: c.name
	}));
};

export const selectAllChantiersLabelsAndIds = (state: RootState) => {
	const chantiers = chantierSelector.selectAll(state);
	return chantiers.map(({ id, name }) => ({ id, label: name }));
};
/*
export const selectChantiersByEntrepriseLabelsAndIds = (clientId: string) => (state: RootState) => {
	const chantiers = selectAllChantiers(state);
	return chantiers.filter(chantier => chantier.client === clientId).map(({ id, name }) => ({ id, label: name }));
}*/

export const selectChantiersByEntreprise = (clientId: string) => (state: RootState) => {
	const chantiers = selectAllChantiers(state);
	return chantiers.filter(chantier => chantier.client === clientId);
}