import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { dateFormat } from '../../../App/app/common/_helpers';
import { Planning as PlanningEntity } from './entities/planning.entity';
import {
    retrieveAllPlanning,
    addOnePlanning,
    deletePlanning,
    updateOnePlanning,
    retrievePlanningByDate,
    attachFileToPlanning,
    addBonsFromPlanning,
    informEntreprises
} from './use-cases';

export const planningEntityAdapter = createEntityAdapter<PlanningEntity>({
    selectId: (planning) => planning.id
});

export const planningSlice = createSlice({
    name: 'planning',
    initialState: planningEntityAdapter.getInitialState({
        search: '',
        date: format(new Date(), dateFormat)
    }),
    reducers: {
        updateSearch: (state, action) => {
            state.search = action.payload;
        },
        updateDate: (state, action) => {
            state.date = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveAllPlanning.fulfilled, (state, action) => {
                planningEntityAdapter.setAll(state, action.payload);
            })
            .addCase(retrievePlanningByDate.fulfilled, (state, action) => {
                planningEntityAdapter.setAll(state, action.payload);
            })
            .addCase(addOnePlanning.fulfilled, (state, action) => {
                const { payload } = action;
                planningEntityAdapter.addOne(state, action.payload);
                state.date = payload.date
            })
            .addCase(deletePlanning.fulfilled, (state, action) => {
                let ids = action.meta?.arg;
                planningEntityAdapter.removeMany(state, ids);
            })
            .addCase(updateOnePlanning.fulfilled, (state, action) => {
                planningEntityAdapter.updateOne(state, action.payload);
            })
            .addCase(addBonsFromPlanning.fulfilled, (state, { payload }) => {
                const updatedObjects = payload.map(({ id, bon }: any) => ({
                    id,
                    changes: { hasBon: !!bon.created_at }
                }));
                planningEntityAdapter.updateMany(state, updatedObjects);
            })
            .addCase(attachFileToPlanning.fulfilled, (state, { payload }) => {
                // updating state of bon, adding uploaded document
                const { _id, mimetype, originalname, on } = payload;
                const planning = state.entities[on];

                if ( planning ) {
                    if ( !planning.docs ) planning.docs = [];
                    planning.docs.push({ _id, mimetype, originalname });
                }
            })
            .addCase(informEntreprises.fulfilled, (state, action: any) => {
                const { payload } = action;

                planningEntityAdapter.updateOne(state, {
                    id: payload.id, changes: {
                        hasBeenWarned: {
                            client: payload.bon?.procedure?.client?.knows_about_bon,
                            fournisseur: payload.bon?.procedure?.fournisseur?.knows_about_bon
                        }
                    }
                })
            })
    }
});

export const { updateSearch, updateDate } = planningSlice.actions;
export default planningSlice.reducer;
