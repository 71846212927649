import React from 'react';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItemText,
  ListSubheader,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectChantiersByEntreprise } from '../../../../core/chantier/selectors';
import useDocumentTitle from '../../../../../_config/react/useDocumentTitle';

export const EntrepriseManageChantiersList = ({ entreprise, onClickAdd, onClickDelete, onClickUpdate }: any) => {
  const chantiers = useSelector(selectChantiersByEntreprise(entreprise?.id));

  useDocumentTitle('MTTP - Chantier entreprise');
  
  return (
    <List
      subheader={
        <ListSubheader>
          <Stack direction="row" justifyContent="space-between" my={1}>
            <Typography variant="h6">Chantiers</Typography>
            <Tooltip title="Ajouter un chantier">
              <Button variant="outlined" color="secondary" size="small" onClick={onClickAdd}>
                <Add />
              </Button>
            </Tooltip>
          </Stack>
        </ListSubheader>
      }
      sx={{ px: 4, py: 2 }}
    >
      {!!chantiers.length ? (
        chantiers.map((c) => (
          <div key={c.id}>
            <Divider />
            <Stack direction="row" alignItems="center" justifyContent="space-between" m={1}>
              <Box>
                <ListItemText
                  primary={c.name}
                  secondary={`${c.adresse ? `${c.adresse?.rue}, ${c.adresse?.cp} - ${c.adresse?.ville}` : ''}`}
                />
              </Box>
              <Stack direction="row">
                <Tooltip title="Mettre à jour">
                  <IconButton size="medium" onClick={() => onClickUpdate(c)}>
                    <Edit color="secondary" sx={{ fontSize: 24 }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer">
                  <IconButton size="medium" onClick={() => onClickDelete(c)}>
                    <Delete color="error" sx={{ fontSize: 24 }} />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          </div>
        ))
      ) : (
        <Typography variant="body1">Aucune donnée...</Typography>
      )}
    </List>
  );
};
