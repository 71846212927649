import { createAsyncThunk } from "@reduxjs/toolkit";
import type ArticleEntity from "../entities";

interface ExtraArgs {
  // extra: { articleQuery: { all: () => Promise<any> } }
  extra: any
}

export const retreiveAllArticles = createAsyncThunk<ArticleEntity[], void, ExtraArgs>(
  "[ARTICLE] retreiveAll",
  async (_, thunk) => {
      try {
      return await thunk.extra.articleQuery.all()
  } catch (error: any) {
      return thunk.rejectWithValue({ message: error.message ?? null });
  }
})