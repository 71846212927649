import { createAsyncThunk } from '@reduxjs/toolkit';

export const generateFile = (id: string) => genFile(id);

const genFile = createAsyncThunk<any, string, { extra: { factureQuery: { generateFile: (id: string) => Promise<any> } } }>(
    "[FACTURE] generateDocument",
    async (id, thunkApi) => {
        return thunkApi.extra.factureQuery.generateFile(id);
    }
)
