import React, { useState } from "react";
import { useSelector } from "react-redux";
import { EntityId } from "@reduxjs/toolkit";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, ListSubheader, Stack, Tooltip, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { selectEntrepriseById } from "../../../App/core/entreprise/selectors";
import PageHeaderBar from "../../../App/app/common/layout/components/PageHeaderBar";
import { ViewCard } from "../../../App/app/common/layout/components";
import { CategoryList } from "../components/reglements/list-of-categories";
import useDocumentTitle from "../../../_config/react/useDocumentTitle";

export function ManageReglements() {
    useDocumentTitle('MTTP - Règlements');
    const { id: entrepriseId } = useParams();
    const uneEntreprise = useSelector(selectEntrepriseById(entrepriseId as EntityId));
    if ( !uneEntreprise ) throw new Error('une entreprise doit etre sélectionnée');

    const navigate = useNavigate();
    const location = useLocation();

    const [value, setValue] = useState<'client' | 'fournisseur'>('fournisseur')
    const onClickAdd = () => {
        navigate('?modal=reglement', {
            state: {
                backgroundLocation: location,
                extra: uneEntreprise?.id,
                reglementType: value,
                keepIdInState: false
            },
            replace: true,
        });
    };

    return <>
        <PageHeaderBar title={"Gestion des reglements de " + uneEntreprise?.name}/>
        <ViewCard>

            <ListSubheader>
                <Stack direction="row" justifyContent="space-between" my={1}>
                    <Typography variant="h6">Reglements</Typography>
                    <Tooltip title="Ajouter un reglement">
                        <Button variant="outlined" color="secondary" size="small" onClick={onClickAdd}>
                            <Add/>
                        </Button>
                    </Tooltip>
                </Stack>
            </ListSubheader>

            <CategoryList
                uneEntreprise={uneEntreprise}
                reglementType={value}
                changeTypeOfReglement={setValue}
            />
        </ViewCard>
    </>
}

