import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';

interface AvoirGenerationPayload {
    aFactureId: EntityId
    anAvoirId: EntityId
}

export const generateAvoirFile = (factureId: EntityId, avoirId: EntityId) => generateAvoirDocument({
    aFactureId: factureId, anAvoirId: avoirId
})

export const generateAvoirDocument = createAsyncThunk<any, AvoirGenerationPayload, { extra: { factureQuery: { generateAvoirFile: (avoirId: EntityId, factureId: EntityId,) => Promise<any> } } }>(
    "[AVOIR] GenerateDocument",
    async ({ aFactureId, anAvoirId }, thunkAPI) => {
        return thunkAPI.extra.factureQuery.generateAvoirFile(anAvoirId, aFactureId);
    }
)

