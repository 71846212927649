import { createAsyncThunk } from '@reduxjs/toolkit';
import { BonEntity } from '../entities';

interface Options {
    filters?: object;
    fromDate?: string;
    toDate?: string;
    query?: string;
}

export const retrieveBons = createAsyncThunk(
    '[BON] retrieveBons',
    async (options: Options, thunk: any): Promise<BonEntity[]> => {
        try {
            return thunk.extra.bonQuery.get(options);
        } catch (error: any) {
            return thunk.rejectWithValue({ message: error.message ?? null });
        }
    }
);
